import React, {Component} from "react";
import {Select, Modal, Input, Checkbox, Button, Row, Col, Upload, DatePicker} from 'antd';
import { Form } from '@ant-design/compatible';
import {bindActionCreators} from "redux";
import * as UsersActions from "../redux/Users/actions";
import {connect} from "react-redux";
import {InboxOutlined, MinusCircleOutlined, PlusOutlined} from "@ant-design/icons";
import moment from "moment/moment";


const FormItem = Form.Item;
const Option = Select.Option;
const confirm = Modal.confirm;
let BASE_URL = process.env.REACT_APP_API_URL;
let API_VERSION = process.env.REACT_APP_API_VERSION;

let uuid = 0;


const formItemLayout = {
    labelCol: {
        xs: { span: 20 },
        sm: { span: 6 },
    },
    wrapperCol: {
        xs: { span: 20 },
        sm: { span: 13 },
    },
};


const formItemLayoutWithOutLabel = {
    wrapperCol: {
        xs: { span: 24, offset: 0 },
        sm: { span: 18, offset: 6 },
    },
};


class UsersDetailModal extends Component {


    componentWillMount() {
    }

    handleSubmit = (e) => {
        e.preventDefault();
        this.props.form.validateFieldsAndScroll((err, values) => {
            if (!err) {
                this.props.actions.saveUserDetail(values, this.props.UsersDetailsUserID);
            }
        });
    };


    componentWillReceiveProps(nextProps) {
        if(this.props.visible && !nextProps.visible) {
            this.props.form.resetFields();
            this.props.actions.resetDetail();
        } else if(!this.props.visible && nextProps.visible && nextProps.UsersDetailsUserID) {
            this.props.actions.LoadUsersDetails(nextProps.UsersDetailsUserID);
        }
    }

    confirmDelete = () => {
        confirm({
            title: "Benutzer löschen",
            content: "Wollen Sie diesen Benutzer wirklich löschen?",
            okText: "Löschen",
            okType: 'danger',
            cancelText: "Abbrechen",
            onOk: () => this.props.actions.deleteUser(this.props.UsersDetailsUserID),
            onCancel() {
            },
        });
    };


    removeHandedOut = (k) => {
        const { form } = this.props;
        const keys = form.getFieldValue('keys');
        form.setFieldsValue({
            keys: keys.filter(key => key !== k),
        });
    };

    addHandedOut = () => {
        const { form } = this.props;
        const keys = form.getFieldValue('keys');
        const nextKeys = keys.concat(uuid);
        uuid++;

        form.setFieldsValue({
            keys: nextKeys,
        });
    };

    normSingleFile = (e) => {
        if (Array.isArray(e)) {
            return e;
        }
        return e && e.fileList.slice(-1);
    };


    render() {
        const { getFieldDecorator } = this.props.form;

        getFieldDecorator('keys', { initialValue: this.props.User.handouts ? this.props.User.handouts.map((c, i) => {return i;} ) : []} );
        const keys = this.props.form.getFieldValue('keys');

        const groupId = this.props.form.getFieldValue('GroupID');

        if(this.props.User.handouts && uuid < this.props.User.handouts.length) { uuid = this.props.User.handouts.length; }

        
        let formItems = keys.map((k, index) => {
            getFieldDecorator(`handouts[${k}].HandoutID`, { initialValue: this.props.User.handouts ? this.props.User.handouts[k] ? this.props.User.handouts[k].HandoutID : -1 : -1 });
            return (
                <Row gutter={10}>
                    <Col span={2}>&nbsp;</Col>
                    <Col span={17} style={{textAlign: 'center'}}>
                        <FormItem
                            {...formItemLayoutWithOutLabel}
                            label={''}
                            required={false}
                            key={k+"title"}
                        >
                            {getFieldDecorator(`handouts[${k}].title`, {
                                validateTrigger: ['onChange', 'onBlur'],
                                initialValue: this.props.User.handouts ? this.props.User.handouts[k] ? this.props.User.handouts[k].title : "" : "",
                                rules: [{
                                    required: true,
                                    message: "Bitte geben Sie einen Titel ein, oder entfernen Sie dieses Feld",
                                }],
                            })(
                                <Input placeholder="Titel" style={{ width: '100%', marginRight: 8 }} />
                            )}
                        </FormItem>
                    </Col>

                    <Col span={4}><MinusCircleOutlined
                        className="dynamic-delete-button"
                        style={{marginTop: '11px'}}
                        onClick={() => this.removeHandedOut(k)}
                    /></Col>

                    <Col span={2}>&nbsp;</Col>
                    <Col span={17} style={{textAlign: 'center'}}>
                        <FormItem
                            {...formItemLayoutWithOutLabel}
                            label={''}
                            required={false}
                            key={k+'upload'}
                        >
                            {getFieldDecorator(`handouts[${k}].files`, {
                                validateTrigger: ['onChange', 'onBlur'],
                                initialValue: this.props.User.handouts ? this.props.User.handouts[k] ? this.props.User.handouts[k].files : [] : [],
                                valuePropName: 'fileList',
                                getValueFromEvent: this.normSingleFile,
                            })(
                                <Upload.Dragger name="files" action={BASE_URL+'api/'+API_VERSION+"/users/"+(this.props.UsersDetailsUserID ? this.props.UsersDetailsUserID : "new")+"/Upload"} headers={{authorization: `Bearer ${localStorage.getItem('id_token') || null}`}}  listType={'picture'}  multiple={false}>
                                    <p className="ant-upload-drag-icon">
                                        <InboxOutlined />
                                    </p>
                                    <p className="ant-upload-text">Hier klicken oder Datei in diesen Bereich ziehen</p>
                                    <p className="ant-upload-hint">Es werden alle Dateien unterstützt.</p>
                                </Upload.Dragger>
                            )}
                        </FormItem>


                    </Col>

                    <Col span={4}>&nbsp;</Col>
                    <Col span={2}>&nbsp;</Col>

                    <Col span={17} style={{textAlign: 'center'}}>

                        <FormItem
                            {...formItemLayoutWithOutLabel}
                            label={''}
                            required={false}
                            key={k+"description"}
                        >
                            {getFieldDecorator(`handouts[${k}].description`, {
                                validateTrigger: ['onChange', 'onBlur'],
                                initialValue: this.props.User.handouts ? this.props.User.handouts[k] ? this.props.User.handouts[k].description : "" : "",
                                rules: [{
                                    required: false,
                                }],
                            })(
                                <Input.TextArea rows={4} placeholder="Beschreibung (optional)" style={{ width: '100%', marginRight: 8 }} />
                            )}
                        </FormItem>
                    </Col>
                    <Col span={4}>&nbsp;</Col>
                </Row>
            );
        });


        return (
                <Modal
                    title={`Benutzer ${this.props.UsersDetailsUserID ? 'bearbeiten' : 'anlegen'}`}
                    visible={this.props.visible}
                    onOk={this.handleSubmit}
                    onCancel={this.props.handleCancel}
                    cancelText={"Abbrechen"}
                    okText={"Speichern"}
                    width={"1024px"}
                    footer={[
                        this.props.UsersDetailsUserID ? <Button key="deleteuser" type="danger" loading={this.props.deletingUser} onClick={this.confirmDelete}>
                            Benutzer löschen
                        </Button> : '',
                        <Button key="cancel" onClick={this.props.handleCancel}>Abbrechen</Button>,
                        <Button key="submit" type="primary" loading={this.props.savingUsers} onClick={this.handleSubmit}>
                            Speichern
                        </Button>
                    ]}
                >



                    <form onSubmit={this.handleSubmit} style={{width: '100%'}}>

                        <FormItem
                            {...formItemLayout}
                            label={"Vorname"}
                            required={true}
                            key={'Vorname'}
                        >
                            {getFieldDecorator('Vorname', {
                                initialValue: this.props.User.Vorname,
                                rules: [{
                                    required: true,
                                    whitespace: true,
                                    message: "Bitte geben Sie diese Information an."
                                }],
                            })(
                                <Input type={"text"}/>
                            )}
                        </FormItem>
                        <FormItem
                            {...formItemLayout}
                            label={"Nachname"}
                            required={true}
                            key={'Nachname'}
                        >
                            {getFieldDecorator('Nachname', {
                                initialValue: this.props.User.Nachname,
                                rules: [{
                                    required: true,
                                    whitespace: true,
                                    message: "Bitte geben Sie diese Information an."
                                }],
                            })(
                                <Input type={"text"}/>
                            )}
                        </FormItem>


                        {groupId !== 4 ? <FormItem
                            {...formItemLayout}
                            label={"Benutzername"}
                            required={true}
                            key={'Username'}
                        >
                            {getFieldDecorator('Username', {
                                initialValue: this.props.User.Username,
                                rules: [{
                                    required: true,
                                    whitespace: true,
                                    message: "Bitte geben Sie diese Information an."
                                }],
                            })(
                                <Input type={"text"}/>
                            )}
                        </FormItem> : null}


                        {groupId !== 4 ? <FormItem
                            {...formItemLayout}
                            label={"Kennwort"}
                            required={(this.props.UsersDetailsUserID ? false : true)}
                            key={'Password'}
                        >
                            {getFieldDecorator('Password', {
                                rules: [{
                                    required: (this.props.UsersDetailsUserID ? false : true),
                                    whitespace: true,
                                    message: "Bitte geben Sie diese Information an."
                                }],
                            })(
                                <Input type={"password"} placeholder={`${this.props.UsersDetailsUserID ? '(unverändert)' : ''}`}/>
                            )}
                        </FormItem> : null}



                        <FormItem
                            {...formItemLayout}
                            label={"Gruppe"}
                            required={true}
                            key={'GroupID'}
                        >
                            {getFieldDecorator('GroupID', {
                                initialValue: this.props.User.GroupID,
                                rules: [{
                                    required: true,
                                    whitespace: false,
                                    message: "Bitte wählen Sie eine Gruppe aus.",
                                    type: 'number'
                                }],
                            })(
                                <Select
                                    showSearch
                                    style={{ width: '100%' }}
                                    placeholder="Wählen Sie eine Gruppe"
                                    optionFilterProp="children"
                                    filterOption={(input, option) => option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
                                >
                                    {this.props.UsersGroups.map(group => <Option key={group.GroupID} value={group.GroupID}>{group.Group}</Option>)}
                                </Select>
                            )}
                        </FormItem>


                        <FormItem {...formItemLayout}
                                  label="Geburtstag:"
                                  key={"Geburtstag"}
                        >{getFieldDecorator("Geburtstag", {
                            rules: [{
                                required: false,
                            }],
                            initialValue: this.props.User["Geburtstag"] ? moment(this.props.User["Geburtstag"]) : null,
                        })(
                            <DatePicker placeholder={"Datum auswählen"} format={"DD.MM.YYYY"} style={{width: '100%'}}/>)}
                        </FormItem>

                        <FormItem
                            {...formItemLayout}
                            label={"Aktiv"}
                            required={false}
                            key={'Active'}
                        >
                            {getFieldDecorator('Active', {
                                initialValue: this.props.User.Active === undefined ? 1 : this.props.User.Active,
                                valuePropName: 'checked',
                                rules: [{
                                    required: false
                                }],
                            })(
                                <Checkbox />
                            )}
                        </FormItem>

                        <FormItem {...formItemLayout} label={"Ausgehändigt"}>
                        </FormItem>
                        {formItems}
                        <FormItem {...formItemLayoutWithOutLabel}>
                            <Button type="dashed" onClick={this.addHandedOut} style={{ width: '77%' }}>
                                <PlusOutlined /> Hinzufügen
                            </Button>
                        </FormItem>

                    </form>


                </Modal>


        );
    }
}



function mapStateToProps(state) {
    const { UsersReducer } = state;
    const { User, UsersDetailsUserID, UsersGroups, savingUsers, keysProject, allProjects, deletingUser } = UsersReducer;
    return {
        User,
        UsersDetailsUserID,
        UsersGroups,
        savingUsers,
        keysProject,
        allProjects,
        deletingUser
    };
}


function mapDispatchToProps(dispatch) {
    return{
        actions: bindActionCreators(UsersActions, dispatch)
    };
}



export default connect(mapStateToProps, mapDispatchToProps)(Form.create()(UsersDetailModal));


//export default Form.create()(UsersDetailModal);