import Auth from './auth/reducer';
import App from './app/reducer';
import RoomPlanReducer from './RoomPlan/reducer';
import CheckInReducer from './CheckIn/reducer';
import PersonsReducer from './Personen/reducer';
import UsersReducer from './Users/reducer';
import SettingsReducer from './Settings/reducer';
import HausverboteReducer from './Hausverbote/reducer';
import KleidungReducer from './Arbeitskleidung/reducer';
import AufgabenReducer from './Aufgaben/reducer';
import RechnungenReducer from './Rechnungen/reducer';
import HolidayReducer from './Holiday/reducer';
import MaschinenReducer from './Maschinen/reducer';
import ReinigungReducer from './Reinigung/reducer';
import TodoRezeptionReducer from "./TodoRezeption/reducer";
import LustmolcheReducer from "./Lustmolche/reducer";

export default {
  Auth,
  App,
    RoomPlanReducer,
    CheckInReducer,
  UsersReducer,
  SettingsReducer,
  HausverboteReducer,
  KleidungReducer,
  PersonsReducer,
  AufgabenReducer,
  RechnungenReducer,
  HolidayReducer,
  MaschinenReducer,
  ReinigungReducer,
  TodoRezeptionReducer,
  LustmolcheReducer,
};
