
const changeThemes = {
  id: 'changeThemes',
  label: 'themeSwitcher',
  options: [
    {
      themeName: 'themedefault',
      buttonColor: '#ffffff',
      textColor: '#323332'
    }
  ]
};
const topbarTheme = {
  id: 'topbarTheme',
  label: 'themeSwitcher.Topbar',
  options: [
    {
      themeName: 'themedefault',
      buttonColor: '#ffffff',
      textColor: '#323332'
    }
  ]
};
const sidebarTheme = {
  id: 'sidebarTheme',
  label: 'themeSwitcher.Sidebar',
  options: [
    {
      themeName: 'themedefault',
      buttonColor: '#323332',
      backgroundColor: undefined,
      textColor: '#788195'
    }
  ]
};
const layoutTheme = {
  id: 'layoutTheme',
  label: 'themeSwitcher.Background',
  options: [
    {
      themeName: 'themedefault',
      buttonColor: '#ffffff',
      backgroundColor: '#F1F3F6',
      textColor: undefined
    }
  ]
};
const customizedThemes = {
  changeThemes,
  topbarTheme,
  sidebarTheme,
  layoutTheme
};
export function getCurrentTheme(attribute) {
  return customizedThemes[attribute].options[0]
}
export default customizedThemes;
