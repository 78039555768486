import React, {Component} from "react";
import {connect} from "react-redux";
import {bindActionCreators} from "redux";
import * as CheckInActions from "../../redux/CheckIn/actions";
import { Input, Button, DatePicker, Table, Row, Col, Radio, Divider, Modal, Dropdown, Menu, Upload } from 'antd';
import Box from '../../components/utility/box';
import Header from '../../components/utility/pageHeader';
import LayoutWrapper from '../../components/utility/layoutWrapper';
import { history } from '../../redux/store';
//import { Document, Page } from 'react-pdf';
//import FileViewer from 'react-file-viewer';
import {DownloadOutlined, UploadOutlined} from "@ant-design/icons";
import { Document, Page, pdfjs } from 'react-pdf';


//pdfjs.GlobalWorkerOptions.workerSrc = 'pdf.worker.min.js';
pdfjs.GlobalWorkerOptions.workerSrc = `//unpkg.com/pdfjs-dist@${pdfjs.version}/build/pdf.worker.min.js`;

let BASE_URL = process.env.REACT_APP_API_URL;
let API_VERSION = process.env.REACT_APP_API_VERSION;


class Doku extends Component {


    onUpload = (e) => {
        if(e.file && e.file.status === "done") {
            window.location.reload();
        }
    };

    onDocumentLoadSuccess = ({ numPages }) => {
        this.setState({ numPages });
    };

    componentWillMount() {
        this.setState({
            numPages: null,
            pageNumber: 1,
            file: {url: BASE_URL+'api/'+API_VERSION+"/doku/"+this.props.match.params.Doku, httpHeaders: { 'authorization': `Bearer ${localStorage.getItem('id_token') || null}` }}
        });
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if(prevProps.match.params.Doku !== this.props.match.params.Doku) {
            this.setState({
                numPages: null,
                pageNumber: 1,
                file: {url: BASE_URL+'api/'+API_VERSION+"/doku/"+this.props.match.params.Doku, httpHeaders: { 'authorization': `Bearer ${localStorage.getItem('id_token') || null}` }}
            });
        }
    }


    render() {

        const { numPages } = this.state;

        return (
                <LayoutWrapper>

                    <Button href={"/app/File/doku/"+this.props.match.params.Doku} target={"_blank"} style={{marginRight: '15px'}}>
                        <DownloadOutlined /> Download
                    </Button>

                    {this.props.isAdmin ?
                    <React.Fragment>
                        <Upload name="files"
                                action={BASE_URL + 'api/' + API_VERSION + "/doku/" + this.props.match.params.Doku + "/Upload"}
                                accept={".pdf"} headers={{authorization: `Bearer ${localStorage.getItem('id_token') || null}`}} onChange={this.onUpload} multiple={true}>
                            <Button>
                                <UploadOutlined /> PDF aktualisieren
                            </Button>
                        </Upload>
                        <br/><br/>
                    </React.Fragment> : ''
                    }

                    <Box>
                        <div style={{textAlign: 'center'}}>
                            {/*<FileViewer
                                fileType={'pdf'}
                                filePath={{url: BASE_URL+'api/'+API_VERSION+"/doku/"+this.props.match.params.Doku, httpHeaders: { 'authorization': `Bearer ${token}` }}}
                                onError={(e) => console.log(e)}/>*/}
                            <Document
                                file={this.state.file}
                                onLoadSuccess={this.onDocumentLoadSuccess}
                                noData={""}
                            >
                                {numPages > 1 ? [...Array(numPages)].map((x,p)=> <Page pageNumber={p+1} />)
                                    :
                                    <Page pageNumber={1} />
                                }
                            </Document>
                        </div>
                    </Box>
                </LayoutWrapper>
        );
    }
}


function mapStateToProps(state) {
    const { CheckInReducer, Auth } = state;
    const { CheckInList, loadingCheckInList, paginationCheckIn, infosTableCheckIn, search, AgreementFormSaving } = CheckInReducer;
    const {isAdmin} = Auth;

    return {
        CheckInList,
        loadingCheckInList,
        paginationCheckIn,
        infosTableCheckIn,
        search,
        AgreementFormSaving,
        isAdmin
    };
}


function mapDispatchToProps(dispatch) {
    return{
        actions: bindActionCreators(CheckInActions, dispatch)
    };
}



export default connect(mapStateToProps, mapDispatchToProps)(Doku);


/*
import React, {Component} from "react";
import {connect} from "react-redux";
import {bindActionCreators} from "redux";
import * as CheckInActions from "../../redux/CheckIn/actions";
import { Icon, Input, Button, DatePicker, Table, Row, Col, Radio, Divider, Modal, Dropdown, Menu, Upload } from 'antd';
import Box from '../../components/utility/box';
import Header from '../../components/utility/pageHeader';
import LayoutWrapper from '../../components/utility/layoutWrapper';
import { history } from '../../redux/store';
import { Document, Page } from "react-pdf/dist/entry.webpack";


let BASE_URL = process.env.REACT_APP_API_URL;
let API_VERSION = process.env.REACT_APP_API_VERSION;
let token = localStorage.getItem('id_token') || null;


class Doku extends Component {


    state = {
        numPages: null,
        pageNumber: 1,
        url: {url: BASE_URL+'api/'+API_VERSION+"/doku/"+this.props.match.params.Doku, httpHeaders: { 'authorization': `Bearer ${token}` }}
    };

    onDocumentLoadSuccess = ({ numPages }) => {
        this.setState({ numPages });
    };

    onUpload = (e) => {
        if(e.file && e.file.status === "done") {
            window.location.reload();
        }
    };



    render() {

        const { numPages } = this.state;

        return (
            <LayoutWrapper>

                {this.props.isAdmin ?
                    <React.Fragment>
                        <Upload name="files"
                                action={BASE_URL + 'api/' + API_VERSION + "/doku/" + this.props.match.params.Doku + "/Upload"}
                                accept={".pdf"} headers={{authorization: `Bearer ${token}`}} onChange={this.onUpload} multiple={true}>
                            <Button>
                                <Icon type="upload"/> PDF aktualisieren
                            </Button>
                        </Upload>
                        <br/><br/>
                    </React.Fragment> : ''
                }
                <Box >

                    <center>
                        <Document
                            file={this.state.url}
                            onLoadSuccess={this.onDocumentLoadSuccess}
                        >
                            {numPages > 1 ? [...Array(numPages)].map((x,p)=> <Page pageNumber={p+1} />)
                                :
                                <Page pageNumber={1} />
                            }
                        </Document>
                    </center>


                </Box>
            </LayoutWrapper>
        );
    }
}


function mapStateToProps(state) {
    const { CheckInReducer, Auth } = state;
    const { CheckInList, loadingCheckInList, paginationCheckIn, infosTableCheckIn, search, AgreementFormSaving } = CheckInReducer;
    const {isAdmin} = Auth;

    return {
        CheckInList,
        loadingCheckInList,
        paginationCheckIn,
        infosTableCheckIn,
        search,
        AgreementFormSaving,
        isAdmin
    };
}


function mapDispatchToProps(dispatch) {
    return{
        actions: bindActionCreators(CheckInActions, dispatch)
    };
}



export default connect(mapStateToProps, mapDispatchToProps)(Doku);*/
