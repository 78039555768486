import {CALL_API} from "../../helpers/middleware";
import HausverboteActions from "../Hausverbote/actions";
import moment from "moment";
import RoomPlanActions from "../RoomPlan/actions";

const PersonActions = {
    LOAD_PERSONS_REQUEST: 'LOAD_PERSONS_REQUEST',
    LOAD_PERSONS_SUCCESS: 'LOAD_PERSONS_SUCCESS',
    LOAD_PERSONS_ERROR: 'LOAD_PERSONS_ERROR',

    LOAD_PERSONS_TABLE_REQUEST: 'LOAD_PERSONS_TABLE_REQUEST',
    LOAD_PERSONS_TABLE_SUCCESS: 'LOAD_PERSONS_TABLE_SUCCESS',
    LOAD_PERSONS_TABLE_ERROR: 'LOAD_PERSONS_TABLE_ERROR',
    
    LOAD_PERSONS_DETAIL_REQUEST: 'LOAD_PERSONS_DETAIL_REQUEST',
    LOAD_PERSONS_DETAIL_SUCCESS: 'LOAD_PERSONS_DETAIL_SUCCESS',
    LOAD_PERSONS_DETAIL_ERROR: 'LOAD_PERSONS_DETAIL_ERROR',

    SET_PERSONS_TABLE_PAGE: 'SET_PERSONS_TABLE_PAGE',
    SET_PERSONS_TABLE_INFO: 'SET_PERSONS_TABLE_INFO',

    SAVE_PERSONS_DETAIL_REQUEST: 'SAVE_PERSONS_DETAIL_REQUEST',
    SAVE_PERSONS_DETAIL_SUCCESS: 'SAVE_PERSONS_DETAIL_SUCCESS',
    SAVE_PERSONS_DETAIL_ERROR: 'SAVE_PERSONS_DETAIL_ERROR',

    SYNC_PERSONS_REQUEST: 'SYNC_PERSONS_REQUEST',
    SYNC_PERSONS_SUCCESS: 'SYNC_PERSONS_SUCCESS',
    SYNC_PERSONS_ERROR: 'SYNC_PERSONS_ERROR',

    SAVE_PERSONS_FILE_NAME_REQUEST: 'SAVE_PERSONS_FILE_NAME_REQUEST',
    SAVE_PERSONS_FILE_NAME_SUCCESS: 'SAVE_PERSONS_FILE_NAME_SUCCESS',
    SAVE_PERSONS_FILE_NAME_ERROR: 'SAVE_PERSONS_FILE_NAME_ERROR',

    SHOW_PERSONS_DETAIL: 'SHOW_PERSONS_DETAIL',
    CLEAR_PERSONS_DETAIL: 'CLEAR_PERSONS_DETAIL',


    SET_SEARCH_TEXT: 'SET_SEARCH_TEXT',
    SHOW_PREVIEW_FILE_MODAL: 'SHOW_PREVIEW_FILE_MODAL'
};

export default PersonActions;



export function loadPersons() {
    return {
        [CALL_API]: {
            reqType: 'GET',
            endpoint: 'persons',
            types: [PersonActions.LOAD_PERSONS_REQUEST, PersonActions.LOAD_PERSONS_SUCCESS, PersonActions.LOAD_PERSONS_ERROR]
        }
    };
}


export function syncPersons() {
    return {
        [CALL_API]: {
            reqType: 'GET',
            endpoint: 'persons/sync',
            types: [PersonActions.SYNC_PERSONS_REQUEST, PersonActions.SYNC_PERSONS_SUCCESS, PersonActions.SYNC_PERSONS_ERROR]
        }
    };
}


export function loadPersonsTable(params = {}) {
    return {
        [CALL_API]: {
            reqType: 'POST',
            endpoint: 'persons/table',
            postData: {
                results: 10,
                ...params,
            },
            types: [PersonActions.LOAD_PERSONS_TABLE_REQUEST, PersonActions.LOAD_PERSONS_TABLE_SUCCESS, PersonActions.LOAD_PERSONS_TABLE_ERROR]
        }
    };
}

export function loadPersonsDetail(id) {
    return {
        [CALL_API]: {
            reqType: 'GET',
            endpoint: 'persons/'+id,
            types: [PersonActions.LOAD_PERSONS_DETAIL_REQUEST, PersonActions.LOAD_PERSONS_DETAIL_SUCCESS, PersonActions.LOAD_PERSONS_DETAIL_ERROR]
        }
    };
}



export function savePersonsDetail(KundenID, values) {

    values = {...values, birthday: values.birthday ? moment(values.birthday).format("YYYY-MM-DD") : undefined};

    return {
        [CALL_API]: {
            reqType: 'PUT',
            endpoint: 'persons/'+KundenID,
            postData: values,
            types: [PersonActions.SAVE_PERSONS_DETAIL_REQUEST, PersonActions.SAVE_PERSONS_DETAIL_SUCCESS, PersonActions.SAVE_PERSONS_DETAIL_ERROR]
        }
    };
}

export function updateFileName(FileID, filename) {
    return {
        [CALL_API]: {
            reqType: 'PUT',
            endpoint: 'persons/Files/'+FileID+'/name',
            postData: {filename},
            types: [PersonActions.SAVE_PERSONS_FILE_NAME_REQUEST, PersonActions.SAVE_PERSONS_FILE_NAME_SUCCESS, PersonActions.SAVE_PERSONS_FILE_NAME_ERROR]
        }
    };
}





export function showPreviewFileModal(show, file = {}) {
    return {
        type: PersonActions.SHOW_PREVIEW_FILE_MODAL,
        show,
        file
    };
};

export function setPersonenTablePage(pager) {
    return {
        type: PersonActions.SET_PERSONS_TABLE_PAGE,
        pager
    };
};

export function setPersonenTableInfo(infos) {
    return {
        type: PersonActions.SET_PERSONS_TABLE_INFO,
        infos
    };
};


export function setSearchText(search) {
    return {
        type: PersonActions.SET_SEARCH_TEXT,
        search
    };
};

export function showPersonsDetail(show, id = 0) {
    return {
        type: PersonActions.SHOW_PERSONS_DETAIL,
        show,
        id,
    };
}

export function clearPersonsDetail() {
    return {
        type: PersonActions.CLEAR_PERSONS_DETAIL,
    };
}





