import React, {Component} from "react";
import {Select, Modal, Input, Checkbox, Button, DatePicker, Upload, Typography, Row, Col, Avatar} from 'antd';
import { Form } from '@ant-design/compatible';
import {bindActionCreators} from "redux";
import * as TodoRezeptionActions from "../../redux/TodoRezeption/actions";
import {connect} from "react-redux";
import {FileAddOutlined, InboxOutlined, MinusCircleOutlined, PlusOutlined} from "@ant-design/icons";
import '../RoomPlan/reservation.style.css';
import moment from "moment/moment";

const { TextArea } = Input;
const FormItem = Form.Item;
const Option = Select.Option;
const confirm = Modal.confirm;

let BASE_URL = process.env.REACT_APP_API_URL;
let API_VERSION = process.env.REACT_APP_API_VERSION;


const formItemLayout = {
    labelCol: {
        xs: { span: 24 },
        sm: { span: 6 },
    },
    wrapperCol: {
        xs: { span: 24 },
        sm: { span: 18 },
    },
};

const formItemLayoutWithOutLabel = {
    wrapperCol: {
        xs: { span: 24, offset: 0 },
        sm: { span: 18, offset: 6 },
    },
};

let uuid = 0;


const TodoNote = ({date, userName, file, onFilePreview, children}) => {
    const userNameArray = (userName || "").split(" ") || [];
    return (
        <div className="ReservationNote">
            <div className="ReservationNoteHeader">
                <div className="ReservationNoteAvatar"><Avatar size="small" style={{ backgroundColor: "#f52929", verticalAlign: "middle" }}>{userNameArray.map((name) => name.substring(0, 1).toUpperCase())}</Avatar></div>
                <div className="ReservationNoteTitle"><Typography.Text><b>{userName}</b></Typography.Text></div>
                <div>
                    <Typography.Text type="secondary">{moment(date).format("DD.MM.YYYY HH:mm")} Uhr</Typography.Text>
                </div>
            </div>
            <div className="ReservationNoteBody">
                {file ? <img height={150} src={file} className="ReservationNoteImage" onClick={() => onFilePreview(file)} /> : null}
                <Typography.Text>{children}</Typography.Text>
            </div>
        </div>
    );
};

const TodoNoteImagePreview = ({file, onClose, open}) => {
    return (
        <Modal
            title="Bildvorschau"
            open={open}
            onCancel={onClose}
            okText={false}
            footer={null}
            style={{ maxWidth: "80vw" }}
            width={"fit-content"}
        >
            <img src={file} style={{objectFit: "contain", height: "70vh", maxHeight: "100%", maxWidth: "100%"}}/>
        </Modal>
    );
}

class TodoDetailModal extends Component {

    state = { todoNoteText: "", todoNoteUpload: []};

    componentWillMount() {
    }

    handleSubmit = (e) => {
        e.preventDefault();
        this.props.form.validateFieldsAndScroll((err, values) => {
            if (!err) {
                this.props.actions.saveTodoDetail(values, this.props.TodoDetailID);
            }
        });
    };


    componentWillReceiveProps(nextProps) {
        if(this.props.TodoModalVisible && !nextProps.TodoModalVisible) {
            this.setState({todoNoteText: "", todoNoteUpload: []});
            this.props.form.resetFields();
        } else if(!this.props.TodoModalVisible && nextProps.TodoModalVisible) {
            uuid = 0;
            if(nextProps.TodoDetailID) {
                this.props.actions.LoadTodoDetail(nextProps.TodoDetailID);
            }
        }
    }

    confirmDelete = () => {
        confirm({
            title: "Aufgabe löschen",
            content: "Wollen Sie diese Aufgabe wirklich löschen?",
            okText: "Löschen",
            okType: 'danger',
            cancelText: "Abbrechen",
            onOk: () => this.props.actions.deleteTodo(this.props.TodoDetailID),
            onCancel() {
            },
        });
    };

    removeToDo = (k) => {
        const { form } = this.props;
        const keys = form.getFieldValue('keys');
        form.setFieldsValue({
            keys: keys.filter(key => key !== k),
        });
    };

    addToDo = () => {
        const { form } = this.props;
        const keys = form.getFieldValue('keys');
        const nextKeys = keys.concat(uuid);
        uuid++;

        form.setFieldsValue({
            keys: nextKeys,
            Checked: false,
        });
    };


    sendTodoNote = () => {
        const note = this.state.todoNoteText;
        const file = this.state.todoNoteUpload?.[0];
        if(note) {
            this.props.actions.saveTodoNote({note, file}, this.props.TodoDetailID).then((resp) => {
                if (resp?.type === "SAVE_REINIGUNG_CLEANING_NOTE_SUCCESS") {
                    this.setState({todoNoteText: "", todoNoteUpload: []});
                }
            });
        }
    }


    render() {
        const { getFieldDecorator, getFieldValue, setFieldsValue } = this.props.form;
        //getFieldDecorator('keys', { initialValue: this.props.workflowList.map((c, i) => {return i;}) });
        getFieldDecorator('keys', { initialValue: this.props.TodoDetail?.todos ? this.props.TodoDetail.todos.map((c, i) => {return i;} ) : []} );
        const keys = this.props.form.getFieldValue('keys');

        if(this.props.TodoDetail?.todos && uuid < this.props.TodoDetail?.todos?.length || 0) { uuid = this.props.TodoDetail?.todos?.length || 0; }

        let formItems = keys.map((k, index) => {
            return (
                <Row gutter={0}>
                    <Col span={6} style={{textAlign: 'center'}}>
                        <FormItem
                            {...formItemLayoutWithOutLabel}
                            label={''}
                            required={false}
                            key={k+'C'}
                        >
                            {getFieldDecorator(`todosC[${k}]`, {
                                validateTrigger: ['onChange', 'onBlur'],
                                initialValue: this.props.TodoDetail?.todos ? this.props.TodoDetail?.todos[k] ? this.props.TodoDetail.todos[k].Done : false : false,
                                valuePropName: 'checked',
                            })(
                                <Checkbox onChange={(e) => !e.target.checked && setFieldsValue({
                                    Checked: false,
                                })} />
                            )}
                        </FormItem>

                    </Col>

                    <Col span={18} style={{textAlign: 'left'}}>

                        <FormItem
                            label={''}
                            required={false}
                            key={k}
                        >
                            {getFieldDecorator(`todos[${k}]`, {
                                validateTrigger: ['onChange', 'onBlur'],
                                initialValue: this.props.TodoDetail?.todos ? this.props.TodoDetail.todos[k] ? this.props.TodoDetail.todos[k].Job : "" : "",
                                rules: [{
                                    required: true,
                                    whitespace: true,
                                    message: "Bitte geben Sie eine Aufgabe ein, oder entfernen Sie dieses Feld",
                                }],
                            })(
                                <Input placeholder="Aufgabe eingeben" style={{ width: '77%', marginRight: 8 }} />
                            )}
                            { (getFieldValue(`todos[${k}]`)?.length === 0 || getFieldValue(`todosC[${k}]`)) ?
                                <MinusCircleOutlined
                                    className="dynamic-delete-button"
                                    onClick={() => this.removeToDo(k)}
                                /> : ''
                            }
                            {getFieldDecorator(`todosU[${k}]`, {
                                initialValue: this.props.TodoDetail?.todos && this.props.TodoDetail?.todos?.[k] ? this.props.TodoDetail?.todos?.[k]?.DoneFromID : undefined,
                            })}
                            {getFieldDecorator(`todosS[${k}]`, {
                                initialValue: this.props.TodoDetail?.todos && this.props.TodoDetail?.todos?.[k] ? this.props.TodoDetail?.todos?.[k]?.schedule : null,
                                rules: [{
                                    required: false,
                                }],
                            })(
                                <Select
                                    getPopupContainer={trigger => trigger.parentNode}
                                    showSearch
                                    style={{ width: '77%' }}
                                    placeholder="Wiederholung"
                                    optionFilterProp="children"
                                    filterOption={(input, option) => option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
                                >
                                    <Option value={null}>Nicht wiederholen</Option>
                                    <Option value={"daily"}>Täglich wiederholen</Option>
                                    <Option value={"weekly"}>Wöchentlich wiederholen</Option>
                                    <Option value={"monthly"}>Monatlich wiederholen</Option>
                                    <Option value={"yearly"}>Jährlich wiederholen</Option>
                                </Select>
                            )}
                            {this.props.TodoDetail?.todos?.[k]?.Done ? <><br/><Typography.Text type="secondary">Erledigt
                                von: {this.props.TodoDetail?.todos[k]?.DoneFrom}</Typography.Text></> : null}


                            {getFieldDecorator(`todosD[${k}]`, {
                                rules: [{
                                    required: false,
                                }],
                                initialValue: this.props.TodoDetail?.todos?.[k]?.date ? moment(this.props.TodoDetail?.todos?.[k]?.date) : null,
                            })(
                                <DatePicker placeholder={"Datum auswählen"} format={"DD.MM.YYYY"} style={{width: '77%'}}/>)}

                        </FormItem>
                    </Col>
                </Row>
            );
        });


        return (
            <>
                <TodoNoteImagePreview file={this.state.TodoNoteFilePreview} open={!!this.state.TodoNoteFilePreview} onClose={() => this.setState({TodoNoteFilePreview: undefined})} />
                <Modal
                    title={`Aufgabe ${this.props.TodoDetailID ? 'bearbeiten' : 'anlegen'}`}
                    visible={this.props.TodoModalVisible}
                    onOk={this.handleSubmit}
                    onCancel={() => this.props.actions.showTodoModal(false, null)}
                    cancelText={"Abbrechen"}
                    okText={"Speichern"}
                    width={"750px"}
                    footer={[
                        this.props.TodoDetailID ? <Button key="deleteban" type="danger" loading={this.props.deletingBan} onClick={this.confirmDelete}>
                            Aufgabe löschen
                        </Button> : '',
                        <Button key="cancel" onClick={() => this.props.actions.showTodoModal(false, null)}>Abbrechen</Button>,
                        <Button key="submit" type="primary" loading={this.props.TodoModalSaving} onClick={this.handleSubmit}>
                            Speichern
                        </Button>
                    ]}
                >



                    <form onSubmit={this.handleSubmit} style={{width: '100%'}}>

                        <FormItem
                            {...formItemLayout}
                            label={"Aufgabe"}
                            required={true}
                            key={'Todo'}
                        >
                            {getFieldDecorator('Todo', {
                                initialValue: this.props.TodoDetail.Todo,
                                rules: [{
                                    required: true,
                                    whitespace: true,
                                    message: "Bitte geben Sie diese Information an."
                                }],
                            })(
                                <Input type={"text"} placeholder={"Aufgabe"} style={{ width: 380 }}/>
                            )}
                        </FormItem>

                        <FormItem
                            {...formItemLayout}
                            label={'Geprüft'}
                            required={false}
                            key={"Geprüft"}
                        >
                            {getFieldDecorator(`Checked`, {
                                validateTrigger: ['onChange', 'onBlur'],
                                initialValue: this.props.TodoDetail.Checked,
                                valuePropName: 'checked',
                            })(
                                <Checkbox />
                            )}
                        </FormItem>

                        <FormItem {...formItemLayout} label={"Aufgaben"}>
                        </FormItem>
                        {formItems}
                        <FormItem {...formItemLayoutWithOutLabel}>
                            <Button type="dashed" onClick={this.addToDo} style={{ width: '77%', margin: "0 auto" }}>
                                <PlusOutlined /> Aufgabe Hinzufügen
                            </Button>
                        </FormItem>

                        <div className="ReservationNoteWrapper">
                            {(this.props.TodoDetail?.todoNotes || []).map((todoNote) =>
                                <TodoNote userName={todoNote.User} date={todoNote.Created} file={todoNote.File} onFilePreview={(file) => this.setState({TodoNoteFilePreview: file})}>{todoNote.Note}</TodoNote>
                            )}
                            {!this.props.TodoDetail?.todoNotes?.length && <Typography.Text type="secondary" style={{display: "block", textAlign: "center"}}>Keine Notizen vorhanden</Typography.Text>}
                        </div>
                        <div className="ReservationNoteInputWrapper">
                            <div className="ReservationNoteInputContainer">
                                <Upload
                                    accept="image/*"
                                    action={(f) => BASE_URL+'api/'+API_VERSION+"/todo/"+(this.props.TodoDetailID)+"/todonote/Upload/"+f.uid}
                                    headers={{authorization: `Bearer ${localStorage.getItem('id_token') || null}`}}
                                    onChange={({fileList}) => {this.setState({todoNoteUpload: fileList.slice(-1)});}}
                                    fileList={this.state.todoNoteUpload}
                                    showUploadList={false}
                                    multiple={false}
                                    disabled={!this.props.TodoDetailID || this.props.TodoDetailTodoNoteSaving}
                                >
                                    <Button type="default" htmlType="button" icon={<FileAddOutlined />} />
                                </Upload>
                                <div className="ReservationNoteInputs">
                                    {/*!this.state.todoNoteUpload?.length ? <TextArea disabled={!this.props.TodoDetailID || this.props.TodoDetailTodoNoteSaving} value={this.state.todoNoteText} onChange={(e) => { this.setState({todoNoteText: e.target.value}); }} /> : null*/}
                                    <TextArea placeholder={"Nachricht eingeben..."} disabled={!this.props.TodoDetailID || this.props.TodoDetailTodoNoteSaving} value={this.state.todoNoteText} onChange={(e) => { this.setState({todoNoteText: e.target.value}); }} />
                                    <Upload fileList={this.state.todoNoteUpload}  onChange={({fileList}) => {this.setState({todoNoteUpload: fileList});}} />
                                </div>
                                <Button htmlType="button" type="primary" onClick={this.sendTodoNote} disabled={this.props.TodoDetailTodoNoteSaving}>Senden</Button>
                            </div>
                        </div>



                    </form>


                </Modal>
                </>
        );
    }
}



function mapStateToProps(state) {
    const { TodoRezeptionReducer } = state;
    const { TodoModalVisible, TodoDetailID, TodoModalSaving, TodoDetail , deletingBan} = TodoRezeptionReducer;
    return {
        TodoModalVisible,
        TodoDetailID,
        TodoModalSaving,
        TodoDetail,
        deletingBan
    };
}


function mapDispatchToProps(dispatch) {
    return{
        actions: bindActionCreators(TodoRezeptionActions, dispatch)
    };
}



export default connect(mapStateToProps, mapDispatchToProps)(Form.create()(TodoDetailModal));


//export default Form.create()(UsersDetailModal);