import React, {Component} from "react";
import {connect} from "react-redux";
import {bindActionCreators} from "redux";
import * as LustmolcheActions from "../../redux/Lustmolche/actions";
import { Input, Button, Table, Tag} from 'antd';
import Box from '../../components/utility/box';
import LayoutWrapper from '../../components/utility/layoutWrapper';
import moment from 'moment';
import './list.style.css';
import {
    CheckOutlined,
    CloseCircleOutlined,
    CloudSyncOutlined,
    FileExcelOutlined,
    SelectOutlined
} from "@ant-design/icons";
import LustmolcheDetail from "./detail";

const Search = Input.Search;
const itemStyle = {margin: '0px'};

let BASE_URL = process.env.REACT_APP_API_URL;
let API_VERSION = process.env.REACT_APP_API_VERSION;

class Lustmolche extends Component {



    componentWillMount() {
        this.props.actions.loadLustmolcheTable({...this.props.infosTableLustmolche, search: this.props.search});
        this.state = {search: this.props.search, ExportLoading: false};
    }


    handleTableChange = (pagination, filters, sorter) => {
        const pager = { ...this.props.paginationLustmolche };
        pager.current = pagination.current;

        this.props.actions.setLustmolcheTablePage(pager);

        this.props.actions.setLustmolcheTableInfo({
            results: pagination.pageSize,
            page: pagination.current,
            sortField: sorter.field,
            sortOrder: sorter.order,
            ...filters,
        });

        this.props.actions.loadLustmolcheTable({
            results: pagination.pageSize,
            page: pagination.current,
            sortField: sorter.field,
            sortOrder: sorter.order,
            ...filters,
            search: this.props.search
        });


    };



    componentWillUpdate(nextProps) {
       if(this.props.search !== nextProps.search) {
            this.props.actions.loadLustmolcheTable({...nextProps.infosTableLustmolche, search: nextProps.search});
        }

        //if(this.props.savingLustmolcheDetail && !nextProps.savingLustmolcheDetail ||
        //    this.props.deletingCleaning && !nextProps.deletingCleaning) {
        if(this.props.savingLustmolcheDetail && !nextProps.savingLustmolcheDetail ||
            this.props.deletingLustmolche && !nextProps.deletingLustmolche) {
            this.props.actions.loadLustmolcheTable({...nextProps.infosTableLustmolche, search: nextProps.search});
        }

    }

    onChangeSearch = (e) => {
        this.setState({ search: e.target.value });
    };



    render() {

        const columns = [
            {
                title: 'Status',
                dataIndex: 'personId',
                key: 'personId',
                render: (text, record) => {
                    if(!!text) {
                        return <Tag color="gray" icon={<CheckOutlined />}>Erledigt</Tag>;
                    }
                    return <Tag color="green" icon={<SelectOutlined />}>Neu</Tag>;
                },
                sorter: true,
                sortOrder: this.props.infosTableLustmolche.sortField === 'personId' && this.props.infosTableLustmolche.sortOrder
            }, {
                title: 'Name',
                dataIndex: 'lastname',
                key: 'lastname',
                sorter: true,
                sortOrder: this.props.infosTableLustmolche.sortField === 'lastname' && this.props.infosTableLustmolche.sortOrder
            }, {
                title: 'Vorname',
                dataIndex: 'firstname',
                key: 'firstname',
                sorter: true,
                sortOrder: this.props.infosTableLustmolche.sortField === 'firstname' && this.props.infosTableLustmolche.sortOrder
            }, {
                title: 'Künstlername',
                dataIndex: 'stagename',
                key: 'stagename',
                sorter: true,
                sortOrder: this.props.infosTableLustmolche.sortField === 'stagename' && this.props.infosTableLustmolche.sortOrder
            },
            {
                title: 'Eingegangen',
                dataIndex: 'created',
                key: 'created',
                sorter: true,
                render: (text) => {return text ? moment(text).format('DD.MM.YYYY HH:mm') : ''},
                sortOrder: this.props.infosTableLustmolche.sortField === 'created' && this.props.infosTableLustmolche.sortOrder
            }
            ];

        const searchClear = this.props.search ? <CloseCircleOutlined onClick={() => {this.props.actions.setSearchText(''); this.setState({ search: '' });}} /> : null;

        return (
            <React.Fragment>
                <LustmolcheDetail />
                <LayoutWrapper>
                    <Search onChange={this.onChangeSearch} value={this.state.search} prefix={searchClear} placeholder={"Suchen..."} onSearch={(value) => this.props.actions.setSearchText(value)} defaultValue={this.props.search} enterButton style={{width: '400px', minHeight: '0px !important'}} />
                    <br/><br/>
                    <Box>
                    <Table
                        columns={columns}
                        dataSource={this.props.LustmolcheList}
                        rowClassName={(record, index) => { return 'tableCursor'}}
                        onRow={(record) => ({
                            onClick: () => { this.props.actions.showLustmolcheDetail(true, record.id); }
                        })}
                        pagination={this.props.paginationLustmolche}
                        loading={this.props.loadingLustmolcheList}
                        onChange={this.handleTableChange}
                        rowKey={(record => record.id)}
                    />



                </Box>
                </LayoutWrapper>
            </React.Fragment>
        );
    }
}


function mapStateToProps(state) {
    const { LustmolcheReducer, Auth } = state;
    const { loadingLustmolcheList, LustmolcheList, paginationLustmolche, infosTableLustmolche, search, savingLustmolcheDetail, deletingLustmolche } = LustmolcheReducer;
    const {idToken} = Auth;
    return {
        LustmolcheList,
        loadingLustmolcheList,
        paginationLustmolche,
        infosTableLustmolche,
        search,
        savingLustmolcheDetail,
        deletingLustmolche,
        idToken
    };
}


function mapDispatchToProps(dispatch) {
    return{
        actions: bindActionCreators(LustmolcheActions, dispatch)
    };
}



export default connect(mapStateToProps, mapDispatchToProps)(Lustmolche);
