import TodoRezeptionActions from "./actions";


const initState = {
    loadingTodoList: true,
    TodoList: [],
    paginationTodo: {},
    infosTableTodo: {},
    search: "",
    TodoDetail: {},

    TodoTodoNoteSaving: false,

    TodoDetailID: null,
    TodoModalVisible: false,
    TodoModalSaving: false,
    TodoModalLoading: false,
    deletingTodo: false,
};

export default function TodoRezeptionReducer(state = initState, action) {
  switch (action.type) {
      case TodoRezeptionActions.LOAD_TODO_REZEPTION_LIST_REQUEST:
        return Object.assign({}, state, {
            loadingTodoList: true
        });
      case TodoRezeptionActions.LOAD_TODO_REZEPTION_LIST_SUCCESS:
          const pagination = { ...state.paginationTodo };
          // Read total count from server
          // pagination.total = data.totalCount;
          pagination.total = action.response.data.Count;

          return Object.assign({}, state, {
              TodoList: action.response.data.todos,
              loadingTodoList: false,
              paginationTodo: pagination
          });
      case TodoRezeptionActions.LOAD_TODO_REZEPTION_LIST_ERROR:
          return Object.assign({}, state, {
              loadingTodoList: false
          });
      case TodoRezeptionActions.SET_TODO_REZEPTION_TABLE_PAGE:
          return Object.assign({}, state, {
              paginationTodo: action.pager
          });
      case TodoRezeptionActions.SET_TODO_REZEPTION_TABLE_INFO:
          return Object.assign({}, state, {
              infosTableTodo: action.infos
          });
      case TodoRezeptionActions.SET_SEARCH_TEXT:
          return Object.assign({}, state, {
              search: action.search,
              paginationTodo: {current: 1},
              infosTableTodo: {}
          });

      case TodoRezeptionActions.SHOW_TODO_REZEPTION_MODAL:
          return Object.assign({}, state, {
              TodoModalVisible: action.show,
              TodoDetailID: action.TodoID,
              TodoDetail: {}
          });
      case TodoRezeptionActions.SAVE_TODO_REZEPTION_REQUEST:
          return Object.assign({}, state, {
              TodoModalSaving: true
          });
      case TodoRezeptionActions.SAVE_TODO_REZEPTION_SUCCESS:
              return Object.assign({}, state, {
                  TodoModalVisible: false,
                  TodoModalSaving: false
              });
      case TodoRezeptionActions.SAVE_TODO_REZEPTION_ERROR:
          return Object.assign({}, state, {
              TodoModalSaving: false
          });

      case TodoRezeptionActions.LOAD_TODO_REZEPTION_DETAIL_REQUEST:
          return Object.assign({}, state, {
              TodoModalLoading: true,
              TodoDetail: {}
          });
      case TodoRezeptionActions.LOAD_TODO_REZEPTION_DETAIL_SUCCESS:
          return Object.assign({}, state, {
              TodoModalLoading: false,
              TodoDetail: action.response.data.todo
          });
      case TodoRezeptionActions.LOAD_TODO_REZEPTION_DETAIL_ERROR:
          return Object.assign({}, state, {
              TodoModalLoading: false
          });

          case TodoRezeptionActions.DELETE_TODO_REZEPTION_REQUEST:
          return Object.assign({}, state, {
              deletingTodo: true
          });
      case TodoRezeptionActions.DELETE_TODO_REZEPTION_SUCCESS:
          return Object.assign({}, state, {
              TodoModalVisible: false,
              deletingTodo: false
          });
      case TodoRezeptionActions.DELETE_TODO_REZEPTION_ERROR:
          return Object.assign({}, state, {
              deletingTodo: false
          });

      case TodoRezeptionActions.SAVE_TODO_REZEPTION_TODO_NOTE_REQUEST:
          return Object.assign({}, state, {
              TodoTodoNoteSaving: true,
          });
      case TodoRezeptionActions.SAVE_TODO_REZEPTION_TODO_NOTE_SUCCESS:
          return Object.assign({}, state, {
              TodoTodoNoteSaving: false,
              TodoDetail: {...state.TodoDetail, todoNotes: [action.response.data, ...(state.TodoDetail?.todoNotes || [])]}
          });
      case TodoRezeptionActions.SAVE_TODO_REZEPTION_TODO_NOTE_ERROR:
          return Object.assign({}, state, {
              TodoTodoNoteSaving: false,
          });

    default:
      return state;
  }
}

