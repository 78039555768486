import React, {Component} from "react";
import {connect} from "react-redux";
import {bindActionCreators} from "redux";
import * as MaschinenActions from "../../redux/Maschinen/actions";
import { Input, Button, Table } from 'antd';
import Box from '../../components/utility/box';
import MaschinenDetailModal from './MaschinenDetailModal';
import './list.style.css';
import {CloseCircleOutlined, PlusOutlined} from "@ant-design/icons";
import LayoutWrapper from "../../components/utility/layoutWrapper";


const Search = Input.Search;
const itemStyle = {margin: '0px'};





class Maschinen extends Component {



    componentWillMount() {
        this.props.actions.LoadMaschinenList({...this.props.infosTableMaschinen, search: this.props.search});
        this.state = {search: this.props.search};
    }


    handleTableChange = (pagination, filters, sorter) => {
        const pager = { ...this.props.paginationMaschinen };
        pager.current = pagination.current;

        this.props.actions.setMaschinenTablePage(pager);

        this.props.actions.setMaschinenTableInfo({
            results: pagination.pageSize,
            page: pagination.current,
            sortField: sorter.field,
            sortOrder: sorter.order,
            ...filters,
        });

        this.props.actions.LoadMaschinenList({
            results: pagination.pageSize,
            page: pagination.current,
            sortField: sorter.field,
            sortOrder: sorter.order,
            ...filters,
            search: this.props.search
        });


    };



    componentWillUpdate(nextProps) {
       if(this.props.search !== nextProps.search) {
            this.props.actions.LoadMaschinenList({...nextProps.infosTableMaschinen, search: nextProps.search});
        }

        if(this.props.MaschinenModalSaving && !nextProps.MaschinenModalSaving ||
            this.props.deletingMachine && !nextProps.deletingMachine) {
            this.props.actions.LoadMaschinenList({...this.props.infosTableMaschinen, search: this.props.search});
        }
    }

    onChangeSearch = (e) => {
        this.setState({ search: e.target.value });
    };






    render() {

        const columns = [
            {
                title: 'ID',
                dataIndex: 'MachineID',
                key: 'ID',
                sorter: true,
                sortOrder: this.props.infosTableMaschinen.sortField === 'MachineID' && this.props.infosTableMaschinen.sortOrder
            }, {
                title: 'Name',
                dataIndex: 'Name',
                key: 'Name',
                sorter: true,
                sortOrder: this.props.infosTableMaschinen.sortField === 'Name' && this.props.infosTableMaschinen.sortOrder
            }, {
                title: 'Dokumente vorhanden',
                dataIndex: 'Documents',
                key: 'Documents',
                sorter: true,
                sortOrder: this.props.infosTableMaschinen.sortField === 'Documents' && this.props.infosTableMaschinen.sortOrder
            }
            ];

        const searchClear = this.props.search ? <CloseCircleOutlined onClick={() => {this.props.actions.setSearchText(''); this.setState({ search: '' });}} /> : null;

        return (
            <React.Fragment>
                <MaschinenDetailModal />
                <LayoutWrapper>
                    <Search onChange={this.onChangeSearch} value={this.state.search} prefix={searchClear} placeholder={"Suchen..."} onSearch={value => this.props.actions.setSearchText(value)} defaultValue={this.props.search} enterButton style={{width: '400px', minHeight: '0px !important'}} />
                    <Button type={"primary"} htmlType={"button"} className={"round"} style={{marginBottom: "10px", marginLeft: "auto"}} onClick={() => this.props.actions.showMaschinenModal(true, null)} icon={<PlusOutlined/>}>Neuen Automaten / Maschine anlegen</Button>
                <Box >


                    <Table
                        columns={columns}
                        dataSource={this.props.MaschinenList}
                        rowClassName={(record, index) => { return 'tableCursor'}}
                        onRow={(record) => ({
                            onClick: () => this.props.actions.showMaschinenModal(true, record.MachineID)
                        })}
                        pagination={this.props.paginationMaschinen}
                        loading={this.props.loadingMaschinenList}
                        onChange={this.handleTableChange}
                        scroll={{x: '100%'}}
                        rowKey={(record => record.MachineID)}
                    />



                </Box>
                </LayoutWrapper>
            </React.Fragment>
        );
    }
}


function mapStateToProps(state) {
    const { MaschinenReducer } = state;
    const { MaschinenList, loadingMaschinenList, paginationMaschinen, infosTableMaschinen, search, MaschinenModalSaving, deletingMachine } = MaschinenReducer;
    return {
        MaschinenList,
        loadingMaschinenList,
        paginationMaschinen,
        infosTableMaschinen,
        search,
        MaschinenModalSaving,
        deletingMachine
    };
}


function mapDispatchToProps(dispatch) {
    return{
        actions: bindActionCreators(MaschinenActions, dispatch)
    };
}



export default connect(mapStateToProps, mapDispatchToProps)(Maschinen);
