import React, {Component} from "react";
import {Select, Modal, Input, Checkbox, Button, DatePicker, Upload, Row, Col} from 'antd';
import { Form } from '@ant-design/compatible';
import {bindActionCreators} from "redux";
import * as KleidungActions from "../redux/Arbeitskleidung/actions";
import {connect} from "react-redux";
import moment from "moment";
import { MinusCircleOutlined, PlusOutlined } from '@ant-design/icons'

const { TextArea } = Input;
const FormItem = Form.Item;
const Option = Select.Option;
const confirm = Modal.confirm;

let uuid = 0;

const formItemLayout = {
    labelCol: {
        xs: { span: 20 },
        sm: { span: 6 },
    },
    wrapperCol: {
        xs: { span: 20 },
        sm: { span: 13 },
    },
};

const formItemLayoutWithOutLabel = {
    wrapperCol: {
        xs: { span: 24, offset: 0 },
        sm: { span: 24, offset: 0 },
    },
};


class ArbeitskleidungDetailModal extends Component {


    componentWillMount() {
    }

    handleSubmit = (e) => {
        e.preventDefault();
        this.props.form.validateFieldsAndScroll((err, values) => {
            if (!err) {
                this.props.actions.SaveArbeitskleidungen(values, this.props.KleidungModalID);
            }
        });
    };


    componentWillReceiveProps(nextProps) {
        if(this.props.KleidungModalVisible && !nextProps.KleidungModalVisible) {
            this.props.form.resetFields();
            uuid = 0;
        }
    }


    removeKleidung = (k) => {
        const { form } = this.props;
        const keys = form.getFieldValue('keys');
        form.setFieldsValue({
            keys: keys.filter(key => key !== k),
        });
    };

    addKleidung = () => {
        const { form } = this.props;
        const keys = form.getFieldValue('keys');
        const nextKeys = keys.concat(uuid);
        uuid++;

        form.setFieldsValue({
            keys: nextKeys,
        });
    };



    render() {
        const { getFieldDecorator } = this.props.form;

        getFieldDecorator('keys', { initialValue: this.props.KleidungModalDetails.Kleidung ? this.props.KleidungModalDetails.Kleidung.map((c, i) => {return i;} ) : []} );
        const keys = this.props.form.getFieldValue('keys');

        if(this.props.KleidungModalDetails.Kleidung && uuid < this.props.KleidungModalDetails.Kleidung.length) { uuid = this.props.KleidungModalDetails.Kleidung.length; }

        let formItems = keys.map((k, index) => {
            return (


                        <FormItem
                            label={''}
                            required={false}
                            key={k}
                        >
                            {getFieldDecorator(`Kleidung[${k}]`, {
                                validateTrigger: ['onChange', 'onBlur'],
                                initialValue: this.props.KleidungModalDetails.Kleidung ? this.props.KleidungModalDetails.Kleidung[k] ? this.props.KleidungModalDetails.Kleidung[k] : "" : "",
                                rules: [{
                                    required: true,
                                    whitespace: true,
                                    message: "Bitte geben Sie eine Kleidung ein, oder entfernen Sie dieses Feld",
                                }],
                            })(
                                <Input placeholder="Kleidung eingeben" style={{ width: '77%', marginRight: 8 }} />
                            )}
                            { (this.props.form.getFieldValue(`Kleidung[${k}]`).length === 0 || this.props.form.getFieldValue(`Kleidung[${k}]`)) ?
                                <MinusCircleOutlined className="dynamic-delete-button" onClick={() => this.removeKleidung(k)} /> : ''
                            }
                        </FormItem>
            );
        });


        return (
                <Modal
                    title={`Arbeitskleidung ${this.props.KleidungModalID ? 'bearbeiten' : 'anlegen'}`}
                    visible={this.props.KleidungModalVisible}
                    onOk={this.handleSubmit}
                    onCancel={() => this.props.actions.showArbeitskleidungModal(false, null)}
                    cancelText={"Abbrechen"}
                    okText={"Speichern"}
                    width={"750px"}
                    footer={[
                        <Button key="back" onClick={() => this.props.actions.showArbeitskleidungModal(false, null)}>Abbrechen</Button>,
                        <Button key="submit" type="primary" loading={this.props.loadingKleidungList} onClick={this.handleSubmit}>Speichern</Button>
                    ]}
                >



                    <form onSubmit={this.handleSubmit} style={{width: '100%'}}>

                        <FormItem
                            {...formItemLayout}
                            label={"Arbeitsposition"}
                            required={false}
                            key={'Arbeitsposition'}
                        >
                            {getFieldDecorator('Group', {
                                initialValue: this.props.KleidungModalDetails.Group,
                                rules: [{
                                    required: true,
                                    whitespace: true,
                                    message: "Bitte geben Sie diese Information an."
                                }],
                            })(
                                <Input type={"text"} disabled={true}/>
                            )}
                        </FormItem>


                        <FormItem {...formItemLayout} label={"Kleidungen"}>
                        </FormItem>
                        {formItems}
                        <FormItem {...formItemLayoutWithOutLabel}>
                            <Button type="dashed" onClick={this.addKleidung} style={{ width: '77%' }}>
                                <PlusOutlined /> Kleidung Hinzufügen
                            </Button>
                        </FormItem>


                    </form>


                </Modal>


        );
    }
}



function mapStateToProps(state) {
    const { KleidungReducer } = state;
    const { KleidungModalVisible, KleidungModalID, KleidungModalDetails, loadingKleidungList } = KleidungReducer;
    return {
        KleidungModalVisible,
        KleidungModalID,
        KleidungModalDetails,
        loadingKleidungList
    };
}


function mapDispatchToProps(dispatch) {
    return{
        actions: bindActionCreators(KleidungActions, dispatch)
    };
}



export default connect(mapStateToProps, mapDispatchToProps)(Form.create()(ArbeitskleidungDetailModal));


//export default Form.create()(UsersDetailModal);