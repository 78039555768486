import {CALL_API} from "../../helpers/middleware";
import {history} from "../store";

const RoomPlanActions = {
    LOAD_ROOMPLAN_REQUEST : 'LOAD_ROOMPLAN_REQUEST',
    LOAD_ROOMPLAN_SUCCESS : 'LOAD_ROOMPLAN_SUCCESS',
    LOAD_ROOMPLAN_ERROR : 'LOAD_ROOMPLAN_ERROR',
    SHOW_RESERVATION: 'SHOW_RESERVATION',
    LOAD_HOUSES_REQUEST: 'LOAD_HOUSES_REQUEST',
    LOAD_HOUSES_SUCCESS: 'LOAD_HOUSES_SUCCESS',
    LOAD_HOUSES_ERROR: 'LOAD_HOUSES_ERROR',
    CHANGE_HOUSE: 'CHANGE_HOUSE',
    CHANGE_ROOM: 'CHANGE_ROOM',
    SAVE_RESERVATION_REQUEST: 'SAVE_RESERVATION_REQUEST',
    SAVE_RESERVATION_SUCCESS: 'SAVE_RESERVATION_SUCCESS',
    SAVE_RESERVATION_ERROR: 'SAVE_RESERVATION_ERROR',
    LOAD_RESERVATION_REQUEST: 'LOAD_RESERVATION_REQUEST',
    LOAD_RESERVATION_SUCCESS: 'LOAD_RESERVATION_SUCCESS',
    LOAD_RESERVATION_ERROR: 'LOAD_RESERVATION_ERROR',
    CLEAR_RESERVATION: 'CLEAR_RESERVATION',
    CHANGE_MONTH: 'CHANGE_MONTH',
    LOAD_RESERVED_DAYS_REQUEST: 'LOAD_RESERVED_DAYS_REQUEST',
    LOAD_RESERVED_DAYS_SUCCESS: 'LOAD_RESERVED_DAYS_SUCCESS',
    LOAD_RESERVED_DAYS_ERROR: 'LOAD_RESERVED_DAYS_ERROR',
    DELETE_RESERVATION_REQUEST: 'DELETE_RESERVATION_REQUEST',
    DELETE_RESERVATION_SUCCESS: 'DELETE_RESERVATION_SUCCESS',
    DELETE_RESERVATION_ERROR: 'DELETE_RESERVATION_ERROR',

    SAVE_RESERVATION_CLEANING_NOTE_REQUEST: 'SAVE_RESERVATION_CLEANING_NOTE_REQUEST',
    SAVE_RESERVATION_CLEANING_NOTE_SUCCESS: 'SAVE_RESERVATION_CLEANING_NOTE_SUCCESS',
    SAVE_RESERVATION_CLEANING_NOTE_ERROR: 'SAVE_RESERVATION_CLEANING_NOTE_ERROR',

    SHOW_TODO: 'SHOW_TODO',
    CLEAR_TODO: 'CLEAR_TODO',
    SAVE_TODO_REQUEST: 'SAVE_TODO_REQUEST',
    SAVE_TODO_SUCCESS: 'SAVE_TODO_SUCCESS',
    SAVE_TODO_ERROR: 'SAVE_TODO_ERROR',

    LOAD_TODO_REQUEST: 'LOAD_TODO_REQUEST',
    LOAD_TODO_SUCCESS: 'LOAD_TODO_SUCCESS',
    LOAD_TODO_ERROR: 'LOAD_TODO_ERROR',


    SHOW_OBJECT: 'SHOW_OBJECT',
    CLEAR_OBJECT: 'CLEAR_OBJECT',
    SAVE_OBJECT_REQUEST: 'SAVE_OBJECT_REQUEST',
    SAVE_OBJECT_SUCCESS: 'SAVE_OBJECT_SUCCESS',
    SAVE_OBJECT_ERROR: 'SAVE_OBJECT_ERROR',

    LOAD_OBJECT_REQUEST: 'LOAD_OBJECT_REQUEST',
    LOAD_OBJECT_SUCCESS: 'LOAD_OBJECT_SUCCESS',
    LOAD_OBJECT_ERROR: 'LOAD_OBJECT_ERROR',
};

export default RoomPlanActions;


export function LoadRoomPlan(month, blank = false) {
    return {
        [CALL_API]: {
            reqType: 'POST',
            endpoint: 'roomplan',
            postData: {month, blank},
            types: [RoomPlanActions.LOAD_ROOMPLAN_REQUEST, RoomPlanActions.LOAD_ROOMPLAN_SUCCESS, RoomPlanActions.LOAD_ROOMPLAN_ERROR]
        }
    };
}

export function loadHouses() {
    return {
        [CALL_API]: {
            reqType: 'GET',
            endpoint: 'houses',
            types: [RoomPlanActions.LOAD_HOUSES_REQUEST, RoomPlanActions.LOAD_HOUSES_SUCCESS, RoomPlanActions.LOAD_HOUSES_ERROR]
        }
    };
}

export function loadReservation(ReservationID) {
    return {
        [CALL_API]: {
            reqType: 'GET',
            endpoint: 'roomplan/reservation/'+ReservationID,
            types: [RoomPlanActions.LOAD_RESERVATION_REQUEST, RoomPlanActions.LOAD_RESERVATION_SUCCESS, RoomPlanActions.LOAD_RESERVATION_ERROR]
        }
    };
}

export function deleteReservation(ReservationID) {
    return {
        [CALL_API]: {
            reqType: 'DELETE',
            endpoint: 'roomplan/reservation/'+ReservationID,
            types: [RoomPlanActions.DELETE_RESERVATION_REQUEST, RoomPlanActions.DELETE_RESERVATION_SUCCESS, RoomPlanActions.DELETE_RESERVATION_ERROR]
        }
    };
}



export function saveReservation(values, ReservationID) {

    if(ReservationID > 0) {
        return {
            [CALL_API]: {
                reqType: 'PUT',
                endpoint: 'roomplan/reservation/'+ReservationID,
                postData: values,
                types: [RoomPlanActions.SAVE_RESERVATION_REQUEST, RoomPlanActions.SAVE_RESERVATION_SUCCESS, RoomPlanActions.SAVE_RESERVATION_ERROR]
            }
        };
    } else {
        return {
            [CALL_API]: {
                reqType: 'POST',
                endpoint: 'roomplan/reservation',
                postData: values,
                types: [RoomPlanActions.SAVE_RESERVATION_REQUEST, RoomPlanActions.SAVE_RESERVATION_SUCCESS, RoomPlanActions.SAVE_RESERVATION_ERROR]
            }
        };
    }

}

export function saveReservationNote(values, ReservationID) {
    return {
        [CALL_API]: {
            reqType: 'POST',
            endpoint: 'roomplan/reservation/'+ReservationID+'/cleaningnote',
            postData: values,
            types: [RoomPlanActions.SAVE_RESERVATION_CLEANING_NOTE_REQUEST, RoomPlanActions.SAVE_RESERVATION_CLEANING_NOTE_SUCCESS, RoomPlanActions.SAVE_RESERVATION_CLEANING_NOTE_ERROR]
        }
    };
}

export function changeHouse(house) {
    return {
        type: RoomPlanActions.CHANGE_HOUSE,
        house
    };
}

export function changeRoom(room, opt = false) {
    return {
        type: RoomPlanActions.CHANGE_ROOM,
        room,
        opt
    }
}


export function loadReservedDays(house, room) {
    return {
        [CALL_API]: {
            reqType: 'POST',
            endpoint: 'roomplan/reserveddays',
            postData: {house, room},
            types: [RoomPlanActions.LOAD_RESERVED_DAYS_REQUEST, RoomPlanActions.LOAD_RESERVED_DAYS_SUCCESS, RoomPlanActions.LOAD_RESERVED_DAYS_ERROR]
        }
    }
}

export function clearReservation() {
    return {
        type: RoomPlanActions.CLEAR_RESERVATION
    }
}

export function showReservation(show, id = 0, date = 0, house = null, room = null) {
    return {
        type: RoomPlanActions.SHOW_RESERVATION,
        show,
        id,
        date,
        house,
        room
    };
}

export function showTodo(show, id = 0, house = null, room = null) {
    return {
        type: RoomPlanActions.SHOW_TODO,
        show,
        id,
        house,
        room
    };
}

export function clearTodo() {
    return {
        type: RoomPlanActions.CLEAR_TODO
    }
}

export function saveTodo(values, TodoID) {
    return {
        [CALL_API]: {
            reqType: 'PUT',
            endpoint: 'roomplan/todo/'+TodoID,
            postData: values,
            types: [RoomPlanActions.SAVE_TODO_REQUEST, RoomPlanActions.SAVE_TODO_SUCCESS, RoomPlanActions.SAVE_TODO_ERROR]
        }
    };
}

export function loadTodo(TodoID) {
    return {
        [CALL_API]: {
            reqType: 'GET',
            endpoint: 'roomplan/todo/'+TodoID,
            types: [RoomPlanActions.LOAD_TODO_REQUEST, RoomPlanActions.LOAD_TODO_SUCCESS, RoomPlanActions.LOAD_TODO_ERROR]
        }
    };
}



export function showObject(show, id = 0, house = null, room = null) {
    return {
        type: RoomPlanActions.SHOW_OBJECT,
        show,
        id,
        house,
        room
    };
}

export function clearObject() {
    return {
        type: RoomPlanActions.CLEAR_OBJECT
    }
}

export function saveObject(values, ObjectID) {
    return {
        [CALL_API]: {
            reqType: 'PUT',
            endpoint: 'roomplan/object/'+ObjectID,
            postData: values,
            types: [RoomPlanActions.SAVE_OBJECT_REQUEST, RoomPlanActions.SAVE_OBJECT_SUCCESS, RoomPlanActions.SAVE_OBJECT_ERROR]
        }
    };
}

export function loadObject(ObjectID) {
    return {
        [CALL_API]: {
            reqType: 'GET',
            endpoint: 'roomplan/object/'+ObjectID,
            types: [RoomPlanActions.LOAD_OBJECT_REQUEST, RoomPlanActions.LOAD_OBJECT_SUCCESS, RoomPlanActions.LOAD_OBJECT_ERROR]
        }
    };
}


function changeMonth(month) {
    return {
        type: RoomPlanActions.CHANGE_MONTH,
        month
    }
}

export function selectMonth(month) {

        return dispatch => {
            dispatch(changeMonth(month));
            dispatch(LoadRoomPlan(month));

        }

}

/*

export function deleteUser() {
    return dispatch => {
        dispatch(deleteUserToSRV()).then(() => {
            dispatch(logoutUser());
        });
    };
}

 */


