import React, {Component} from "react";
import { Modal, Button, Spin } from 'antd';
import {bindActionCreators} from "redux";
import {connect} from "react-redux";
import * as RechnungenActions from "../redux/Rechnungen/actions";
import { Document, Page, pdfjs } from 'react-pdf';

//pdfjs.GlobalWorkerOptions.workerSrc = 'pdf.worker.min.js';
pdfjs.GlobalWorkerOptions.workerSrc = `//unpkg.com/pdfjs-dist@${pdfjs.version}/build/pdf.worker.min.js`;


class PreviewBillModal extends Component {



    onDocumentLoadSuccess = ({ numPages }) => {
        this.setState({ numPages });
    };

    componentWillMount() {
        this.setState({
            numPages: null,
            pageNumber: 1,
        });
    }


    handleCancel = () => {
        this.setState({ numPages: null });
        this.props.actions.hidePreviewBillModal();
    }



    render() {
        const { numPages } = this.state;


        return (
                <Modal
                    title={'Rechnungsvorschau'}
                    visible={this.props.previewModalVisible}
                    onCancel={this.handleCancel}
                    cancelText={"Schließen"}
                    width={"650px"}
                    footer={[
                        <Button key="cancel" onClick={this.handleCancel}>Schließen</Button>,
                    ]}
                >

                    <Spin spinning={this.props.loadingPreview}>
                        {this.props.previewBLOB ?
                            <Document
                                file={this.props.previewBLOB || null}
                                onLoadSuccess={this.onDocumentLoadSuccess}
                                noData={""}
                            >
                                {numPages > 1 ?
                                    [...Array(numPages)].map((x,p)=> <Page pageNumber={p+1} />)
                                    :
                                    <Page pageNumber={1} />
                                }
                            </Document>
                        : null}
                    </Spin>
                </Modal>


        );
    }
}



function mapStateToProps(state) {
    const { RechnungenReducer } = state;
    const { previewModalVisible, previewBLOB, loadingPreview } = RechnungenReducer;
    return {
        previewModalVisible,
        previewBLOB,
        loadingPreview
    };
}


function mapDispatchToProps(dispatch) {
    return{
        actions: bindActionCreators(RechnungenActions, dispatch)
    };
}



export default connect(mapStateToProps, mapDispatchToProps)(PreviewBillModal);


//export default Form.create()(UsersDetailModal);