import notification from './feedback/notification';

const createNotification = (type, message, description, duration = 4.5, customProps = {}) => {
  notification[type]({
    message,
    description,
    duration,
    ...customProps
  });
};
export default createNotification;
