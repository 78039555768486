import React, {Component} from "react";
import {connect} from "react-redux";
import {bindActionCreators} from "redux";
import * as RoomPlanActions from "../../redux/RoomPlan/actions";
import {Button, DatePicker, Table, Tooltip, Modal, Spin, Badge} from 'antd';
import Box from '../../components/utility/box';
import LayoutWrapper from '../../components/utility/layoutWrapper';
import Reservation from './Reservation';
import './list.style.css';
import {CopyToClipboard} from 'react-copy-to-clipboard';
import {
    CloseOutlined,
    CopyOutlined,
    PlusOutlined,
    DownloadOutlined,
    ReloadOutlined,
    CheckSquareOutlined, FileOutlined, GiftTwoTone
} from "@ant-design/icons";
import Todos from "./Todos";
import Objects from "./Objects";


const { MonthPicker } = DatePicker;

let BASE_URL = process.env.REACT_APP_API_URL;
let API_VERSION = process.env.REACT_APP_API_VERSION;



class RoomPlan extends Component {

    interval = null;
    state = {BlankLoading: false, BlankVisible: false, disabled34: false};

    componentWillMount() {

        let month = this.props.selectedMonth;
        let blank = this.props.blank;
        if(this.props.blank) {
            month = this.props.blankmonth;
        }
        this.props.actions.LoadRoomPlan(month, blank);

        if([3, 4].includes(this.props.GroupID)) {
            this.setState({disabled34: true});
        }
    }

    showReservation = (id, date, house = null, room = null) => {
        this.props.actions.showReservation(true, id, date, house, room);
    };

    addReservation = () => {
        this.props.actions.showReservation(true);
    };


    showTodos = (id, house = null, room = null) => {
        this.props.actions.showTodo(true, id, house, room);
    };

    showObjects = (id, house = null, room = null) => {
        this.props.actions.showObject(true, id, house, room);
    };

    componentWillUpdate(nextProps) {

        if(this.props.ReservationSaved === 1 && nextProps.ReservationSaved === 0) {
            this.props.actions.LoadRoomPlan(this.props.selectedMonth);
        }
        if(this.props.TodoSaved === 1 && nextProps.TodoSaved === 0) {
            this.props.actions.LoadRoomPlan(this.props.selectedMonth);
        }
        if(this.props.ObjectSaved === 1 && nextProps.ObjectSaved === 0) {
            this.props.actions.LoadRoomPlan(this.props.selectedMonth);
        }

    }


    componentDidMount() {
        this.interval = setInterval(() => this.props.actions.LoadRoomPlan(this.props.selectedMonth), 600000);
    }
    componentWillUnmount() {
        clearInterval(this.interval);
    }



    downloadBlank = () => {
        this.setState({BlankVisible: true});
    };
    cancelBlank = () => {
        this.setState({BlankVisible: false});
    };


    submitBlank = () => {

        this.setState({BlankLoading: true});

        let params = {
            month: this.state.BlankMonth || this.props.selectedMonth
        };

        let url = BASE_URL+'api/' + API_VERSION + "/roomplan/blank";
        return fetch(url, {
            method: 'POST',
            headers: {
                'Authorization': `Bearer ${this.props.idToken}`,
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            },
            cache: "no-cache",
            body: JSON.stringify(params)
        }).then((resp) => {
            return resp.blob();
        }).then((blob) => {
            var fileURL = URL.createObjectURL(blob);
            //window.open(fileURL);
            var fileLink = document.createElement('a');
            fileLink.href = fileURL;
            fileLink.download = "blank.pdf";
            fileLink.click();
            this.setState({BlankLoading: false, BlankVisible: false});
        });
    };

    getCellClass = (status) => {
        let cellClass = "";
        switch(status){
            case 0:
                cellClass = "cellGreen";
                break;
            case 1:
                cellClass = "cellYellow";
                break;
            case 2:
                cellClass = "";
                break;
            case 3:
                cellClass = "cellBlue";
                break;
            case 4:
                cellClass = "cellOrange";
                break;
            case 5:
                cellClass = "cellRed";
                break;
            default:
                cellClass = "";
        }
        return cellClass;
    };


    render() {

        const tables = [];

        if(this.props.RoomPlan.houses) {

            for (let H = 0; H < this.props.RoomPlan.houses.length; H++) {


                let columnsDays = [];
                for (let d = 1; d <= this.props.RoomPlan.days; d++) {
                    let day = ('0' + d).slice(-2);

                    let dayTitleString = day + "." + (('0' + this.props.RoomPlan.month).slice(-2));

                    columnsDays.push({
                        title: dayTitleString,
                        dataIndex: dayTitleString,
                        key: dayTitleString + H,
                        render: (text, record) => {
                            var jobs = text.jobs || 0;
                            var today = text.today || 0;
                            var changedReservation = text.changedReservation || false;
                            var departUnknown = text.departUnknown || false;
                            if(text.status === 0) {
                                //children: text.text + "€"
                                return {
                                    props: {
                                        className: `cellGreen ${ jobs ? ' jobs ' : '' } ${ today ? ' today ' : '' } ${ changedReservation ? ' changedReservation ' : '' }`,
                                        ReservationID: text.ReservationID || 0
                                    },
                                    children: ""
                                };
                            } else if(text.status === 1) {
                                return {
                                    props: {
                                        className: `cellYellow ${ jobs ? ' jobs ' : '' } ${ today ? ' today ' : '' } ${ changedReservation ? ' changedReservation ' : '' }`,
                                        ReservationID: text.ReservationID || 0
                                    },
                                    children:  text.text === "-" ? "" : text.text + "€"
                                };
                            } else if(text.status === 2) {
                                return {
                                    props: {
                                        className: `${ jobs ? ' jobs ' : '' } ${ today ? ' today ' : '' } ${ changedReservation ? ' changedReservation ' : '' }`,
                                        ReservationID: text.ReservationID || 0
                                    },
                                    children: text.text === "-" ? "" : text.text + "€"
                                };
                            }  else if(text.status === 3) {
                                return {
                                    props: {
                                        className: `cellBlue ${ jobs ? ' jobs ' : '' } ${ today ? ' today ' : '' } ${ changedReservation ? ' changedReservation ' : '' } ${ departUnknown ? ' cellDeepBlue ' : '' }`,
                                        ReservationID: text.ReservationID || 0
                                    },
                                    children:  text.text === "-" ? "" : text.text + "€"
                                };
                            } else if(text.status === 4) {
                                return {
                                    props: {
                                        className: `cellOrange ${ jobs ? ' jobs ' : '' } ${ today ? ' today ' : '' } ${ changedReservation ? ' changedReservation ' : '' }`,
                                        ReservationID: text.ReservationID || 0
                                    },
                                    children:  text.text === "-" ? "" : text.text + "€"
                                };
                            } else if(text.status === 5) {
                                return {
                                    props: {
                                        className: `cellRed ${ jobs ? ' jobs ' : '' } ${ today ? ' today ' : '' } ${ changedReservation ? ' changedReservation ' : '' }`,
                                        ReservationID: text.ReservationID || 0
                                    },
                                    children:  text.text === "-" ? "" : text.text + "€"
                                };
                            }
                        },
                        onCell: record => ({
                            onClick: (e) => {
                                //console.log('Click cell', record, e.target.attributes['ReservationID']);
                                let ReservationID = e.target.attributes['ReservationID'] ? e.target.attributes['ReservationID'].value : 0;
                                this.showReservation(ReservationID, dayTitleString+"."+this.props.RoomPlan.year, this.props.RoomPlan.houses[H].name,record.room.fullName);
                            },
                        })
                    });
                }


                const columns = [
                    {
                        title: () => <div>
                            <span>{this.props.RoomPlan.houses[H].name}</span>
                            <Button
                                size="small"
                                danger={this.props.RoomPlan.houses[H].openTodos}
                                style={{float:'right', border: 'none', borderRadius: '3px'}}
                                onClick={(event) => { event.preventDefault(); event.stopPropagation(); this.showTodos(this.props.RoomPlan.houses[H].name, this.props.RoomPlan.houses[H].name); }}
                            >
                                <CheckSquareOutlined />
                            </Button>
                            <Button
                                size="small"
                                danger={this.props.RoomPlan.houses[H].hasObjects}
                                style={{float:'right', border: 'none', borderRadius: '3px'}}
                                onClick={(event) => { event.preventDefault(); event.stopPropagation(); this.showObjects(this.props.RoomPlan.houses[H].name, this.props.RoomPlan.houses[H].name); }}
                            >
                                <FileOutlined />
                            </Button>
                        </div>,
                        dataIndex: 'room',
                        key: 'room' + H,
                        fixed: 'left',
                        width: '320px',
                        render: (text) => {
                            var jobs = text.openJobs || false;
                            var notesCount = text.notesCount || 0;
                                return {
                                    props: {
                                        className: `${(jobs ? ' jobs ' : '') + this.getCellClass(text.actTenant.status)} ${text.actTenant.status === 3 && text.actTenant.departUnknown ? "cellDeepBlue" : ""}`
                                    },
                                    children: <span className={'roomTitle'}><span
                                        style={{color: text.actTenant.note ? "red" : "inherit"}}>{text.actTenant.text} {text.actTenant.expired ?
                                        <Tooltip title={text.actTenant.expiredMessage}><CloseOutlined style={{
                                            color: 'red',
                                            fontWeight: 'bold'
                                        }}/></Tooltip> : ''} {text.actTenant.hasBirthday ?
                                        <GiftTwoTone/> : null} </span><br/><span>{text.name}</span>
                                                <span className={'testel'}>{text.actTenant.kdnr > 0 &&
                                                    <CopyToClipboard text={text.actTenant.kdnr}>
                                                        <Button size="small" style={{
                                                            float: 'right',
                                                            border: 'none',
                                                            borderRadius: '3px'
                                                        }} onClick={(event) => {
                                                            event.preventDefault();
                                                            event.stopPropagation();
                                                        }}>
                                                            <CopyOutlined/>
                                                        </Button>
                                                    </CopyToClipboard>}
                                                </span>
                                                <Button size="small" danger={text.openTodos}
                                                        style={{float: 'right', border: 'none', borderRadius: '3px'}}
                                                        onClick={(event) => {
                                                            event.preventDefault();
                                                            event.stopPropagation();
                                                            this.showTodos(text.fullName, this.props.RoomPlan.houses[H].name, text.fullName);
                                                        }}>
                                                    <CheckSquareOutlined/>
                                                </Button>
                                                <Button size="small" danger={text.hasObjects}
                                                        style={{float: 'right', border: 'none', borderRadius: '3px'}}
                                                        onClick={(event) => {
                                                            event.preventDefault();
                                                            event.stopPropagation();
                                                            this.showObjects(text.fullName, this.props.RoomPlan.houses[H].name, text.fullName);
                                                        }}>
                                                    <FileOutlined/>
                                                </Button>
                                                <span style={{float: "right", marginRight: "5px"}}><Badge count={notesCount}/></span>
                                              </span>
                                };
                        },
                        onCell: record => ({
                            onClick: (e) => {
                                if(parseInt(record.room.actReservationID) > 0 && !e.target.toString().includes('HTMLButtonElement')) {
                                    this.showReservation(record.room.actReservationID, "01."+this.props.RoomPlan.year, this.props.RoomPlan.houses[H].name,record.room.fullName);
                                }

                            },
                        })


                    }, ...columnsDays
                ];

                /*
                , {
                        title: 'Check In',
                        dataIndex: 'checkin',
                        key: 'checkin' + H,
                        fixed: 'left',
                        width: '150px',
                    }, {
                        title: 'Person',
                        dataIndex: 'Person',
                        key: 'Person' + H,
                        fixed: 'left',
                        width: '150px',
                    }
                */
                tables.push({columns: columns, data: this.props.RoomPlan.houses[H].rooms});
            }

        }


        return (
            <React.Fragment>
                <Modal
                    title="Blank herunterladen"
                    visible={this.state.BlankVisible}
                    onOk={this.submitBlank}
                    onCancel={this.cancelBlank}
                    footer={[
                        <Button key="back" onClick={this.cancelBlank} disabled={this.state.BlankLoading}>Abbrechen</Button>,
                        <Button key="submit" type="primary" onClick={this.submitBlank} disabled={this.state.BlankLoading} loading={this.state.BlankLoading}>{this.state.BlankLoading ? 'Wird erstellt...' : 'Download'}</Button>
                    ]}
                >
                    <p>Sie können hier eine leere Vorlage der Übersicht herunterladen.<br/>Bitte wählen Sie hierzu den gewünschten Zeitraum aus.<br/><br/><b>Hinweis:</b> Die Erstellung dauert ca. 25 Sekunden.</p>
                    <MonthPicker id={"monthPickerBlank"} value={(this.state.BlankMonth ? this.state.BlankMonth : this.props.selectedMonth)} onChange={(value) => this.setState({BlankMonth: value})} />
                </Modal>
                
                <Reservation />
                <Todos />
                <Objects />
                <Spin tip="Loading..." spinning={(this.props.loadingRoomPlan && !this.props.RoomPlan.houses)}>
                <LayoutWrapper>
                    <MonthPicker disabled={this.props.loadingRoomPlan} id={"monthPicker"} value={this.props.selectedMonth} onChange={(value) => this.props.actions.selectMonth(value)} style={{height: '100%', width: 'auto'}} />
                    <div style={{marginBottom: "10px", marginLeft: "auto"}}>
                        {/*<Button type={"default"} htmlType={"button"} style={{marginLeft: "10px"}} className={"round"} onClick={() => {history.push("/app/Doku/Zimmerbelegung")}} icon={"read"}>Bedienungsanleitung</Button>*/}
                    <Button type={"primary"} htmlType={"button"}  className={"round"} onClick={this.downloadBlank} icon={<DownloadOutlined/>} disabled={this.state.BlankLoading} loading={this.state.BlankLoading} >{this.state.BlankLoading ? 'Wird erstellt...' : 'Download Blank'}</Button>
                    <Button type={"primary"} htmlType={"button"} style={{marginLeft: "10px"}} className={"round"} onClick={() => this.props.actions.LoadRoomPlan(this.props.selectedMonth)} icon={<ReloadOutlined/>} disabled={this.props.loadingRoomPlan} loading={this.props.loadingRoomPlan}>Aktualisieren</Button>
                    {!this.state.disabled34 && <Button type={"primary"} htmlType={"button"} style={{marginLeft: "10px"}} className={"round"} onClick={this.addReservation} icon={<PlusOutlined/>}>Hinzufügen</Button>}

                    </div>
                <Box >

                    {tables.map(house =>
                        <Table
                                className={"roomplanTable"}
                                columns={house.columns}
                               dataSource={house.data}
                                pagination={false}
                               rowClassName={(record, index) => 'tableCursor'}
                               loading={this.props.loadingRoomPlan}
                               scroll={this.props.serverprint ? {} : {x: window.innerWidth > 1220 ? '230%' : '500%'}}
                               bordered={true}
                               rowStyle={(record, index, indent) => (index === 0 ? { backgroundColor: 'red' } : null)}
                        />

                    )}

                    {/*

                    onRow={(record, index) => ({
                                   onClick: () => {this.showReservation(record); console.log(index) }
                               })}

                    this.props.RoomPlan.map(house =>
                        <Table columns={house.columns}
                               dataSource={house.data}
                               onRow={(record) => ({
                                   onClick: () => this.showAntrag(record.id)
                               })}
                               rowClassName={(record, index) => 'tableCursor'}
                               loading={this.props.loadingRoomPlan}
                               scroll={{x: '400%'}}
                               bordered={true}
                               rowStyle={(record, index, indent) => (index == 0 ? { backgroundColor: 'red' } : null)}
                        />

                    )*/}


                </Box>
                </LayoutWrapper>
                </Spin>
            </React.Fragment>
        );
    }
}


function mapStateToProps(state) {
    const { RoomPlanReducer, Auth } = state;
    const { RoomPlan, loadingRoomPlan, selectedMonth, ReservationSaved, TodoSaved, ObjectSaved } = RoomPlanReducer;
    const {serverprint, blank, blankmonth, idToken, GroupID} = Auth;
    return {
        RoomPlan,
        loadingRoomPlan,
        selectedMonth,
        ReservationSaved,
        TodoSaved,
        ObjectSaved,
        serverprint,
        blank,
        blankmonth,
        idToken,
        GroupID
    };
}


function mapDispatchToProps(dispatch) {
    return{
        actions: bindActionCreators(RoomPlanActions, dispatch)
    };
}



export default connect(mapStateToProps, mapDispatchToProps)(RoomPlan);
