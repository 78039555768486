import {CALL_API} from "../../helpers/middleware";

const TodoRezeptionActions = {

    LOAD_TODO_REZEPTION_LIST_REQUEST : 'LOAD_TODO_REZEPTION_LIST_REQUEST',
    LOAD_TODO_REZEPTION_LIST_SUCCESS : 'LOAD_TODO_REZEPTION_LIST_SUCCESS',
    LOAD_TODO_REZEPTION_LIST_ERROR : 'LOAD_TODO_REZEPTION_LIST_ERROR',
    SET_TODO_REZEPTION_TABLE_PAGE: 'SET_TODO_REZEPTION_TABLE_PAGE',
    SET_TODO_REZEPTION_TABLE_INFO: 'SET_TODO_REZEPTION_TABLE_INFO',
    SET_SEARCH_TEXT: 'SET_SEARCH_TEXT',

    SHOW_TODO_REZEPTION_MODAL: 'SHOW_TODO_REZEPTION_MODAL',
    SAVE_TODO_REZEPTION_REQUEST: 'SAVE_TODO_REZEPTION_REQUEST',
    SAVE_TODO_REZEPTION_SUCCESS: 'SAVE_TODO_REZEPTION_SUCCESS',
    SAVE_TODO_REZEPTION_ERROR: 'SAVE_TODO_REZEPTION_ERROR',

    LOAD_TODO_REZEPTION_DETAIL_REQUEST: 'LOAD_TODO_REZEPTION_DETAIL_REQUEST',
    LOAD_TODO_REZEPTION_DETAIL_SUCCESS: 'LOAD_TODO_REZEPTION_DETAIL_SUCCESS',
    LOAD_TODO_REZEPTION_DETAIL_ERROR: 'LOAD_TODO_REZEPTION_DETAIL_ERROR',

    SAVE_TODO_REZEPTION_TODO_NOTE_REQUEST: 'SAVE_TODO_REZEPTION_TODO_NOTE_REQUEST',
    SAVE_TODO_REZEPTION_TODO_NOTE_SUCCESS: 'SAVE_TODO_REZEPTION_TODO_NOTE_SUCCESS',
    SAVE_TODO_REZEPTION_TODO_NOTE_ERROR: 'SAVE_TODO_REZEPTION_TODO_NOTE_ERROR',

    DELETE_TODO_REZEPTION_REQUEST: 'DELETE_TODO_REZEPTION_REQUEST',
    DELETE_TODO_REZEPTION_SUCCESS: 'DELETE_TODO_REZEPTION_SUCCESS',
    DELETE_TODO_REZEPTION_ERROR: 'DELETE_TODO_REZEPTION_ERROR',
};

export default TodoRezeptionActions;


export function LoadTodoList(params = {}) {
    return {
        [CALL_API]: {
            reqType: 'POST',
            endpoint: 'todos/rezeption',
            postData: {
                results: 10,
                ...params,
            },
            types: [TodoRezeptionActions.LOAD_TODO_REZEPTION_LIST_REQUEST, TodoRezeptionActions.LOAD_TODO_REZEPTION_LIST_SUCCESS, TodoRezeptionActions.LOAD_TODO_REZEPTION_LIST_ERROR]
        }
    };
}

export function LoadMyTodoList() {
    return {
        [CALL_API]: {
            reqType: 'POST',
            endpoint: 'todos/rezeption',
            postData: {
                results: 100,
            },
            types: [null, null, null]
        }
    };
}

export function saveTodoDetail(values, TodoID) {
    let url = "todos/rezeption";
    let type = "POST";
    if(TodoID) {
        url += "/"+TodoID;
        type = "PUT";
    } else {
        url += "/new";
    }

    return {
        [CALL_API]: {
            reqType: type,
            endpoint: url,
            postData: values,
            types: [TodoRezeptionActions.SAVE_TODO_REZEPTION_REQUEST, TodoRezeptionActions.SAVE_TODO_REZEPTION_SUCCESS, TodoRezeptionActions.SAVE_TODO_REZEPTION_ERROR]
        }
    };
}

export function LoadTodoDetail(TodoID) {
    return {
        [CALL_API]: {
            reqType: 'GET',
            endpoint: 'todos/rezeption/'+TodoID,
            types: [TodoRezeptionActions.LOAD_TODO_REZEPTION_DETAIL_REQUEST, TodoRezeptionActions.LOAD_TODO_REZEPTION_DETAIL_SUCCESS, TodoRezeptionActions.LOAD_TODO_REZEPTION_DETAIL_ERROR]
        }
    };
}

export function deleteTodo(TodoID) {
    return {
        [CALL_API]: {
            reqType: 'DELETE',
            endpoint: 'todos/rezeption/'+TodoID,
            types: [TodoRezeptionActions.DELETE_TODO_REZEPTION_REQUEST, TodoRezeptionActions.DELETE_TODO_REZEPTION_SUCCESS, TodoRezeptionActions.DELETE_TODO_REZEPTION_ERROR]
        }
    };
}


export function saveTodoNote(values, TodoID) {
    return {
        [CALL_API]: {
            reqType: 'POST',
            endpoint: 'todos/rezeption/'+TodoID+'/todonote',
            postData: values,
            types: [TodoRezeptionActions.SAVE_TODO_REZEPTION_TODO_NOTE_REQUEST, TodoRezeptionActions.SAVE_TODO_REZEPTION_TODO_NOTE_SUCCESS, TodoRezeptionActions.SAVE_TODO_REZEPTION_TODO_NOTE_ERROR]
        }
    };
}


export function showTodoModal(show, TodoID) {
    return {
        type: TodoRezeptionActions.SHOW_TODO_REZEPTION_MODAL,
        show,
        TodoID
    };
}

export function setTodoTablePage(pager) {
    return {
        type: TodoRezeptionActions.SET_TODO_REZEPTION_TABLE_PAGE,
        pager
    };
};

export function setTodoTableInfo(infos) {
    return {
        type: TodoRezeptionActions.SET_TODO_REZEPTION_TABLE_INFO,
        infos
    };
};

export function setSearchText(search) {
    return {
        type: TodoRezeptionActions.SET_SEARCH_TEXT,
        search
    };
};




