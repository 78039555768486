import React, {Component} from "react";
import {connect} from "react-redux";
import {bindActionCreators} from "redux";
import * as LustmolcheActions from "../../redux/Lustmolche/actions";
import {
    Input,
    Button,
    Radio,
    Divider,
    Modal,
    Select,
    InputNumber,
    Spin
} from 'antd';
import { Form } from '@ant-design/compatible';
import './list.style.css';
import FileModal from '../../components/FileModal';
import * as PersonsActions from "../../redux/Personen/actions";

let BASE_URL = process.env.REACT_APP_API_URL;
let API_VERSION = process.env.REACT_APP_API_VERSION;

const { TextArea } = Input;
const Option = Select.Option;
const FormItem = Form.Item;
const confirm = Modal.confirm;


const formItemLayout = {
    labelCol: {
        xs: { span: 24 },
        sm: { span: 8 },
    },
    wrapperCol: {
        xs: { span: 24 },
        sm: { span: 8 },
    },
};




class LustmolcheDetail extends Component {

    constructor(props) {
        super(props);
    }

    componentWillMount() {
        this.setState({ fileName: '', fileExtension: ''});
        this.props.PersonsActions.loadPersons();
    }


    componentWillReceiveProps(nextProps) {
        if(this.props.LustmolcheDetailVisible && !nextProps.LustmolcheDetailVisible) {
            this.props.actions.clearLustmolcheDetail();
            this.props.form.resetFields();
            this.props.PersonsActions.loadPersons();
            this.props.afterClose && this.props.afterClose();
        } else if(!this.props.LustmolcheDetailVisible && nextProps.LustmolcheDetailVisible) {

            if(nextProps.actLustmolcheID) {

                this.props.actions.loadLustmolcheDetail(nextProps.actLustmolcheID);
            }
        }
    }

    handleSubmit = (e) => {
        e.preventDefault();
        this.props.form.validateFieldsAndScroll({scroll: {offsetTop: 150}}, (err, values) => {
            if (!err) {
                this.props.actions.saveLustmolcheDetail(this.props.actLustmolcheID, values);
            }
        });
    };



    handleCancel = () => {
        this.props.actions.showLustmolcheDetail(false);
    };




    confirmDelete = () => {
        confirm({
            title: "Formular löschen",
            content: "Wollen Sie dieses Formular wirklich löschen?",
            okText: "Löschen",
            okType: 'danger',
            cancelText: "Abbrechen",
            onOk: () => this.props.actions.deleteLustmolche(this.props.actLustmolcheID),
            onCancel() {
            },
        });
    };





    render() {
        const { getFieldDecorator } = this.props.form;

        return (
            <React.Fragment>
            <FileModal visible={this.props.previewFileModalVisible} file={this.props.previewFileModalFile} onOk={this.previewFileModalOK} onCancel={this.previewFileModalCancel}/>
            <Modal
                title="Lustmolche Anmeldung"
                visible={this.props.LustmolcheDetailVisible}
                onOk={this.handleSubmit}
                onCancel={this.handleCancel}
                width={"80%"}
                footer={[
                    <Button key="deleteLustmolche" type="danger" loading={this.props.deletingLustmolche} onClick={this.confirmDelete}>
                        Formular löschen
                    </Button>,
                    <Button key="back" onClick={this.handleCancel}>Abbrechen</Button>,
                    <Button key="submit" type="primary" onClick={this.handleSubmit}>Speichern</Button>
                ]}
            >
                <Spin spinning={this.props.loadingLustmolcheDetail}>

                    <form onSubmit={this.handleSubmit} style={{width: '100%'}}>

                        <Divider orientation="center" className={"lightDivider"}>Kunde</Divider>
                        <FormItem
                            {...formItemLayout}
                            label={"Person zuweisen"}
                            key={'personId'}
                        >
                            {getFieldDecorator('personId', {
                                rules: [
                                    {
                                        validator: this.checkPerson
                                    }
                                ],
                                initialValue: this.props.LustmolcheDetail["personId"],
                            })(
                                <Select
                                    getPopupContainer={trigger => trigger.parentNode}
                                    showSearch
                                    placeholder="Wählen Sie eine Person"
                                    optionFilterProp="children"
                                    filterOption={(input, option) => option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
                                    disabled={!!this.props.LustmolcheDetail["personId"]}
                                >
                                    {this.props.Persons.map(person => <Option key={person.id} value={person.id}>{person.displayname}</Option>)}
                                </Select>
                            )}
                        </FormItem>

                        <Divider orientation="center" className={"lightDivider"}>Lustmolche</Divider>

                                <FormItem {...formItemLayout}
                                          label="Name:">{getFieldDecorator("lastname", {
                                    rules: [{
                                        required: false,
                                    }], initialValue: this.props.LustmolcheDetail["lastname"],
                                })(
                                    <Input type={"text"} disabled={!!this.props.LustmolcheDetail["personId"]} />)}
                                </FormItem>
                                <FormItem {...formItemLayout}
                                          label="Vorname:">{getFieldDecorator("firstname", {
                                    rules: [{
                                        required: false,
                                    }], initialValue: this.props.LustmolcheDetail["firstname"],
                                })(
                                    <Input type={"text"} disabled={!!this.props.LustmolcheDetail["personId"]} />)}
                                </FormItem>
                                <FormItem {...formItemLayout}
                                          label="Künstlername:">{getFieldDecorator("stagename", {
                                    rules: [{
                                        required: false,
                                    }], initialValue: this.props.LustmolcheDetail["stagename"],
                                })(
                                    <Input type={"text"} disabled={!!this.props.LustmolcheDetail["personId"]} />)}
                                </FormItem>
                                <FormItem {...formItemLayout}
                                          label="Nationalität:">{getFieldDecorator("nationality", {
                                    rules: [{
                                        required: false,
                                    }], initialValue: this.props.LustmolcheDetail["nationality"],
                                })(
                                    <Input type={"text"} disabled={!!this.props.LustmolcheDetail["personId"]} />)}
                                </FormItem>
                                <FormItem {...formItemLayout}
                                          label="Sternzeichen:">{getFieldDecorator("starsign", {
                                    rules: [{
                                        required: false,
                                    }], initialValue: this.props.LustmolcheDetail["starsign"],
                                })(
                                    <Select
                                        getPopupContainer={trigger => trigger.parentNode}
                                        disabled={!!this.props.LustmolcheDetail["personId"]} >
                                        {Sternzeichen.map(item => (
                                            <Select.Option key={item} value={item}>
                                                {item}
                                            </Select.Option>
                                        ))}
                                    </Select>)}
                                </FormItem>
                                <FormItem {...formItemLayout}
                                          label="Alter:">{getFieldDecorator("age", {
                                    rules: [{
                                        required: false,
                                    }], initialValue: this.props.LustmolcheDetail["age"],
                                })(
                                    <InputNumber style={{width: '100%'}} min={18} disabled={!!this.props.LustmolcheDetail["personId"]}/>)}
                                </FormItem>
                                <FormItem {...formItemLayout}
                                          label="Haarfarbe:">{getFieldDecorator("haircolor", {
                                    rules: [{
                                        required: false,
                                    }], initialValue: this.props.LustmolcheDetail["haircolor"],
                                })(
                                    <Input type={"text"} disabled={!!this.props.LustmolcheDetail["personId"]} />)}
                                </FormItem>
                                <FormItem {...formItemLayout}
                                          label="BH-Größe:">{getFieldDecorator("brasize", {
                                    rules: [{
                                        required: false,
                                    }], initialValue: this.props.LustmolcheDetail["brasize"],
                                })(
                                    <Input type={"text"} disabled={!!this.props.LustmolcheDetail["personId"]} />)}
                                </FormItem>
                                <FormItem {...formItemLayout}
                                          label="Körpergröße:">{getFieldDecorator("height", {
                                    rules: [{
                                        required: false,
                                    }], initialValue: this.props.LustmolcheDetail["height"],
                                })(
                                    <InputNumber min={0} max={250} step={1} style={{width: '100%'}} disabled={!!this.props.LustmolcheDetail["personId"]}/>)}
                                </FormItem>
                                <FormItem {...formItemLayout}
                                          label="Telefon:">{getFieldDecorator("tel", {
                                    rules: [{
                                        required: false,
                                    }], initialValue: this.props.LustmolcheDetail["tel"],
                                })(
                                    <Input type={"text"} disabled={!!this.props.LustmolcheDetail["personId"]} />)}
                                </FormItem>
                                <FormItem {...formItemLayout}
                                          label="E-Mail:">{getFieldDecorator("email", {
                                    rules: [
                                        {
                                            type: 'email',
                                            message: 'Bitte geben Sie eine gültie E-Mail Adresse ein',
                                        },
                                        {
                                            required: false,
                                        },
                                    ], initialValue: this.props.LustmolcheDetail["email"],
                                })(
                                    <Input type={"email"} disabled={!!this.props.LustmolcheDetail["personId"]} />)}
                                </FormItem>
                                <FormItem {...formItemLayout}
                                          label="Bundesland:">{getFieldDecorator("state", {
                                    rules: [{
                                        required: false,
                                    }], initialValue: this.props.LustmolcheDetail["state"],
                                })(
                                    <Select
                                        getPopupContainer={trigger => trigger.parentNode}
                                        disabled={!!this.props.LustmolcheDetail["personId"]} >
                                        {Bundeslaender.map(item => (
                                            <Select.Option key={item} value={item}>
                                                {item}
                                            </Select.Option>
                                        ))}
                                    </Select>)}
                                </FormItem>
                                <FormItem {...formItemLayout}
                                          label="Gewicht:">{getFieldDecorator("weight", {
                                    rules: [{
                                        required: false,
                                    }], initialValue: this.props.LustmolcheDetail["weight"],
                                })(
                                    <InputNumber style={{width: '100%'}} disabled={!!this.props.LustmolcheDetail["personId"]}/> )}
                                </FormItem>

                                <FormItem {...formItemLayout}
                                          label="Facebook Link:">{getFieldDecorator("facebook", {
                                    rules: [{
                                        required: false,
                                    }], initialValue: this.props.LustmolcheDetail["facebook"],
                                })(
                                    <Input type={"text"} disabled={!!this.props.LustmolcheDetail["personId"]} />)}
                                </FormItem>
                                <FormItem {...formItemLayout}
                                          label="Instagram Link:">{getFieldDecorator("instagram", {
                                    rules: [{
                                        required: false,
                                    }], initialValue: this.props.LustmolcheDetail["instagram"],
                                })(
                                    <Input type={"text"} disabled={!!this.props.LustmolcheDetail["personId"]} />)}
                                </FormItem>
                                <FormItem {...formItemLayout}
                                          label="Snapchat Name:">{getFieldDecorator("snapchat", {
                                    rules: [{
                                        required: false,
                                    }], initialValue: this.props.LustmolcheDetail["snapchat"],
                                })(
                                    <Input type={"text"} disabled={!!this.props.LustmolcheDetail["personId"]} />)}
                                </FormItem>
                                <FormItem {...formItemLayout}
                                          label="Kategorie:">{getFieldDecorator("category", {
                                    rules: [{
                                        required: false,
                                    }], initialValue: this.props.LustmolcheDetail["category"],
                                })(
                                    <Select
                                            getPopupContainer={trigger => trigger.parentNode}
                                            disabled={!!this.props.LustmolcheDetail["personId"]}
                                    >
                                        <Option value={"Dominas"} key={"Dominas"}>
                                            Dominas
                                        </Option>
                                        <Option value={"Ladys"} key={"Ladys"}>
                                            Ladys
                                        </Option>
                                        <Option value={"She-Males"} key={"She-Males"}>
                                            She-Males
                                        </Option>
                                    </Select>)}
                                </FormItem>


                                <FormItem {...formItemLayout}
                                          label="Leidenschaften:">{getFieldDecorator("passions", {
                                    rules: [{
                                        required: false,
                                    }], initialValue: this.props.LustmolcheDetail["passions"],
                                })(
                                    <Select
                                        mode="multiple"
                                        placeholder="Leidenschaften auswählen"
                                        style={{ width: '100%' }}
                                        getPopupContainer={trigger => trigger.parentNode}
                                        disabled={!!this.props.LustmolcheDetail["personId"]}
                                    >
                                        {Leidenschaften.map(item => (
                                            <Select.Option key={item} value={item}>
                                                {item}
                                            </Select.Option>
                                        ))}
                                    </Select>
                                )}
                                </FormItem>

                                <FormItem {...formItemLayout}
                                          label="Sprachen:">{getFieldDecorator("languages", {
                                    rules: [{
                                        required: false,
                                    }], initialValue: this.props.LustmolcheDetail["languages"],
                                })(
                                    <Select
                                        mode="multiple"
                                        placeholder="Sprachen auswählen"
                                        style={{ width: '100%' }}
                                        getPopupContainer={trigger => trigger.parentNode}
                                        disabled={!!this.props.LustmolcheDetail["personId"]}
                                    >
                                        {Sprachen.map(item => (
                                            <Select.Option key={item} value={item}>
                                                {item}
                                            </Select.Option>
                                        ))}
                                    </Select>
                                )}
                                </FormItem>

                                <FormItem {...formItemLayout}
                                          label="Rasiert:">{getFieldDecorator("shaved", {
                                    rules: [{
                                        required: false,
                                    }], initialValue: this.props.LustmolcheDetail["shaved"] || "Nein",
                                })(
                                    <Radio.Group disabled={!!this.props.LustmolcheDetail["personId"]} >
                                        <Radio value="Ja">Ja</Radio>
                                        <Radio value="Nein">Nein</Radio>
                                    </Radio.Group>)}
                                </FormItem>

                                <FormItem {...formItemLayout}
                                          label="Piercings:">{getFieldDecorator("piercings", {
                                    rules: [{
                                        required: false,
                                    }], initialValue: this.props.LustmolcheDetail["piercings"] || "Nein",
                                })(
                                    <Radio.Group disabled={!!this.props.LustmolcheDetail["personId"]} >
                                        <Radio value="Ja">Ja</Radio>
                                        <Radio value="Nein">Nein</Radio>
                                    </Radio.Group>)}
                                </FormItem>

                                <FormItem {...formItemLayout}
                                          label="Tattoos:">{getFieldDecorator("inked", {
                                    rules: [{
                                        required: false,
                                    }], initialValue: this.props.LustmolcheDetail["inked"] || "Nein",
                                })(
                                    <Radio.Group disabled={!!this.props.LustmolcheDetail["personId"]} >
                                        <Radio value="Ja">Ja</Radio>
                                        <Radio value="Nein">Nein</Radio>
                                    </Radio.Group>)}
                                </FormItem>

                                <FormItem {...formItemLayout}
                                          label="Raucherin:">{getFieldDecorator("smoker", {
                                    rules: [{
                                        required: false,
                                    }], initialValue: this.props.LustmolcheDetail["smoker"] || "Nein",
                                })(
                                    <Radio.Group disabled={!!this.props.LustmolcheDetail["personId"]} >
                                        <Radio value="Ja">Ja</Radio>
                                        <Radio value="Nein">Nein</Radio>
                                    </Radio.Group>)}
                                </FormItem>

                                <FormItem {...formItemLayout}
                                          label="Behinderte:">{getFieldDecorator("handicapped", {
                                    rules: [{
                                        required: false,
                                    }], initialValue: this.props.LustmolcheDetail["handicapped"] || "Nein",
                                })(
                                    <Radio.Group disabled={!!this.props.LustmolcheDetail["personId"]} >
                                        <Radio value="Ja">Ja</Radio>
                                        <Radio value="Nein">Nein</Radio>
                                    </Radio.Group>)}
                                </FormItem>

                                <FormItem {...formItemLayout}
                                          label="Beschreibung:">{getFieldDecorator("description", {
                                    rules: [{
                                        required: false,
                                    }], initialValue: this.props.LustmolcheDetail["description"],
                                })(<TextArea disabled={!!this.props.LustmolcheDetail["personId"]}  />)}
                                </FormItem>

                    </form>
                </Spin>
            </Modal>
        </React.Fragment>
        );
    }
}


function mapStateToProps(state) {
    const { LustmolcheReducer, PersonsReducer } = state;
    const { actLustmolcheID, loadingLustmolcheDetail, deletingLustmolche, LustmolcheDetail, savingLustmolcheDetail, previewFileModalVisible, previewFileModalFile, LustmolcheDetailVisible } = LustmolcheReducer;
    const { Persons } = PersonsReducer;
    return {
        Persons,
        actLustmolcheID,
        deletingLustmolche,
        loadingLustmolcheDetail,
        LustmolcheDetail,
        savingLustmolcheDetail,
        previewFileModalVisible,
        previewFileModalFile,
        LustmolcheDetailVisible
    };
}


function mapDispatchToProps(dispatch) {
    return{
        actions: bindActionCreators(LustmolcheActions, dispatch),
        PersonsActions : bindActionCreators(PersonsActions, dispatch)
    };
}



export default connect(mapStateToProps, mapDispatchToProps)(Form.create()(LustmolcheDetail));


const Leidenschaften = [
    "Schmusen & Küssen",
    "Zungenküsse",
    "Körperküsse",
    "Striptease",
    "Verbalerotik",
    "Dirty Talk",
    "Fußerotik",
    "Schuherotik",
    "Straps- und Nylonerotik",
    "Busenerotik",
    "Fingerspiele",
    "Dildospiele",
    "Fesselspiele",
    "Rollenspiele",
    "Gesichtsbesamung",
    "Körperbesamung",
    "Natursekt aktiv",
    "Natursekt passiv",
    "Kaviar aktiv",
    "Stellungswechsel",
    "Girlfriend Sex",
    "Double Penetration",
    "Threesome (MFF)",
    "Threesome (MMF)",
    "LS mit Freundin(nen)",
    "Masturbation, HE",
    "Französisch",
    "Eierlecken",
    "Facesitting",
    "69",
    "Deep Throat",
    "Hodenmassage",
    "Prostatamassage",
    "Analverkehr",
    "Anal bei Dir",
    "Erotikmassage",
    "Body to Body",
    "Lack und Leder",
    "Sexy Dessous",
    "Dominanzspiele",
    "Leichte Erziehung",
    "Duschservice",
    "Badeservice",
    "Extra langes Vorspiel",
    "Haus- und Hotelbesuche",
    "Escort",
    "SM Services",
    "Demütigung",
    "Verbalerniedrigung",
    "Körperliche Demütigung",
    "Faustfick",
    "Fisting aktiv",
    "Fisting passiv",
    "Sklavenerziehung",
    "Tierdressur",
    "Hundevorführung",
    "Verhöre",
    "Erziehungsspielchen",
    "Bondage",
    "BDSM",
    "Anspucken",
    "Speichelspiele",
    "Körperfolter",
    "Nippelfolter",
    "Genitalfolter",
    "Trampeln",
    "Flagellation",
    "Ohrfeigen",
    "Auspeitschen",
    "Analbehandlung",
    "Wachsbehandlung",
    "Atemreduktion",
    "Elektrostimulation",
    "Intimrasur",
    "Intim Massage",
    "Tantra Massage",
    "Kama Sutra Massage",
    "Kama Sutra Praktiken",
    "Erotikfotos",
    "Erotikfilme",
    "Swingen",
    "Clubbegleitung"
];


const Bundeslaender = [
    "Baden-Württemberg",
    "Bayern",
    "Berlin",
    "Bremen",
    "Hamburg",
    "Hessen",
    "Mecklenburg-Vorpommern",
    "Niedersachsen",
    "Nordrhein-Westfalen",
    "Rheinland-Pfalz",
    "Saarland",
    "Sachsen",
    "Sachsen-Anhalt",
    "Schleswig-Holstein",
    "Thüringen"
];

const Sternzeichen = [
    "Wassermann",
    "Fische",
    "Widder",
    "Stier",
    "Zwillinge",
    "Krebs",
    "Jungfrau",
    "Waage",
    "Skorpion",
    "Steinbock",
    "Löwe",
    "Schütze",
];

const Sprachen = [
    "Deutsch",
    "Englisch",
    "Französisch",
    "Italienisch",
    "Polnisch",
    "Rumänisch",
    "Russisch",
    "Bulgarisch",
    "Ungarisch",
    "Spanisch",
    "Türkisch",
    "Portugiesisch",
    "Tschechisch",
    "Moldavisch",
    "Afrikaans",
    "Japanisch",
    "Koreanisch",
    "Griechisch"
];