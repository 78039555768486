import React, { Component } from 'react';
import { connect } from 'react-redux';
//import { Layout } from 'antd';
import * as appActions from '../../redux/app/actions';
import ActionFooterWrapper from './actionFooter.style';
import {bindActionCreators} from "redux";

class ActionFooter extends Component {
    render() {
        const collapsed = this.props.collapsed && !this.props.openDrawer;
        /*const styling = {
            background: customizedTheme.backgroundColor,
            position: 'fixed',
            width: '100%',
            height: 60
        };*/
        return (
            <ActionFooterWrapper
                className={ collapsed ? 'actionFooter collapsed' : 'actionFooter' }
            >


                {this.props.children}
            </ActionFooterWrapper>
        );
    }
}

function mapStateToProps(state) {
    const { App} = state;
    const {collapsed, openDrawer } = App;

    return {
        collapsed,
        openDrawer
    };
}


function mapDispatchToProps(dispatch) {
    return{
        actions: bindActionCreators(appActions, dispatch)
    };
}


export default connect(mapStateToProps, mapDispatchToProps)(ActionFooter);




/*
<Header
                    style={styling}
                    className={
                        collapsed ? 'isomorphicTopbar collapsed' : 'isomorphicTopbar'
                    }
                >
                    <div className="isoLeft">
                        <button
                            className={
                                collapsed ? 'triggerBtn menuCollapsed' : 'triggerBtn menuOpen'
                            }
                            style={{ color: customizedTheme.textColor }}
                            onClick={toggleCollapsed}
                        />
                    </div>

                    <ul className="isoRight">
                        <li
                            onClick={() => this.setState({ selectedItem: 'user' })}
                            className="isoUser"
                        >
                            <TopbarUser />
                        </li>
                    </ul>
                </Header>
 */