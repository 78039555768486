import React, {Component} from "react";
import {Select, Modal, Input, Checkbox, Button, DatePicker} from 'antd';
import { Form } from '@ant-design/compatible';
import {bindActionCreators} from "redux";
import * as HolidayActions from "../../redux/Holiday/actions";
import * as UsersActions from "../../redux/Users/actions";
import {connect} from "react-redux";


const FormItem = Form.Item;
const Option = Select.Option;
const confirm = Modal.confirm;


const formItemLayout = {
    labelCol: {
        xs: { span: 20 },
        sm: { span: 6 },
    },
    wrapperCol: {
        xs: { span: 20 },
        sm: { span: 13 },
    },
};

const formItemLayoutWithOutLabel = {
    wrapperCol: {
        xs: { span: 24, offset: 0 },
        sm: { span: 24, offset: 0 },
    },
};


class HolidayDetailModal extends Component {


    componentWillMount() {
        this.props.UsersActions.LoadUsers({results: 9999});
    }

    handleSubmit = (e) => {
        e.preventDefault();
        this.props.form.validateFieldsAndScroll((err, values) => {
            if (!err) {
                this.props.actions.saveHolidayDetail(values, this.props.HolidayDetailsID);
            }
        });
    };


    componentWillReceiveProps(nextProps) {
        if(this.props.visible && !nextProps.visible) {
            this.props.form.resetFields();
            this.props.actions.resetDetail();
        } else if(!this.props.visible && nextProps.visible && nextProps.HolidayDetailsID) {
            this.props.actions.LoadHolidayDetails(nextProps.HolidayDetailsID);
        }
    }

    confirmDelete = () => {
        confirm({
            title: "Urlaub löschen",
            content: "Wollen Sie diesen Urlaub wirklich löschen?",
            okText: "Löschen",
            okType: 'danger',
            cancelText: "Abbrechen",
            onOk: () => this.props.actions.deleteHoliday(this.props.HolidayDetailsID),
            onCancel() {
            },
        });
    };



    render() {
        const { getFieldDecorator } = this.props.form;


        return (
                <Modal
                    title={`Urlaub ${this.props.HolidayDetailsID ? 'bearbeiten' : 'anlegen'}`}
                    visible={this.props.visible}
                    onOk={this.handleSubmit}
                    onCancel={this.props.handleCancel}
                    cancelText={"Abbrechen"}
                    okText={"Speichern"}
                    width={"750px"}
                    footer={[
                        this.props.HolidayDetailsID ? <Button key="deleteholiday" type="danger" loading={this.props.deletingHoliday} onClick={this.confirmDelete}>
                            Urlaub löschen
                        </Button> : '',
                        <Button key="cancel" onClick={this.props.handleCancel}>Abbrechen</Button>,
                        <Button key="submit" type="primary" loading={this.props.savingHoliday} onClick={this.handleSubmit}>
                            Speichern
                        </Button>
                    ]}
                >



                    <form onSubmit={this.handleSubmit} style={{width: '100%'}}>

                        <FormItem
                            {...formItemLayout}
                            label={"Mitarbeiter"}
                            required={true}
                            key={'UserID'}
                        >
                            {getFieldDecorator('UserID', {
                                initialValue: this.props.Holiday.UserID,
                                rules: [{
                                    required: true,
                                    message: "Bitte geben Sie diese Information an."
                                }],
                            })(
                                <Select
                                    showSearch
                                    style={{ width: '100%' }}
                                    placeholder="Wählen Sie eine Person aus"
                                    optionFilterProp="children"
                                    filterOption={(input, option) => option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
                                    loading={this.props.loadingUsers}
                                    getPopupContainer={trigger => trigger.parentNode}
                                >
                                    {this.props.Users.map(person => <Option key={person.UserID} value={person.UserID}>{person.Vorname + " " + person.Nachname}</Option>)}
                                </Select>
                            )}
                        </FormItem>



                        <FormItem
                            {...formItemLayout}
                            label={"Urlaubsbeginn"}
                            required={true}
                            key={'StartDatum'}
                        >
                            {getFieldDecorator('StartDatum', {
                                initialValue: this.props.Holiday.StartDatum,
                                rules: [{
                                    required: true,
                                    message: "Bitte geben Sie diese Information an."
                                }],
                            })(
                                <DatePicker getPopupContainer={trigger => trigger.parentNode} format={"DD.MM.YYYY"} placeholder={"Wählen Sie den Beginn des Urlaubes"} />
                            )}
                        </FormItem>
                        <FormItem
                            {...formItemLayout}
                            label={"Urlaubsende"}
                            required={true}
                            key={'EndDatum'}
                        >
                            {getFieldDecorator('EndDatum', {
                                initialValue: this.props.Holiday.EndDatum,
                                rules: [{
                                    required: true,
                                    message: "Bitte geben Sie diese Information an."
                                }],
                            })(
                                <DatePicker getPopupContainer={trigger => trigger.parentNode} format={"DD.MM.YYYY"} placeholder={"Wählen Sie das Ende des Urlaubes"} />
                            )}
                        </FormItem>

                    </form>


                </Modal>


        );
    }
}



function mapStateToProps(state) {
    const { HolidayReducer, UsersReducer } = state;
    const { Holiday, HolidayDetailsID, savingHoliday, deletingHoliday } = HolidayReducer;
    const { loadingUsers, Users } = UsersReducer;
    return {
        Holiday,
        HolidayDetailsID,
        savingHoliday,
        deletingHoliday,

        loadingUsers,
        Users,
    };
}


function mapDispatchToProps(dispatch) {
    return{
        actions: bindActionCreators(HolidayActions, dispatch),
        UsersActions: bindActionCreators(UsersActions, dispatch),
    };
}



export default connect(mapStateToProps, mapDispatchToProps)(Form.create()(HolidayDetailModal));


//export default Form.create()(HolidayDetailModal);