import React, {Component} from "react";
import {connect} from "react-redux";
import {bindActionCreators} from "redux";
import * as AufgabenActions from "../../redux/Aufgaben/actions";
import { Input, Button, DatePicker, Table, Row, Col, Radio, Divider, Modal, Dropdown, Menu, Card } from 'antd';
import Box from '../../components/utility/box';
import Header from '../../components/utility/pageHeader';
import LayoutWrapper from '../../components/utility/layoutWrapper';
import { history } from '../../redux/store';
import AufgabenDetailModal from './AufgabenDetailModal';
import './list.style.css';
import {EditOutlined} from "@ant-design/icons";

const { RangePicker } = DatePicker;
const RadioGroup = Radio.Group;


const Search = Input.Search;
const itemStyle = {margin: '0px'};





class Aufgaben extends Component {



    componentWillMount() {
        this.props.actions.LoadAufgaben();
    }



    componentWillUpdate(nextProps) {
        if(this.props.AufgabenModalVisible && !nextProps.AufgabenModalVisible &&
            this.props.loadingAufgabenList && !nextProps.loadingAufgabenList) {
            this.props.actions.LoadAufgaben();
        }
    }




    render() {


        return (
            <React.Fragment>
                <AufgabenDetailModal />
                <LayoutWrapper>
                    <Row gutter={16} style={{width: '100%'}}>

                    {this.props.AufgabenList.map(aufgabe =>
                        <Col span={6}>
                            <Card title={<span><span>{aufgabe.Group}</span>{this.props.isAdmin ? <a onClick={() =>this.props.actions.showAufgabenModal(true, aufgabe.GroupID)} style={{float: 'right'}}><EditOutlined /></a> : ''}</span>} bordered={false} style={{ width: 300 }}>
                                <ul>
                                {aufgabe.tasks.map(task =>
                                {
                                    if(task) {
                                        return <li>{task}</li>
                                    }
                                }
                                )}
                                </ul>
                            </Card>
                        </Col>
                    )}
                    </Row>
                </LayoutWrapper>
            </React.Fragment>
        );
    }
}


function mapStateToProps(state) {
    const { AufgabenReducer, Auth } = state;
    const { AufgabenList, loadingAufgabenList, AufgabenModalVisible } = AufgabenReducer;
    const {isAdmin} = Auth;

    return {
        AufgabenList,
        loadingAufgabenList,
        AufgabenModalVisible,
        isAdmin
    };
}


function mapDispatchToProps(dispatch) {
    return{
        actions: bindActionCreators(AufgabenActions, dispatch)
    };
}



export default connect(mapStateToProps, mapDispatchToProps)(Aufgaben);
