import styled from 'styled-components';
import { transition } from '../../config/style-util';

const ActionFooterWrapper = styled.div`
  & {
    display: flex;
    position: fixed;
    width:100%;
    bottom: 0;
    left:0;
    justify-content: space-between;
    background-color: rgba(255,255,255,0.9);
    border-top: 1px solid rgba(0, 0, 0, 0.1);
    box-shadow: 0px 0px 10px 0px rgba(219,219,219,0.85);
    padding: 10px 31px 10px 265px;
    z-index: 9;
    ${transition()};

    @media only screen and (max-width: 767px) {
      padding: 10px 15px 10px 260px !important;
    }

    &.collapsed {
      padding: 10px 31px 10px 109px;
      @media only screen and (max-width: 767px) {
        padding: 10px 15px !important;
      }
    }

    .isoLeft {
      display: flex;
      align-items: center;

      @media only screen and (max-width: 767px) {
        margin: 0 20px 0 0;
      }
    }

    .isoRight {
      display: flex;
      align-items: center;
      margin-bottom: 0;


    }
  }
`;

export default ActionFooterWrapper;
