import React, { Component } from 'react';
import { connect } from 'react-redux';
import clone from 'clone';
import { Link } from 'react-router-dom';
import { Layout } from 'antd';
import Scrollbars from '../../components/utility/customScrollBar.js';
import { Menu } from 'antd';
import SidebarWrapper from './sidebar.style';
import * as appActions from '../../redux/app/actions';
import Logo from '../../components/utility/logo';
import { getCurrentTheme } from '../ThemeSwitcher/config';
import {bindActionCreators} from "redux";
import {
    BookOutlined, CheckSquareOutlined, ClearOutlined,
    CompassOutlined,
    CreditCardOutlined, EditOutlined, FilePdfOutlined,
    LoginOutlined, MailOutlined, MessageOutlined,
    ShoppingCartOutlined,
    TeamOutlined, UserOutlined
} from "@ant-design/icons";


const SubMenu = Menu.SubMenu;
const MenuItemGroup = Menu.ItemGroup;
const { Sider } = Layout;



class Sidebar extends Component {
  constructor(props) {
    super(props);
    this.handleClick = this.handleClick.bind(this);
    this.onOpenChange = this.onOpenChange.bind(this);
  }




  handleClick(e) {
    this.props.appActions.changeCurrent([e.key]);

      if (this.props.openDrawer === true) {
          this.props.appActions.toggleOpenDrawer();
      }
    /*if (this.props.view === 'MobileView') {
      setTimeout(() => {
              this.props.appActions.toggleCollapsed();
              this.props.appActions.toggleOpenDrawer();
      }, 100);
    }*/
  }
  onOpenChange(newOpenKeys) {
    const latestOpenKey = newOpenKeys.find(
      key => !(this.props.openKeys.indexOf(key) > -1)
    );
    const latestCloseKey = this.props.openKeys.find(
      key => !(newOpenKeys.indexOf(key) > -1)
    );
    let nextOpenKeys = [];
    if (latestOpenKey) {
      nextOpenKeys = this.getAncestorKeys(latestOpenKey).concat(latestOpenKey);
    }
    if (latestCloseKey) {
      nextOpenKeys = this.getAncestorKeys(latestCloseKey);
    }
    this.props.appActions.changeOpenKeys(nextOpenKeys);
  }
  getAncestorKeys = key => {
    const map = {
      sub3: ['sub2'],
    };
    return map[key] || [];
  };

  render() {
    const { url } = this.props;
    const customizedTheme = getCurrentTheme('sidebarTheme');
    const collapsed = clone(this.props.collapsed) && !clone(this.props.openDrawer);
    const mode = collapsed === true ? 'vertical' : 'inline';
    const onMouseEnter = event => {
      if (this.props.openDrawer === false && this.props.view !== 'MobileView') {
        this.props.appActions.toggleOpenDrawer();
      }
    };
    const onMouseLeave = () => {
      if (this.props.openDrawer === true && this.props.view !== 'MobileView') {
          this.props.appActions.toggleOpenDrawer();
      }
    };


      const onTouchStart = event => {
          if (this.props.openDrawer === false) {
              this.props.appActions.toggleOpenDrawer();
          }
      };


    const scrollheight = this.props.height;

    const styling = {
      backgroundColor: customizedTheme.backgroundColor,
    };
    const submenuStyle = {
          backgroundColor: 'rgba(0,0,0,0.3)',
          color: customizedTheme.textColor,
    };
    const submenuColor = {
      color: customizedTheme.textColor,
    };
    return (
      <SidebarWrapper>
        <Sider
          trigger={null}
          collapsible={true}
          collapsed={collapsed}
          width="250px"
          className="isomorphicSidebar"
          onMouseEnter={onMouseEnter}
          onMouseLeave={onMouseLeave}
          onTouchStart={onTouchStart}
          style={styling}
        >
          <Logo collapsed={collapsed} />
          <Scrollbars style={{ height: scrollheight - 70 }}>
            <Menu
              onClick={this.handleClick}
              theme="dark"
              mode={mode}
              openKeys={collapsed ? [] : this.props.openKeys}
              selectedKeys={this.props.current}
              onOpenChange={this.onOpenChange}
              className="isoDashboardMenu"
            >

                {/*<Menu.Item>
                    <Link to={`${url}/dashboard`}>
                          <span className="isoMenuHolder" style={submenuColor}>
                            <Icon type="home" />
                            <span className="nav-text">
                               Dashboard
                            </span>
                          </span>
                    </Link>
                </Menu.Item>*/}

                {/*[1, 2].includes(this.props.GroupID) &&
                <Menu.Item>
                    <Link to={`${url}/CheckIn`}>
                          <span className="isoMenuHolder" style={submenuColor}>
                            <Icon type="login"/>
                            <span className="nav-text">
                               CheckIn
                            </span>
                          </span>
                    </Link>
                </Menu.Item>*/
                }

                {[1, 2, 22].includes(this.props.GroupID) ?
                <SubMenu
                    key="checkinsub"
                    title={
                        <span className="isoMenuHolder" style={submenuColor}>
                            <LoginOutlined />
                            <span className="nav-text">CheckIn</span>
                        </span>
                    }
                >
                    <Menu.Item style={submenuStyle} key="checkin">
                        <Link style={submenuColor} to={`${url}/CheckIn`}>
                            Verträge
                        </Link>
                    </Menu.Item>

                    <Menu.Item style={submenuStyle} key="anabreise">
                        <Link style={submenuColor} to={`${url}/Doku/AnAbreise`}>
                            An/Abreise
                        </Link>
                    </Menu.Item>

                </SubMenu> : null
                }


                {/*<Menu.Item>
                    <Link to={`${url}/RoomPlan`}>
                          <span className="isoMenuHolder" style={submenuColor}>
                            <Icon type="book" />
                            <span className="nav-text">
                               Zimmerbelegung
                            </span>
                          </span>
                    </Link>
                </Menu.Item>*/}

                <SubMenu
                    key="rommplansub"
                    title={
                        <span className="isoMenuHolder" style={submenuColor}>
                            <BookOutlined />
                            <span className="nav-text">Zimmerbelegung</span>
                        </span>
                    }
                >
                    <Menu.Item style={submenuStyle} key="roomplan">
                        <Link style={submenuColor} to={`${url}/RoomPlan`}>
                               Übersicht
                        </Link>
                    </Menu.Item>
                    {this.props.GroupID !== 3 ? (<><Menu.Item style={submenuStyle} key="Housekeeping">
                        <Link style={submenuColor} to={`${url}/Doku/Housekeeping`}>
                            Housekeeping
                        </Link>
                    </Menu.Item>
                    <Menu.Item style={submenuStyle} key="Zimmerausstattung">
                        <Link style={submenuColor} to={`${url}/Doku/Zimmerausstattung`}>
                            Zimmerausstattung
                        </Link>
                    </Menu.Item>
                    <Menu.Item style={submenuStyle} key="hausplan">
                        <Link style={submenuColor} to={`${url}/Doku/Hausplan`}>
                            Hausplan
                        </Link>
                    </Menu.Item>
                    </>) : null}
                </SubMenu>


                {[1, 2, 22].includes(this.props.GroupID) ? (
                    <SubMenu
                        key="todosub"
                        title={
                            <span className="isoMenuHolder" style={submenuColor}>
                            <CheckSquareOutlined />
                            <span className="nav-text">Aufgaben</span>
                        </span>
                        }
                    >
                        <Menu.Item style={submenuStyle} key="cleaning">
                            <Link style={submenuColor} to={`${url}/Reinigung`}>
                                Reinigung
                            </Link>
                        </Menu.Item>
                        <Menu.Item style={submenuStyle} key="cleaning">
                            <Link style={submenuColor} to={`${url}/TodoRezeption`}>
                                Rezeption
                            </Link>
                        </Menu.Item>
                    </SubMenu>
                ): null}


                {/*<Menu.Item>
                    <a href="https://flour.cloud" target="_blank">
                          <span className="isoMenuHolder" style={submenuColor}>
                            <Icon type="shopping-cart" />
                            <span className="nav-text">
                               Kasse
                            </span>
                          </span>
                    </a>
                </Menu.Item>

                <Menu.Item>
                    <Link to={`${url}/Doku/Kasse`}>
                          <span className="isoMenuHolder" style={submenuColor}>
                            <Icon type="read" />
                            <span className="nav-text">
                               Anleitung Kasse
                            </span>
                          </span>
                    </Link>
                </Menu.Item>*/}

                {[1, 2, 22, 3].includes(this.props.GroupID) ?
                <SubMenu
                    key="kassesub"
                    title={
                        <span className="isoMenuHolder" style={submenuColor}>
                            <ShoppingCartOutlined />
                            <span className="nav-text">Kasse</span>
                        </span>
                    }
                >
                    <Menu.Item style={submenuStyle} key="zurkasse">
                        <a href="https://flour.cloud" style={submenuColor} target="_blank">
                            Zur Kasse
                        </a>
                    </Menu.Item>
                    <Menu.Item style={submenuStyle} key="DokuKasse">
                        <Link style={submenuColor} to={`${url}/Doku/Kasse`}>
                            Anleitung
                        </Link>
                    </Menu.Item>
                </SubMenu> : null
                }

                {[1, 2, 22].includes(this.props.GroupID) ? (<>
                <SubMenu
                    key="userssub"
                    title={
                        <span className="isoMenuHolder" style={submenuColor}>
                            <UserOutlined />
                            <span className="nav-text">Benutzer</span>
                        </span>
                    }
                >
                    {this.props.isAdmin ?
                    <Menu.Item style={submenuStyle} key="zurzeiterfassung">
                        <a href="https://app.timemoto.com" style={submenuColor} target="_blank">
                            Zeiterfassung
                        </a>
                    </Menu.Item> : null }
                    {this.props.isAdmin ?
                    <Menu.Item style={submenuStyle} key="usermanagement">
                        <Link style={submenuColor} to={`${url}/Users`}>
                            Benutzerverwaltung
                        </Link>
                    </Menu.Item>
                        : null}
                    {this.props.isAdmin ?
                        <Menu.Item style={submenuStyle} key="userloginlogs">
                            <Link style={submenuColor} to={`${url}/Users/Logins`}>
                                Anmeldeprotokoll
                            </Link>
                        </Menu.Item>
                        : null}
                    <Menu.Item style={submenuStyle} key="hausverbote">
                        <Link style={submenuColor} to={`${url}/Hausverbote`}>
                            Hausverbote
                        </Link>
                    </Menu.Item>
                    <Menu.Item style={submenuStyle} key="arbeitskleidung">
                        <Link style={submenuColor} to={`${url}/Arbeitskleidung`}>
                            Arbeitskleidung
                        </Link>
                    </Menu.Item>
                    <Menu.Item style={submenuStyle} key="aufgaben">
                        <Link style={submenuColor} to={`${url}/Aufgaben`}>
                            Aufgaben
                        </Link>
                    </Menu.Item>
                    <Menu.Item style={submenuStyle} key="Urlaub">
                        <Link style={submenuColor} to={`${url}/Doku/Urlaub`}>
                            Urlaub
                        </Link>
                    </Menu.Item>
                    <Menu.Item style={submenuStyle} key="Urlaubsantrag">
                        <Link style={submenuColor} to={`${url}/Doku/Urlaubsantrag`}>
                            Urlaubsantrag
                        </Link>
                    </Menu.Item>
                    <Menu.Item style={submenuStyle} key="Urlaubskalender">
                        <Link style={submenuColor} to={`${url}/HolidayCalendar`}>
                            Urlaubskalender
                        </Link>
                    </Menu.Item>
                    <Menu.Item style={submenuStyle} key="Schichtplan">
                        <Link style={submenuColor} to={`${url}/Doku/Schichtplan`}>
                            Schichtplan
                        </Link>
                    </Menu.Item>
                    <Menu.Item style={submenuStyle} key="wlan-tickets">
                        <a href="https://www.hotsplots.de/kundenbereich.html" style={submenuColor} target="_blank">
                            WLAN-Tickets
                        </a>
                    </Menu.Item>
                </SubMenu></>) : null}
                {[1, 2, 22].includes(this.props.GroupID) ?
                <Menu.Item>
                    <Link to={`${url}/Kunden`}>
                        <span className="isoMenuHolder" style={submenuColor}>
                            <TeamOutlined />
                            <span className="nav-text">
                               Kunden
                            </span>
                          </span>
                    </Link>
                </Menu.Item>
                    : null}
                {[1, 2, 22].includes(this.props.GroupID) ?
                    <Menu.Item>
                        <Link to={`${url}/Lustmolche`}>
                        <span className="isoMenuHolder" style={submenuColor}>
                            <EditOutlined />
                            <span className="nav-text">
                               Lustmolche Anmeldungen
                            </span>
                          </span>
                        </Link>
                    </Menu.Item>
                    : null}
                {[1, 2, 22].includes(this.props.GroupID) ?
                <Menu.Item>
                    <Link to={`${url}/Rechnungen`}>
                        <span className="isoMenuHolder" style={submenuColor}>
                            <CreditCardOutlined />
                            <span className="nav-text">
                               Rechnungen
                            </span>
                          </span>
                    </Link>
                </Menu.Item>
                    : null }

                {[1, 2, 22].includes(this.props.GroupID) ?
                    <Menu.Item key="maschinen">
                        <Link style={submenuColor} to={`${url}/Maschinen`}>
                            <span className="isoMenuHolder" style={submenuColor}>
                            <FilePdfOutlined/>
                                <span className="nav-text">
                            Automaten Maschinen
                                </span>
                            </span>
                        </Link>
                    </Menu.Item>
                    : null}

                {this.props.GroupID !== 3 ? (<>
                <Menu.Item>
                    <a href='https://dashboard.tawk.to/login' target="_blank">
                        <span className="isoMenuHolder" style={submenuColor}>
                            <MessageOutlined />
                            <span className="nav-text">
                               Chat
                            </span>
                          </span>
                    </a>
                </Menu.Item>

                <Menu.Item>
                    <a href='https://account-app.brevo.com/account/login' target="_blank">
                        <span className="isoMenuHolder" style={submenuColor}>
                            <MailOutlined />
                            <span className="nav-text">
                               Newsletter
                            </span>
                          </span>
                    </a>
                </Menu.Item>

                <Menu.Item>
                    <a href='https://www.lustra.de' target="_blank">
                        <span className="isoMenuHolder" style={submenuColor}>
                            <CompassOutlined />
                            <span className="nav-text">
                               Homepage
                            </span>
                          </span>
                    </a>
                </Menu.Item>
                    </>): null}

                {this.props.isAdmin ?
                    <Menu.Item>
                        <a href='https://reinigung.lustra.de/' target="_blank">
                        <span className="isoMenuHolder" style={submenuColor}>
                            <ClearOutlined />
                            <span className="nav-text">
                               Reinigungsoberfläche
                            </span>
                          </span>
                        </a>
                    </Menu.Item>
                    : null}




            </Menu>
          </Scrollbars>
        </Sider>
      </SidebarWrapper>
    );
  }
}

function mapStateToProps(state) {
    const { App, Auth } = state;
    const {collapsed, view, height, openDrawer, openKeys, current} = App;
    const {isAdmin, GroupID} = Auth;

    return {
        openKeys,
        collapsed,
        view,
        height,
        openDrawer,
        current,
        isAdmin,
        GroupID
    };
}

function mapDispatchToProps(dispatch) {
    return{
        appActions: bindActionCreators(appActions, dispatch)
    };
}


export default connect(mapStateToProps, mapDispatchToProps)(Sidebar);