import React from 'react';
import { Link } from 'react-router-dom';
import logo from '../../image/logo.png';
import logoSmall from '../../image/logo-small.png';

export default ({ collapsed }) => {
    return (
        <div className="isoLogoWrapper">
            {collapsed ? (
                <div>
                    <h3>
                        <Link to="/app/RoomPlan">
                            <img src={logoSmall} height={"30%"} width={"70%"} />
                        </Link>
                    </h3>
                </div>
            ) : (
                <h3>
                    <Link to="/app/RoomPlan"><img src={logo} height={"100%"} width={"80%"} /></Link>
                </h3>
            )}
        </div>
    );
};
