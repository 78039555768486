import HausverboteActions from "./actions";


const initState = {
    loadingHausverboteList: true,
    HausverboteList: [],
    paginationHausverbote: {},
    infosTableHausverbote: {},
    search: "",
    HausverbotDetail: {},

    HausverboteDetailID: null,
    HausverboteModalVisible: false,
    HausverboteModalSaving: false,
    HausverboteModalLoading: false,
    deletingBan: false,
};

export default function HausverboteReducer(state = initState, action) {
  switch (action.type) {
      case HausverboteActions.LOAD_HAUSVERBOTE_LIST_REQUEST:
        return Object.assign({}, state, {
            loadingHausverboteList: true
        });
      case HausverboteActions.LOAD_HAUSVERBOTE_LIST_SUCCESS:
          const pagination = { ...state.paginationHausverbote };
          // Read total count from server
          // pagination.total = data.totalCount;
          pagination.total = action.response.data.Count;

          return Object.assign({}, state, {
              HausverboteList: action.response.data.Hausverbote,
              loadingHausverboteList: false,
              paginationHausverbote: pagination
          });
      case HausverboteActions.LOAD_HAUSVERBOTE_LIST_ERROR:
          return Object.assign({}, state, {
              loadingHausverboteList: false
          });
      case HausverboteActions.SET_HAUSVERBOTE_TABLE_PAGE:
          return Object.assign({}, state, {
              paginationHausverbote: action.pager
          });
      case HausverboteActions.SET_HAUSVERBOTE_TABLE_INFO:
          return Object.assign({}, state, {
              infosTableHausverbote: action.infos
          });
      case HausverboteActions.SET_SEARCH_TEXT:
          return Object.assign({}, state, {
              search: action.search,
              paginationHausverbote: {current: 1},
              infosTableHausverbote: {}
          });

      case HausverboteActions.SHOW_HAUSVERBOTE_MODAL:
          return Object.assign({}, state, {
              HausverboteModalVisible: action.show,
              HausverboteDetailID: action.BanID,
              HausverbotDetail: {}
          });
      case HausverboteActions.SAVE_HAUSVERBOTE_REQUEST:
          return Object.assign({}, state, {
              HausverboteModalSaving: true
          });
      case HausverboteActions.SAVE_HAUSVERBOTE_SUCCESS:
              return Object.assign({}, state, {
                  HausverboteModalVisible: false,
                  HausverboteModalSaving: false
              });
      case HausverboteActions.SAVE_HAUSVERBOTE_ERROR:
          return Object.assign({}, state, {
              HausverboteModalSaving: false
          });

      case HausverboteActions.LOAD_HAUSVERBOTE_DETAIL_REQUEST:
          return Object.assign({}, state, {
              HausverboteModalLoading: true,
              HausverbotDetail: {}
          });
      case HausverboteActions.LOAD_HAUSVERBOTE_DETAIL_SUCCESS:
          return Object.assign({}, state, {
              HausverboteModalLoading: false,
              HausverbotDetail: action.response.data.ban
          });
      case HausverboteActions.LOAD_HAUSVERBOTE_DETAIL_ERROR:
          return Object.assign({}, state, {
              HausverboteModalLoading: false
          });

          case HausverboteActions.DELETE_HAUSVERBOTE_REQUEST:
          return Object.assign({}, state, {
              deletingBan: true
          });
      case HausverboteActions.DELETE_HAUSVERBOTE_SUCCESS:
          return Object.assign({}, state, {
              HausverboteModalVisible: false,
              deletingBan: false
          });
      case HausverboteActions.DELETE_HAUSVERBOTE_ERROR:
          return Object.assign({}, state, {
              deletingBan: false
          });

    default:
      return state;
  }
}

