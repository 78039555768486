import AufgabenActions from "./actions";


const initState = {
    loadingAufgabenList: true,
    AufgabenList: [],

    AufgabenModalVisible: false,
    AufgabenModalID: 0,
    AufgabenModalDetails: {}
};

/*

    HausverbotDetail: {},

    HausverboteDetailID: null,
    HausverboteModalVisible: false,
    HausverboteModalSaving: false,
    HausverboteModalLoading: false,
    deletingBan: false,

 */

export default function AufgabenReducer(state = initState, action) {
    switch (action.type) {
        case AufgabenActions.LOAD_AUFGABE_REQUEST:
            return Object.assign({}, state, {
                loadingAufgabenList: true
            });
        case AufgabenActions.LOAD_AUFGABE_SUCCESS:
            return Object.assign({}, state, {
                AufgabenList: action.response.data.tasks,
                loadingAufgabenList: false
            });
        case AufgabenActions.LOAD_AUFGABE_ERROR:
            return Object.assign({}, state, {
                loadingAufgabenList: false
            });

        case AufgabenActions.SHOW_AUFGABE_MODAL:
            return Object.assign({}, state, {
                AufgabenModalVisible: action.show,
                AufgabenModalID: action.GroupID,
                AufgabenModalDetails: action.GroupID ? state.AufgabenList.filter(al => al.GroupID === action.GroupID)[0] : {}
            });

        case AufgabenActions.SAVE_AUFGABE_REQUEST:
            return Object.assign({}, state, {
                loadingAufgabenList: true
            });
        case AufgabenActions.SAVE_AUFGABE_SUCCESS:
            return Object.assign({}, state, {
                loadingAufgabenList: false,
                AufgabenModalVisible: false,
                AufgabenModalID: 0,
                AufgabenModalDetails: {}
            });
        case AufgabenActions.SAVE_AUFGABE_ERROR:
            return Object.assign({}, state, {
                loadingAufgabenList: false
            });

        default:
            return state;
    }
}

