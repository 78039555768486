import {CALL_API} from "../../helpers/middleware";

const AufgabenActions = {

    LOAD_AUFGABE_REQUEST : 'LOAD_AUFGABE_REQUEST',
    LOAD_AUFGABE_SUCCESS : 'LOAD_AUFGABE_SUCCESS',
    LOAD_AUFGABE_ERROR : 'LOAD_AUFGABE_ERROR',

    SHOW_AUFGABE_MODAL: 'SHOW_AUFGABE_MODAL',


    SAVE_AUFGABE_REQUEST: 'SAVE_AUFGABE_REQUEST',
    SAVE_AUFGABE_SUCCESS: 'SAVE_AUFGABE_SUCCESS',
    SAVE_AUFGABE_ERROR: 'SAVE_AUFGABE_ERROR',

};

export default AufgabenActions;


export function LoadAufgaben() {
    return {
        [CALL_API]: {
            reqType: 'GET',
            endpoint: 'usertasks',
            types: [AufgabenActions.LOAD_AUFGABE_REQUEST, AufgabenActions.LOAD_AUFGABE_SUCCESS, AufgabenActions.LOAD_AUFGABE_ERROR]
        }
    };
}

export function LoadMyAufgaben() {
    return {
        [CALL_API]: {
            reqType: 'GET',
            endpoint: 'usertasks/mytasks',
            types: [null, null, null]
        }
    };
}

export function SaveAufgaben(values, GroupID) {
    return {
        [CALL_API]: {
            reqType: 'PUT',
            endpoint: 'usertasks/'+GroupID,
            postData: {tasks: values.tasks},
            types: [AufgabenActions.SAVE_AUFGABE_REQUEST, AufgabenActions.SAVE_AUFGABE_SUCCESS, AufgabenActions.SAVE_AUFGABE_ERROR]
        }
    };
}

export function showAufgabenModal(show, GroupID) {
    return {
        type: AufgabenActions.SHOW_AUFGABE_MODAL,
        show,
        GroupID
    };
}


/*
export function saveHausverboteDetail(values, BanID) {
    let url = "ban";
    let type = "POST";
    if(BanID) {
        url += "/"+BanID;
        type = "PUT";
    } else {
        url += "/new";
    }

    return {
        [CALL_API]: {
            reqType: type,
            endpoint: url,
            postData: values,
            types: [HausverboteActions.SAVE_HAUSVERBOTE_REQUEST, HausverboteActions.SAVE_HAUSVERBOTE_SUCCESS, HausverboteActions.SAVE_HAUSVERBOTE_ERROR]
        }
    };
}

export function LoadHausverboteDetail(BanID) {
    return {
        [CALL_API]: {
            reqType: 'GET',
            endpoint: 'ban/'+BanID,
            types: [HausverboteActions.LOAD_HAUSVERBOTE_DETAIL_REQUEST, HausverboteActions.LOAD_HAUSVERBOTE_DETAIL_SUCCESS, HausverboteActions.LOAD_HAUSVERBOTE_DETAIL_ERROR]
        }
    };
}

export function deleteHausverbot(BanID) {
    return {
        [CALL_API]: {
            reqType: 'DELETE',
            endpoint: 'ban/'+BanID,
            types: [HausverboteActions.DELETE_HAUSVERBOTE_REQUEST, HausverboteActions.DELETE_HAUSVERBOTE_SUCCESS, HausverboteActions.DELETE_HAUSVERBOTE_ERROR]
        }
    };
}




export function setHausverboteTablePage(pager) {
    return {
        type: HausverboteActions.SET_HAUSVERBOTE_TABLE_PAGE,
        pager
    };
};

export function setHausverboteTableInfo(infos) {
    return {
        type: HausverboteActions.SET_HAUSVERBOTE_TABLE_INFO,
        infos
    };
};

export function setSearchText(search) {
    return {
        type: HausverboteActions.SET_SEARCH_TEXT,
        search
    };
};
*/
 




