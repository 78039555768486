import CheckInActions from "./actions";


const initState = {
    loadingCheckInList: true,
    CheckInList: [],
    paginationCheckIn: {},
    infosTableCheckIn: {},
    search: "",
    Persons: [],
    newAgreementVisible: false,
    signAgreementVisible: false,
    signAgreementLustraVisible: false,
    showAgreementVisible: false,
    actAgreementID: 0,
    actPersonID: 0,
    signableAgreement: false,
    signAgreementSaving: false,
    signAgreementLustraSaving: false,
    showUploadImagesPersonsVisible: false,
    CheckIn: {},
    AgreementForm1Values: {},
    AgreementFormVisible: 1,
    AgreementFormSave: false,
    AgreementFormSaving: false,
    confirmForceCheckinVisible: false
};

export default function CheckInReducer(state = initState, action) {
  switch (action.type) {
      case CheckInActions.LOAD_CHECKIN_LIST_REQUEST:
        return Object.assign({}, state, {
            loadingCheckInList: true
        });
      case CheckInActions.LOAD_CHECKIN_LIST_SUCCESS:
          const pagination = { ...state.paginationCheckIn };
          // Read total count from server
          // pagination.total = data.totalCount;
          pagination.total = action.response.data.Count;

          return Object.assign({}, state, {
              CheckInList: action.response.data.CheckIns,
              loadingCheckInList: false,
              paginationCheckIn: pagination
          });
      case CheckInActions.LOAD_CHECKIN_LIST_ERROR:
          return Object.assign({}, state, {
              loadingCheckInList: false
          });
      case CheckInActions.SET_CHECKIN_TABLE_PAGE:
          return Object.assign({}, state, {
              paginationCheckIn: action.pager
          });
      case CheckInActions.SET_CHECKIN_TABLE_INFO:
          return Object.assign({}, state, {
              infosTableCheckIn: action.infos
          });
      case CheckInActions.SET_SEARCH_TEXT:
          return Object.assign({}, state, {
              search: action.search,
              paginationCheckIn: {current: 1},
              infosTableCheckIn: {}
          });
      case CheckInActions.LOAD_PERSONS_REQUEST:
          return state;
      case CheckInActions.LOAD_PERSONS_SUCCESS:
          return Object.assign({}, state, {
              Persons: action.response.data.persons
          });
      case CheckInActions.LOAD_PERSONS_ERROR:
          return state;
      case CheckInActions.SHOW_NEW_AGREEMENT:
          return Object.assign({}, state, {
              AgreementFormVisible: 1,
              AgreementForm1Values: {},
              newAgreementVisible: action.show
          });
      case CheckInActions.SHOW_SIGN_AGREEMENT:
          return Object.assign({}, state, {
              signAgreementVisible: action.show,
              actAgreementID: action.AgreementID
          });
      case CheckInActions.SHOW_SIGN_AGREEMENT_LUSTRA:
          return Object.assign({}, state, {
              signAgreementLustraVisible: action.show,
              actAgreementID: action.AgreementID
          });
      case CheckInActions.SHOW_SHOW_AGREEMENT:
          return Object.assign({}, state, {
              showAgreementVisible: action.show,
              actAgreementID: action.AgreementID,
              signableAgreement: action.signable
          });
      case CheckInActions.SHOW_UPLOAD_IMAGES_PERSONS:
          return Object.assign({}, state, {
              showUploadImagesPersonsVisible: action.show,
              actAgreementID: action.AgreementID,
              actPersonID: action.PersonID,
              signableAgreement: action.signable
          });
      case CheckInActions.SIGN_AGREEMENT_REQUEST:
          return Object.assign({}, state, {
              signAgreementSaving: true
          });
      case CheckInActions.SIGN_AGREEMENT_SUCCESS:
          return Object.assign({}, state, {
              signAgreementSaving: false,
              signAgreementVisible: false
          });
      case CheckInActions.SIGN_AGREEMENT_ERROR:
          return Object.assign({}, state, {
              signAgreementSaving: false
          });
      case CheckInActions.SIGN_AGREEMENT_LUSTRA_REQUEST:
          return Object.assign({}, state, {
              signAgreementLustraSaving: true
          });
      case CheckInActions.SIGN_AGREEMENT_LUSTRA_SUCCESS:
          return Object.assign({}, state, {
              signAgreementLustraSaving: false,
              signAgreementLustraVisible: false
          });
      case CheckInActions.SIGN_AGREEMENT_LUSTRA_ERROR:
          return Object.assign({}, state, {
              signAgreementLustraSaving: false
          });
      case CheckInActions.SAVE_AGREEMENT_FORM1:
          return Object.assign({}, state, {
              AgreementFormVisible: 2,
              AgreementForm1Values: action.values
          });
      case CheckInActions.SET_SAVE_AGREEMENT_FORM:
          return Object.assign({}, state, {
              AgreementFormSave: action.save
          });
      case CheckInActions.LOAD_PERSON_DETAIL_SUCCESS:
          return Object.assign({}, state, {
              AgreementForm1Values: {...state.AgreementForm1Values, ...action.response.data}
          });
      case CheckInActions.SAVE_AGREEMENT_REQUEST:
          return Object.assign({}, state, {
              AgreementFormSaving: true
          });
      case CheckInActions.SAVE_AGREEMENT_SUCCESS:
          if(action.response.data.conflict) {
              //There is an other reservation, we need to confirm overwrite:
              return Object.assign({}, state, {
                  confirmForceCheckinVisible: true
              });
          } else {
              //Successfully saved
              return Object.assign({}, state, {
                  AgreementFormVisible: 1,
                  AgreementForm1Values: {},
                  newAgreementVisible: false,
                  AgreementFormSaving: false
              });
          }
          break;
      case CheckInActions.SAVE_AGREEMENT_ERROR:
          return Object.assign({}, state, {
              AgreementFormSaving: false
          });
      case CheckInActions.SAVE_STORNO_REQUEST:
          return Object.assign({}, state, {
              AgreementFormSaving: true
          });
      case CheckInActions.SAVE_STORNO_SUCCESS:
          return Object.assign({}, state, {
              AgreementFormSaving: false
          });
      case CheckInActions.SAVE_STORNO_ERROR:
          return Object.assign({}, state, {
              AgreementFormSaving: false
          });
      case CheckInActions.HIDE_CONFIRM_FORCE_CHECKIN:
          return Object.assign({}, state, {
              confirmForceCheckinVisible: false
          });
    default:
      return state;
  }
}

