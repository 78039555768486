import React, {Component} from "react";
import {Button, Select, Modal, DatePicker, Checkbox, Divider} from 'antd';
import {bindActionCreators} from "redux";
import * as CheckInActions from "../redux/CheckIn/actions";
import * as RoomPlanActions from "../redux/RoomPlan/actions";
import * as PersonsActions from '../redux/Personen/actions';
import connect from "react-redux/es/connect/connect";
import moment from "moment";
import { Form } from '@ant-design/compatible';
import {EditOutlined, PlusOutlined} from "@ant-design/icons";
import PersonenDetail from "../containers/Personen/detail";

//import NewAgreementForm1 from './NewAgreementForm1';
//import NewAgreementForm2 from './NewAgreementForm2';


const FormItem = Form.Item;
const Option = Select.Option;
const { RangePicker } = DatePicker;
const confirm = Modal.confirm;

const formItemLayout = {
    labelCol: {
        xs: { span: 24 },
        sm: { span: 6 },
    },
    wrapperCol: {
        xs: { span: 24 },
        sm: { span: 18 },
    },
};


class NewAgreement extends Component {

    actPersonID = 0;

    componentWillMount() {
        this.props.PersonsActions.loadPersons();
        this.props.RoomPlanActions.loadHouses();
        this.setState({actPersonFeedback: "", actPersonHelp: "", actPersonFeedbackHealth: "", actPersonHelpHealth: "", actPersonFeedbackWeapon: "", actPersonHelpWeapon: "", actPersonNote: ""});
    }

    componentDidMount() {

    }

    reloadPersons = () => {
        this.props.PersonsActions.loadPersons().then(() => {
            const currentPerson = this.props.form.getFieldValue('person');
            currentPerson && this.checkPerson(null, currentPerson, () => {});
        });
    };

    handleSubmit = (e) => {
        e.preventDefault();
        this.props.form.validateFieldsAndScroll((err, values) => {
            if (!err) {
                if(this.state.actPersonFeedbackHealth === 'warning' || this.state.actPersonFeedbackWeapon === 'warning' ) {
                    if(this.state.actPersonHealthWarning === 1 || this.state.actPersonWeaponWarning === 1) {
                        this.showConfirmExpiredCert();
                    } else {
                        this.showConfirmNoCert();
                    }
                } else {
                    this.props.actions.saveAgreement({...values, price: this.props.Room.price+",00"});
                }
            }
        });
    };

    /*handleSubmit = (e) => {
        e.preventDefault();
    };*/


    checkPerson = (rule, value, callback) => {
        this.setState({actPersonFeedback: "", actPersonHelp: "", actPersonNote: ""});
        this.actPersonID = 0;
        if(value) {
            let actPerson = this.props.Persons.filter(p => { return p.id === value});
            if(actPerson.length === 1) {
                this.actPersonID = actPerson[0].id;

                if(actPerson[0].note) {
                    this.setState({ actPersonNote: actPerson[0].note });
                }

                callback();
                this.checkPersonHealthCertificate(null, -1);
                this.checkPersonWeaponCertificate(null, -1);

            }
            callback();

        } else {
            try {
                this.setState({actPersonFeedback: 'error', actPersonHelp: 'Bitte wählen Sie eine Person aus.'});
                throw new Error("Bitte wählen Sie eine Person aus.");
            } catch (err) {
                callback(err);
            }
        }
    };

    changePerson = (value) => {
        let actPerson = this.props.Persons.filter(p => { return p.id === value});
            if(actPerson.length === 1) {
                this.props.form.setFieldsValue({
                    healthCertificate: actPerson[0].healthCertificate ? moment(actPerson[0].healthCertificate) : null,
                    healthCertificateRemember: actPerson[0].healthCertificateRemember,
                    weaponCertificate: actPerson[0].weaponCertificate ? moment(actPerson[0].weaponCertificate) : null,
                    weaponCertificateRemember: actPerson[0].weaponCertificateRemember
                });

                //this.checkPersonHealthCertificate(null, -1);
               // this.checkPersonWeaponCertificate(null, -1);
            }
    };


    /*checkPersonHealthCertificate = (rule, value, callback) => {
        this.setState({actPersonFeedbackHealth: "", actPersonHelpHealth: ""});
        if(value) {
            let actPerson = this.props.Persons.filter(p => { return p.id === this.actPersonID});
            if(actPerson.length === 1) {

                let healthRemember = this.props.form.getFieldValue('healthCertificateRemember');
                let now = moment();
                if(value.clone().subtract(healthRemember, 'day').isBefore(now)) {
                    this.setState({actPersonFeedbackHealth: 'warning', actPersonHelpHealth: "Das Gesundheitszeugnis für diese Person läuft ab oder ist abgelaufen."});
                }
                callback();
            }
        } else {
            callback();
        }
    };

    checkPersonHealthCertificateRemember = (rule, value, callback) => {
        this.setState({actPersonFeedbackHealth: "", actPersonHelpHealth: ""});
        if(value) {
            let actPerson = this.props.Persons.filter(p => { return p.id === this.actPersonID});
            if(actPerson.length === 1) {
                let healthExp = this.props.form.getFieldValue('healthCertificate');
                let now = moment();
                if(healthExp.clone().subtract(value, 'day').isBefore(now)) {
                    this.setState({actPersonFeedbackHealth: 'warning', actPersonHelpHealth: "Das Gesundheitszeugnis für diese Person läuft ab oder ist abgelaufen."});
                }
                callback();
            }
        } else {
            callback();
        }
    };*/


   /* checkPersonWeaponCertificate = (rule, value, callback) => {
        this.setState({actPersonFeedbackWeapon: "", actPersonHelpWeapon: ""});
        if(value) {
            let actPerson = this.props.Persons.filter(p => { return p.id === this.actPersonID});
            if(actPerson.length === 1) {

                let weaponRemember = this.props.form.getFieldValue('weaponCertificateRemember');
                let now = moment();
                if(value.clone().subtract(weaponRemember, 'day').isBefore(now)) {
                    this.setState({actPersonFeedbackWeapon: 'warning', actPersonHelpWeapon: "Der Waffenschein für diese Person läuft ab oder ist abgelaufen."});
                }
                callback();
            }
        } else {
            callback();
        }
    };

    checkPersonWeaponCertificateRemember = (rule, value, callback) => {
        this.setState({actPersonFeedbackWeapon: "", actPersonHelpWeapon: ""});
        if(value) {
            let actPerson = this.props.Persons.filter(p => { return p.id === this.actPersonID});
            if(actPerson.length === 1) {
                let weaponExp = this.props.form.getFieldValue('weaponCertificate');
                let now = moment();
                if(weaponExp.clone().subtract(value, 'day').isBefore(now)) {
                    this.setState({actPersonFeedbackWeapon: 'warning', actPersonHelpWeapon: "Der Waffenschein für diese Person läuft ab oder ist abgelaufen."});
                }
                callback();
            }
        } else {
            callback();
        }
    };
*/

   skipValidation = (rule, value, callback) => {
     callback();
   };

    checkPersonHealthCertificate = (value, changed) => {
        this.setState({actPersonFeedbackHealth: "", actPersonHelpHealth: "", actPersonHealthWarning: 0});
        let actPerson = this.props.Persons.filter(p => { return p.id === this.actPersonID});
        if(actPerson.length === 1) {
            let healthExp = this.props.form.getFieldValue('healthCertificate');
            let healthRemember = this.props.form.getFieldValue('healthCertificateRemember');
            if(changed === 0) {
                healthExp = value;
            } else if(changed === 1) {
                healthRemember = value;
            }


            if(healthExp && healthRemember) {
                let now = moment();
                if(healthExp.clone().subtract(healthRemember, 'day').isSameOrBefore(now)) {
                    this.setState({actPersonFeedbackHealth: 'warning', actPersonHelpHealth: "Das Gesundheitszeugnis für diese Person läuft ab oder ist abgelaufen.", actPersonHealthWarning: 1});
                }
            } else {
                this.setState({actPersonFeedbackHealth: 'warning', actPersonHelpHealth: "Das Gesundheitszeugnis für diese Person sollte hinterlegt werden.", actPersonHealthWarning: 2});
            }

        }
    };

    checkPersonWeaponCertificate = (value, changed) => {
        this.setState({actPersonFeedbackWeapon: "", actPersonHelpWeapon: "", actPersonWeaponWarning: 0});
        let actPerson = this.props.Persons.filter(p => { return p.id === this.actPersonID});
        if(actPerson.length === 1) {
            let weaponExp = this.props.form.getFieldValue('weaponCertificate');
            let weaponRemember = this.props.form.getFieldValue('weaponCertificateRemember');
            if(changed === 0) {
                weaponExp = value;
            } else if(changed === 1) {
                weaponRemember = value;
            }

            if(weaponExp && weaponRemember) {
                let now = moment();
                if (weaponExp.clone().subtract(weaponRemember, 'day').isSameOrBefore(now)) {
                    this.setState({
                        actPersonFeedbackWeapon: 'warning',
                        actPersonHelpWeapon: "Der Waffenschein für diese Person läuft ab oder ist abgelaufen.",
                        actPersonWeaponWarning: 1
                    });
                }
            } else {
                this.setState({actPersonFeedbackWeapon: 'warning', actPersonHelpWeapon: "Der Waffenschein für diese Person sollte hinterlegt werden.", actPersonWeaponWarning: 2});
            }
        }
    };

    handleCancel = () => {
        this.props.actions.showNewAgreement(false);
    };


    componentWillReceiveProps(nextProps) {
        if(this.props.newAgreementVisible && !nextProps.newAgreementVisible) {
            this.props.PersonsActions.loadPersons();
            this.props.form.resetFields();
            this.setState({actPersonFeedback: "", actPersonHelp: "", actPersonFeedbackHealth: "", actPersonHelpHealth: "", actPersonFeedbackWeapon: "", actPersonHelpWeapon: "", actPersonNote: ""});
            this.actPersonID = 0;
        }
        if(!this.props.confirmForceCheckinVisible && nextProps.confirmForceCheckinVisible) {
            this.showConfirmOverlap();
        }
    }


    showConfirmOverlap = () => {
        confirm({
            title: 'Bestehende Reservierung gefunden!',
            content: 'Ihr Zeitraum überschneidet sich mit einer Reservierung für eine andere Person. Checkin trotzdem erstellen?',
            okText: 'Ja',
            cancelText: 'Nein',
            onOk: () => {
                this.props.actions.hideConfirmForceCheckin();
                this.props.form.validateFieldsAndScroll((err, values) => {
                    if (!err) {
                        //this.props.actions.saveAgreement(values);
                        this.props.actions.saveAgreement({...values, price: this.props.Room.price+",00", force:true});
                    }
                });
            },
            onCancel: () => {
                this.props.actions.hideConfirmForceCheckin();
            },
        });
    };


    showConfirmExpiredCert = () => {
        confirm({
            title: 'Abgelaufener Waffenschein/Gesundheitszeugnis gefunden!',
            content: 'Ihre ausgewählte Person hat ein abgelaufenes Gesundheitszeugnis oder einen abgelaufenen Waffenschein. Checkin trotzdem erstellen?',
            okText: 'Ja',
            cancelText: 'Nein',
            onOk: () => {
                this.props.form.validateFieldsAndScroll((err, values) => {
                    if (!err) {
                        //this.props.actions.saveAgreement(values);
                        this.props.actions.saveAgreement({...values, price: this.props.Room.price+",00"});
                    }
                });
            },
            onCancel: () => {
            },
        });
    };


    showConfirmNoCert = () => {
        confirm({
            title: 'Kein Waffenschein/Gesundheitszeugnis angegeben!',
            content: 'Ihre ausgewählte Person hat kein Gesundheitszeugnis oder keinen Waffenschein hinterlegt. Checkin trotzdem erstellen?',
            okText: 'Ja',
            cancelText: 'Nein',
            onOk: () => {
                this.props.form.validateFieldsAndScroll((err, values) => {
                    if (!err) {
                        //this.props.actions.saveAgreement(values);
                        this.props.actions.saveAgreement({...values, price: this.props.Room.price+",00"});
                    }
                });
            },
            onCancel: () => {
            },
        });
    };

    editCurrentPerson = () => {
        const currentPerson = this.props.form.getFieldValue('person');
        currentPerson && this.props.PersonsActions.showPersonsDetail(true, currentPerson);
    };

    setUnknownDeparture = (isUnknown) => {
        this.props.form.setFieldsValue({abreise: isUnknown ? moment(this.props.form.getFieldValue('anreise') || undefined).add(1, "year") : null })
    }

    render() {

        const { getFieldDecorator, getFieldValue } = this.props.form;
        return (
            <React.Fragment>
                <PersonenDetail afterClose={this.reloadPersons} hideCheckins={true}/>
                <Modal
                    title="Neuer Vertrag"
                    visible={this.props.newAgreementVisible}
                    onOk={this.handleSubmit}
                    onCancel={this.handleCancel}
                    okText={"Vertrag erstellen"}
                    okButtonProps={{ loading: this.props.AgreementFormSaving }}
                    cancelText={"Abbrechen"}
                    width={"850px"}
                >

                    <form onSubmit={this.handleSubmit} style={{width: '100%'}}>

                        {/* hasFeedback
                            validateStatus={this.state.actPersonWarning ? 'warning' : ''}*/}

                        <Divider orientation="center" className={"lightDivider"}>Kunde</Divider>
                        {/*                   validateStatus={this.state.actPersonFeedback}
                        help={this.state.actPersonHelp}*/}
                        <FormItem
                            {...formItemLayout}
                            label={"Person"}
                            required={true}
                            key={'person'}
                            extra={<b style={{color: "red"}}>{this.state.actPersonNote}</b>}
                        >
                            {getFieldDecorator('person', {
                                rules: [
                                    {
                                        validator: this.checkPerson
                                    }
                                ],
                                initialValue: this.props.CheckIn.Person,
                            })(
                                <Select
                                    getPopupContainer={trigger => trigger.parentNode}
                                    onChange={(value) => this.changePerson(value)}
                                    showSearch
                                    style={{ width: 400 }}
                                    placeholder="Wählen Sie eine Person"
                                    optionFilterProp="children"
                                    filterOption={(input, option) => option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
                                >
                                    {this.props.Persons.map(person => <Option key={person.id} value={person.id}>{person.displayname}</Option>)}
                                </Select>
                            )}
                            <Button type={"default"} htmlType={"button"} style={{marginLeft: "10px"}} className={"round"} onClick={this.editCurrentPerson} icon={<EditOutlined />} />
                            <Button type={"default"} htmlType={"button"} style={{marginLeft: "10px"}} className={"round"} onClick={() => {window.open('https://flour.cloud')}} icon={<PlusOutlined />}>Hinzufügen</Button>
                        </FormItem>

                        <Divider orientation="center" className={"lightDivider"}>Gesundheitszeugnis</Divider>
                        <FormItem {...formItemLayout}
                                  label="Gültig bis:"
                                  hasFeedback
                                  validateStatus={this.state.actPersonFeedbackHealth}
                                  help={this.state.actPersonHelpHealth}
                        >{getFieldDecorator("healthCertificate", {

                            initialValue: null,
                        })(
                            <DatePicker placeholder={"Datum auswählen"} getPopupContainer={trigger => trigger.parentNode} onChange={(e) => this.checkPersonHealthCertificate(e, 0)} format={"DD.MM.YYYY"} style={{width: 400}}/>)}
                        </FormItem>

                        <FormItem {...formItemLayout}
                                  label="Erinnerung:">{getFieldDecorator("healthCertificateRemember", {

                             initialValue: "1",
                        })(
                            <Select
                                onChange={(e) => this.checkPersonHealthCertificate(e, 1)}
                                style={{ width: 400 }}
                                getPopupContainer={trigger => trigger.parentNode}>
                                <Option value={"1"}>
                                    1 Tag vorher
                                </Option>
                                <Option value={"7"}>
                                    1 Woche vorher
                                </Option>
                                <Option value={"14"}>
                                    2 Wochen vorher
                                </Option>
                                <Option value={"31"}>
                                    1 Monat vorher
                                </Option>
                            </Select>)}
                        </FormItem>


                        <Divider orientation="center" className={"lightDivider"}>Waffenschein</Divider>
                        <FormItem {...formItemLayout}
                                  label="Gültig bis:"
                                  hasFeedback
                                  validateStatus={this.state.actPersonFeedbackWeapon}
                                  help={this.state.actPersonHelpWeapon}
                        >{getFieldDecorator("weaponCertificate", {

                             initialValue: null,
                        })(
                            <DatePicker placeholder={"Datum auswählen"} getPopupContainer={trigger => trigger.parentNode} onChange={(e) => this.checkPersonWeaponCertificate(e, 0)} format={"DD.MM.YYYY"} style={{width: 400}}/>)}
                        </FormItem>

                        <FormItem {...formItemLayout}
                                  label="Erinnerung:">{getFieldDecorator("weaponCertificateRemember", {
                            initialValue: "1",
                        })(
                            <Select
                                onChange={(e) => this.checkPersonWeaponCertificate(e, 1)}
                                style={{ width: 400 }}
                                getPopupContainer={trigger => trigger.parentNode}>
                                <Option value={"1"}>
                                    1 Tag vorher
                                </Option>
                                <Option value={"7"}>
                                    1 Woche vorher
                                </Option>
                                <Option value={"14"}>
                                    2 Wochen vorher
                                </Option>
                                <Option value={"31"}>
                                    1 Monat vorher
                                </Option>
                            </Select>)}
                        </FormItem>

                        <Divider orientation="center" className={"lightDivider"}>Miete</Divider>
                        <FormItem
                            {...formItemLayout}
                            label={"Haus"}
                            required={true}
                            key={'house'}
                        >
                            {getFieldDecorator('house', {
                                initialValue: this.props.CheckIn.House,
                                rules: [{
                                    required: true,
                                    whitespace: true,
                                    message: "Bitte wählen Sie ein Haus aus."
                                }],
                            })(
                                <Select
                                    showSearch
                                    style={{ width: 400 }}
                                    placeholder="Wählen Sie ein Haus"
                                    optionFilterProp="children"
                                    onChange={(val) => this.props.RoomPlanActions.changeHouse(val)}
                                    filterOption={(input, option) => option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
                                >
                                    {this.props.Houses.map(house => <Option key={house.name} value={house.name}>{house.name}</Option>)}
                                </Select>
                            )}
                        </FormItem>
                        <FormItem
                            {...formItemLayout}
                            label={"Zimmer"}
                            required={true}
                            key={'room'}
                        >
                            {getFieldDecorator('room', {
                                initialValue: this.props.CheckIn.Room,
                                rules: [{
                                    required: true,
                                    whitespace: true,
                                    message: "Bitte wählen Sie ein Zimmer aus."
                                }],
                            })(
                                <Select
                                    showSearch
                                    style={{ width: 400 }}
                                    placeholder="Wählen Sie ein Zimmer"
                                    optionFilterProp="children"
                                    onChange={(val) => this.props.RoomPlanActions.changeRoom(val)}
                                    filterOption={(input, option) => option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
                                >
                                    {this.props.Rooms.map(room => <Option key={room.name} value={room.name}>{room.shortName}</Option>)}
                                </Select>
                            )}
                        </FormItem>

                        {this.props.Room.shortName === "Whirlpoolzimmer" && <FormItem
                            {...formItemLayout}
                            label={"Whirlpool Nutzung (+ 30€)"}
                            required={false}
                            key={'whirlpool'}
                        >
                            {getFieldDecorator('whirlpool', {
                                initialValue:  false,
                                rules: [{
                                    required: false
                                }],
                            })(
                                <Checkbox onChange={(val) => {this.props.RoomPlanActions.changeRoom(this.props.Room.name, {add: !val.target.value, price: 30});}}/>
                            )}
                        </FormItem>}

                        <FormItem
                            {...formItemLayout}
                            label={"Miete / Tag"}
                            required={false}
                            key={'miete'}
                        >

                            <a>{this.props.Room.price} &euro;</a>

                        </FormItem>


                        <FormItem
                            {...formItemLayout}
                            label={"Anreise"}
                            required={true}
                            key={'Anreise'}
                        >
                            {getFieldDecorator('anreise', {
                                initialValue:  (this.props.CheckIn.Arrive ? moment(this.props.CheckIn.Arrive): null),
                                rules: [{
                                    required: true,
                                    message: "Bitte wählen Sie ein Anreisedatum aus."
                                }],
                            })(
                                <DatePicker
                                    style={{ width: 400 }}
                                    placeholder={"Anreisedatum"}
                                    format={'DD.MM.YYYY'}
                                >
                                </DatePicker>
                            )}
                        </FormItem>

                        <FormItem
                            {...formItemLayout}
                            label={"Letzter Tag"}
                            required={true}
                            key={'Letzter Tag'}
                        >
                            {getFieldDecorator('abreise', {
                                initialValue:   (this.props.CheckIn.Depart ? moment(this.props.CheckIn.Depart): null),
                                rules: [{
                                    required: true,
                                    message: "Bitte wählen Sie ein Abreisedatum aus."
                                }],
                            })(
                                <DatePicker
                                    style={{ width: 400 }}
                                    placeholder={"Letzter Tag"}
                                    format={'DD.MM.YYYY'}
                                    disabled={getFieldValue("abreiseUnknown")}
                                >
                                </DatePicker>
                            )}
                        </FormItem>

                        <FormItem
                            {...formItemLayout}
                            colon={false}
                            label={" "}
                            key={'AbreiseUnbekannt'}
                        >
                            {getFieldDecorator('abreiseUnknown', {
                                validateTrigger: ['onChange', 'onBlur'],
                                initialValue: this.props.CheckIn.DepartUnknown === undefined ? 0 : this.props.CheckIn.DepartUnknown,
                                valuePropName: 'checked',
                            })(
                                <Checkbox onChange={(e) => this.setUnknownDeparture(e.target.checked)}>Abreise unbekannt</Checkbox>
                            )}
                        </FormItem>

                        <FormItem
                            {...formItemLayout}
                            label={"Zwischenreinigung"}
                            colon={false}
                            key={'Zwischenreinigung'}
                        >
                            {getFieldDecorator('cleaningSchedule', {
                                initialValue: this.props.CheckIn.CleaningSchedule === undefined ? null : this.props.CheckIn.CleaningSchedule,
                            })(
                                <Select
                                    getPopupContainer={trigger => trigger.parentNode}
                                    showSearch
                                    style={{ width: 400 }}
                                    placeholder="Wählen Sie eine Zwischenreinigung"
                                    optionFilterProp="children"
                                    filterOption={(input, option) => option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
                                >
                                    <Option value={null}>Keine</Option>
                                    <Option value={"weekly"}>Wöchentlich</Option>
                                    <Option value={"2 weeks"}>Alle 2 Wochen</Option>
                                    <Option value={"3 weeks"}>Alle 3 Wochen</Option>
                                    <Option value={"4 weeks"}>Alle 4 Wochen</Option>
                                    <Option value={"5 weeks"}>Alle 5 Wochen</Option>
                                </Select>
                            )}
                        </FormItem>

                        <FormItem
                            {...formItemLayout}
                            colon={false}
                            label={" "}
                            key={'printCustomerInfo'}
                        >
                            {getFieldDecorator('printCustomerInfo', {
                                validateTrigger: ['onChange', 'onBlur'],
                                initialValue: true,
                                valuePropName: 'checked',
                            })(
                                <Checkbox>Kundeninformationen drucken</Checkbox>
                            )}
                        </FormItem>

                        {/*<FormItem
                            {...formItemLayout}
                            label={"Zeitraum"}
                            required={true}
                            key={'Zeitraum'}
                        >
                            {getFieldDecorator('zeitraum', {
                                initialValue:  [(this.props.CheckIn.Arrive ? moment(this.props.CheckIn.Arrive): null), (this.props.CheckIn.Depart ? moment(this.props.CheckIn.Depart): null)],
                                rules: [{
                                    required: true,
                                    message: "Bitte wählen Sie einen Zeitraum aus."
                                }],
                            })(
                                <RangePicker
                                    style={{ width: 400 }}
                                    placeholder={["Anreisedatum", "Abreisedatum"]}
                                >
                                </RangePicker>
                            )}
                        </FormItem>*/}
                    </form>

                </Modal>
            </React.Fragment>
        );
    }
}



function mapStateToProps(state) {
    const { CheckInReducer, RoomPlanReducer, PersonsReducer } = state;
    const { newAgreementVisible, CheckIn, confirmForceCheckinVisible, AgreementFormSaving } = CheckInReducer;
    const { Houses, Rooms, Room } = RoomPlanReducer;
    const { Persons } = PersonsReducer;
    return {
        Persons,
        newAgreementVisible,
        CheckIn,
        Houses,
        Rooms,
        Room,
        confirmForceCheckinVisible,
        AgreementFormSaving
    };
}


function mapDispatchToProps(dispatch) {
    return{
        actions: bindActionCreators(CheckInActions, dispatch),
        RoomPlanActions : bindActionCreators(RoomPlanActions, dispatch),
        PersonsActions : bindActionCreators(PersonsActions, dispatch)
    };
}

const FormNewAgreement = Form.create()(NewAgreement);
export default connect(mapStateToProps, mapDispatchToProps)(FormNewAgreement);

//export default connect(mapStateToProps, mapDispatchToProps)(NewAgreement);









/*




<Modal
                    title="Neuer Vertrag"
                    visible={this.props.newAgreementVisible}
                    onOk={this.handleSubmit}
                    onCancel={this.handleCancel}
                    okText={"Vertrag erstellen"}
                    cancelText={"Abbrechen"}
                    width={"750px"}
                >

                    <form onSubmit={this.handleSubmit} style={{width: '100%'}}>


                        <FormItem
                            {...formItemLayout}
                            label={"Person"}
                            required={false}
                            key={'person'}
                        >
                            {getFieldDecorator('person', {
                                validateTrigger: ['onChange', 'onBlur'],
                                initialValue: this.props.CheckIn.Person,
                                rules: [{
                                    required: true,
                                    whitespace: true,
                                    message: "Bitte wählen Sie eine Person aus."
                                }],
                            })(
                                <Select
                                    showSearch
                                    style={{ width: 400 }}
                                    placeholder="Wählen Sie eine Person"
                                    optionFilterProp="children"

                                    filterOption={(input, option) => option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
                                >
                                    {this.props.Persons.map(person => <Option key={person.kdnr} value={person.kdnr}>{person.displayname}</Option>)}
                                </Select>
                            )}
                        </FormItem>
                        <FormItem
                            {...formItemLayout}
                            label={"Haus"}
                            required={true}
                            key={'house'}
                        >
                            {getFieldDecorator('house', {
                                validateTrigger: ['onChange', 'onBlur'],
                                initialValue: this.props.CheckIn.House,
                                rules: [{
                                    required: true,
                                    whitespace: true,
                                    message: "Bitte wählen Sie ein Haus aus."
                                }],
                            })(
                                <Select
                                    showSearch
                                    style={{ width: 400 }}
                                    placeholder="Wählen Sie ein Haus"
                                    optionFilterProp="children"
                                    onChange={(val) => this.props.RoomPlanActions.changeHouse(val)}
                                    filterOption={(input, option) => option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
                                >
                                    {this.props.Houses.map(house => <Option key={house.name} value={house.name}>{house.name}</Option>)}
                                </Select>
                            )}
                        </FormItem>
                        <FormItem
                            {...formItemLayout}
                            label={"Zimmer"}
                            required={true}
                            key={'room'}
                        >
                            {getFieldDecorator('room', {
                                validateTrigger: ['onChange', 'onBlur'],
                                initialValue: this.props.CheckIn.Room,
                                rules: [{
                                    required: true,
                                    whitespace: true,
                                    message: "Bitte wählen Sie ein Zimmer aus."
                                }],
                            })(
                                <Select
                                    showSearch
                                    style={{ width: 400 }}
                                    placeholder="Wählen Sie ein Zimmer"
                                    optionFilterProp="children"
                                    onChange={(val) => this.props.RoomPlanActions.changeRoom(val)}
                                    filterOption={(input, option) => option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
                                >
                                    {this.props.Rooms.map(room => <Option key={room.name} value={room.name}>{room.name}</Option>)}
                                </Select>
                            )}
                        </FormItem>

                        <FormItem
                            {...formItemLayout}
                            label={"Miete / Tag"}
                            required={false}
                            key={'miete'}
                        >

                            <a>{this.props.Room.price} &euro;</a>

                        </FormItem>


                        <FormItem
                            {...formItemLayout}
                            label={"Zeitraum"}
                            required={true}
                            key={'Zeitraum'}
                        >
                            {getFieldDecorator('zeitraum', {
                                initialValue:  [(this.props.CheckIn.Arrive ? moment(this.props.CheckIn.Arrive): null), (this.props.CheckIn.Depart ? moment(this.props.CheckIn.Depart): null)],
                                rules: [{
                                    required: true,
                                    message: "Bitte wählen Sie einen Zeitraum aus."
                                }],
                            })(
                                <RangePicker
                                    style={{ width: 400 }}
                                    placeholder={["Anreisedatum", "Abreisedatum"]}
                                >
                                </RangePicker>
                            )}
                        </FormItem>

                    </form>


                </Modal>


 */