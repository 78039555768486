import RoomPlanActions from "./actions";
import moment from 'moment';

const initState = {
    loadingRoomPlan: true,
    RoomPlan: [],
    ReservationVisible: false,
    actReservationID: 0,
    actReservationDate: 0,
    Houses: [],
    House: '',
    Rooms: [],
    Room: {name: '', price: 0},
    Reservation: {},
    selectedMonth: moment(),
    ReservationSaved: 0,
    reservedDays: [],

    TodoVisible: false,
    actTodoID: 0,
    Todo: {},
    TodoSaved: 0,
    actTodoIsRoom: false,

    ObjectVisible: false,
    actObjectID: 0,
    Object: {},
    ObjectSaved: 0,
    actObjectIsRoom: false,
};

export default function RoomPlanReducer(state = initState, action) {
  switch (action.type) {
    case RoomPlanActions.LOAD_ROOMPLAN_REQUEST:
        return Object.assign({}, state, {
            loadingRoomPlan: true
        });
    case RoomPlanActions.LOAD_ROOMPLAN_SUCCESS:
        return Object.assign({}, state, {
            RoomPlan: action.response.data.RoomPlan,
            loadingRoomPlan: false,
        });
      case RoomPlanActions.LOAD_ROOMPLAN_ERROR:
          return Object.assign({}, state, {
              loadingRoomPlan: false
          });
      case RoomPlanActions.SHOW_RESERVATION:
          return Object.assign({}, state, {
              ReservationVisible: action.show,
              actReservationID: action.id,
              actReservationDate: action.date,
              ...((action.house !== null && action.room !== null) ? {Reservation: {House: action.house, Room: action.room, todos: [], objects: []}} : {Reservation: {}})
          });
      case RoomPlanActions.LOAD_HOUSES_SUCCESS:
          return Object.assign({}, state, {
              Houses: action.response.data.houses
          });
      case RoomPlanActions.CHANGE_HOUSE:
          let actHouse = state.Houses.filter(house => {return house.name === action.house});
          return Object.assign({}, state, {
              House: action.house,
              Rooms: actHouse.length > 0 ? actHouse[0].rooms : [],
              Room: {price: actHouse.length > 0 ? actHouse[0].rooms.filter(room => {return room.name === state.Room.name}).length > 0 ? state.Houses.filter(house => {return house.name === action.house})[0].rooms.filter(room => {return room.name === state.Room.name})[0].price : 0 : 0}
          });
      case RoomPlanActions.CHANGE_ROOM:
          let actRoom = state.Rooms.filter(room => {return room.name === action.room});
          let optPrice = 0;
          if(action.opt && action.opt.add) {
              optPrice = action.opt.price
          }
          return Object.assign({}, state, {
              Room: {name: actRoom.length > 0 ? actRoom[0].name : '' ,price: actRoom.length > 0 ? (actRoom[0].price+optPrice) : 0, shortName: actRoom.length > 0 ? actRoom[0].shortName : ''}
          });
      case RoomPlanActions.SAVE_RESERVATION_REQUEST:
          return Object.assign({}, state, {
              ReservationSaved: 1,
              ReservationVisible: false
          });
      case RoomPlanActions.SAVE_RESERVATION_SUCCESS:
          return Object.assign({}, state, {
              ReservationSaved: 0,
              ReservationVisible: false
          });
      case RoomPlanActions.SAVE_RESERVATION_ERROR:
          return Object.assign({}, state, {
              ReservationSaved: -1,
              ReservationVisible: false
          });

      case RoomPlanActions.SAVE_RESERVATION_CLEANING_NOTE_REQUEST:
            return Object.assign({}, state, {
                ReservationCleaningNoteSaving: true,
            });
      case RoomPlanActions.SAVE_RESERVATION_CLEANING_NOTE_SUCCESS:
          return Object.assign({}, state, {
              ReservationCleaningNoteSaving: false,
              Reservation: {...state.Reservation, cleaningNotes: [action.response.data, ...(state.Reservation?.cleaningNotes || [])]}
          });
      case RoomPlanActions.SAVE_RESERVATION_CLEANING_NOTE_ERROR:
          return Object.assign({}, state, {
              ReservationCleaningNoteSaving: false,
          });

      case RoomPlanActions.LOAD_RESERVATION_REQUEST:
          return Object.assign({}, state, {
              Reservation: {}
          });
      case RoomPlanActions.LOAD_RESERVATION_SUCCESS:
          return Object.assign({}, state, {
              Reservation: action.response.data
          });
      case RoomPlanActions.CLEAR_RESERVATION:
          return Object.assign({}, state, {
              Reservation: {},
              actReservationID: 0
          });
      case RoomPlanActions.CHANGE_MONTH:
          return Object.assign({}, state, {
              selectedMonth: action.month
          });
      case RoomPlanActions.LOAD_RESERVED_DAYS_REQUEST:
          return Object.assign({}, state, {
              reservedDays: []
          });

      case RoomPlanActions.LOAD_RESERVED_DAYS_SUCCESS:
          return Object.assign({}, state, {
              reservedDays: action.response.data
          });
      case RoomPlanActions.DELETE_RESERVATION_REQUEST:
          return Object.assign({}, state, {
              ReservationSaved: 1,
              ReservationVisible: true
          });
      case RoomPlanActions.DELETE_RESERVATION_SUCCESS:
          return Object.assign({}, state, {
              ReservationSaved: 0,
              ReservationVisible: false
          });
      case RoomPlanActions.DELETE_RESERVATION_ERROR:
          return Object.assign({}, state, {
              ReservationSaved: -1,
              ReservationVisible: true
          });


      case RoomPlanActions.SHOW_TODO:
          return Object.assign({}, state, {
              TodoVisible: action.show,
              actTodoID: action.id,
              actTodoIsRoom: !!action.room,
              Todo: {house: action.house, room: action.room, todos: [] }
          });
      case RoomPlanActions.CLEAR_TODO:
          return Object.assign({}, state, {
              Todo: {},
              actTodoID: 0,
              actTodoIsRoom: false,
          });
      case RoomPlanActions.LOAD_TODO_REQUEST:
          return Object.assign({}, state, {
              Todo: {}
          });
      case RoomPlanActions.LOAD_TODO_SUCCESS:
          return Object.assign({}, state, {
              Todo: action.response.data
          });
          
      case RoomPlanActions.SAVE_TODO_REQUEST:
          return Object.assign({}, state, {
              TodoVisible: false,
              TodoSaved: 1,
          });
      case RoomPlanActions.SAVE_TODO_SUCCESS:
          return Object.assign({}, state, {
              TodoVisible: false,
              TodoSaved: 0,
          });
      case RoomPlanActions.SAVE_TODO_ERROR:
          return Object.assign({}, state, {
              TodoVisible: false,
              TodoSaved: -1,
          });


      case RoomPlanActions.SHOW_OBJECT:
          return Object.assign({}, state, {
              ObjectVisible: action.show,
              actObjectID: action.id,
              actObjectIsRoom: !!action.room,
              Object: {house: action.house, room: action.room, objects: [] }
          });
      case RoomPlanActions.CLEAR_OBJECT:
          return Object.assign({}, state, {
              Object: {},
              actObjectID: 0,
              actObjectIsRoom: false,
          });
      case RoomPlanActions.LOAD_OBJECT_REQUEST:
          return Object.assign({}, state, {
              Object: {}
          });
      case RoomPlanActions.LOAD_OBJECT_SUCCESS:
          return Object.assign({}, state, {
              Object: action.response.data
          }); 

      case RoomPlanActions.SAVE_OBJECT_REQUEST:
          return Object.assign({}, state, {
              ObjectVisible: false,
              ObjectSaved: 1,
          });
      case RoomPlanActions.SAVE_OBJECT_SUCCESS:
          return Object.assign({}, state, {
              ObjectVisible: false,
              ObjectSaved: 0,
          });
      case RoomPlanActions.SAVE_OBJECT_ERROR:
          return Object.assign({}, state, {
              ObjectVisible: false,
              ObjectSaved: -1,
          });

    default:
      return state;
  }
}

