import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Popover, Avatar } from 'antd';
import * as authAction from '../../redux/auth/actions';
import TopbarDropdownWrapper from './topbarDropdown.style';
import {bindActionCreators} from 'redux';
import { Link } from 'react-router-dom';
import {LogoutOutlined, SettingOutlined, UserOutlined} from "@ant-design/icons";


const iconStyle = {
  marginRight: '10px'
};


class TopbarUser extends Component {
  constructor(props) {
    super(props);
    this.handleVisibleChange = this.handleVisibleChange.bind(this);
    this.hide = this.hide.bind(this);
    this.state = {
      visible: false
    };
  }
  hide() {
    this.setState({ visible: false });
  }
  handleVisibleChange() {
    this.setState({ visible: !this.state.visible });
  }


  render() {
    const content = (
      <TopbarDropdownWrapper onClick={this.props.clicked} className="isoUserDropdown">
          {/*this.props.isAdmin &&
          <Link to={"/app/Einstellungen"} className="isoDropdownLink">
              <SettingOutlined style={iconStyle}/> Einstellungen
          </Link>*/
          }
            <Link to={"/app/Settings"} className="isoDropdownLink">
                <SettingOutlined style={iconStyle}/> Einstellungen
            </Link>
          <a className="isoDropdownLink" onClick={() => this.props.actions.logout()}>
              <LogoutOutlined style={iconStyle} /> Abmelden
          </a>
      </TopbarDropdownWrapper>
    );

    return (
      <Popover
        content={content}
        trigger="click"
        open={this.state.visible}
        onOpenChange={this.handleVisibleChange}
        arrowPointAtCenter={true}
        placement="bottomLeft"
      >
        <div className="isoImgWrapper">
            <Avatar size="large"  icon={<UserOutlined />} ></Avatar>
        </div>
      </Popover>
    );
  }
}

 function mapStateToProps(state) {
     const { Auth } = state;
     const { idToken, isAdmin } = Auth;

     return {
         idToken,
         isAdmin
     }
 }

function mapDispatchToProps(dispatch) {
    return {
        actions: bindActionCreators(authAction, dispatch),
    };
}
export default connect(mapStateToProps, mapDispatchToProps)(TopbarUser);
