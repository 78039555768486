import React, {Component} from "react";
import {connect} from "react-redux";
import {bindActionCreators} from "redux";
import * as UsersActions from "../../redux/Users/actions";
import { Input, Button, Tabs, Table } from 'antd';
import Box from '../../components/utility/box';
import LayoutWrapper from '../../components/utility/layoutWrapper';
import { history } from '../../redux/store';
import './style.css';
import UsersDetailModal from '../../components/UsersDetailModal';
import {CloseCircleOutlined, PlusOutlined} from "@ant-design/icons";

const Search = Input.Search;



class UserLoginLogs extends Component {

    state = {search: this.props.search};

    constructor(props) {
        super(props);
        this.onChangeSearch = this.onChangeSearch.bind(this);
    }

    componentWillMount() {
        this.props.actions.LoadUserLoginLogs({...this.props.infosTableUserLoginLogs, search: this.props.search});
        this.setState({search: this.props.search});
    }


    handleTableChange = (pagination, filters, sorter) => {
        const pager = { ...this.props.paginationUserLoginLogs };
        pager.current = pagination.current;
        pager.pageSize = 75;

        console.log("sorter",sorter);


        this.props.actions.setUserLoginLogsPage(pager);

        this.props.actions.setUserLoginLogsTableInfo({
            results: pagination.pageSize,
            page: pagination.current,
            sortField: sorter.field,
            sortOrder: sorter.order,
            filter: filters,
        });

        this.props.actions.LoadUserLoginLogs({
            results: pagination.pageSize,
            page: pagination.current,
            sortField: sorter.field,
            sortOrder: sorter.order,
            filter: filters,
            search: this.props.searchUserLoginLogs
        });

    };



    componentWillUpdate(nextProps) {
        if(this.props.search !== nextProps.search) {
            //this.props.actions.LoadUserLoginLogs({all: nextProps.alleUsers})
            this.props.actions.LoadUserLoginLogs({...nextProps.infosTableUserLoginLogs, search: nextProps.search});
        }
    }

    onChangeSearch = (e) => {
        this.setState({ search: e.target.value });
    };


    render() {

        const columns = [
            {
                title: 'Anmeldezeitpunkt',
                dataIndex: 'Datum',
                key: 'Datum',
                sorter: true,
                sortOrder: this.props.infosTableUserLoginLogs.sortField === 'Datum' && this.props.infosTableUserLoginLogs.sortOrder
            },
            {
                title: 'Benutzername',
                dataIndex: 'Username',
                key: 'Username',
                sorter: true,
                sortOrder: this.props.infosTableUserLoginLogs.sortField === 'Username' && this.props.infosTableUserLoginLogs.sortOrder
            }, {
                title: 'Vorname',
                dataIndex: 'Vorname',
                key: 'Vorname',
                sorter: true,
                sortOrder: this.props.infosTableUserLoginLogs.sortField === 'Vorname' && this.props.infosTableUserLoginLogs.sortOrder
            },
            {
                title: 'Name',
                dataIndex: 'Nachname',
                key: 'Nachname',
                sorter: true,
                sortOrder: this.props.infosTableUserLoginLogs.sortField === 'Nachname' && this.props.infosTableUserLoginLogs.sortOrder
            },{
                title: 'Gerät',
                dataIndex: 'Gerät',
                key: 'Gerät',
                sorter: true,
                sortOrder: this.props.infosTableUserLoginLogs.sortField === 'Gerät' && this.props.infosTableUserLoginLogs.sortOrder
            }];


        //const searchClear = this.props.search ? <CloseCircleOutlined onClick={() => {this.props.actions.setSearchTextUserLoginLogs(''); this.setState({ search: '' });}} /> : null;

        return (
            <LayoutWrapper>
                {/*<Search onChange={this.onChangeSearch} value={this.state.search} prefix={searchClear} placeholder={"Suchen..."} onSearch={value => this.props.actions.setSearchTextUserLoginLogs(value)} defaultValue={this.props.searchUserLoginLogs} enterButton style={{width: '400px', minHeight: '0px !important'}} />*/}
            <Box >
                <Table
                    columns={columns}
                    dataSource={this.props.UserLoginLogs}
                    pagination={this.props.paginationUserLoginLogs}
                    loading={this.props.loadingUserLoginLogs}
                    onChange={this.handleTableChange}
                    scroll={{x: '100%'}}
                    rowKey={(record => record.Datum)}
                />
            </Box>
            </LayoutWrapper>
        );
    }
}


function mapStateToProps(state) {
    const { UsersReducer } = state;
    const { loadingUserLoginLogs, paginationUserLoginLogs, UserLoginLogs, infosTableUserLoginLogs, searchUserLoginLogs } = UsersReducer;
    return {
        loadingUserLoginLogs,
        paginationUserLoginLogs,
        UserLoginLogs,
        infosTableUserLoginLogs,
        searchUserLoginLogs,
    };
}


function mapDispatchToProps(dispatch) {
    return{
        actions: bindActionCreators(UsersActions, dispatch)
    };
}



export default connect(mapStateToProps, mapDispatchToProps)(UserLoginLogs);
