import PersonActions from "./actions";


const initState = {
    loadingPersonList: true,
    PersonList: [],
    paginationPerson: {},
    infosTablePerson: {},
    search: "",
    Persons: [],
    actPersonID: 0,
    PersonDetail: {},
    PersonDetailVisible: false,
    loadingPersonDetail: false,
    savingPersonDetail: false,
    personsSyncLoading: false,
    previewFileModalVisible: false,
    previewFileModalFile: {}
};

export default function PersonReducer(state = initState, action) {
  switch (action.type) {
      case PersonActions.LOAD_PERSONS_TABLE_REQUEST:
        return Object.assign({}, state, {
            loadingPersonList: true
        });
      case PersonActions.LOAD_PERSONS_TABLE_SUCCESS:
          const pagination = { ...state.paginationPerson };
          // Read total count from server
          // pagination.total = data.totalCount;
          pagination.total = action.response.data.Count;

          return Object.assign({}, state, {
              PersonList: action.response.data.Persons,
              loadingPersonList: false,
              paginationPerson: pagination
          });
      case PersonActions.LOAD_PERSONS_TABLE_ERROR:
          return Object.assign({}, state, {
              loadingPersonList: false
          });
      case PersonActions.SET_PERSONS_TABLE_PAGE:
          return Object.assign({}, state, {
              paginationPerson: action.pager
          });
      case PersonActions.SET_PERSONS_TABLE_INFO:
          return Object.assign({}, state, {
              infosTablePerson: action.infos
          });
      case PersonActions.SET_SEARCH_TEXT:
          return Object.assign({}, state, {
              search: action.search,
              paginationPerson: {current: 1},
              infosTablePerson: {}
          });

      case PersonActions.LOAD_PERSONS_DETAIL_REQUEST:
          return Object.assign({}, state, {
              loadingPersonDetail: true
          });
      case PersonActions.LOAD_PERSONS_DETAIL_SUCCESS:
          return Object.assign({}, state, {
              PersonDetail: action.response.data.person,
              loadingPersonDetail: false
          });
      case PersonActions.LOAD_PERSONS_DETAIL_ERROR:
          return Object.assign({}, state, {
              loadingPersonDetail: false
          });

      case PersonActions.SAVE_PERSONS_DETAIL_REQUEST:
          return Object.assign({}, state, {
              savingPersonDetail: true,
          });
      case PersonActions.SAVE_PERSONS_DETAIL_SUCCESS:
          return Object.assign({}, state, {
              savingPersonDetail: false,
              PersonDetailVisible: false,
          });
      case PersonActions.SAVE_PERSONS_DETAIL_ERROR:
          return Object.assign({}, state, {
              savingPersonDetail: false,
          });

      case PersonActions.LOAD_PERSONS_REQUEST:
          return state;
      case PersonActions.LOAD_PERSONS_SUCCESS:
          return Object.assign({}, state, {
              Persons: action.response.data.persons
          });
      case PersonActions.LOAD_PERSONS_ERROR:
          return state;
      case PersonActions.SAVE_PERSONS_FILE_NAME_SUCCESS:
          let newPersonDetail = state.PersonDetail;
          newPersonDetail.images = newPersonDetail.images.map(img => { if(img.uid === parseInt(action.response.data.fileId)) {img.name = action.response.data.filename;}  return img;});
          return Object.assign({}, state, {
              PersonDetail: newPersonDetail,
              previewFileModalVisible: false,
              previewFileModalFile: {}
          });
      case PersonActions.SHOW_PREVIEW_FILE_MODAL:
          return Object.assign({}, state, {
              previewFileModalVisible: action.show,
              previewFileModalFile: action.file
          });
      case PersonActions.SYNC_PERSONS_REQUEST:
          return Object.assign({}, state, {
              personsSyncLoading: true
          });
      case PersonActions.SYNC_PERSONS_SUCCESS:
          return Object.assign({}, state, {
              personsSyncLoading: false
          });
      case PersonActions.SYNC_PERSONS_ERROR:
          return Object.assign({}, state, {
              personsSyncLoading: false
          });

      case PersonActions.SHOW_PERSONS_DETAIL:
          return Object.assign({}, state, {
              PersonDetailVisible: action.show,
              actPersonID: action.id,
          });

      case PersonActions.CLEAR_PERSONS_DETAIL:
          return Object.assign({}, state, {
              PersonDetail: {},
              actPersonID: 0
          });

    default:
      return state;
  }
}

