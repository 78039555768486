import RechnungenActions from "./actions";


const initState = {
    loadingRechnungenList: true,
    RechnungenList: [],
    paginationRechnungen: {},
    infosTableRechnungen: {},
    search: "",
    Rechnungen: [],

    RechnungDetail: {},
    loadingRechnungenDetail: false,
    savingRechnungenDetail: false,

    previewModalVisible: false,
    loadingPreview: false,
    previewBLOB: false,

    billPayedModalVisible: false,
    setBillPayedLoading: false,
    setBillPayedRNR: 0
};

export default function RechnungenReducer(state = initState, action) {
  switch (action.type) {
      case RechnungenActions.LOAD_BILLS_TABLE_REQUEST:
        return Object.assign({}, state, {
            loadingRechnungenList: true
        });
      case RechnungenActions.LOAD_BILLS_TABLE_SUCCESS:
          const pagination = { ...state.paginationRechnungen };
          // Read total count from server
          // pagination.total = data.totalCount;
          pagination.total = action.response.data.Count;

          return Object.assign({}, state, {
              RechnungenList: action.response.data.bills,
              loadingRechnungenList: false,
              paginationRechnungen: pagination
          });
      case RechnungenActions.LOAD_BILLS_TABLE_ERROR:
          return Object.assign({}, state, {
              loadingRechnungenList: false
          });
      case RechnungenActions.SET_BILLS_TABLE_PAGE:
          return Object.assign({}, state, {
              paginationRechnungen: action.pager
          });
      case RechnungenActions.SET_BILLS_TABLE_INFO:
          return Object.assign({}, state, {
              infosTableRechnungen: action.infos
          });
      case RechnungenActions.SET_SEARCH_TEXT:
          return Object.assign({}, state, {
              search: action.search,
              paginationRechnungen: {current: 1},
              infosTableRechnungen: {}
          });

      case RechnungenActions.LOAD_BILLS_DETAIL_REQUEST:
          return Object.assign({}, state, {
              loadingRechnungenDetail: true
          });
      case RechnungenActions.LOAD_BILLS_DETAIL_SUCCESS:
          return Object.assign({}, state, {
              RechnungDetail: action.response.data.bill,
              loadingRechnungenDetail: false
          });
      case RechnungenActions.LOAD_BILLS_DETAIL_ERROR:
          return Object.assign({}, state, {
              loadingRechnungenDetail: false
          });

      case RechnungenActions.CREATE_BILLS_REQUEST:
          return Object.assign({}, state, {
              savingRechnungenDetail: true
          });
      case RechnungenActions.CREATE_BILLS_SUCCESS:
          return Object.assign({}, state, {
              savingRechnungenDetail: false
          });
      case RechnungenActions.CREATE_BILLS_ERROR:
          return Object.assign({}, state, {
              savingRechnungenDetail: false
          });
      case RechnungenActions.PREVIEW_BILLS_REQUEST:
          return Object.assign({}, state, {
              previewModalVisible: true,
              loadingPreview: true
          });
      case RechnungenActions.PREVIEW_BILLS_SUCCESS:
      case RechnungenActions.PREVIEW_BILLS_ERROR:
          return Object.assign({}, state, {
              loadingPreview: false
          });
      case RechnungenActions.HIDE_PREVIEW_BILL_MODAL:
          return Object.assign({}, state, {
              previewModalVisible: false,
              previewBLOB: false
          });

      case RechnungenActions.SHOW_PREVIEW_BLOB:
          return Object.assign({}, state, {
              previewBLOB: action.previewBLOB
          });

      case RechnungenActions.TOGGLE_BILL_PAYED_MODAL:
          return Object.assign({}, state, {
              billPayedModalVisible: action.show,
              setBillPayedRNR: action.rnr
          });
      case RechnungenActions.SAVE_BILL_PAYED_REQUEST:
          return Object.assign({}, state, {
              setBillPayedLoading: true
          });
      case RechnungenActions.SAVE_BILL_PAYED_SUCCESS:
          return Object.assign({}, state, {
              setBillPayedLoading: false,
              billPayedModalVisible: false,
              setBillPayedRNR: 0
          });
      case RechnungenActions.SAVE_BILL_PAYED_ERROR:
          return Object.assign({}, state, {
              setBillPayedLoading: false
          });

    default:
      return state;
  }
}

