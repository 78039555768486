import {CALL_API} from "../../helpers/middleware";
import moment from "moment/moment";


const UsersActions = {
    LOAD_USERS_REQUEST : 'LOAD_USERS_REQUEST',
    LOAD_USERS_SUCCESS : 'LOAD_USERS_SUCCESS',
    LOAD_USERS_ERROR : 'LOAD_USERS_ERROR',
    LOAD_USERS_GROUPS_REQUEST : 'LOAD_USERS_GROUPS_REQUEST',
    LOAD_USERS_GROUPS_SUCCESS : 'LOAD_USERS_GROUPS_SUCCESS',
    LOAD_USERS_GROUPS_ERROR : 'LOAD_USERS_GROUPS_ERROR',
    SET_USERS_PAGE : 'SET_USERS_PAGE',
    SET_USERS_TABLE_INFO: 'SET_USERS_TABLE_INFO',
    SET_SEARCH_TEXT: 'SET_SEARCH_TEXT',
    SET_USERS_DETAILS_VISIBLE: 'SET_USERS_DETAILS_VISIBLE',
    SAVE_USERS_REQUEST : 'SAVE_USERS_REQUEST',
    SAVE_USERS_SUCCESS : 'SAVE_USERS_SUCCESS',
    SAVE_USERS_ERROR : 'SAVE_USERS_ERROR',
    LOAD_USERS_DETAILS_REQUEST : 'LOAD_USERS_DETAILS_REQUEST',
    LOAD_USERS_DETAILS_SUCCESS : 'LOAD_USERS_DETAILS_SUCCESS',
    LOAD_USERS_DETAILS_ERROR : 'LOAD_USERS_DETAILS_ERROR',
    RESET_USER_DETAIL: 'RESET_USER_DETAIL',
    DELETE_USERS_REQUEST : 'DELETE_USERS_REQUEST',
    DELETE_USERS_SUCCESS : 'DELETE_USERS_SUCCESS',
    DELETE_USERS_ERROR : 'DELETE_USERS_ERROR',

    LOAD_USER_LOGIN_LOGS_REQUEST : 'LOAD_USER_LOGIN_LOGS_REQUEST',
    LOAD_USER_LOGIN_LOGS_SUCCESS : 'LOAD_USER_LOGIN_LOGS_SUCCESS',
    LOAD_USER_LOGIN_LOGS_ERROR : 'LOAD_USER_LOGIN_LOGS_ERROR',

    SET_USER_LOGIN_LOGS_PAGE : 'SET_USER_LOGIN_LOGS_PAGE',
    SET_USER_LOGIN_LOGS_TABLE_INFO : 'SET_USER_LOGIN_LOGS_TABLE_INFO',
    SET_USER_LOGIN_LOGS_SEARCH_TEXT : 'SET_USER_LOGIN_LOGS_SEARCH_TEXT',
};

export default UsersActions;


export function LoadUsers(params = {}) {
    return {
        [CALL_API]: {
            reqType: 'POST',
            endpoint: 'users/list',
            postData: {
                results: 25,
                ...params,
            },
            types: [UsersActions.LOAD_USERS_REQUEST, UsersActions.LOAD_USERS_SUCCESS, UsersActions.LOAD_USERS_ERROR]
        }
    };
}


export function LoadUserLoginLogs(params = {}) {
    return {
        [CALL_API]: {
            reqType: 'POST',
            endpoint: 'users/login-logs',
            postData: {
                results: 75,
                ...params,
            },
            types: [UsersActions.LOAD_USER_LOGIN_LOGS_REQUEST, UsersActions.LOAD_USER_LOGIN_LOGS_SUCCESS, UsersActions.LOAD_USER_LOGIN_LOGS_ERROR]
        }
    };
}

export function setUserLoginLogsPage(pager) {
    return {
        type: UsersActions.SET_USER_LOGIN_LOGS_PAGE,
        pager
    };
}

export function setUserLoginLogsTableInfo(infos) {
    return {
        type: UsersActions.SET_USER_LOGIN_LOGS_TABLE_INFO,
        infos
    };
}

export function setSearchTextUserLoginLogs(search) {
    return {
        type: UsersActions.SET_USER_LOGIN_LOGS_SEARCH_TEXT,
        search
    };
}


export function LoadUsersGroups(visible, UserID = false) {
    return {
        [CALL_API]: {
            reqType: 'GET',
            endpoint: 'users/groups',
            types: [UsersActions.LOAD_USERS_GROUPS_REQUEST, UsersActions.LOAD_USERS_GROUPS_SUCCESS, UsersActions.LOAD_USERS_GROUPS_ERROR]
        }
    }
}

export function LoadUsersDetails(UserID) {
    return {
        [CALL_API]: {
            reqType: 'GET',
            endpoint: 'users/'+UserID,
            types: [UsersActions.LOAD_USERS_DETAILS_REQUEST, UsersActions.LOAD_USERS_DETAILS_SUCCESS, UsersActions.LOAD_USERS_DETAILS_ERROR]
        }
    }
}



export function saveUserDetail(values, UserID) {

    values = {...values, Geburtstag: values.Geburtstag ? moment(values.Geburtstag).format("YYYY-MM-DD") : undefined};

    if(UserID) {
        return {
            [CALL_API]: {
                reqType: 'PUT',
                endpoint: 'users/'+UserID,
                postData: values,
                types: [UsersActions.SAVE_USERS_REQUEST, UsersActions.SAVE_USERS_SUCCESS, UsersActions.SAVE_USERS_ERROR]
            }
        }
    } else {
        return {
            [CALL_API]: {
                reqType: 'POST',
                endpoint: 'users',
                postData: values,
                types: [UsersActions.SAVE_USERS_REQUEST, UsersActions.SAVE_USERS_SUCCESS, UsersActions.SAVE_USERS_ERROR]
            }
        }
    }

}



export function deleteUser(UserID) {
    return {
        [CALL_API]: {
            reqType: 'DELETE',
            endpoint: 'users/'+UserID,
            types: [UsersActions.DELETE_USERS_REQUEST, UsersActions.DELETE_USERS_SUCCESS, UsersActions.DELETE_USERS_ERROR]
        }
    }
}


export function setUsersPage(pager) {
    return {
        type: UsersActions.SET_USERS_PAGE,
        pager
    };
}

export function setUsersTableInfo(infos) {
    return {
        type: UsersActions.SET_USERS_TABLE_INFO,
        infos
    };
}

export function setSearchText(search) {
    return {
        type: UsersActions.SET_SEARCH_TEXT,
        search
    };
}

export function setUsersTab(tab) {
    return {
        type: UsersActions.SET_USERS_TAB,
        tab
    };
}

export function showUsersDetail(visible, UserID = false) {
    return {
        type: UsersActions.SET_USERS_DETAILS_VISIBLE,
        visible,
        UserID
    }
}


export function resetDetail() {
    return {
        type: UsersActions.RESET_USER_DETAIL
    };
}





