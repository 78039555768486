import ReinigungActions from "./actions";


const initState = {
    loadingReinigungList: true,
    ReinigungList: [],
    paginationReinigung: {},
    infosTableReinigung: {},
    search: "",
    ReinigungDetail: {},

    ReinigungCleaningNoteSaving: false,

    ReinigungDetailID: null,
    ReinigungModalVisible: false,
    ReinigungModalSaving: false,
    ReinigungModalLoading: false,
    deletingCleaning: false,
};

export default function ReinigungReducer(state = initState, action) {
  switch (action.type) {
      case ReinigungActions.LOAD_REINIGUNG_LIST_REQUEST:
        return Object.assign({}, state, {
            loadingReinigungList: true
        });
      case ReinigungActions.LOAD_REINIGUNG_LIST_SUCCESS:
          const pagination = { ...state.paginationReinigung };
          // Read total count from server
          // pagination.total = data.totalCount;
          pagination.total = action.response.data.Count;

          return Object.assign({}, state, {
              ReinigungList: action.response.data.cleaning,
              loadingReinigungList: false,
              paginationReinigung: pagination
          });
      case ReinigungActions.LOAD_REINIGUNG_LIST_ERROR:
          return Object.assign({}, state, {
              loadingReinigungList: false
          });
      case ReinigungActions.SET_REINIGUNG_TABLE_PAGE:
          return Object.assign({}, state, {
              paginationReinigung: action.pager
          });
      case ReinigungActions.SET_REINIGUNG_TABLE_INFO:
          return Object.assign({}, state, {
              infosTableReinigung: action.infos
          });
      case ReinigungActions.SET_SEARCH_TEXT:
          return Object.assign({}, state, {
              search: action.search,
              paginationReinigung: {current: 1},
              infosTableReinigung: {}
          });

      case ReinigungActions.SHOW_REINIGUNG_MODAL:
          return Object.assign({}, state, {
              ReinigungModalVisible: action.show,
              ReinigungDetailID: action.CleaningID,
              ReinigungDetail: {}
          });
      case ReinigungActions.SAVE_REINIGUNG_REQUEST:
          return Object.assign({}, state, {
              ReinigungModalSaving: true
          });
      case ReinigungActions.SAVE_REINIGUNG_SUCCESS:
              return Object.assign({}, state, {
                  ReinigungModalVisible: false,
                  ReinigungModalSaving: false
              });
      case ReinigungActions.SAVE_REINIGUNG_ERROR:
          return Object.assign({}, state, {
              ReinigungModalSaving: false
          });

      case ReinigungActions.LOAD_REINIGUNG_DETAIL_REQUEST:
          return Object.assign({}, state, {
              ReinigungModalLoading: true,
              ReinigungDetail: {}
          });
      case ReinigungActions.LOAD_REINIGUNG_DETAIL_SUCCESS:
          return Object.assign({}, state, {
              ReinigungModalLoading: false,
              ReinigungDetail: action.response.data.cleaning
          });
      case ReinigungActions.LOAD_REINIGUNG_DETAIL_ERROR:
          return Object.assign({}, state, {
              ReinigungModalLoading: false
          });

          case ReinigungActions.DELETE_REINIGUNG_REQUEST:
          return Object.assign({}, state, {
              deletingCleaning: true
          });
      case ReinigungActions.DELETE_REINIGUNG_SUCCESS:
          return Object.assign({}, state, {
              ReinigungModalVisible: false,
              deletingCleaning: false
          });
      case ReinigungActions.DELETE_REINIGUNG_ERROR:
          return Object.assign({}, state, {
              deletingCleaning: false
          });

      case ReinigungActions.SAVE_REINIGUNG_CLEANING_NOTE_REQUEST:
          return Object.assign({}, state, {
              ReinigungCleaningNoteSaving: true,
          });
      case ReinigungActions.SAVE_REINIGUNG_CLEANING_NOTE_SUCCESS:
          return Object.assign({}, state, {
              ReinigungCleaningNoteSaving: false,
              ReinigungDetail: {...state.ReinigungDetail, cleaningNotes: [action.response.data, ...(state.ReinigungDetail?.cleaningNotes || [])]}
          });
      case ReinigungActions.SAVE_REINIGUNG_CLEANING_NOTE_ERROR:
          return Object.assign({}, state, {
              ReinigungCleaningNoteSaving: false,
          });

    default:
      return state;
  }
}

