import React, {Component} from "react";
import {connect} from "react-redux";
import {bindActionCreators} from "redux";
import * as HolidayActions from "../../redux/Holiday/actions";
import { Input, Button, Tabs, Table } from 'antd';
import Box from '../../components/utility/box';
import LayoutWrapper from '../../components/utility/layoutWrapper';
import { history } from '../../redux/store';
import './style.css';
import HolidayDetailModal from './HolidayDetailModal';
import {CloseCircleOutlined, PlusOutlined} from "@ant-design/icons";
import {LoadHolidays} from "../../redux/Holiday/actions";
import moment from "moment";
import {Calendar, momentLocalizer, Views} from 'react-big-calendar';
import "react-big-calendar/lib/css/react-big-calendar.css";


class HolidayCalendar extends Component {

    state = {date: undefined};


    fetchCalendar = () => {
        let date;
        if(this.state.date) {
            date = moment(this.state.date).format('YYYY-MM-DD');
        }

        this.props.actions.LoadHolidays({ date });
    };

    constructor(props) {
        super(props);
    }

    componentWillMount() {
        this.fetchCalendar();
    }



    componentDidUpdate(prevProps, prevState) {
        if(((!this.props.savingHoliday && prevProps.savingHoliday) || (!this.props.deletingHoliday && prevProps.deletingHoliday)) && !this.props.HolidayDetailsVisible && prevProps.HolidayDetailsVisible || prevState.date !== this.state.date) {
            this.fetchCalendar();
        }
    }


    Event = ({ event }) => {
        return (
            <span>
                <span>{event.data.Vorname} {event.data.Nachname}</span>
            </span>
        )
    }

    handleSelectSlot = (selection) => {

        const start = selection.slots[0];
        const end = selection.slots[(selection.slots.length -1)];

        this.props.actions.showHolidayDetail(true, false, { StartDatum: moment(start), EndDatum: moment(end) });
    };



    localizer = momentLocalizer(moment);

    render() {


        return (
            <LayoutWrapper>
                <HolidayDetailModal
                    visible={this.props.HolidayDetailsVisible}
                    handleCancel={() => this.props.actions.showHolidayDetail(false)}
                />
                <Button type={"primary"} htmlType={"button"} className={"round"} style={{marginBottom: "10px", marginLeft: "auto"}} onClick={() => this.props.actions.showHolidayDetail(true)} icon={<PlusOutlined/>}>Hinzufügen</Button>
            <Box >
                <Calendar
                    /*selectable
                    onSelectSlot={(newEvent) => {
                        setNewDetailInitial({
                            startDate: moment(newEvent.start)
                        });
                        setDetailId('new');
                    }}*/
                    defaultView={Views.MONTH}
                    messages={{
                        allDay:"ganzer Tag",
                        previous:"Vorheriger",
                        next: "Nächster",
                        today: "Heute",
                        month:"Monat",
                        week: "Woche",
                        day: "Tag",
                        agenda: "Agenda",
                        date: "Datum",
                        time:"Uhrzeit",
                        event:"Ereignis",
                        showMore: (total) => `+ ${total}`
                    }}
                    components={{event: this.Event}}
                    localizer={this.localizer}
                    events={this.props.Holidays}
                    startAccessor="start"
                    endAccessor="end"
                    style={{ height: 1000 }}
                    onSelectEvent={(item) => this.props.actions.showHolidayDetail(true, item.data.HolidayID) }
                    onSelectSlot={this.handleSelectSlot}
                    selectable
                    onNavigate={(newDate) => this.setState({ date: newDate } )}
                />
            </Box>
            </LayoutWrapper>
        );
    }
}


function mapStateToProps(state) {
    const { HolidayReducer } = state;
    const { Holidays, HolidayDetailsVisible, savingHoliday, deletingHoliday } = HolidayReducer;
    return {
        Holidays,
        HolidayDetailsVisible,
        savingHoliday,
        deletingHoliday
    };
}


function mapDispatchToProps(dispatch) {
    return{
        actions: bindActionCreators(HolidayActions, dispatch)
    };
}



export default connect(mapStateToProps, mapDispatchToProps)(HolidayCalendar);
