import MaschinenActions from "./actions";


const initState = {
    loadingMaschinenList: true,
    MaschinenList: [],
    paginationMaschinen: {},
    infosTableMaschinen: {},
    search: "",
    MaschineDetail: {},

    MaschinenDetailID: null,
    MaschinenModalVisible: false,
    MaschinenModalSaving: false,
    MaschinenModalLoading: false,
    deletingMachine: false,
};

export default function MaschinenReducer(state = initState, action) {
  switch (action.type) {
      case MaschinenActions.LOAD_MASCHINEN_LIST_REQUEST:
        return Object.assign({}, state, {
            loadingMaschinenList: true
        });
      case MaschinenActions.LOAD_MASCHINEN_LIST_SUCCESS:
          const pagination = { ...state.paginationMaschinen };
          // Read total count from server
          // pagination.total = data.totalCount;
          pagination.total = action.response.data.Count;

          return Object.assign({}, state, {
              MaschinenList: action.response.data.Maschinen,
              loadingMaschinenList: false,
              paginationMaschinen: pagination
          });
      case MaschinenActions.LOAD_MASCHINEN_LIST_ERROR:
          return Object.assign({}, state, {
              loadingMaschinenList: false
          });
      case MaschinenActions.SET_MASCHINEN_TABLE_PAGE:
          return Object.assign({}, state, {
              paginationMaschinen: action.pager
          });
      case MaschinenActions.SET_MASCHINEN_TABLE_INFO:
          return Object.assign({}, state, {
              infosTableMaschinen: action.infos
          });
      case MaschinenActions.SET_SEARCH_TEXT:
          return Object.assign({}, state, {
              search: action.search,
              paginationMaschinen: {current: 1},
              infosTableMaschinen: {}
          });

      case MaschinenActions.SHOW_MASCHINEN_MODAL:
          return Object.assign({}, state, {
              MaschinenModalVisible: action.show,
              MaschinenDetailID: action.MachineID,
              MaschineDetail: {}
          });
      case MaschinenActions.SAVE_MASCHINEN_REQUEST:
          return Object.assign({}, state, {
              MaschinenModalSaving: true
          });
      case MaschinenActions.SAVE_MASCHINEN_SUCCESS:
              return Object.assign({}, state, {
                  MaschinenModalVisible: false,
                  MaschinenModalSaving: false
              });
      case MaschinenActions.SAVE_MASCHINEN_ERROR:
          return Object.assign({}, state, {
              MaschinenModalSaving: false
          });

      case MaschinenActions.LOAD_MASCHINEN_DETAIL_REQUEST:
          return Object.assign({}, state, {
              MaschinenModalLoading: true,
              MaschineDetail: {}
          });
      case MaschinenActions.LOAD_MASCHINEN_DETAIL_SUCCESS:
          return Object.assign({}, state, {
              MaschinenModalLoading: false,
              MaschineDetail: action.response.data.machine
          });
      case MaschinenActions.LOAD_MASCHINEN_DETAIL_ERROR:
          return Object.assign({}, state, {
              MaschinenModalLoading: false
          });

          case MaschinenActions.DELETE_MASCHINEN_REQUEST:
          return Object.assign({}, state, {
              deletingMachine: true
          });
      case MaschinenActions.DELETE_MASCHINEN_SUCCESS:
          return Object.assign({}, state, {
              MaschinenModalVisible: false,
              deletingMachine: false
          });
      case MaschinenActions.DELETE_MASCHINEN_ERROR:
          return Object.assign({}, state, {
              deletingMachine: false
          });

    default:
      return state;
  }
}

