import {CALL_API} from "../../helpers/middleware";
import moment from "moment";


const HolidayActions = {
    LOAD_HOLIDAYS_REQUEST : 'LOAD_HOLIDAYS_REQUEST',
    LOAD_HOLIDAYS_SUCCESS : 'LOAD_HOLIDAYS_SUCCESS',
    LOAD_HOLIDAYS_ERROR : 'LOAD_HOLIDAYS_ERROR',
    SET_HOLIDAY_DETAILS_VISIBLE: 'SET_HOLIDAY_DETAILS_VISIBLE',
    SAVE_HOLIDAY_REQUEST : 'SAVE_HOLIDAY_REQUEST',
    SAVE_HOLIDAY_SUCCESS : 'SAVE_HOLIDAY_SUCCESS',
    SAVE_HOLIDAY_ERROR : 'SAVE_HOLIDAY_ERROR',
    LOAD_HOLIDAY_DETAILS_REQUEST : 'LOAD_HOLIDAY_DETAILS_REQUEST',
    LOAD_HOLIDAY_DETAILS_SUCCESS : 'LOAD_HOLIDAY_DETAILS_SUCCESS',
    LOAD_HOLIDAY_DETAILS_ERROR : 'LOAD_HOLIDAY_DETAILS_ERROR',
    RESET_HOLIDAY_DETAIL: 'RESET_HOLIDAY_DETAIL',
    DELETE_HOLIDAY_REQUEST : 'DELETE_HOLIDAY_REQUEST',
    DELETE_HOLIDAY_SUCCESS : 'DELETE_HOLIDAY_SUCCESS',
    DELETE_HOLIDAY_ERROR : 'DELETE_HOLIDAY_ERROR',
};

export default HolidayActions;


export function LoadHolidays(params = {}) {

    const searchParams = new URLSearchParams(params);

    return {
        [CALL_API]: {
            reqType: 'GET',
            endpoint: `holidays?${searchParams.toString()}`,
            types: [HolidayActions.LOAD_HOLIDAYS_REQUEST, HolidayActions.LOAD_HOLIDAYS_SUCCESS, HolidayActions.LOAD_HOLIDAYS_ERROR]
        }
    };
}


export function LoadHolidayDetails(HolidayID) {
    return {
        [CALL_API]: {
            reqType: 'GET',
            endpoint: 'holidays/'+HolidayID,
            types: [HolidayActions.LOAD_HOLIDAY_DETAILS_REQUEST, HolidayActions.LOAD_HOLIDAY_DETAILS_SUCCESS, HolidayActions.LOAD_HOLIDAY_DETAILS_ERROR]
        }
    }
}



export function saveHolidayDetail(values, HolidayID) {

    values = {...values, StartDatum: moment(values.StartDatum).format("YYYY-MM-DD"), EndDatum: moment(values.EndDatum).format("YYYY-MM-DD"), };

    if(HolidayID) {
        return {
            [CALL_API]: {
                reqType: 'PUT',
                endpoint: 'holidays/'+HolidayID,
                postData: values,
                types: [HolidayActions.SAVE_HOLIDAY_REQUEST, HolidayActions.SAVE_HOLIDAY_SUCCESS, HolidayActions.SAVE_HOLIDAY_ERROR]
            }
        }
    } else {
        return {
            [CALL_API]: {
                reqType: 'POST',
                endpoint: 'holidays',
                postData: values,
                types: [HolidayActions.SAVE_HOLIDAY_REQUEST, HolidayActions.SAVE_HOLIDAY_SUCCESS, HolidayActions.SAVE_HOLIDAY_ERROR]
            }
        }
    }

}



export function deleteHoliday(HolidayID) {
    return {
        [CALL_API]: {
            reqType: 'DELETE',
            endpoint: 'holidays/'+HolidayID,
            types: [HolidayActions.DELETE_HOLIDAY_REQUEST, HolidayActions.DELETE_HOLIDAY_SUCCESS, HolidayActions.DELETE_HOLIDAY_ERROR]
        }
    }
}


export function showHolidayDetail(visible, HolidayID = false, initialValues) {
    return {
        type: HolidayActions.SET_HOLIDAY_DETAILS_VISIBLE,
        visible,
        HolidayID,
        initialValues,
    }
}


export function resetDetail() {
    return {
        type: HolidayActions.RESET_HOLIDAY_DETAIL
    };
}





