import React from 'react';
import { Provider } from 'react-redux';
import { store, history } from './redux/store';
import PublicRoutes from './router';
import DashAppHolder from './dashAppStyle';

const DashApp = () => (
        <DashAppHolder>
            <Provider store={store}>
                <PublicRoutes history={history} />
            </Provider>
        </DashAppHolder>
);

export default DashApp;
