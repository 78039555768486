import React from 'react';
import { Switch, Route } from 'react-router-dom';
import RoomPlan from '../RoomPlan';
import CheckIn from '../CheckIn';
import Hausverbote from '../Hausverbote';
import Personen from '../Personen';
import FileDownload from "../../components/FileDownload";
import Arbeitskleidung from "../Arbeitskleidung";
import Aufgaben from '../Aufgaben';
import Rechnungen from "../Rechnungen";
import RechnungenDetail from "../Rechnungen/detail";
import Doku from '../Doku';
import Users from "../Users";
import HolidayCalendar from "../HolidayCalendar";
import Settings from "../Settings";
import { history } from '../../redux/store';
import Maschinen from "../Maschinen";
import Reinigung from "../Reinigung";
import UserLoginLogs from "../UserLoginLogs";
import TodoRezeption from "../TodoRezeption";
import Lustmolche from "../Lustmolche";



const AdminRoute = ({ component: Component, isAdmin, ...rest }) => (
    <Route
        {...rest}
        render={props => isAdmin
            ? <Component {...props} />
            : history.goBack()}
    />
);

const PermissionRoute = ({ component: Component, GroupID, allowedGroups, ...rest }) => (
    <Route
        {...rest}
        render={props => allowedGroups.includes(GroupID)
            ? <Component {...props} />
            : history.goBack()}
    />
);


class AppRouter extends React.Component {
  render() {
    const { url, isAdmin, GroupID } = this.props;
    return (
      <Switch>
          <Route
              exact
              path={`${url}/RoomPlan`}
              component={RoomPlan}
          />
          <PermissionRoute
              GroupID={GroupID}
              allowedGroups={[1,2,22]}
              exact
              path={`${url}/CheckIn`}
              component={CheckIn}
          />
          <PermissionRoute
              GroupID={GroupID}
              allowedGroups={[1,2,22]}
              exact
              path={`${url}/Hausverbote`}
              component={Hausverbote}
          />
          <PermissionRoute
              GroupID={GroupID}
              allowedGroups={[1,2,22]}
              exact
              path={`${url}/Maschinen`}
              component={Maschinen}
          />
          <PermissionRoute
              GroupID={GroupID}
              allowedGroups={[1,2,22]}
              exact
              path={`${url}/Reinigung`}
              component={Reinigung}
          />

          <PermissionRoute
              GroupID={GroupID}
              allowedGroups={[1,2,22]}
              exact
              path={`${url}/TodoRezeption`}
              component={TodoRezeption}
          />

          <PermissionRoute
              GroupID={GroupID}
              allowedGroups={[1,2,22]}
              exact
              path={`${url}/HolidayCalendar`}
              component={HolidayCalendar}
          />
          <PermissionRoute
              GroupID={GroupID}
              allowedGroups={[1,2,22]}
              exact
              path={`${url}/Kunden`}
              component={Personen}
          />
            <PermissionRoute
                GroupID={GroupID}
                allowedGroups={[1,2,22]}
                exact
                path={`${url}/Lustmolche`}
                component={Lustmolche}
            />
          <Route
              exact
              path={`${url}/File/:type/:FileID`}
              component={FileDownload}
          />
          <Route
              exact
              path={`${url}/Arbeitskleidung`}
              component={Arbeitskleidung}
          />
          <Route
              exact
              path={`${url}/Aufgaben`}
              component={Aufgaben}
          />
          <PermissionRoute
              GroupID={GroupID}
              allowedGroups={[1,2,22]}
              exact
              path={`${url}/Rechnungen`}
              component={Rechnungen}
          />
          <PermissionRoute
              GroupID={GroupID}
              allowedGroups={[1,2,22]}
              exact
              path={`${url}/Rechnungen/neu`}
              component={RechnungenDetail}
          />
          <Route
              exact
              path={`${url}/Doku/:Doku`}
              component={Doku}
          />
          <AdminRoute
              isAdmin={isAdmin}
              exact
              path={`${url}/Users`}
              component={Users}
          />
          <AdminRoute
              isAdmin={isAdmin}
              exact
              path={`${url}/Users/Logins`}
              component={UserLoginLogs}
          />
          <Route
              exact
              path={`${url}/Settings`}
              component={Settings}
          />
      </Switch>
    );
  }
}


export default AppRouter;
