import React, {Component} from "react";
import {connect} from "react-redux";
import {bindActionCreators} from "redux";
import * as CheckInActions from "../../redux/CheckIn/actions";
import { Input, Button, DatePicker, Table, Row, Col, Radio, Divider, Modal, Dropdown, Menu } from 'antd';
import Box from '../../components/utility/box';
import Header from '../../components/utility/pageHeader';
import LayoutWrapper from '../../components/utility/layoutWrapper';
import { history } from '../../redux/store';
import NewAgreement from '../../components/NewAgreement';
import SignAgreement from '../../components/SignAgreement';
import SignAgreementLustra from '../../components/SignAgreementLustra';
import ShowAgreement from '../../components/ShowAgreement';
import './list.style.css';
import UploadImagesPersons from "../../components/UploadFilesPersons";
import {
    CloseCircleOutlined,
    CloseOutlined,
    DownloadOutlined,
    EditOutlined,
    FileOutlined,
    PlusOutlined
} from "@ant-design/icons";

const { RangePicker } = DatePicker;
const RadioGroup = Radio.Group;
const { confirm } = Modal;

const Search = Input.Search;
const itemStyle = {margin: '0px'};





class CheckIn extends Component {



    componentWillMount() {

        if(this.props.customerId) {
            this.props.actions.setCheckInTableInfo({});
        }

        this.props.actions.LoadCheckInList({...this.props.infosTableCheckIn, search: this.props.search, customerId: this.props.customerId});
        this.state = {search: this.props.search};
    }

    addReservation = () => {
        this.props.actions.showNewAgreement(true);
    };

    handleTableChange = (pagination, filters, sorter) => {
        const pager = { ...this.props.paginationCheckIn };
        pager.current = pagination.current;

        this.props.actions.setCheckInTablePage(pager);

        this.props.actions.setCheckInTableInfo({
            results: pagination.pageSize,
            page: pagination.current,
            sortField: sorter.field,
            sortOrder: sorter.order,
            ...filters,
        });

        this.props.actions.LoadCheckInList({
            results: pagination.pageSize,
            page: pagination.current,
            sortField: sorter.field,
            sortOrder: sorter.order,
            ...filters,
            search: this.props.search,
            customerId: this.props.customerId
        });


    };



    componentWillUpdate(nextProps) {
       if(this.props.search !== nextProps.search) {
            this.props.actions.LoadCheckInList({...nextProps.infosTableCheckIn, search: nextProps.search});
        }

        if(this.props.AgreementFormSaving && !nextProps.AgreementFormSaving ||
            this.props.signAgreementSaving && !nextProps.signAgreementSaving ||
            this.props.signAgreementLustraSaving && !nextProps.signAgreementLustraSaving ||
            this.props.customerId !== nextProps.customerId) {
            this.props.actions.LoadCheckInList({...this.props.infosTableCheckIn, search: this.props.search, customerId: this.props.customerId});
        }
    }

    onChangeSearch = (e) => {
        this.setState({ search: e.target.value });
    };


    confirmStorno (AgreementID) {
        confirm({
            title: "Vertrag stornieren",
            content: "Wollen Sie diesen Vertrag wirklich stronieren? \n Dieser Vorgang kann nicht Rückgängig gemacht werden!",
            okText: "Stornieren",
            okType: 'danger',
            cancelText: "Abbrechen",
            onOk: () => this.props.actions.setAgreementStorno(AgreementID),
            onCancel() {
            },
        });
    };



    render() {

        const columns = [
            {
                title: 'Vertragsnummer',
                dataIndex: 'AgreementID',
                key: 'AgreementID',
                sorter: true,
                sortOrder: this.props.infosTableCheckIn.sortField === 'AgreementID' && this.props.infosTableCheckIn.sortOrder
            }, {
                title: 'Vorname',
                dataIndex: 'FirstName',
                key: 'FirstName',
                sorter: true,
                sortOrder: this.props.infosTableCheckIn.sortField === 'FirstName' && this.props.infosTableCheckIn.sortOrder
            }, {
                title: 'Nachname',
                dataIndex: 'LastName',
                key: 'LastName',
                sorter: true,
                sortOrder: this.props.infosTableCheckIn.sortField === 'LastName' && this.props.infosTableCheckIn.sortOrder
            }, {
                title: 'Haus',
                dataIndex: 'House',
                key: 'House',
                sorter: true,
                sortOrder: this.props.infosTableCheckIn.sortField === 'House' && this.props.infosTableCheckIn.sortOrder
            }, {
                title: 'Zimmer',
                dataIndex: 'Room',
                key: 'Room',
                sorter: true,
                sortOrder: this.props.infosTableCheckIn.sortField === 'Room' && this.props.infosTableCheckIn.sortOrder
            }, {
                title: 'Anreise',
                dataIndex: 'Arrive',
                key: 'Arrive',
                sorter: true,
                sortOrder: this.props.infosTableCheckIn.sortField === 'Arrive' && this.props.infosTableCheckIn.sortOrder
            }, {
                title: 'Abreise',
                dataIndex: 'Depart',
                key: 'Depart',
                sorter: true,
                sortOrder: this.props.infosTableCheckIn.sortField === 'Depart' && this.props.infosTableCheckIn.sortOrder
            }, {
                title: 'Ersteller',
                dataIndex: 'CreatedFrom',
                key: 'CreatedFrom',
                sorter: true,
                sortOrder: this.props.infosTableCheckIn.sortField === 'CreatedFrom' && this.props.infosTableCheckIn.sortOrder
            }, {
                title: 'Vertragsdatum',
                dataIndex: 'Created',
                key: 'Created',
                sorter: true,
                sortOrder: this.props.infosTableCheckIn.sortField === 'Created' && this.props.infosTableCheckIn.sortOrder
            }, {
                title: 'Storniert',
                dataIndex: 'Storno',
                key: 'Storno',
                sorter: true,
                sortOrder: this.props.infosTableCheckIn.sortField === 'Storno' && this.props.infosTableCheckIn.sortOrder,
                render: storno => { return storno ? 'Ja' : 'Nein' }
            }, {
                title: 'Kunde unterschrieben',
                dataIndex: 'SignedCustomer',
                key: 'SignedCustomer',
                sorter: true,
                sortOrder: this.props.infosTableCheckIn.sortField === 'SignedCustomer' && this.props.infosTableCheckIn.sortOrder,
                render: signed => { return signed ? 'Ja' : 'Nein' }
            }, {
                title: 'Lustra unterschrieben',
                dataIndex: 'SignedLustra',
                key: 'SignedLustra',
                sorter: true,
                sortOrder: this.props.infosTableCheckIn.sortField === 'SignedLustra' && this.props.infosTableCheckIn.sortOrder,
                render: signed => { return signed ? 'Ja' : 'Nein' }
            }, {
                title: 'Aktion',
                dataIndex: 'AgreementID',
                key: 'action',
                render: (aid, record) => {  const menu = (
                    <Menu onClick={(itm) => { if(itm.key === "1") { this.props.actions.showShowAgreement(true, aid, (!record.Signed && !record.Storno)); } else if(itm.key === "2") { this.props.actions.showAgreementPDF(aid); } else if(itm.key === "3") { this.confirmStorno(aid); } else if(itm.key === "4") { this.props.actions.showSignAgreement(true, aid); } else if(itm.key === "5") { this.props.actions.showSignAgreementLustra(true, aid); } }}>
                        <Menu.Item key="1"><FileOutlined /> Vertrag anzeigen</Menu.Item>
                        <Menu.Item key="2"><DownloadOutlined /> Vertrag herunterladen</Menu.Item>
                        <Menu.Item key="3" disabled={record.Storno ? true : false}><CloseOutlined /> Vertrag stornieren</Menu.Item>
                        <Menu.Item key="4" disabled={(record.Storno || record.SignedCustomer) ? true : false}><EditOutlined /> Vertrag unterschreiben (Kunde)</Menu.Item>
                        <Menu.Item key="5" disabled={(record.Storno || record.SignedLustra) ? true : false}><EditOutlined /> Vertrag unterschreiben (Lustra)</Menu.Item>
                    </Menu>
                );
                return  <Dropdown.Button trigger={['hover', 'click']} overlay={menu}> Aktion </Dropdown.Button>
                }
            }];

        const searchClear = this.props.search ? <CloseCircleOutlined onClick={() => {this.props.actions.setSearchText(''); this.setState({ search: '' });}} /> : null;

        return (
            <React.Fragment>
                {this.props.customerId ? null :
                    <NewAgreement/>
                }
                <SignAgreement />
                <SignAgreementLustra />
                <ShowAgreement />
                <UploadImagesPersons />
                {this.props.customerId === undefined ?
                        <LayoutWrapper>
                            <Search onChange={this.onChangeSearch} value={this.state.search} prefix={searchClear} placeholder={"Suchen..."} onSearch={value => this.props.actions.setSearchText(value)} defaultValue={this.props.search} enterButton style={{width: '400px', minHeight: '0px !important'}} />
                            <Button type={"primary"} htmlType={"button"} className={"round"} style={{marginBottom: "10px", marginLeft: "auto"}} onClick={this.addReservation} icon={<PlusOutlined/>}>Neuen Vertrag erstellen</Button>
                            <Box>
                                <Table
                                    columns={columns}
                                    dataSource={this.props.CheckInList}
                                    rowClassName={(record) => { return record.Storno ? ' stornorow ' : ''; }}
                                    pagination={this.props.paginationCheckIn}
                                    loading={this.props.loadingCheckInList}
                                    onChange={this.handleTableChange}
                                    scroll={{x: '150%'}}
                                    rowKey={(record => record.AgreementID)}
                                />
                            </Box>
                        </LayoutWrapper>
                    :
                            <Table
                                columns={columns}
                                dataSource={this.props.CheckInList}
                                rowClassName={(record) => { return record.Storno ? ' stornorow ' : ''; }}
                                pagination={this.props.paginationCheckIn}
                                loading={this.props.loadingCheckInList}
                                onChange={this.handleTableChange}
                                scroll={{x: '150%'}}
                                rowKey={(record => record.AgreementID)}
                            />
                    }






            </React.Fragment>
        );
    }
}


function mapStateToProps(state) {
    const { CheckInReducer } = state;
    const { CheckInList, loadingCheckInList, paginationCheckIn, infosTableCheckIn, search, AgreementFormSaving, signAgreementSaving, signAgreementLustraSaving } = CheckInReducer;
    return {
        CheckInList,
        loadingCheckInList,
        paginationCheckIn,
        infosTableCheckIn,
        search,
        AgreementFormSaving,
        signAgreementSaving,
        signAgreementLustraSaving
    };
}


function mapDispatchToProps(dispatch) {
    return{
        actions: bindActionCreators(CheckInActions, dispatch)
    };
}



export default connect(mapStateToProps, mapDispatchToProps)(CheckIn);
