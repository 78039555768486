import React, {Component} from "react";
import {Input, Button, Select, Modal, DatePicker, Checkbox, Row, Col, Upload} from 'antd';
import { Form } from '@ant-design/compatible';
import {bindActionCreators} from "redux";
import * as RoomPlanActions from "../../redux/RoomPlan/actions";
import connect from "react-redux/es/connect/connect";
import moment from 'moment';
import {DeleteOutlined, InboxOutlined, MinusCircleOutlined, PlusOutlined, UploadOutlined} from "@ant-design/icons";
import HouseImage from './HouseImage.svg';
import RoomImage from './RoomImage.svg';

const FormItem = Form.Item;
const Option = Select.Option;

let BASE_URL = process.env.REACT_APP_API_URL;
let API_VERSION = process.env.REACT_APP_API_VERSION;

const formItemLayout = {
    labelCol: {
        xs: { span: 24 },
        sm: { span: 6 },
    },
    wrapperCol: {
        xs: { span: 24 },
        sm: { span: 18 },
    },
};

const formItemLayoutWithOutLabel = {
    wrapperCol: {
        xs: { span: 24, offset: 0 },
        sm: { span: 18, offset: 6 },
    },
};


const formItemLayoutObjects = {
    labelCol: {
        xs: { span: 24 },
    },
    wrapperCol: {
        xs: { span: 24 },
    },
    labelAlign: "left"
};



let uuid = 0;


class Objects extends Component {


    componentWillMount() {
        this.props.actions.loadHouses();
    }

    normSingleFile = (e) => {
        if (Array.isArray(e)) {
            return e;
        }
        return e && e.fileList.slice(-1);
    };



    handleSubmit = (e) => {
        e.preventDefault();
        this.props.form.validateFieldsAndScroll((err, values) => {
            if (!err) {
                this.props.actions.saveObject(values, this.props.actObjectID);
            }
        });
    };

    handleCancel = () => {
        this.props.actions.showObject(false);
    };



    componentWillReceiveProps(nextProps) {

        if(this.props.ObjectVisible && !nextProps.ObjectVisible) {
            this.props.actions.clearObject();
            this.props.form.resetFields();
        } else if(!this.props.ObjectVisible && nextProps.ObjectVisible) {
            uuid = 0;
            if(nextProps.actObjectID) {
                //alert("loading reservation");
                this.props.actions.loadObject(nextProps.actObjectID);
            }



        }

        if(nextProps.ObjectVisible) {
            //TO Set House and Room correctly - e.g. Price & Rooms for correct House
            if(this.props.Object.house !== nextProps.Object.house) {
                this.props.actions.changeHouse(nextProps.Object.house);
            }
           /*if(this.props.Object.room !== nextProps.Object.room) {
                this.props.actions.changeRoom(nextProps.Object.room);
            }*/
        }


        /*if(this.props.workflowList.length > uuid) {
            uuid = this.props.workflowList.length;
        }*/
    }


    removeObject = (k) => {
        const { form } = this.props;
        const keys = form.getFieldValue('keys');
        form.setFieldsValue({
            keys: keys.filter(key => key !== k),
        });
    };

    addObject = () => {
        const { form } = this.props;
        const keys = form.getFieldValue('keys');
        const nextKeys = keys.concat(uuid);
        uuid++;

        form.setFieldsValue({
            keys: nextKeys,
        });
    };



    render() {
        const { getFieldDecorator, getFieldValue } = this.props.form;

        //getFieldDecorator('keys', { initialValue: this.props.workflowList.map((c, i) => {return i;}) });

        //getFieldDecorator('keys', { initialValue: this.props.workflowList.map((c, i) => {return i;}) });
        getFieldDecorator('keys', { initialValue: this.props.Object.objects ? this.props.Object.objects.map((c, i) => {return i;} ) : []} );
        const keys = this.props.form.getFieldValue('keys');

        if(this.props.Object.objects && uuid < this.props.Object.objects.length) { uuid = this.props.Object.objects.length; }

        let formItems = keys.map((k, index) => {

            return (
                <Row gutter={10}>
                    <Col span={6}>
                    </Col>
                    <Col span={13}>
                        <FormItem
                            {...formItemLayoutObjects}
                            label={'Titel'}
                            required={false}
                            key={k}
                        >
                            {getFieldDecorator(`objects[${k}].Title`, {
                                initialValue: this.props.Object.objects ? this.props.Object.objects[k] ? this.props.Object.objects[k].Title : "" : "",
                                rules: [{
                                    required: true,
                                    message: "Bitte geben Sie einen Titel ein, oder entfernen Sie dieses Feld",
                                }],
                            })(
                                <Input placeholder="Titel eingeben" style={{ width: '100%', marginRight: 8 }} />
                            )}
                        </FormItem>
                    </Col>

                    <Col span={4}>
                        <MinusCircleOutlined
                            className="dynamic-delete-button"
                            style={{marginTop: '51px'}}
                            onClick={() => this.removeObject(k)}
                        />
                    </Col>

                    <Col span={6}>&nbsp;</Col>
                    <Col span={13}>
                        <FormItem
                            {...formItemLayoutObjects}
                            label={'Beschreibung (optional)'}
                            required={false}
                            key={k+"description"}
                        >
                            {getFieldDecorator(`objects[${k}].Description`, {
                                initialValue: this.props.Object.objects ? this.props.Object.objects[k] ? this.props.Object.objects[k].Description : "" : "",
                                rules: [{
                                    required: false,
                                }],
                            })(
                                <Input.TextArea style={{ width: '100%' }} />
                            )}
                        </FormItem>
                    </Col>
                    <Col span={4}>&nbsp;</Col>

                    <Col span={6}>&nbsp;</Col>
                    <Col span={13} >
                        <FormItem
                            {...formItemLayoutObjects}
                            label={'Dateianhang'}
                            required={false}
                            key={k}
                        >
                            {getFieldDecorator(`objects[${k}].file`, {
                                initialValue: this.props.Object.objects ? this.props.Object.objects[k] ? this.props.Object.objects[k].file : [] : [],
                                valuePropName: 'fileList',
                                getValueFromEvent: this.normSingleFile,
                            })(
                                <Upload style={{width: '100%'}} name="files" action={BASE_URL+'api/'+API_VERSION+"/roomplan/object/"+(this.props.actObjectID)+"/Upload"} headers={{authorization: `Bearer ${localStorage.getItem('id_token') || null}`}} multiple={false}>
                                    <Button block style={{width: '649px'}}  icon={<UploadOutlined />}>Datei hochladen</Button>
                                </Upload>
                            )}
                        </FormItem>
                    </Col>
                    <Col span={4}>&nbsp;</Col>
                </Row>
            );
        });

        return (
                <Modal
                    title="Objekte"
                    open={this.props.ObjectVisible}
                    onOk={this.handleSubmit}
                    onCancel={this.handleCancel}
                    width={"80%"}
                    footer={[
                        <Button key="back" onClick={this.handleCancel}>Abbrechen</Button>,
                        <Button key="submit" type="primary" onClick={this.handleSubmit}>Speichern</Button>
                    ]}
                >
                    <Row>
                        <Col span={24}>
                            <img src={!!this.props.actObjectIsRoom ? RoomImage : HouseImage} width={"100%"} />
                        </Col>
                        <Col span={24}>
                            &nbsp;
                        </Col>
                        <Col span={24}>
                            <form onSubmit={this.handleSubmit} style={{width: '100%'}}>
                                <FormItem
                                    {...formItemLayout}
                                    label={"Haus"}
                                    key={'house'}
                                >
                                    {getFieldDecorator('house', {
                                        initialValue: this.props.Object.house,
                                        rules: [{
                                            required: true,
                                            whitespace: true,
                                            message: "Bitte wählen Sie ein Haus aus."
                                        }],
                                    })(
                                        <Select
                                            disabled={false}
                                            showSearch
                                            style={{ width: 649 }}
                                            placeholder="Wählen Sie ein Haus"
                                            optionFilterProp="children"
                                            onChange={(val) => this.props.actions.changeHouse(val)}
                                            filterOption={(input, option) => option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
                                        >
                                            {this.props.Houses.map(house => <Option key={house.name} value={house.name}>{house.name}</Option>)}
                                        </Select>
                                    )}
                                </FormItem>

                                {/*!!this.props.Object.Room ?
                                    <FormItem
                                        {...formItemLayout}
                                        label={"Zimmer"}
                                        required={true}
                                        key={'room'}
                                    >
                                        {getFieldDecorator('room', {
                                            initialValue: this.props.Object.room,
                                            rules: [{
                                                required: true,
                                                whitespace: true,
                                                message: "Bitte wählen Sie ein Zimmer aus."
                                            }],
                                        })(
                                            <Select
                                                disabled={true}
                                                showSearch
                                                style={{ width: 400 }}
                                                placeholder="Wählen Sie ein Zimmer"
                                                optionFilterProp="children"
                                                onChange={(val) => {this.props.actions.changeRoom(val); this.props.actions.loadReservedDays(this.props.House, val); }}
                                                filterOption={(input, option) => option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
                                            >
                                                {this.props.Rooms.map(room => <Option key={room.name} value={room.name}>{room.shortName}</Option>)}
                                            </Select>
                                        )}
                                    </FormItem>
                                    : null*/}


                                <FormItem {...formItemLayout} label={"Objekte"}>
                                </FormItem>
                                {formItems}
                                <FormItem {...formItemLayoutWithOutLabel}>
                                    <Button type="dashed" onClick={this.addObject} style={{ width: '77%' }}>
                                        <PlusOutlined />Objekt Hinzufügen
                                    </Button>
                                </FormItem>
                            </form>
                        </Col>
                    </Row>
                </Modal>
        );
    }
}



function mapStateToProps(state) {
    const { RoomPlanReducer, Auth } = state;
    const { ObjectVisible, Houses, Rooms, Room, actObjectID, actObjectIsRoom, Object, House } = RoomPlanReducer;
    const { GroupID } = Auth;

    return {
        Houses,
        House,
        Rooms,
        Room,
        ObjectVisible,
        actObjectID,
        Object,
        GroupID,
        actObjectIsRoom
    };
}


function mapDispatchToProps(dispatch) {
    return{
        actions: bindActionCreators(RoomPlanActions, dispatch),
    };
}

const FormObjects = Form.create()(Objects);

export default connect(mapStateToProps, mapDispatchToProps)(FormObjects);
