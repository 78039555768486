import {CALL_API} from "../../helpers/middleware";
var FileSaver = require('file-saver');

const MaschinenActions = {

    LOAD_MASCHINEN_LIST_REQUEST : 'LOAD_MASCHINEN_LIST_REQUEST',
    LOAD_MASCHINEN_LIST_SUCCESS : 'LOAD_MASCHINEN_LIST_SUCCESS',
    LOAD_MASCHINEN_LIST_ERROR : 'LOAD_MASCHINEN_LIST_ERROR',
    SET_MASCHINEN_TABLE_PAGE: 'SET_MASCHINEN_TABLE_PAGE',
    SET_MASCHINEN_TABLE_INFO: 'SET_MASCHINEN_TABLE_INFO',
    SET_SEARCH_TEXT: 'SET_SEARCH_TEXT',

    SHOW_MASCHINEN_MODAL: 'SHOW_MASCHINEN_MODAL',
    SAVE_MASCHINEN_REQUEST: 'SAVE_MASCHINEN_REQUEST',
    SAVE_MASCHINEN_SUCCESS: 'SAVE_MASCHINEN_SUCCESS',
    SAVE_MASCHINEN_ERROR: 'SAVE_MASCHINEN_ERROR',

    LOAD_MASCHINEN_DETAIL_REQUEST: 'LOAD_MASCHINEN_DETAIL_REQUEST',
    LOAD_MASCHINEN_DETAIL_SUCCESS: 'LOAD_MASCHINEN_DETAIL_SUCCESS',
    LOAD_MASCHINEN_DETAIL_ERROR: 'LOAD_MASCHINEN_DETAIL_ERROR',

    DELETE_MASCHINEN_REQUEST: 'DELETE_MASCHINEN_REQUEST',
    DELETE_MASCHINEN_SUCCESS: 'DELETE_MASCHINEN_SUCCESS',
    DELETE_MASCHINEN_ERROR: 'DELETE_MASCHINEN_ERROR',
};

export default MaschinenActions;


export function LoadMaschinenList(params = {}) {
    return {
        [CALL_API]: {
            reqType: 'POST',
            endpoint: 'machine',
            postData: {
                results: 10,
                ...params,
            },
            types: [MaschinenActions.LOAD_MASCHINEN_LIST_REQUEST, MaschinenActions.LOAD_MASCHINEN_LIST_SUCCESS, MaschinenActions.LOAD_MASCHINEN_LIST_ERROR]
        }
    };
}

export function saveMaschinenDetail(values, MachineID) {
    let url = "machine";
    let type = "POST";
    if(MachineID) {
        url += "/"+MachineID;
        type = "PUT";
    } else {
        url += "/new";
    }

    return {
        [CALL_API]: {
            reqType: type,
            endpoint: url,
            postData: values,
            types: [MaschinenActions.SAVE_MASCHINEN_REQUEST, MaschinenActions.SAVE_MASCHINEN_SUCCESS, MaschinenActions.SAVE_MASCHINEN_ERROR]
        }
    };
}

export function LoadMaschinenDetail(MachineID) {
    return {
        [CALL_API]: {
            reqType: 'GET',
            endpoint: 'machine/'+MachineID,
            types: [MaschinenActions.LOAD_MASCHINEN_DETAIL_REQUEST, MaschinenActions.LOAD_MASCHINEN_DETAIL_SUCCESS, MaschinenActions.LOAD_MASCHINEN_DETAIL_ERROR]
        }
    };
}

export function deleteMaschine(MachineID) {
    return {
        [CALL_API]: {
            reqType: 'DELETE',
            endpoint: 'machine/'+MachineID,
            types: [MaschinenActions.DELETE_MASCHINEN_REQUEST, MaschinenActions.DELETE_MASCHINEN_SUCCESS, MaschinenActions.DELETE_MASCHINEN_ERROR]
        }
    };
}


export function showMaschinenModal(show, MachineID) {
    return {
        type: MaschinenActions.SHOW_MASCHINEN_MODAL,
        show,
        MachineID
    };
}

export function setMaschinenTablePage(pager) {
    return {
        type: MaschinenActions.SET_MASCHINEN_TABLE_PAGE,
        pager
    };
};

export function setMaschinenTableInfo(infos) {
    return {
        type: MaschinenActions.SET_MASCHINEN_TABLE_INFO,
        infos
    };
};

export function setSearchText(search) {
    return {
        type: MaschinenActions.SET_SEARCH_TEXT,
        search
    };
};




