import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Layout } from 'antd';
import { Debounce } from 'react-throttle';
import WindowResizeListener from 'react-window-size-listener';
import { ThemeProvider } from 'styled-components';
import * as appActions from '../../redux/app/actions';
import Sidebar from '../Sidebar/Sidebar';
import Topbar from '../Topbar/Topbar';
import AppRouter from './AppRouter';
import theme from '../../config/themes';
import AppHolder from './commonStyle';
import './global.css';
import {bindActionCreators} from "redux";

const { Content, Footer } = Layout;




export class App extends Component {

    onTouchEnd = () => {
            this.props.appActions.toggleOpenDraweronOpen();
    };


  render() {
    const { url } = this.props.match;
    const { isAdmin, GroupID } = this.props;

    return (

    this.props.serverprint ? <AppRouter url={url} isAdmin={isAdmin} GroupID={GroupID} /> :

      <ThemeProvider theme={theme}>
        <AppHolder>
          <Layout style={{ height: '100vh' }}>
            <Debounce time="1000" handler="onResize">
              <WindowResizeListener
                onResize={windowSize =>
                  this.props.appActions.toggleAll(
                    windowSize.windowWidth,
                    windowSize.windowHeight
                  )}
              />
            </Debounce>
            <Topbar url={url} />
            <Layout style={{ flexDirection: 'row', overflowX: 'hidden' }}>
              <Sidebar url={url} />
              <Layout
                className="isoContentMainLayout"
                style={{
                  height: '100vh'
                }}
              >
                <Content
                    onTouchStart={this.onTouchEnd}
                  className="isomorphicContent"
                  style={{
                    padding: '70px 0 0',
                    flexShrink: '0',
                    background: '#f1f3f6'
                  }}
                >
                  <AppRouter url={url} isAdmin={isAdmin} GroupID={GroupID} />
                </Content>
                <Footer
                  style={{
                    background: '#ffffff',
                    textAlign: 'center',
                    borderTop: '1px solid #ededed'
                  }}
                >
                </Footer>
              </Layout>
            </Layout>
          </Layout>
        </AppHolder>
      </ThemeProvider>
    );
  }
}

function mapStateToProps(state) {
    const { Auth, App } = state;
    const { isAdmin, GroupID, serverprint } = Auth;

    return {
        isAdmin,
        GroupID,
        serverprint,
    }
}


function mapDispatchToProps(dispatch) {
    return{
        appActions: bindActionCreators(appActions, dispatch)
    };
}


export default connect(mapStateToProps, mapDispatchToProps)(App);
