import React, {Component} from "react";
import {connect} from "react-redux";
import {bindActionCreators} from "redux";
import * as TodoRezeptionActions from "../../redux/TodoRezeption/actions";
import {Input, Button, Table} from 'antd';
import Box from '../../components/utility/box';
import TodoDetailModal from './TodoDetailModal';
import './list.style.css';
import {CheckCircleOutlined, CheckOutlined, CloseCircleOutlined, PlusOutlined} from "@ant-design/icons";
import LayoutWrapper from "../../components/utility/layoutWrapper";


const Search = Input.Search;
const itemStyle = {margin: '0px'};





class TodoRezeption extends Component {



    componentWillMount() {
        this.props.actions.LoadTodoList({...this.props.infosTableTodo, search: this.props.search});
        this.state = {search: this.props.search};
    }


    handleTableChange = (pagination, filters, sorter) => {
        const pager = { ...this.props.paginationTodo };
        pager.current = pagination.current;

        this.props.actions.setTodoTablePage(pager);

        this.props.actions.setTodoTableInfo({
            results: pagination.pageSize,
            page: pagination.current,
            sortField: sorter.field,
            sortOrder: sorter.order,
            ...filters,
        });

        this.props.actions.LoadTodoList({
            results: pagination.pageSize,
            page: pagination.current,
            sortField: sorter.field,
            sortOrder: sorter.order,
            ...filters,
            search: this.props.search
        });


    };



    componentWillUpdate(nextProps) {
       if(this.props.search !== nextProps.search) {
            this.props.actions.LoadTodoList({...nextProps.infosTableTodo, search: nextProps.search});
        }

        if(this.props.TodoModalSaving && !nextProps.TodoModalSaving ||
            this.props.deletingTodo && !nextProps.deletingTodo) {
            this.props.actions.LoadTodoList({...this.props.infosTableTodo, search: this.props.search});
        }
    }

    onChangeSearch = (e) => {
        this.setState({ search: e.target.value });
    };






    render() {

        const columns = [
            {
                title: 'Aufgabe',
                dataIndex: 'Todo',
                key: 'Todo',
                sorter: true,
                sortOrder: this.props.infosTableTodo.sortField === 'Todo' && this.props.infosTableTodo.sortOrder
            },
            {
                title: 'Erledigte Aufgaben',
                dataIndex: 'JobsDone',
                key: 'Done',
                render: (_, record) => (record.JobsDone + '/' + record.JobsCount),
                sorter: true,
                sortOrder: this.props.infosTableTodo.sortField === 'Done' && this.props.infosTableTodo.sortOrder
            },
            {
                title: 'Vollständig erledigt',
                dataIndex: 'Done',
                key: 'Done',
                render: (text) => (text ? <CheckCircleOutlined style={{color: "green"}} /> : <CloseCircleOutlined style={{color: "red"}} />),
                sorter: true,
                sortOrder: this.props.infosTableTodo.sortField === 'Done' && this.props.infosTableTodo.sortOrder
            },
            {
                title: 'Geprüft',
                dataIndex: 'Checked',
                key: 'Checked',
                render: (text, record) => record.Done ? (text ? <CheckCircleOutlined style={{color: "green"}} /> : <CloseCircleOutlined style={{color: "red"}} />) : "-",
                sorter: true,
                sortOrder: this.props.infosTableTodo.sortField === 'Checked' && this.props.infosTableTodo.sortOrder
            },
            ];

        const searchClear = this.props.search ? <CloseCircleOutlined onClick={() => {this.props.actions.setSearchText(''); this.setState({ search: '' });}} /> : null;

        return (
            <React.Fragment>
                <TodoDetailModal />
                <LayoutWrapper>
                    <Search onChange={this.onChangeSearch} value={this.state.search} prefix={searchClear} placeholder={"Suchen..."} onSearch={value => this.props.actions.setSearchText(value)} defaultValue={this.props.search} enterButton style={{width: '400px', minHeight: '0px !important'}} />
                    <Button type={"primary"} htmlType={"button"} className={"round"} style={{marginBottom: "10px", marginLeft: "auto"}} onClick={() => this.props.actions.showTodoModal(true, null)} icon={<PlusOutlined/>}>Neue Aufgabe anlegen</Button>
                <Box >


                    <Table
                        columns={columns}
                        dataSource={this.props.TodoList}
                        rowClassName={(record, index) => { return 'tableCursor'}}
                        onRow={(record) => ({
                            onClick: () => this.props.actions.showTodoModal(true, record.TodoID)
                        })}
                        pagination={this.props.paginationTodo}
                        loading={this.props.loadingTodoList}
                        onChange={this.handleTableChange}
                        scroll={{x: '100%'}}
                        rowKey={(record => record.TodoID)}
                    />



                </Box>
                </LayoutWrapper>
            </React.Fragment>
        );
    }
}


function mapStateToProps(state) {
    const { TodoRezeptionReducer } = state;
    const { TodoList, loadingTodoList, paginationTodo, infosTableTodo, search, TodoModalSaving, deletingTodo } = TodoRezeptionReducer;
    return {
        TodoList,
        loadingTodoList,
        paginationTodo,
        infosTableTodo,
        search,
        TodoModalSaving,
        deletingTodo
    };
}


function mapDispatchToProps(dispatch) {
    return{
        actions: bindActionCreators(TodoRezeptionActions, dispatch)
    };
}



export default connect(mapStateToProps, mapDispatchToProps)(TodoRezeption);
