import React, {Component} from "react";
import {connect} from "react-redux";
import {bindActionCreators} from "redux";
import * as RechnungenActions from "../../redux/Rechnungen/actions";
import { Input, Button, DatePicker, Table, Row, Col, Radio, Modal, Tag, Dropdown, Menu } from 'antd';
import Box from '../../components/utility/box';
import LayoutWrapper from '../../components/utility/layoutWrapper';
import {history} from "../../redux/store";
import './list.style.css';
import moment from "moment";
import BillPayedModal from '../../components/BillPayedModal';
import {CheckOutlined, CloseCircleOutlined, CloseOutlined, FileOutlined, PlusOutlined} from "@ant-design/icons";

const Search = Input.Search;
const itemStyle = {margin: '0px'};
const { confirm } = Modal;


class Rechnungen extends Component {



    componentWillMount() {
        if(this.props.isAdmin) {
            this.props.actions.loadRechnungenTable({...this.props.infosTableRechnungen, search: this.props.search});
        }

        this.state = {search: this.props.search};
    }


    handleTableChange = (pagination, filters, sorter) => {
        const pager = { ...this.props.paginationRechnungen };
        pager.current = pagination.current;

        this.props.actions.setRechnungenTablePage(pager);

        this.props.actions.setRechnungenTableInfo({
            results: pagination.pageSize,
            page: pagination.current,
            sortField: sorter.field,
            sortOrder: sorter.order,
            ...filters,
        });

        this.props.actions.loadRechnungenTable({
            results: pagination.pageSize,
            page: pagination.current,
            sortField: sorter.field,
            sortOrder: sorter.order,
            ...filters,
            search: this.props.search
        });


    };



    componentWillUpdate(nextProps) {
       if(this.props.search !== nextProps.search ||
           this.props.savingRechnungenDetail && !nextProps.savingRechnungenDetail ||
           this.props.setBillPayedLoading && !nextProps.setBillPayedLoading
       ) {
           if(this.props.isAdmin) {
               this.props.actions.loadRechnungenTable({...nextProps.infosTableRechnungen, search: nextProps.search});
           }
        }
    }

    onChangeSearch = (e) => {
        this.setState({ search: e.target.value });
    };


    confirmStorno (rnr) {
        confirm({
            title: "Rechnung stornieren",
            content: "Wollen Sie diese Rechnung wirklich stronieren? \n Dieser Vorgang kann nicht Rückgängig gemacht werden!",
            okText: "Stornieren",
            okType: 'danger',
            cancelText: "Abbrechen",
            onOk: () => this.props.actions.setBillStorno(rnr),
            onCancel() {
            },
        });
    };



    render() {

        const columns = [
            {
                title: 'Rechnung Nr.',
                dataIndex: 'rnr',
                key: 'rnr',
                sorter: true,
                sortOrder: this.props.infosTableRechnungen.sortField === 'rnr' && this.props.infosTableRechnungen.sortOrder
            }, {
                title: 'Firmenname',
                dataIndex: 'company',
                key: 'company',
                sorter: true,
                sortOrder: this.props.infosTableRechnungen.sortField === 'company' && this.props.infosTableRechnungen.sortOrder
            }, {
                title: 'Bezahlt',
                dataIndex: 'payed',
                key: 'payed',
                sorter: true,
                render: (text) => {return text ? moment(text).format('DD.MM.YYYY') : ''},
                sortOrder: this.props.infosTableRechnungen.sortField === 'payed' && this.props.infosTableRechnungen.sortOrder
            }, {
                title: 'Bruttobetrag',
                dataIndex: 'brutto',
                key: 'brutto',
                sorter: true,
                render: (text) => {return text ? parseFloat(text).toFixed(2).toString().replace(".",",").replace(/\B(?=(\d{3})+(?!\d))/g, ".")+" €" : ''},
                sortOrder: this.props.infosTableRechnungen.sortField === 'brutto' && this.props.infosTableRechnungen.sortOrder
            }, {
                title: 'Zahlungsziel',
                dataIndex: 'deadline',
                key: 'deadline',
                sorter: true,
                render: (text) => {return text ? moment(text).format('DD.MM.YYYY') : ''},
                sortOrder: this.props.infosTableRechnungen.sortField === 'deadline' && this.props.infosTableRechnungen.sortOrder
            }, {
                title: 'Erstellt',
                dataIndex: 'created',
                key: 'created',
                sorter: true,
                render: (text) => {return text ? moment(text).format('DD.MM.YYYY') : ''},
                sortOrder: this.props.infosTableRechnungen.sortField === 'created' && this.props.infosTableRechnungen.sortOrder
            }, {
                title: 'Tags',
                dataIndex: 'tags',
                key: 'tags',
                sorter: false,
                render: tags => (
                    <span>
                        {tags.map(tag => {

                            return (
                                <Tag color={tag.color} key={tag}>
                                    {tag.text.toUpperCase()}
                                </Tag>
                            );
                        })}
                      </span>
                ),
            }, {
                title: 'Aktion',
                dataIndex: 'rnr',
                key: 'action',
                render: (rnr, record) => {  const menu = (
                    <Menu onClick={(itm) => {if(itm.key === "1") { this.props.actions.showBillPDF(rnr); } else if(itm.key === "2") { this.props.actions.showBillPayedModal(rnr, true) } else if(itm.key === "3") { this.confirmStorno(rnr); } }}>
                        <Menu.Item key="1"><FileOutlined /> Rechnung anzeigen</Menu.Item>
                        <Menu.Item key="2"><CheckOutlined /> Rechnung als bezahlt markieren</Menu.Item>
                        <Menu.Item key="3" disabled={record.storno ? true : false}><CloseOutlined /> Rechnung stornieren</Menu.Item>
                    </Menu>
                );
                    return  <Dropdown.Button onClick={() => {}} overlay={menu}> Aktion </Dropdown.Button>
                },
            }
            ];

        const searchClear = this.props.search ? <CloseCircleOutlined onClick={() => {this.props.actions.setSearchText(''); this.setState({ search: '' });}} /> : null;

        return (
            <React.Fragment>
                <BillPayedModal />
                <LayoutWrapper>
                    {this.props.isAdmin ? <Search onChange={this.onChangeSearch} value={this.state.search} prefix={searchClear} placeholder={"Suchen..."} onSearch={(value) => this.props.actions.setSearchText(value)} defaultValue={this.props.search} enterButton style={{width: '400px', minHeight: '0px !important'}} /> : '' }
                    <Button type={"primary"} htmlType={"button"} className={"round"} style={{marginBottom: "10px", marginLeft: "auto"}} onClick={() => history.push('/app/Rechnungen/neu')} icon={<PlusOutlined/>}>Neue Rechnung erstellen</Button>
                <Box>


                    {this.props.isAdmin ?
                    <Table
                        columns={columns}
                        dataSource={this.props.RechnungenList}
                        rowClassName={(record) => {
                            return record.storno ? ' stornorow ' : '';
                        }}
                        pagination={this.props.paginationRechnungen}
                        loading={this.props.loadingRechnungenList}
                        onChange={this.handleTableChange}
                        scroll={{x: '100%'}}
                        rowKey={(record => record.rnr)}
                    /> : <span>Erstellte Rechnungen sind nur für den Administrator sichtbar.</span>
                    }


                </Box>
                </LayoutWrapper>
            </React.Fragment>
        );
    }
}


function mapStateToProps(state) {
    const { RechnungenReducer, Auth } = state;
    const { loadingRechnungenList, RechnungenList, paginationRechnungen, infosTableRechnungen, search, savingRechnungenDetail, setBillPayedLoading } = RechnungenReducer;
    const { isAdmin } = Auth;
    return {
        RechnungenList,
        loadingRechnungenList,
        paginationRechnungen,
        infosTableRechnungen,
        search,
        savingRechnungenDetail,
        setBillPayedLoading,
        isAdmin
    };
}


function mapDispatchToProps(dispatch) {
    return{
        actions: bindActionCreators(RechnungenActions, dispatch)
    };
}



export default connect(mapStateToProps, mapDispatchToProps)(Rechnungen);
