import React, {Component} from "react";
import {
    Input,
    Button,
    Select,
    Modal,
    DatePicker,
    Checkbox,
    Row,
    Col,
    Divider,
    Avatar,
    Typography,
    Empty,
    Upload
} from 'antd';
import { Form } from '@ant-design/compatible';
import {bindActionCreators} from "redux";
import * as RoomPlanActions from "../../redux/RoomPlan/actions";
import * as PersonsActions from '../../redux/Personen/actions';
import connect from "react-redux/es/connect/connect";
import moment from 'moment';
import {DeleteOutlined, EditOutlined, FileAddOutlined, MinusCircleOutlined, PlusOutlined} from "@ant-design/icons";
import PersonenDetail from "../Personen/detail";
import './reservation.style.css';


let BASE_URL = process.env.REACT_APP_API_URL;
let API_VERSION = process.env.REACT_APP_API_VERSION;

const FormItem = Form.Item;
const Option = Select.Option;
const { TextArea } = Input;
const { RangePicker } = DatePicker;

const formItemLayout = {
    labelCol: {
        xs: { span: 24 },
        sm: { span: 6 },
    },
    wrapperCol: {
        xs: { span: 24 },
        sm: { span: 18 },
    },
};

const formItemLayoutWithOutLabel = {
    wrapperCol: {
        xs: { span: 24, offset: 0 },
        sm: { span: 18, offset: 6 },
    },
};

const ulstyle = {
    listStyle: 'none',
    padding: '0px'
};




let uuid = 0;

const CleaningNote = ({date, userName, file, onFilePreview, children}) => {
    const userNameArray = (userName || "").split(" ") || [];
    return (
        <div className="ReservationNote">
            <div className="ReservationNoteHeader">
                <div className="ReservationNoteAvatar"><Avatar size="small" style={{ backgroundColor: "#f52929", verticalAlign: "middle" }}>{userNameArray.map((name) => name.substring(0, 1).toUpperCase())}</Avatar></div>
                <div className="ReservationNoteTitle"><Typography.Text><b>{userName}</b></Typography.Text></div>
                <div>
                    <Typography.Text type="secondary">{moment(date).format("DD.MM.YYYY HH:mm")} Uhr</Typography.Text>
                </div>
            </div>
            <div className="ReservationNoteBody">
                {file ? <img height={150} src={file} className="ReservationNoteImage" onClick={() => onFilePreview(file)} /> : null}
                <Typography.Text>{children}</Typography.Text>
            </div>
        </div>
    );
};

const CleaningNoteImagePreview = ({file, onClose, open}) => {
    return (
        <Modal
            title="Bildvorschau"
            open={open}
            onCancel={onClose}
            okText={false}
            footer={null}
            style={{ maxWidth: "80vw" }}
            width={"fit-content"}
        >
            <img src={file} style={{objectFit: "contain", height: "70vh", maxHeight: "100%", maxWidth: "100%"}}/>
        </Modal>
    );
}


class Reservation extends Component {

    Arrive = null;
    Depart = null;

    state = {disabled34: false, hidden4: false, cleaningNoteText: "", cleaningNoteUpload: []};

    componentWillMount() {
        this.props.PersonsActions.loadPersons();
        this.props.actions.loadHouses();

        this.setState({actPersonFeedback: "", actPersonHelp: "", actPersonNote: "", cleaningNoteText: "", cleaningNoteUpload: []});

        if([3, 4].includes(this.props.GroupID)) {
            this.setState({disabled34: true});
            if(this.props.GroupID === 4) {
                this.setState({hidden4: true});
            }
        }
    }


    reloadPersons = () => {
        this.props.PersonsActions.loadPersons().then(() => {
            const currentPerson = this.props.form.getFieldValue('person');
            currentPerson && this.checkPerson(null, currentPerson, () => {});
        });
    };

    handleSubmit = (e) => {
        e.preventDefault();
        this.props.form.validateFieldsAndScroll((err, values) => {
            if (!err) {
                this.props.actions.saveReservation(values, this.props.actReservationID);
            }
        });
    };

    handleCancel = () => {
        this.props.actions.showReservation(false);
    };

    checkPerson = (rule, value, callback) => {
        this.setState({actPersonFeedback: "", actPersonHelp: "", actPersonNote: ""});
        if(value) {
            let actPerson = this.props.Persons.filter(p => { return p.id === value});

            if(actPerson.length === 1) {
                let newState = {};
                if(actPerson[0].note) {
                    newState = { actPersonNote: actPerson[0].note };
                }
                if(actPerson[0].message) {
                    newState = {...newState, actPersonFeedback: 'warning', actPersonHelp: actPerson[0].message };
                }
                this.setState(newState);
            }
            callback();
        } else {
            try {
                this.setState({actPersonFeedback: 'error', actPersonHelp: 'Bitte wählen Sie eine Person aus.'});
                throw new Error("Bitte wählen Sie eine Person aus.");
            } catch (err) {
                callback(err);
            }
        }
    };


    editCurrentPerson = () => {
        const currentPerson = this.props.form.getFieldValue('person');
        currentPerson && this.props.PersonsActions.showPersonsDetail(true, currentPerson);
    };



    sendCleaningNote = () => {
        const note = this.state.cleaningNoteText;
        const file = this.state.cleaningNoteUpload?.[0];
        if(note || file) {
            this.props.actions.saveReservationNote({note, file}, this.props.actReservationID).then((resp) => {
                if (resp?.type === "SAVE_RESERVATION_CLEANING_NOTE_SUCCESS") {
                    this.setState({cleaningNoteText: "", cleaningNoteUpload: []});
                }
            });
        }
    }


    componentWillReceiveProps(nextProps) {

        if(this.props.ReservationVisible && !nextProps.ReservationVisible) {
            this.props.actions.clearReservation();
            this.props.form.resetFields();
            this.setState({actPersonFeedback: "", actPersonHelp: "", actPersonNote: "", cleaningNoteText: "", cleaningNoteUpload: []});
        } else if(!this.props.ReservationVisible && nextProps.ReservationVisible) {
            this.Arrive = null;
            this.Depart = null;
            uuid = 0;
            if(nextProps.actReservationID > 0) {
                //alert("loading reservation");
                this.props.actions.loadReservation(nextProps.actReservationID).then(loaded => {
                    this.checkPerson(null, loaded.response.data.Person, () => {});
                });
            } else {
                //alert("Setting date... "+nextProps.actReservationDate);

                if(nextProps.actReservationDate) {
                    this.Arrive = moment(nextProps.actReservationDate, 'DD.MM.YYYY');
                    this.Depart = moment(nextProps.actReservationDate, 'DD.MM.YYYY');
                }

            }



        }

        if(nextProps.ReservationVisible) {
            //TO Set House and Room correctly - e.g. Price & Rooms for correct House
            if(this.props.Reservation.House !== nextProps.Reservation.House) {
                this.props.actions.changeHouse(nextProps.Reservation.House);
            }
            if(this.props.Reservation.Room !== nextProps.Reservation.Room) {
                this.props.actions.changeRoom(nextProps.Reservation.Room);
            }
        }


        /*if(this.props.workflowList.length > uuid) {
            uuid = this.props.workflowList.length;
        }*/
    }


    removeToDo = (k) => {
        const { form } = this.props;
        const keys = form.getFieldValue('keys');
        form.setFieldsValue({
            keys: keys.filter(key => key !== k),
        });
    };

    addToDo = () => {
        const { form } = this.props;
        const keys = form.getFieldValue('keys');
        const nextKeys = keys.concat(uuid);
        uuid++;

        form.setFieldsValue({
            keys: nextKeys,
        });
    };

    setUnknownDeparture = (isUnknown) => {
        this.props.form.setFieldsValue({abreise: isUnknown ? moment(this.props.form.getFieldValue('anreise') || undefined).add(1, "year") : moment() })
    }


    disabledDate = (current) => {
        //return current && current.date() === moment('01/01/2019').date();
        //return current && this.props.reservedDays.filter(rd => {return moment(rd).format('DD-MM-YYYY') === current.format('DD-MM-YYYY')}).length > 0;
        let dates = this.props.reservedDays.filter(rd => {return moment(rd).isSame(current, 'day')});
        return current && dates.length > 0;
    };


    render() {
        const { getFieldDecorator, getFieldValue } = this.props.form;
        //getFieldDecorator('keys', { initialValue: this.props.workflowList.map((c, i) => {return i;}) });
        getFieldDecorator('keys', { initialValue: this.props.Reservation.todos ? this.props.Reservation.todos.map((c, i) => {return i;} ) : []} );
        const keys = this.props.form.getFieldValue('keys');

        if(this.props.Reservation.todos && uuid < this.props.Reservation.todos.length) { uuid = this.props.Reservation.todos.length; }

        let formItems = keys.map((k, index) => {
            return (
                <Row gutter={0}>
                    <Col span={6} style={{textAlign: 'center'}}>
                        <FormItem
                            {...formItemLayoutWithOutLabel}
                            label={''}
                            required={false}
                            key={k+'C'}
                        >
                            {getFieldDecorator(`todosC[${k}]`, {
                                validateTrigger: ['onChange', 'onBlur'],
                                initialValue: this.props.Reservation?.todos ? this.props.Reservation?.todos?.[k] ? this.props.Reservation?.todos?.[k]?.Done : false : false,
                                valuePropName: 'checked',
                            })(
                                <Checkbox />
                            )}
                        </FormItem>

                    </Col>

                    <Col span={18} style={{textAlign: 'left'}}>

                        <FormItem
                                                        label={''}
                            required={false}
                            key={k}
                        >
                            {getFieldDecorator(`todos[${k}]`, {
                                validateTrigger: ['onChange', 'onBlur'],
                                initialValue: this.props.Reservation?.todos ? this.props.Reservation?.todos?.[k] ? this.props.Reservation?.todos?.[k]?.Job : "" : "",
                                rules: [{
                                    required: true,
                                    whitespace: true,
                                    message: "Bitte geben Sie eine Aufgabe ein, oder entfernen Sie dieses Feld",
                                }],
                            })(
                                <Input placeholder="Aufgabe eingeben" style={{ width: '77%', marginRight: 8 }} />
                            )}
                            { (this.props.form.getFieldValue(`todos[${k}]`).length === 0 || this.props.form.getFieldValue(`todosC[${k}]`)) ?
                                <MinusCircleOutlined
                                    className="dynamic-delete-button"
                                    onClick={() => this.removeToDo(k)}
                                /> : ''
                            }
                            {getFieldDecorator(`todosU[${k}]`, {
                                initialValue: this.props.Reservation?.todos && this.props.Reservation?.todos?.[k] ? this.props.Reservation?.todos?.[k]?.DoneFromID : undefined,
                            })}
                            {this.props.Reservation?.todos?.[k]?.Done ? <><br/><Typography.Text type="secondary">Erledigt
                                von: {this.props.Reservation?.todos?.[k]?.DoneFrom}</Typography.Text></> : null}
                        </FormItem>
                    </Col>
                </Row>
            );
        });

        return (
            <React.Fragment>
                <PersonenDetail afterClose={this.reloadPersons} hideCheckins={true} />
                <CleaningNoteImagePreview file={this.state.CleaningNoteFilePreview} open={!!this.state.CleaningNoteFilePreview} onClose={() => this.setState({CleaningNoteFilePreview: undefined})} />
                <Modal
                    title="Reservierung"
                    visible={this.props.ReservationVisible}
                    onOk={this.handleSubmit}
                    onCancel={this.handleCancel}
                    width={"850px"}
                    footer={[
                        (this.props.actReservationID > 0 && !this.state.disabled34) ? <Button type={"danger"} htmlType={"button"} style={{marginLeft: "10px", float: 'left'}} className={"round"} onClick={() => {if(window.confirm('Wollen Sie diese Reservierung wirklich löschen?')) { this.props.actions.deleteReservation(this.props.actReservationID); }}} icon={<DeleteOutlined />}>Reservierung löschen</Button> : '',
                        <Button key="back" onClick={this.handleCancel}>Abbrechen</Button>,
                        <Button key="submit" type="primary" onClick={this.handleSubmit}>Speichern</Button>
                    ]}
                >

                    <form onSubmit={this.handleSubmit} style={{width: '100%'}}>

                    {!this.state.hidden4 &&
                        <React.Fragment>
                            <FormItem
                                {...formItemLayout}
                                label={"Person"}
                                required={true}
                                key={'person'}
                                validateStatus={this.state.actPersonFeedback}
                                help={this.state.actPersonHelp}
                                extra={<b style={{color: "red"}}>{this.state.actPersonNote}</b>}
                            >
                                {getFieldDecorator('person', {
                                    initialValue: this.props.Reservation.Person,
                                    rules: [{
                                        validator: this.checkPerson
                                    }],
                                })(
                                    <Select
                                        showSearch
                                        style={{ width: 350 }}
                                        placeholder="Wählen Sie eine Person"
                                        optionFilterProp="children"
                                        disabled={this.state.disabled34}
                                        filterOption={(input, option) => option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
                                    >
                                        {this.props.Persons.map(person => <Option key={person.id} value={person.id}>{person.displayname}</Option>)}
                                    </Select>
                                )}
                                {!this.state.disabled34 && <Button type={"default"} htmlType={"button"} style={{marginLeft: "10px"}} className={"round"} onClick={this.editCurrentPerson} icon={<EditOutlined />} />}
                                {!this.state.disabled34 && <Button type={"default"} htmlType={"button"} style={{marginLeft: "10px"}} className={"round"} onClick={() => {window.open('https://flour.cloud')}} icon={<PlusOutlined />}>Hinzufügen</Button>}
                            </FormItem>
                                <FormItem
                                    {...formItemLayout}
                                    label={"Haus"}
                                    required={true}
                                    key={'house'}
                                >
                                    {getFieldDecorator('house', {
                                        initialValue: this.props.Reservation.House,
                                        rules: [{
                                            required: true,
                                            whitespace: true,
                                            message: "Bitte wählen Sie ein Haus aus."
                                        }],
                                    })(
                                        <Select
                                            showSearch
                                            style={{ width: 400 }}
                                            placeholder="Wählen Sie ein Haus"
                                            optionFilterProp="children"
                                            onChange={(val) => this.props.actions.changeHouse(val)}
                                            disabled={this.state.disabled34}
                                            filterOption={(input, option) => option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
                                        >
                                            {this.props.Houses.map(house => <Option key={house.name} value={house.name}>{house.name}</Option>)}
                                        </Select>
                                    )}
                                </FormItem>
                                <FormItem
                                    {...formItemLayout}
                                    label={"Zimmer"}
                                    required={true}
                                    key={'room'}
                                >
                                    {getFieldDecorator('room', {
                                        initialValue: this.props.Reservation.Room,
                                        rules: [{
                                            required: true,
                                            whitespace: true,
                                            message: "Bitte wählen Sie ein Zimmer aus."
                                        }],
                                    })(
                                        <Select
                                            showSearch
                                            style={{ width: 400 }}
                                            placeholder="Wählen Sie ein Zimmer"
                                            optionFilterProp="children"
                                            onChange={(val) => {this.props.actions.changeRoom(val); this.props.actions.loadReservedDays(this.props.House, val); }}
                                            disabled={this.state.disabled34}
                                            filterOption={(input, option) => option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
                                        >
                                            {this.props.Rooms.map(room => <Option key={room.name} value={room.name}>{room.shortName}</Option>)}
                                        </Select>
                                    )}
                                </FormItem>

                                <FormItem
                                    {...formItemLayout}
                                    label={"Miete / Tag"}
                                    required={false}
                                    key={'miete'}
                                >

                                    <a>{this.props.Room.price} &euro;</a>

                                </FormItem>

                                {/*<FormItem
                                    {...formItemLayout}
                                    label={"Anreise"}
                                    required={true}
                                    key={'anreise'}
                                >
                                    {getFieldDecorator('anreise', {
                                        initialValue:  this.props.Reservation.Arrive ? moment(this.props.Reservation.Arrive): this.Arrive,
                                        rules: [{
                                            required: true,
                                            message: "Bitte wählen Sie ein Anreisedatum aus."
                                        }],
                                    })(
                                        <DatePicker
                                            style={{ width: 400 }}
                                            placeholder="Wählen Sie ein Anreisedatum"
                                            disabledDate={disabledDate}
                                        >
                                        </DatePicker>
                                    )}
                                </FormItem>*/}


                                <FormItem
                                    {...formItemLayout}
                                    label={"Anreise"}
                                    required={true}
                                    key={'Anreise'}
                                >
                                    {getFieldDecorator('anreise', {
                                        initialValue:  (this.props.Reservation.Arrive ? moment(this.props.Reservation.Arrive): this.Arrive),
                                        rules: [{
                                            required: true,
                                            message: "Bitte wählen Sie ein Anreisedatum aus."
                                        }],
                                    })(
                                        <DatePicker
                                            style={{ width: 400 }}
                                            placeholder={"Anreisedatum"}
                                            format={'DD.MM.YYYY'}
                                            disabled={this.state.disabled34}
                                        >
                                        </DatePicker>
                                    )}
                                </FormItem>

                                <FormItem
                                    {...formItemLayout}
                                    label={"Letzter Tag"}
                                    required={true}
                                    key={'Letzter Tag'}
                                >
                                    {getFieldDecorator('abreise', {
                                        initialValue:   (this.props.Reservation.Depart ? moment(this.props.Reservation.Depart): this.Depart),
                                        rules: [{
                                            required: true,
                                            message: "Bitte wählen Sie ein Abreisedatum aus."
                                        }],
                                    })(
                                        <DatePicker
                                            style={{ width: 400 }}
                                            placeholder={"Letzter Tag"}
                                            format={'DD.MM.YYYY'}
                                            disabled={getFieldValue("abreiseUnknown") || this.state.disabled34}
                                        >
                                        </DatePicker>
                                    )}
                                </FormItem>

                            <FormItem
                                {...formItemLayout}
                                colon={false}
                                label={" "}
                                key={'AbreiseUnbekannt'}
                            >
                                {getFieldDecorator('abreiseUnknown', {
                                    validateTrigger: ['onChange', 'onBlur'],
                                    initialValue: this.props.Reservation.DepartUnknown === undefined ? 0 : this.props.Reservation.DepartUnknown,
                                    valuePropName: 'checked',
                                })(
                                    <Checkbox onChange={(e) => this.setUnknownDeparture(e.target.checked)} disabled={this.state.disabled34}>Abreise unbekannt</Checkbox>
                                )}
                            </FormItem>

                        </React.Fragment>
                    }

                        {/* <FormItem
                            {...formItemLayout}
                            label={"Zeitraum"}
                            required={true}
                            key={'Zeitraum'}
                        >
                            {getFieldDecorator('zeitraum', {
                                initialValue:  [(this.props.Reservation.Arrive ? moment(this.props.Reservation.Arrive): this.Arrive), (this.props.Reservation.Depart ? moment(this.props.Reservation.Depart): this.Depart)],
                                rules: [{
                                    required: true,
                                    message: "Bitte wählen Sie einen Zeitraum aus."
                                }],
                            })(
                                <RangePicker
                                    style={{ width: 400 }}
                                    placeholder={["Anreisedatum", "Abreisedatum"]}
                                    disabledDate={this.disabledDate}
                                >
                                </RangePicker>
                            )}
                        </FormItem>
*/}
                        {/*<FormItem
                            {...formItemLayout}
                            label={"Abreise"}
                            required={true}
                            key={'abreise'}
                        >
                            {getFieldDecorator('abreise', {
                                initialValue: this.props.Reservation.Depart ? moment(this.props.Reservation.Depart): this.Depart,
                                rules: [{
                                    required: true,
                                    message: "Bitte wählen Sie ein Abreisedatum aus."
                                }],
                            })(
                                <DatePicker
                                    style={{ width: 400 }}
                                    placeholder="Wählen Sie ein Abreisedatum"

                                >
                                </DatePicker>
                            )}
                        </FormItem>*/}

                        <FormItem
                            {...formItemLayout}
                            label={"Notiz"}
                            required={false}
                            key={'notiz'}
                        >
                            {getFieldDecorator('note', {
                                initialValue: this.props.Reservation.Note,
                                rules: [{
                                    required: false
                                }],
                            })(
                                <TextArea
                                    rows={4}
                                    style={{ width: 400 }}
                                    placeholder="Optionale Notizen"

                                >
                                </TextArea>
                            )}
                        </FormItem>

                        <Divider orientation={"left"}>Reinigung</Divider>

                        <FormItem
                            {...formItemLayout}
                            label={"Zwischenreinigung"}
                            colon={false}
                            key={'Zwischenreinigung'}
                        >
                            {getFieldDecorator('cleaningSchedule', {
                                initialValue: this.props.Reservation.CleaningSchedule === undefined ? null : this.props.Reservation.CleaningSchedule,
                            })(
                                <Select
                                    getPopupContainer={trigger => trigger.parentNode}
                                    showSearch
                                    style={{ width: 400 }}
                                    placeholder="Wählen Sie eine Zwischenreinigung"
                                    optionFilterProp="children"
                                    filterOption={(input, option) => option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
                                >
                                    <Option value={null}>Keine</Option>
                                    <Option value={"weekly"}>Wöchentlich</Option>
                                    <Option value={"2 weeks"}>Alle 2 Wochen</Option>
                                    <Option value={"3 weeks"}>Alle 3 Wochen</Option>
                                    <Option value={"4 weeks"}>Alle 4 Wochen</Option>
                                    <Option value={"5 weeks"}>Alle 5 Wochen</Option>
                                </Select>
                            )}
                        </FormItem>

                        <FormItem
                            {...formItemLayout}
                            label={'Gereinigt'}
                            required={false}
                            key={'Cleaned'}
                        >
                            {getFieldDecorator(`Cleaned`, {
                                validateTrigger: ['onChange', 'onBlur'],
                                initialValue: this.props.Reservation.Cleaned === undefined ? 0 : this.props.Reservation.Cleaned,
                                valuePropName: 'checked',
                            })(
                                <Checkbox />
                            )}
                            {this.props.Reservation?.Cleaned && this.props.Reservation?.CleanedFrom ? <><br/><Typography.Text type="secondary">Gereinigt
                                von: {this.props.Reservation?.CleanedFrom}</Typography.Text></> : null}
                        </FormItem>

                        <FormItem
                            {...formItemLayout}
                            label={'Geprüft'}
                            required={false}
                            key={'Checked'}
                        >
                            {getFieldDecorator(`Checked`, {
                                validateTrigger: ['onChange', 'onBlur'],
                                initialValue: this.props.Reservation.Checked === undefined ? 0 : this.props.Reservation.Checked,
                                valuePropName: 'checked',
                            })(
                                <Checkbox disabled={!this.props.form.getFieldValue('Cleaned')} />
                            )}
                        </FormItem>

                        <FormItem {...formItemLayout} label={"Aufgaben"}>
                        </FormItem>
                        {formItems}
                        <FormItem {...formItemLayoutWithOutLabel}>
                            <Button type="dashed" onClick={this.addToDo} style={{ width: '77%' }}>
                                <PlusOutlined /> Aufgabe Hinzufügen
                            </Button>
                        </FormItem>

                        <div className="ReservationNoteWrapper">
                            {(this.props.Reservation?.cleaningNotes || []).map((cleaningNote) =>
                                <CleaningNote userName={cleaningNote.User} date={cleaningNote.Created} file={cleaningNote.File} onFilePreview={(file) => this.setState({CleaningNoteFilePreview: file})}>{cleaningNote.Note}</CleaningNote>
                            )}
                            {!this.props.Reservation?.cleaningNotes?.length && <Typography.Text type="secondary" style={{display: "block", textAlign: "center"}}>Keine Notizen vorhanden</Typography.Text>}
                        </div>
                        <div className="ReservationNoteInputWrapper">
                            <div className="ReservationNoteInputContainer">
                                <Upload
                                    accept="image/*"
                                    action={(f) => BASE_URL+'api/'+API_VERSION+"/roomplan/reservation/"+(this.props.actReservationID)+"/cleaningnote/Upload/"+f.uid}
                                    headers={{authorization: `Bearer ${localStorage.getItem('id_token') || null}`}}
                                    onChange={({fileList}) => {this.setState({cleaningNoteUpload: fileList.slice(-1)});}}
                                    fileList={this.state.cleaningNoteUpload}
                                    showUploadList={false}
                                    multiple={false}
                                    disabled={!this.props.actReservationID || this.props.ReservationCleaningNoteSaving}
                                >
                                    <Button type="default" htmlType="button" icon={<FileAddOutlined />} />
                                </Upload>
                                <div className="ReservationNoteInputs">
                                    {/*!this.state.cleaningNoteUpload?.length ? <TextArea disabled={!this.props.actReservationID || this.props.ReservationCleaningNoteSaving} value={this.state.cleaningNoteText} onChange={(e) => { this.setState({cleaningNoteText: e.target.value}); }} /> : null*/}
                                    <TextArea placeholder={"Nachricht eingeben..."} disabled={!this.props.actReservationID || this.props.ReservationCleaningNoteSaving} value={this.state.cleaningNoteText} onChange={(e) => { this.setState({cleaningNoteText: e.target.value}); }} />
                                    <Upload fileList={this.state.cleaningNoteUpload}  onChange={({fileList}) => {this.setState({cleaningNoteUpload: fileList});}} />
                                </div>
                            <Button htmlType="button" type="primary" onClick={this.sendCleaningNote} disabled={this.props.ReservationCleaningNoteSaving}>Senden</Button>
                            </div>
                        </div>


                        <Divider orientation={"left"}>Informationen</Divider>
                        <Row gutter={15}>
                            <Col span={3}>&nbsp;</Col>
                            <Col span={9}>
                                <FormItem
                                    label={"Erstellt am"}
                                    key={'Created'}
                                >
                                    {getFieldDecorator('Created', {
                                        initialValue:   (this.props.Reservation.Created ? moment(this.props.Reservation.Created): ""),
                                        rules: [{
                                            required: false,
                                        }],
                                    })(
                                        <DatePicker
                                            disabled={true}
                                            style={{ width: 300 }}
                                            placeholder={" "}
                                            format={'DD.MM.YYYY HH:mm'}
                                        >
                                        </DatePicker>
                                    )}
                                </FormItem>
                            </Col>
                            <Col span={9}>
                                <FormItem
                                    label={"Erstellt von"}
                                    key={'CreatedFrom'}
                                >
                                    {getFieldDecorator('CreatedFrom', {
                                        initialValue:  this.props.Reservation.UpdatedFrom || " ",
                                        rules: [{
                                            required: false,
                                        }],
                                    })(
                                        <Input
                                            disabled={true}
                                            style={{ width: 300 }}
                                        >
                                        </Input>
                                    )}
                                </FormItem>
                            </Col>
                            <Col span={3}>&nbsp;</Col>
                        </Row>

                        <Row gutter={15}>
                            <Col span={3}>&nbsp;</Col>
                            <Col span={9}>
                                <FormItem
                                    label={"Zuletzt aktualisiert am"}
                                    key={'Updated'}
                                >
                                    {getFieldDecorator('Updated', {
                                        initialValue:   (this.props.Reservation.Updated ? moment(this.props.Reservation.Updated): null),
                                        rules: [{
                                            required: false,
                                        }],
                                    })(
                                        <DatePicker
                                            disabled={true}
                                            style={{ width: 300 }}
                                            placeholder={" "}
                                            format={'DD.MM.YYYY  HH:mm'}
                                        >
                                        </DatePicker>
                                    )}
                                </FormItem>
                            </Col>
                            <Col span={9}>
                                <FormItem
                                    label={"Zuletzt aktualisiert von"}
                                    key={'UpdatedFrom'}
                                >
                                    {getFieldDecorator('UpdatedFrom', {
                                        initialValue:  this.props.Reservation.UpdatedFrom,
                                        rules: [{
                                            required: false,
                                        }],
                                    })(
                                        <Input
                                            disabled={true}
                                            style={{ width: 300 }}
                                        >
                                        </Input>
                                    )}
                                </FormItem>
                            </Col>
                            <Col span={3}>&nbsp;</Col>
                        </Row>

                    </form>
                </Modal>
            </React.Fragment>

        );
    }
}



function mapStateToProps(state) {
    const { RoomPlanReducer, Auth, PersonsReducer } = state;
    const { ReservationVisible, Houses, Rooms, Room, actReservationID, actReservationDate, Reservation, House, reservedDays } = RoomPlanReducer;
    const { Persons } = PersonsReducer;
    const { GroupID } = Auth;

    return {
        Persons,
        Houses,
        House,
        Rooms,
        Room,
        ReservationVisible,
        actReservationID,
        actReservationDate,
        Reservation,
        reservedDays,
        GroupID
    };
}


function mapDispatchToProps(dispatch) {
    return{
        actions: bindActionCreators(RoomPlanActions, dispatch),
        PersonsActions : bindActionCreators(PersonsActions, dispatch)
    };
}

const FormReservation = Form.create()(Reservation);

export default connect(mapStateToProps, mapDispatchToProps)(FormReservation);
