import React, {Component} from "react";
import { Select, Modal, Input, Checkbox, Button, DatePicker, Upload } from 'antd';
import { Form } from '@ant-design/compatible';
import {bindActionCreators} from "redux";
import * as MaschinenActions from "../../redux/Maschinen/actions";
import {connect} from "react-redux";
import moment from "moment";
import {InboxOutlined} from "@ant-design/icons";

const { TextArea } = Input;
const FormItem = Form.Item;
const Option = Select.Option;
const confirm = Modal.confirm;

let BASE_URL = process.env.REACT_APP_API_URL;
let API_VERSION = process.env.REACT_APP_API_VERSION;


const formItemLayout = {
    labelCol: {
        xs: { span: 20 },
        sm: { span: 6 },
    },
    wrapperCol: {
        xs: { span: 20 },
        sm: { span: 13 },
    },
};

const formItemLayoutWithOutLabel = {
    wrapperCol: {
        xs: { span: 24, offset: 0 },
        sm: { span: 24, offset: 0 },
    },
};


class MaschinenDetailModal extends Component {


    componentWillMount() {
    }

    handleSubmit = (e) => {
        e.preventDefault();
        this.props.form.validateFieldsAndScroll((err, values) => {
            if (!err) {
                this.props.actions.saveMaschinenDetail(values, this.props.MaschinenDetailID);
            }
        });
    };


    componentWillReceiveProps(nextProps) {
        if(this.props.MaschinenModalVisible && !nextProps.MaschinenModalVisible) {
            this.props.form.resetFields();
        } else if(!this.props.MaschinenModalVisible && nextProps.MaschinenModalVisible && nextProps.MaschinenDetailID) {
            this.props.actions.LoadMaschinenDetail(nextProps.MaschinenDetailID);
        }
    }

    confirmDelete = () => {
        confirm({
            title: "Maschine löschen",
            content: "Wollen Sie diese Maschine wirklich löschen?",
            okText: "Löschen",
            okType: 'danger',
            cancelText: "Abbrechen",
            onOk: () => this.props.actions.deleteMaschine(this.props.MaschinenDetailID),
            onCancel() {
            },
        });
    };

    normMultiFile = (e) => {
        if (Array.isArray(e)) {
            return e;
        }
        return e && e.fileList;
    };



    render() {
        const { getFieldDecorator } = this.props.form;


        return (
                <Modal
                    title={`Automaten / Maschine ${this.props.MaschinenDetailID ? 'bearbeiten' : 'anlegen'}`}
                    visible={this.props.MaschinenModalVisible}
                    onOk={this.handleSubmit}
                    onCancel={() => this.props.actions.showMaschinenModal(false, null)}
                    cancelText={"Abbrechen"}
                    okText={"Speichern"}
                    width={"750px"}
                    footer={[
                        this.props.MaschinenDetailID ? <Button key="deleteban" type="danger" loading={this.props.deletingBan} onClick={this.confirmDelete}>
                            Maschine löschen
                        </Button> : '',
                        <Button key="cancel" onClick={() => this.props.actions.showMaschinenModal(false, null)}>Abbrechen</Button>,
                        <Button key="submit" type="primary" loading={this.props.MaschinenModalSaving} onClick={this.handleSubmit}>
                            Speichern
                        </Button>
                    ]}
                >



                    <form onSubmit={this.handleSubmit} style={{width: '100%'}}>

                        <FormItem
                            {...formItemLayout}
                            label={"Name"}
                            required={true}
                            key={'Name'}
                        >
                            {getFieldDecorator('Name', {
                                initialValue: this.props.MaschineDetail.Name,
                                rules: [{
                                    required: true,
                                    whitespace: true,
                                    message: "Bitte geben Sie diese Information an."
                                }],
                            })(
                                <Input type={"text"} placeholder={"Name"}/>
                            )}
                        </FormItem>
                        <FormItem
                            {...formItemLayout}
                            label={"Notiz"}
                            required={false}
                            key={'Note'}
                        >
                            {getFieldDecorator('Note', {
                                initialValue: this.props.MaschineDetail.Note,
                                rules: [{
                                    required: false,
                                    message: "Bitte geben Sie diese Information an."
                                }],
                            })(
                                <TextArea
                                    rows={4}
                                    style={{ width: 380 }}
                                    placeholder="Optionale Notizen"
                                >
                                </TextArea>
                            )}
                        </FormItem>

                        <Form.Item
                            {...formItemLayout}
                            label="Dateien"
                        >

                            {getFieldDecorator('files', {
                                valuePropName: 'fileList',
                                getValueFromEvent: this.normMultiFile,
                                initialValue: this.props.MaschineDetail["files"],
                            })(
                                <Upload.Dragger name="files" action={(f) => BASE_URL+'api/'+API_VERSION+"/machine/"+(this.props.MaschinenDetailID ? this.props.MaschinenDetailID : "new")+"/Upload/"+f.uid} headers={{authorization: `Bearer ${localStorage.getItem('id_token') || null}`}}  listType={'picture'}  multiple={true}>
                                    <p className="ant-upload-drag-icon">
                                        <InboxOutlined />
                                    </p>
                                    <p className="ant-upload-text">Hier klicken oder Datei in diesen Bereich ziehen</p>
                                    <p className="ant-upload-hint">Es werden alle Dateien unterstützt.</p>
                                </Upload.Dragger>
                            )}
                        </Form.Item>



                    </form>


                </Modal>


        );
    }
}



function mapStateToProps(state) {
    const { MaschinenReducer } = state;
    const { MaschinenModalVisible, MaschinenDetailID, MaschinenModalSaving, MaschineDetail , deletingBan} = MaschinenReducer;
    return {
        MaschinenModalVisible,
        MaschinenDetailID,
        MaschinenModalSaving,
        MaschineDetail,
        deletingBan
    };
}


function mapDispatchToProps(dispatch) {
    return{
        actions: bindActionCreators(MaschinenActions, dispatch)
    };
}



export default connect(mapStateToProps, mapDispatchToProps)(Form.create()(MaschinenDetailModal));


//export default Form.create()(UsersDetailModal);