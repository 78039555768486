import React, {Component} from "react";
import {connect} from "react-redux";
import {bindActionCreators} from "redux";
import * as ReinigungActions from "../../redux/Reinigung/actions";
import {Input, Button, Table, Typography} from 'antd';
import Box from '../../components/utility/box';
import ReinigungDetailModal from './ReinigungDetailModal';
import './list.style.css';
import {CheckCircleOutlined, CheckOutlined, CloseCircleOutlined, PlusOutlined} from "@ant-design/icons";
import LayoutWrapper from "../../components/utility/layoutWrapper";


const Search = Input.Search;
const itemStyle = {margin: '0px'};





class Reinigung extends Component {



    componentWillMount() {
        this.props.actions.LoadReinigungList({...this.props.infosTableReinigung, search: this.props.search});
        this.state = {search: this.props.search};
    }


    handleTableChange = (pagination, filters, sorter) => {
        const pager = { ...this.props.paginationReinigung };
        pager.current = pagination.current;

        this.props.actions.setReinigungTablePage(pager);

        this.props.actions.setReinigungTableInfo({
            results: pagination.pageSize,
            page: pagination.current,
            sortField: sorter.field,
            sortOrder: sorter.order,
            ...filters,
        });

        this.props.actions.LoadReinigungList({
            results: pagination.pageSize,
            page: pagination.current,
            sortField: sorter.field,
            sortOrder: sorter.order,
            ...filters,
            search: this.props.search
        });


    };



    componentWillUpdate(nextProps) {
       if(this.props.search !== nextProps.search) {
            this.props.actions.LoadReinigungList({...nextProps.infosTableReinigung, search: nextProps.search});
        }

        if(this.props.ReinigungModalSaving && !nextProps.ReinigungModalSaving ||
            this.props.deletingCleaning && !nextProps.deletingCleaning) {
            this.props.actions.LoadReinigungList({...this.props.infosTableReinigung, search: this.props.search});
        }
    }

    onChangeSearch = (e) => {
        this.setState({ search: e.target.value });
    };






    render() {

        const columns = [
            {
                title: 'Raum',
                dataIndex: 'Room',
                key: 'Room',
                sorter: true,
                sortOrder: this.props.infosTableReinigung.sortField === 'Room' && this.props.infosTableReinigung.sortOrder
            },
            {
                title: 'Erledigte Aufgaben',
                dataIndex: 'JobsDone',
                key: 'Cleaned',
                render: (_, record) => (record.JobsDone + '/' + record.JobsCount),
                sorter: true,
                sortOrder: this.props.infosTableReinigung.sortField === 'Cleaned' && this.props.infosTableReinigung.sortOrder
            },
            {
                title: 'Vollständig gereinigt',
                dataIndex: 'Cleaned',
                key: 'Cleaned',
                render: (text) => (text ? <CheckCircleOutlined style={{color: "green"}} /> : <CloseCircleOutlined style={{color: "red"}} />),
                sorter: true,
                sortOrder: this.props.infosTableReinigung.sortField === 'Cleaned' && this.props.infosTableReinigung.sortOrder
            },
            {
                title: 'Geprüft',
                dataIndex: 'Checked',
                key: 'Checked',
                render: (text, record) => record.Cleaned ? (text ? <CheckCircleOutlined style={{color: "green"}} /> : <CloseCircleOutlined style={{color: "red"}} />) : "-",
                sorter: true,
                sortOrder: this.props.infosTableReinigung.sortField === 'Checked' && this.props.infosTableReinigung.sortOrder
            },
            ];

        const searchClear = this.props.search ? <CloseCircleOutlined onClick={() => {this.props.actions.setSearchText(''); this.setState({ search: '' });}} /> : null;

        return (
            <React.Fragment>
                <ReinigungDetailModal />
                <LayoutWrapper>
                    <Search onChange={this.onChangeSearch} value={this.state.search} prefix={searchClear} placeholder={"Suchen..."} onSearch={value => this.props.actions.setSearchText(value)} defaultValue={this.props.search} enterButton style={{width: '400px', minHeight: '0px !important'}} />
                    <Button type={"primary"} htmlType={"button"} className={"round"} style={{marginBottom: "10px", marginLeft: "auto"}} onClick={() => this.props.actions.showReinigungModal(true, null)} icon={<PlusOutlined/>}>Neue Reinigung anlegen</Button>
                <Box >


                    <Table
                        columns={columns}
                        dataSource={this.props.ReinigungList}
                        rowClassName={(record, index) => { return 'tableCursor'}}
                        onRow={(record) => ({
                            onClick: () => this.props.actions.showReinigungModal(true, record.CleaningID)
                        })}
                        pagination={this.props.paginationReinigung}
                        loading={this.props.loadingReinigungList}
                        onChange={this.handleTableChange}
                        scroll={{x: '100%'}}
                        rowKey={(record => record.CleaningID)}
                    />



                </Box>
                </LayoutWrapper>
            </React.Fragment>
        );
    }
}


function mapStateToProps(state) {
    const { ReinigungReducer } = state;
    const { ReinigungList, loadingReinigungList, paginationReinigung, infosTableReinigung, search, ReinigungModalSaving, deletingCleaning } = ReinigungReducer;
    return {
        ReinigungList,
        loadingReinigungList,
        paginationReinigung,
        infosTableReinigung,
        search,
        ReinigungModalSaving,
        deletingCleaning
    };
}


function mapDispatchToProps(dispatch) {
    return{
        actions: bindActionCreators(ReinigungActions, dispatch)
    };
}



export default connect(mapStateToProps, mapDispatchToProps)(Reinigung);
