import React, {Component} from "react";
import {connect} from "react-redux";
import {bindActionCreators} from "redux";
import * as CheckInActions from "../redux/CheckIn/actions";
import * as PersonActions from "../redux/Personen/actions";
import {Button, Modal, Divider, Upload, Input} from 'antd';
import { Form } from '@ant-design/compatible';
import '../containers/CheckIn/list.style.css';
//import FileViewer from 'react-file-viewer';
import Box from "./utility/box";
import FileModal from '../components/FileModal';
import { InboxOutlined } from '@ant-design/icons';

const FormItem = Form.Item;
const confirm = Modal.confirm;

let BASE_URL = process.env.REACT_APP_API_URL;
let API_VERSION = process.env.REACT_APP_API_VERSION;


const formItemLayout = {
    labelCol: {
        xs: { span: 24 },
        sm: { span: 8 },
    },
    wrapperCol: {
        xs: { span: 24 },
        sm: { span: 8 },
    },
};


class UploadFilesPersons extends Component {


    state = {fileName: '', fileExtension: ''};

    componentWillMount() {
        //this.props.actions.loadPersonsDetail(this.KundenID);
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if(this.props.showUploadImagesPersonsVisible && !prevProps.showUploadImagesPersonsVisible) {
            this.props.PersonActions.loadPersonsDetail(this.props.actPersonID);
        }
    }

    handleCancel = () => {
        this.props.actions.showUploadImagesPersons(false);
    };


    handleSubmit = (e) => {
        e.preventDefault();
        this.props.form.validateFieldsAndScroll({scroll: {offsetTop: 150}}, (err, values) => {
            if (!err) {
                this.props.actions.saveImagesPersons(this.props.actAgreementID, this.props.actPersonID, values);
                this.props.actions.showUploadImagesPersons(false);
            }
        });
    };

    normMultiFile = (e) => {
        if (Array.isArray(e)) {
            return e;
        }
        e.file.name = this.state.fileName+"."+this.state.fileExtension;
        return e && e.fileList;
    };


    previewFile(file) {
        this.props.PersonActions.showPreviewFileModal(true, file);
    }
    previewFileModalOK = (value, FileID) => {
        this.props.PersonActions.updateFileName(FileID, value);
    };
    previewFileModalCancel = () => {
        this.props.PersonActions.showPreviewFileModal(false);
    };

    renameBeforeUpload = (file) => {
        let [fileName, fileExtension] = file.name.split(/\.(?=[^\.]+$)/);
        this.setState({fileName, fileExtension});
        return new Promise(resolve => {
            confirm({
                title: 'Bitte geben Sie den Dateinamen ein:',
                content: <React.Fragment>
                    <Input defaultValue={this.state.fileName} onChange={event => this.setState({fileName:event.target.value})} />
                </React.Fragment>,
                okText: 'Speichern',
                cancelText: 'Abbrechen',
                onOk: () => {
                    let newFile = new File([file], (this.state.fileName+"."+this.state.fileExtension), {type: file.type});
                    return resolve(newFile);
                },
                onCancel: () => {
                    //return resolve(false);
                    return resolve(file);
                },
            });
        });
    };

    render() {

        const { getFieldDecorator } = this.props.form;

        return (
            <React.Fragment>
                <FileModal visible={this.props.previewFileModalVisible} file={this.props.previewFileModalFile} onOk={this.previewFileModalOK} onCancel={this.previewFileModalCancel}/>
                <Modal
                    title="Personendaten hinzufügen"
                    visible={this.props.showUploadImagesPersonsVisible}
                    onOk={this.handleSubmit}
                    onCancel={this.handleCancel}
                    width={"750px"}
                    style={{ top: 10 }}
                    footer={[
                        <Button key="back" onClick={this.handleCancel}>
                            Schließen
                        </Button>,
                        <Button key="submit" type="primary" onClick={this.handleSubmit}>
                                Speichern & Vertrag anzeigen
                        </Button>,
                    ]}
                >
                    <form onSubmit={this.handleSubmit} style={{width: '100%'}}>
                        <Divider orientation="left">Dokumente</Divider>
                        <Form.Item
                            {...formItemLayout}
                            label="Dateien"
                        >

                            {getFieldDecorator('images', {
                                valuePropName: 'fileList',
                                getValueFromEvent: this.normMultiFile,
                                initialValue: this.props.PersonDetail["images"],
                            })(
                                <Upload.Dragger name="files"
                                                action={BASE_URL+'api/'+API_VERSION+"/persons/"+this.props.actPersonID+"/Upload"}
                                                accept={".png,.jpg,.jpeg,.pdf"} headers={{authorization: `Bearer ${localStorage.getItem('id_token') || null}`}}
                                                listType={'picture'}
                                                multiple={true}
                                                onPreview={(file) => this.previewFile(file)}
                                                transformFile={this.renameBeforeUpload}
                                >
                                    <p className="ant-upload-drag-icon">
                                        <InboxOutlined />
                                    </p>
                                    <p className="ant-upload-text">Hier klicken oder Datei in diesen Bereich ziehen</p>
                                    <p className="ant-upload-hint">Es werden nur .JPG, .JPEG, .PNG und .PDF Dateien unterstützt.</p>
                                </Upload.Dragger>
                            )}
                        </Form.Item>
                    </form>
                </Modal>
            </React.Fragment>
        );
    }
}


function mapStateToProps(state) {
    const { CheckInReducer, PersonsReducer } = state;
    const { showUploadImagesPersonsVisible, actAgreementID, actPersonID } = CheckInReducer;
    const { loadingPersonDetail, PersonDetail, previewFileModalVisible, previewFileModalFile} = PersonsReducer;
    return {
        showUploadImagesPersonsVisible,
        actAgreementID,
        actPersonID,
        PersonDetail,
        previewFileModalVisible,
        previewFileModalFile
    };
}


function mapDispatchToProps(dispatch) {
    return{
        actions: bindActionCreators(CheckInActions, dispatch),
        PersonActions: bindActionCreators(PersonActions, dispatch)

    };
}



export default connect(mapStateToProps, mapDispatchToProps)(Form.create()(UploadFilesPersons));
