import HolidayActions from "./actions";
import moment from "moment";


const initState = {
    loadingHolidayDetail: true,
    loadingHolidays: true,
    Holidays: [],
    HolidayDetailsVisible: false,
    HolidayDetailsID: false,
    Holiday: {},
    savingHoliday: false,
    deletingHoliday: false
};

export default function HolidayReducer(state = initState, action) {
  switch (action.type) {
    case HolidayActions.LOAD_HOLIDAYS_REQUEST:
        return Object.assign({}, state, {
            loadingHolidayDetail: true
        });
    case HolidayActions.LOAD_HOLIDAYS_SUCCESS:
        return Object.assign({}, state, {
            Holidays: (action.response.data?.Holidays || []).map(holiday => ({data: holiday, start: moment.utc(holiday.StartDatum, "YYYY-MM-DD").toDate(), end: moment.utc(holiday.EndDatum, "YYYY-MM-DD").toDate()})),
            loadingHolidays: false,
        });
      case HolidayActions.LOAD_HOLIDAYS_ERROR:
          return Object.assign({}, state, {
              loadingHolidays: false
          });
      case HolidayActions.SET_HOLIDAY_DETAILS_VISIBLE:
          return Object.assign({}, state, {
              HolidayDetailsVisible: action.visible,
              HolidayDetailsID: action.HolidayID,
              Holiday: action.initialValues || {},
          });
      case HolidayActions.SAVE_HOLIDAY_REQUEST:
          return Object.assign({}, state, {
              savingHoliday: true
          });
      case HolidayActions.SAVE_HOLIDAY_SUCCESS:
          return Object.assign({}, state, {
              savingHoliday: false,
              HolidayDetailsVisible: false
          });
      case HolidayActions.SAVE_HOLIDAY_ERROR:
          return Object.assign({}, state, {
              savingHoliday: false
          });
      case HolidayActions.LOAD_HOLIDAY_DETAILS_REQUEST:
          return Object.assign({}, state, {
              Holiday: {}
          });
      case HolidayActions.LOAD_HOLIDAY_DETAILS_SUCCESS:
          const holiday = action.response.data.Holiday;
          return Object.assign({}, state, {
              Holiday: {...holiday, StartDatum: moment.utc(holiday.StartDatum, "YYYY-MM-DD"), EndDatum: moment.utc(holiday.EndDatum, "YYYY-MM-DD")},
          });
      case HolidayActions.LOAD_HOLIDAY_DETAILS_ERROR:
          return Object.assign({}, state, {
              HolidayDetailsVisible: false
          });
      case HolidayActions.RESET_HOLIDAY_DETAIL:
          return state;
      case HolidayActions.DELETE_HOLIDAY_REQUEST:
          return Object.assign({}, state, {
              deletingHoliday: true
          });
      case HolidayActions.DELETE_HOLIDAY_SUCCESS:
          return Object.assign({}, state, {
              deletingHoliday: false,
              HolidayDetailsVisible: false
          });
      case HolidayActions.DELETE_HOLIDAY_ERROR:
          return Object.assign({}, state, {
              deletingHoliday: false
          });
    default:
      return state;
  }
}

