import {CALL_API} from "../../helpers/middleware";

const ReinigungActions = {

    LOAD_REINIGUNG_LIST_REQUEST : 'LOAD_REINIGUNG_LIST_REQUEST',
    LOAD_REINIGUNG_LIST_SUCCESS : 'LOAD_REINIGUNG_LIST_SUCCESS',
    LOAD_REINIGUNG_LIST_ERROR : 'LOAD_REINIGUNG_LIST_ERROR',
    SET_REINIGUNG_TABLE_PAGE: 'SET_REINIGUNG_TABLE_PAGE',
    SET_REINIGUNG_TABLE_INFO: 'SET_REINIGUNG_TABLE_INFO',
    SET_SEARCH_TEXT: 'SET_SEARCH_TEXT',

    SHOW_REINIGUNG_MODAL: 'SHOW_REINIGUNG_MODAL',
    SAVE_REINIGUNG_REQUEST: 'SAVE_REINIGUNG_REQUEST',
    SAVE_REINIGUNG_SUCCESS: 'SAVE_REINIGUNG_SUCCESS',
    SAVE_REINIGUNG_ERROR: 'SAVE_REINIGUNG_ERROR',

    LOAD_REINIGUNG_DETAIL_REQUEST: 'LOAD_REINIGUNG_DETAIL_REQUEST',
    LOAD_REINIGUNG_DETAIL_SUCCESS: 'LOAD_REINIGUNG_DETAIL_SUCCESS',
    LOAD_REINIGUNG_DETAIL_ERROR: 'LOAD_REINIGUNG_DETAIL_ERROR',

    SAVE_REINIGUNG_CLEANING_NOTE_REQUEST: 'SAVE_REINIGUNG_CLEANING_NOTE_REQUEST',
    SAVE_REINIGUNG_CLEANING_NOTE_SUCCESS: 'SAVE_REINIGUNG_CLEANING_NOTE_SUCCESS',
    SAVE_REINIGUNG_CLEANING_NOTE_ERROR: 'SAVE_REINIGUNG_CLEANING_NOTE_ERROR',

    DELETE_REINIGUNG_REQUEST: 'DELETE_REINIGUNG_REQUEST',
    DELETE_REINIGUNG_SUCCESS: 'DELETE_REINIGUNG_SUCCESS',
    DELETE_REINIGUNG_ERROR: 'DELETE_REINIGUNG_ERROR',
};

export default ReinigungActions;


export function LoadReinigungList(params = {}) {
    return {
        [CALL_API]: {
            reqType: 'POST',
            endpoint: 'cleaning',
            postData: {
                results: 10,
                ...params,
            },
            types: [ReinigungActions.LOAD_REINIGUNG_LIST_REQUEST, ReinigungActions.LOAD_REINIGUNG_LIST_SUCCESS, ReinigungActions.LOAD_REINIGUNG_LIST_ERROR]
        }
    };
}

export function saveReinigungDetail(values, CleaningID) {
    let url = "cleaning";
    let type = "POST";
    if(CleaningID) {
        url += "/"+CleaningID;
        type = "PUT";
    } else {
        url += "/new";
    }

    return {
        [CALL_API]: {
            reqType: type,
            endpoint: url,
            postData: values,
            types: [ReinigungActions.SAVE_REINIGUNG_REQUEST, ReinigungActions.SAVE_REINIGUNG_SUCCESS, ReinigungActions.SAVE_REINIGUNG_ERROR]
        }
    };
}

export function LoadReinigungDetail(CleaningID) {
    return {
        [CALL_API]: {
            reqType: 'GET',
            endpoint: 'cleaning/'+CleaningID,
            types: [ReinigungActions.LOAD_REINIGUNG_DETAIL_REQUEST, ReinigungActions.LOAD_REINIGUNG_DETAIL_SUCCESS, ReinigungActions.LOAD_REINIGUNG_DETAIL_ERROR]
        }
    };
}

export function deleteReinigung(CleaningID) {
    return {
        [CALL_API]: {
            reqType: 'DELETE',
            endpoint: 'cleaning/'+CleaningID,
            types: [ReinigungActions.DELETE_REINIGUNG_REQUEST, ReinigungActions.DELETE_REINIGUNG_SUCCESS, ReinigungActions.DELETE_REINIGUNG_ERROR]
        }
    };
}


export function saveCleaningNote(values, CleaningID) {
    return {
        [CALL_API]: {
            reqType: 'POST',
            endpoint: 'cleaning/'+CleaningID+'/cleaningnote',
            postData: values,
            types: [ReinigungActions.SAVE_REINIGUNG_CLEANING_NOTE_REQUEST, ReinigungActions.SAVE_REINIGUNG_CLEANING_NOTE_SUCCESS, ReinigungActions.SAVE_REINIGUNG_CLEANING_NOTE_ERROR]
        }
    };
}


export function showReinigungModal(show, CleaningID) {
    return {
        type: ReinigungActions.SHOW_REINIGUNG_MODAL,
        show,
        CleaningID
    };
}

export function setReinigungTablePage(pager) {
    return {
        type: ReinigungActions.SET_REINIGUNG_TABLE_PAGE,
        pager
    };
};

export function setReinigungTableInfo(infos) {
    return {
        type: ReinigungActions.SET_REINIGUNG_TABLE_INFO,
        infos
    };
};

export function setSearchText(search) {
    return {
        type: ReinigungActions.SET_SEARCH_TEXT,
        search
    };
};




