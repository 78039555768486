import React, {Component} from "react";
import {connect} from "react-redux";
import {bindActionCreators} from "redux";
import * as CheckInActions from "../redux/CheckIn/actions";
import { Button, Modal } from 'antd';
import '../containers/CheckIn/list.style.css';
//import FileViewer from 'react-file-viewer';
//import { Document, Page } from 'react-pdf/dist/esm/entry.webpack';
import { Document, Page, pdfjs } from 'react-pdf';


//pdfjs.GlobalWorkerOptions.workerSrc = 'pdf.worker.min.js';
pdfjs.GlobalWorkerOptions.workerSrc = `//unpkg.com/pdfjs-dist@${pdfjs.version}/build/pdf.worker.min.js`;

let BASE_URL = process.env.REACT_APP_API_URL;
let API_VERSION = process.env.REACT_APP_API_VERSION;


class ShowAgreement extends Component {

    onDocumentLoadSuccess = ({ numPages }) => {
        this.setState({ numPages });
    };

    componentWillMount() {
        this.setState({
            numPages: null,
            pageNumber: 1,
            file: {url: BASE_URL+'api/'+API_VERSION+"/checkin/pdf/"+this.props.actAgreementID, httpHeaders: { 'authorization': `Bearer ${localStorage.getItem('id_token') || null}` }}
        });
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if(prevProps.showAgreementVisible && !this.props.showAgreementVisible) {
            this.setState({
                numPages: null,
                pageNumber: 1,
                file: undefined,
            });
        }
        if(prevProps.actAgreementID !== this.props.actAgreementID || (!prevProps.showAgreementVisible && this.props.showAgreementVisible)) {
            this.setState({
                numPages: null,
                pageNumber: 1,
                file: {url: BASE_URL+'api/'+API_VERSION+"/checkin/pdf/"+this.props.actAgreementID, httpHeaders: { 'authorization': `Bearer ${localStorage.getItem('id_token') || null}` }}
            });
        }
    }


    handleCancel = () => {
        this.props.actions.showShowAgreement(false);
    };

    handleSubmit = () => {
        this.props.actions.showShowAgreement(false);
        if(this.props.signableAgreement) {
            this.props.actions.showSignAgreement(true,this.props.actAgreementID)
        }
    };


    render() {

        const { numPages } = this.state;

        return (
            <Modal
                title="Vertrag anzeigen"
                visible={this.props.showAgreementVisible}
                onOk={this.handleSubmit}
                onCancel={this.handleCancel}
                width={"750px"}
                style={{ top: 10 }}
                footer={[
                    <Button key="back" onClick={this.handleCancel}>
                        Schließen
                    </Button>,
                    <Button key="download" onClick={() => this.props.actions.showAgreementPDF(this.props.actAgreementID)}>
                        Download
                    </Button>,
                    this.props.signableAgreement ?
                        <Button key="submit" type="primary" onClick={this.handleSubmit}>
                            Vertrag unterschreiben
                    </Button> : '',
                ]}
            >
                {this.props.actAgreementID > 0 && this.props.showAgreementVisible ?
                <div className={"ShowAgreement-View"}>
                    {/*<FileViewer
                        fileType={'pdf'}
                        filePath={{url: BASE_URL+'api/'+API_VERSION+"/checkin/pdf/"+this.props.actAgreementID, httpHeaders: { 'authorization': `Bearer ${token}` }}}
                        onError={(e) => console.log(e)}/>*/}

                    <Document
                        file={this.state.file}
                        onLoadSuccess={this.onDocumentLoadSuccess}
                        noData={""}
                    >
                        {numPages > 1 ? [...Array(numPages)].map((x,p)=> <Page pageNumber={p+1} />)
                            :
                            <Page pageNumber={1} />
                        }
                    </Document>

                </div>
                :
                ''}
            </Modal>
        );
    }
}


function mapStateToProps(state) {
    const { CheckInReducer } = state;
    const { showAgreementVisible, actAgreementID, signableAgreement } = CheckInReducer;
    return {
        showAgreementVisible,
        actAgreementID,
        signableAgreement
    };
}


function mapDispatchToProps(dispatch) {
    return{
        actions: bindActionCreators(CheckInActions, dispatch)
    };
}



export default connect(mapStateToProps, mapDispatchToProps)(ShowAgreement);
