import UsersActions from "./actions";


const initState = {
    loadingUsers: true,
    paginationUsers: {},
    Users: [],
    infosTableUsers: {},
    search: "",

    loadingUserLoginLogs: true,
    paginationUserLoginLogs: {},
    UserLoginLogs: [],
    infosTableUserLoginLogs: {},
    searchUserLoginLogs: "",



    UsersDetailsVisible: false,
    UsersDetailsUserID: false,
    User: {},
    UsersGroups: [],
    savingUsers: false,
    deletingUser: false
};

export default function UsersReducer(state = initState, action) {
  switch (action.type) {
    case UsersActions.LOAD_USERS_REQUEST:
        return Object.assign({}, state, {
            loadingUsers: true
        });
    case UsersActions.LOAD_USERS_SUCCESS:
        const pagination = { ...state.paginationUsers };
        pagination.total = action.response.data.Count;
        return Object.assign({}, state, {
            Users: action.response.data.Users,
            loadingUsers: false,
            paginationUsers: pagination
        });
      case UsersActions.LOAD_USERS_ERROR:
          return Object.assign({}, state, {
              loadingUsers: false
          });
      case UsersActions.SET_USERS_PAGE:
          return Object.assign({}, state, {
              paginationUsers: action.pager
          });
      case UsersActions.SET_USERS_TABLE_INFO:
          return Object.assign({}, state, {
              infosTableUsers: action.infos
          });
      case UsersActions.SET_SEARCH_TEXT:
          return Object.assign({}, state, {
              search: action.search,
              paginationUsers: {current: 1, pageSize: 25},
              infosTableUsers: {page: 1, results: 25, sortField: "UserID", sortOrder: "descend"}
          });
      case UsersActions.SET_USERS_DETAILS_VISIBLE:
          return Object.assign({}, state, {
              UsersDetailsVisible: action.visible,
              UsersDetailsUserID: action.UserID,
              User: {}
          });


      case UsersActions.LOAD_USER_LOGIN_LOGS_REQUEST:
          return Object.assign({}, state, {
              loadingUserLoginLogs: true
          });
      case UsersActions.LOAD_USER_LOGIN_LOGS_SUCCESS:
          const paginationUL = { ...state.paginationUserLoginLogs };
          paginationUL.total = action.response.data.Count;
          return Object.assign({}, state, {
              UserLoginLogs: action.response.data.Logins,
              loadingUserLoginLogs: false,
              paginationUserLoginLogs: paginationUL
          });
      case UsersActions.LOAD_USER_LOGIN_LOGS_ERROR:
          return Object.assign({}, state, {
              loadingUserLoginLogs: false
          });
      case UsersActions.SET_USER_LOGIN_LOGS_PAGE:
          return Object.assign({}, state, {
              paginationUserLoginLogs: action.pager
          });
      case UsersActions.SET_USER_LOGIN_LOGS_TABLE_INFO:
          return Object.assign({}, state, {
              infosTableUserLoginLogs: action.infos
          });
      case UsersActions.SET_USER_LOGIN_LOGS_SEARCH_TEXT:
          return Object.assign({}, state, {
              searchUserLoginLogs: action.search,
              paginationUserLoginLogs: {current: 1, pageSize: 75},
              infosTableUserLoginLogs: {page: 1, results: 75, sortField: "Datum", sortOrder: "descend"}
          });


      case UsersActions.LOAD_USERS_GROUPS_SUCCESS:
          return Object.assign({}, state, {
              UsersGroups: action.response.data.UsersGroups
          });
      case UsersActions.SAVE_USERS_REQUEST:
          return Object.assign({}, state, {
              savingUsers: true
          });
      case UsersActions.SAVE_USERS_SUCCESS:
          return Object.assign({}, state, {
              savingUsers: false,
              UsersDetailsVisible: false
          });
      case UsersActions.SAVE_USERS_ERROR:
          return Object.assign({}, state, {
              savingUsers: false
          });
      case UsersActions.LOAD_USERS_DETAILS_REQUEST:
          return Object.assign({}, state, {
              User: {}
          });
      case UsersActions.LOAD_USERS_DETAILS_SUCCESS:
          return Object.assign({}, state, {
              User: action.response.data.User,
          });
      case UsersActions.LOAD_USERS_DETAILS_ERROR:
          return Object.assign({}, state, {
              UsersDetailsVisible: false
          });
      case UsersActions.RESET_USER_DETAIL:
          return state;
      case UsersActions.DELETE_USERS_REQUEST:
          return Object.assign({}, state, {
              deletingUser: true
          });
      case UsersActions.DELETE_USERS_SUCCESS:
          return Object.assign({}, state, {
              deletingUser: false,
              UsersDetailsVisible: false
          });
      case UsersActions.DELETE_USERS_ERROR:
          return Object.assign({}, state, {
              deletingUser: false
          });
    default:
      return state;
  }
}

