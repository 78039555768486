import React, {Component} from "react";
import {Button, Input, Modal, Row, Col} from 'antd';


class FileModal extends Component {



    componentWillMount() {
        this.state = {
            inputValue: '',
            fileExtension: ''
        };
    }
    componentDidUpdate(prevProps, prevState, snapshot) {
        if(this.props.file.name !== prevProps.file.name) {
            if(this.props.file && this.props.file.name) {
                let [fileName, fileExtension] = this.props.file.name.split(/\.(?=[^\.]+$)/);
                this.setState({inputValue: fileName, fileExtension});
            } else {
                this.setState({inputValue: '', fileExtension: ''});
            }
        }
    }


    save = () => {
        this.props.onOk(this.state.inputValue+'.'+this.state.fileExtension, this.props.file.uid);
    };

    updateInputValue(e) {
        this.setState({
            inputValue: e.target.value
        });
    }


    render() {

        return (
            <Modal
                visible={this.props.visible}
                title="Datei Vorschau"
                onOk={this.save}
                onCancel={this.props.onCancel}
                okText={"Speichern"}
                cancelText={"Schließen"}
                width={"750px"}
            >
                <Row>
                    <Col span={12}>
                        {this.props.file.thumbUrl ? <img src={this.props.file.thumbUrl} width={"100%"} /> : <h4 style={{textAlign: "center"}}>Keine Vorschau verfügbar. Bitte klicken Sie auf Herunterladen</h4>}
                    </Col>
                    <Col span={12} style={{padding: '20px'}}>
                        <label>Dateiname:</label>
                        <Input placeholder="Dateiname" value={this.state.inputValue} onChange={(e) => this.updateInputValue(e)} />
                        <br/>
                        <br/>
                        <Button type={"default"} htmlType={"button"} block onClick={() => window.open(this.props.file.url, '_blank').focus()}>Herunterladen</Button>
                    </Col>
                </Row>
            </Modal>
        );
    }
}



export default FileModal;
