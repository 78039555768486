import React, {Component} from "react";
import {connect} from "react-redux";
import {bindActionCreators} from "redux";
import * as RechnungenActions from "../../redux/Rechnungen/actions";
import {
    Input,
    Button,
    DatePicker,
    Row,
    Col,
    Radio,
    Divider,
    Modal,
    Switch,
    Select,
    InputNumber,
    Checkbox,
    Table
} from 'antd';
import { Form } from '@ant-design/compatible';
import Box from '../../components/utility/box';
import LayoutWrapper from '../../components/utility/layoutWrapper';
import {history} from "../../redux/store";
import Header from '../../components/utility/pageHeader';
import ActionFooter from '../../components/actionFooter/actionFooter';
import { DndProvider, DragSource, DropTarget } from 'react-dnd';
import {HTML5Backend} from 'react-dnd-html5-backend';
import update from 'immutability-helper';
import './list.style.css';
import moment from "moment";
import PreviewBillModal from '../../components/PreviewBillModal';
import {CheckOutlined, DeleteOutlined, LeftOutlined, SearchOutlined, PlusOutlined} from "@ant-design/icons";

const { TextArea } = Input;
const FormItem = Form.Item;
const ButtonGroup = Button.Group;
const RadioGroup = Radio.Group;
const { Column, ColumnGroup } = Table;


const formItemLayout = {
    labelCol: {
        xs: { span: 24 },
        sm: { span: 8 },
    },
    wrapperCol: {
        xs: { span: 24 },
        sm: { span: 8 },
    },
};

const formItemLayoutHidden = {
    labelCol: {
        xs: { span: 0 },
    },
    wrapperCol: {
        xs: { span: 0 },
    },
};


/*const columns = [
    {
        title: 'Text',
        dataIndex: 'text',
        key: 'text',
        render: text => <FormItem>{this.props.form.getFieldDecorator("text[]", {
            rules: [{
                required: false,
            }], initialValue: this.props.RechnungDetail["text"],
        })(<Input type={"text"}/>)}
        </FormItem>,
    },
    {
        title: 'Anzahl',
        dataIndex: 'count',
        key: 'count',
        render: text => <a>{text}</a>,
    },
    {
        title: 'Nettobetrag',
        dataIndex: 'netto',
        key: 'netto',
        render: text => <a>{text}</a>,
    }
    ];
*/


let dragingIndex = -1;
let tableIndex = 1;

class BodyRow extends React.Component {
    render() {
        const { isOver, connectDragSource, connectDropTarget, moveRow, ...restProps } = this.props;
        const style = { ...restProps.style, cursor: 'move' };

        let { className } = restProps;
        if (isOver) {
            if (restProps.index > dragingIndex) {
                className += ' drop-over-downward';
            }
            if (restProps.index < dragingIndex) {
                className += ' drop-over-upward';
            }
        }

        return connectDragSource(
            connectDropTarget(<tr {...restProps} className={className} style={style} />),
        );
    }
}

const rowSource = {
    beginDrag(props) {
        dragingIndex = props.index;
        return {
            index: props.index,
        };
    },
};

const rowTarget = {
    drop(props, monitor) {
        const dragIndex = monitor.getItem().index;
        const hoverIndex = props.index;

        // Don't replace items with themselves
        if (dragIndex === hoverIndex) {
            return;
        }

        // Time to actually perform the action
        props.moveRow(dragIndex, hoverIndex);

        // Note: we're mutating the monitor item here!
        // Generally it's better to avoid mutations,
        // but it's good here for the sake of performance
        // to avoid expensive index searches.
        monitor.getItem().index = hoverIndex;
    },
};

const DragableBodyRow = DropTarget('row', rowTarget, (connect, monitor) => ({
    connectDropTarget: connect.dropTarget(),
    isOver: monitor.isOver(),
}))(
    DragSource('row', rowSource, connect => ({
        connectDragSource: connect.dragSource(),
    }))(BodyRow),
);



class NumericInput extends React.Component {
    onChange = (e) => {
        const { value } = e.target;
        const reg = /^-?(0|[1-9][0-9]*)(\.[0-9]*)?$/;
        if ((!isNaN(value) && reg.test(value)) || value === '' || value === '-') {
            this.props.onChange(value);
        }
    };


    render() {
        return (
            <Input
                {...this.props}
                onChange={this.onChange}
                onBlur={this.onBlur}
                maxLength="25"
            />
        );
    }
}



class RechnungenDetail extends Component {

    unblock;


    componentWillMount() {
        this.setState({ConfirmCancelVisible: false, tableData: [{key: 0, position: '', count: 0, netto: 0, sumNetto: 0}]});
    }



    componentWillUnmount() {
        if(typeof this.unblock !== 'undefined') {
           // this.props.actions.resetGruda();
            this.props.form.resetFields();
            this.unblock();
        }
    }

    componentWillUpdate(nextProps) {

        if(this.props.form.isFieldsTouched() && typeof this.unblock === 'undefined')
        {
            this.unblock = history.block(targetLocation => {
                if( window.confirm("Sie verlassen diese Seite ohne zu speichern.") ) {
                    return true;
                } else {
                    return false;
                }
            });
        } else if(typeof this.unblock !== 'undefined' && !this.props.form.isFieldsTouched()) {
            this.unblock();
        }

    }

    componentWillReceiveProps(nextProps) {
    }

    handleSubmit = (e) => {
        e.preventDefault();
        this.props.form.setFieldsValue({tableOrder: this.state.tableData} );
        this.props.form.validateFieldsAndScroll({scroll: {offsetTop: 150}}, (err, values) => {
            if (!err) {
                this.unblock();
                //console.log(values);
                this.props.actions.saveRechnung(values);
            }
        });
    };


    previewBill = () => {
        this.props.form.setFieldsValue({tableOrder: this.state.tableData} );
        this.props.form.validateFieldsAndScroll({scroll: {offsetTop: 150}}, (err, values) => {
            if (!err) {
                this.props.actions.previewRechnung(values);
            }
        });
    };


    addTableRow = () => {
        this.setState({tableData: [...this.state.tableData, {key: tableIndex, position: '', count: 0, netto: 0, sumNetto: 0}]});
        tableIndex++;
    };

    deleteTableRow(index) {
        this.setState({tableData: this.state.tableData.filter((tD, i) => i !== index)});
    };


    backAction = () => {
        history.goBack();
        window.scrollTo(0, 0);
    };

    cancelAction = () => {
        //this.props.actions.toggleEdit();
        this.setState({
            ConfirmCancelVisible: true
        });
    };



    cancelConfirmCancel = () => {
        this.setState({
            ConfirmCancelVisible: false,
        });
    };

    okConfirmCancel = () => {
        this.setState({
            ConfirmCancelVisible: false,
        });
        this.props.form.resetFields();
    };


    components = {
        body: {
            row: DragableBodyRow,
        },
    };

    moveRow = (dragIndex, hoverIndex) => {
        const { tableData } = this.state;
        const dragRow = tableData[dragIndex];

        this.setState(
            update(this.state, {
                tableData: {
                    $splice: [[dragIndex, 1], [hoverIndex, 0, dragRow]],
                },
            }),
        );
    };


    render() {

        const { getFieldDecorator } = this.props.form;

        return (
            <React.Fragment>
                <PreviewBillModal />
                <Modal
                    title="Bestätigen"
                    visible={this.state.ConfirmCancelVisible}
                    onOk={this.okConfirmCancel}
                    onCancel={this.cancelConfirmCancel}
                    okText="Ja"
                    cancelText="Nein"
                >
                    <p>Wollen Sie wirklich Abbrechen?<br/>Ihre Änderungen gehen dadurch verloren!</p>
                </Modal>

                <form onSubmit={this.handleSubmit} style={{width: '100%'}}>
                    <LayoutWrapper style={{position: 'relative'}}>
                        <Header>Rechnung erstellen</Header>

                        <Box>

                            <Divider orientation="left">Kundeninformationen</Divider>
                            <FormItem {...formItemLayout}
                                      label="Firma:">{getFieldDecorator("company", {
                                rules: [{
                                    required: false,
                                }], initialValue: this.props.RechnungDetail["company"],
                            })(
                                <Input type={"text"} />)}
                            </FormItem>
                            <FormItem {...formItemLayout}
                                required={true}
                                      label="Name:">{getFieldDecorator("lastname", {
                                rules: [{
                                    required: true,
                                    message: "Bitte geben Sie diese Information an."
                                }], initialValue: this.props.RechnungDetail["lastname"],
                            })(
                                <Input type={"text"} />)}
                            </FormItem>
                            <FormItem {...formItemLayout}
                                      required={true}
                                      label="Vorname:">{getFieldDecorator("firstname", {
                                rules: [{
                                    required: true,
                                    message: "Bitte geben Sie diese Information an."
                                }], initialValue: this.props.RechnungDetail["firstname"],
                            })(
                                <Input type={"text"} />)}
                            </FormItem>
                            <FormItem {...formItemLayout}
                                      required={true}
                                      label="Straße und Hausnummer:">{getFieldDecorator("address", {
                                rules: [{
                                    required: true,
                                    message: "Bitte geben Sie diese Information an."
                                }], initialValue: this.props.RechnungDetail["address"],
                            })(
                                <Input type={"text"} />)}
                            </FormItem>
                            <FormItem {...formItemLayout}
                                      required={true}
                                      label="PLZ und Ort:">{getFieldDecorator("plzort", {
                                rules: [{
                                    required: true,
                                    message: "Bitte geben Sie diese Information an."
                                }], initialValue: this.props.RechnungDetail["plzort"],
                            })(
                                <Input type={"text"} />)}
                            </FormItem>
                        </Box>



                        <Box>
                            <Divider orientation="left">Rechnungsinformationen</Divider>
                            <FormItem {...formItemLayout}
                                      required={true}
                                      label="Beschreibung:">{getFieldDecorator("description", {
                                rules: [{
                                    required: true,
                                    message: "Bitte geben Sie diese Information an."
                                }], initialValue: this.props.RechnungDetail["description"] || "Sehr geehrte/r Frau/Herr ,\n\nhiermit erhalten Sie unsere Rechnung für die von uns bereitgestellten Dienstleistungen.\nVielen Dank für Ihren Auftrag.",
                            })(<TextArea rows={8}/>)}
                            </FormItem>
                        </Box>

                        <Box>
                            <Divider orientation="left">Rechnungspositionen</Divider>
                            {/*<Table dataSource={this.state.tableData} columns={columns} />*/}
                            <DndProvider backend={HTML5Backend}>
                                <Table
                                    dataSource={this.state.tableData}
                                    pagination={false}
                                    components={this.components}
                                    onRow={(record, index) => ({
                                        index,
                                        moveRow: this.moveRow,
                                    })}
                                >
                                    <Column
                                        title="Position"
                                        dataIndex="position"
                                        key="position"
                                        render={(text,record, i) => (
                                            <Input type={"text"} onChange={(e) => { this.setState({
                                                tableData: update(this.state.tableData, {[i]: {position: {$set: e.target.value}}})
                                            }) /*this.state.tableData[i].position = e.target.value;*/ }}/>
                                        )}
                                    />
                                    <Column
                                        title="Anzahl"
                                        dataIndex="count"
                                        key="count"
                                        render={(text,record, i) => (
                                            <InputNumber step={1} decimalSeparator={","} style={{width: '100%'}} onChange={(val) => { this.setState({
                                                tableData: update(this.state.tableData, {[i]: {count: {$set: val}}})
                                            }) /*this.state.tableData[i].count = val;*/ }}/>
                                        )}
                                    />
                                    <Column
                                        title="Einzelpreis (netto)"
                                        dataIndex="netto"
                                        key="netto"
                                        render={(text,record, i) => (
                                            <InputNumber step={0.01} decimalSeparator={","} style={{width: '100%'}} onChange={(val) => { this.setState({
                                                tableData: update(this.state.tableData, {[i]: {netto: {$set: val}}})
                                            }) /*this.state.tableData[i].netto = val;*/}}/>
                                        )}
                                    />
                                    <Column
                                        title="Gesamt"
                                        dataIndex="SummeNetto"
                                        key="SummeNetto"
                                        render={(text,record, i) => (
                                            <a>{(record.count*record.netto).toFixed(2).toString().replace(".", ",")} &euro;</a>
                                        )}
                                    />
                                    <Column
                                        title="Aktion"
                                        dataIndex="action"
                                        key="action"
                                        render={(text,record, i) => (
                                            <a onClick={() => this.deleteTableRow(i)}><DeleteOutlined style={{fontSize: '20px'}}/></a>
                                        )}
                                    />


                                    {/*<Column
                                        title="Position"
                                        dataIndex="position"
                                        key="position"
                                        render={(text,record, i) => (
                                            <FormItem>{this.props.form.getFieldDecorator("position["+record.index+"]", {
                                                rules: [{
                                                    required: false,
                                                }], initialValue: text,
                                            })(<Input type={"text"}/>)}
                                            </FormItem>
                                        )}
                                    />
                                    <Column
                                        title="Anzahl"
                                        dataIndex="count"
                                        key="count"
                                        render={(text,record, i) => (
                                            <FormItem>{this.props.form.getFieldDecorator("count["+record.index+"]", {
                                                rules: [{
                                                    required: false,
                                                }], initialValue: text,
                                            })(<InputNumber step={1} decimalSeparator={","} style={{width: '100%'}}/>)}
                                            </FormItem>
                                        )}
                                    />
                                    <Column
                                        title="Nettobetrag"
                                        dataIndex="netto"
                                        key="netto"
                                        render={(text,record, i) => (
                                            <FormItem>{this.props.form.getFieldDecorator("netto["+record.index+"]", {
                                                rules: [{
                                                    required: false,
                                                }], initialValue: text,
                                            })(<InputNumber step={0.01} decimalSeparator={","} style={{width: '100%'}}/>)}
                                            </FormItem>
                                        )}
                                    />*/}
                                </Table>
                            </DndProvider>



                            <Button type={"default"} htmlType={"button"} onClick={this.addTableRow} style={{width: '100%'}} icon={<PlusOutlined/>}>Hinzufügen</Button>

                            <FormItem>{this.props.form.getFieldDecorator("tableOrder", {
                                rules: [{
                                    required: false,
                                }],
                            })(<Input type={"hidden"} /> )}
                            </FormItem>

                            {/*
                            <FormItem {...formItemLayout}
                                      label="Beschreibung:">{getFieldDecorator("description", {
                                rules: [{
                                    required: false,
                                }], initialValue: this.props.RechnungDetail["description"],
                            })(<TextArea/>)}
                            </FormItem>*/}
                        </Box>


                        <ActionFooter>
                            <div className="isoLeft">
                                <Button
                                    type="default"
                                    htmlType="button"
                                    onClick={this.backAction}
                                >
                                    <LeftOutlined />Zurück
                                </Button>
                            </div>

                            <div className="isoRight">
                                <ButtonGroup>
                                    <Button
                                        type="default"
                                        htmlType="button"
                                        disabled={!this.props.form.isFieldsTouched()}
                                        loading={this.props.savingRechnungenDetail}
                                        onClick={this.previewBill}
                                    >
                                        <SearchOutlined />Vorschau
                                    </Button>
                                    <Button
                                        type="primary"
                                        htmlType="submit"
                                        disabled={!this.props.form.isFieldsTouched()}
                                        loading={this.props.savingRechnungenDetail}
                                    >
                                        <CheckOutlined />Speichern
                                    </Button>
                                </ButtonGroup>
                            </div>
                        </ActionFooter>
                    </LayoutWrapper>
                </form>
            </React.Fragment>
        );
    }
}


function mapStateToProps(state) {
    const { RechnungenReducer } = state;
    const { RechnungDetail, savingRechnungenDetail } = RechnungenReducer;
    return {
        RechnungDetail,
        savingRechnungenDetail,
    };
}


function mapDispatchToProps(dispatch) {
    return{
        actions: bindActionCreators(RechnungenActions, dispatch)
    };
}



export default connect(mapStateToProps, mapDispatchToProps)(Form.create()(RechnungenDetail));
