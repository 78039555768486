import KleidungActions from "./actions";


const initState = {
    loadingKleidungList: true,
    KleidungList: [],

    KleidungModalVisible: false,
    KleidungModalID: 0,
    KleidungModalDetails: {}
};

/*

    HausverbotDetail: {},

    HausverboteDetailID: null,
    HausverboteModalVisible: false,
    HausverboteModalSaving: false,
    HausverboteModalLoading: false,
    deletingBan: false,

 */

export default function KleidungReducer(state = initState, action) {
    switch (action.type) {
        case KleidungActions.LOAD_KLEIDUNG_REQUEST:
            return Object.assign({}, state, {
                loadingKleidungList: true
            });
        case KleidungActions.LOAD_KLEIDUNG_SUCCESS:
            return Object.assign({}, state, {
                KleidungList: action.response.data.clothes,
                loadingKleidungList: false
            });
        case KleidungActions.LOAD_KLEIDUNG_ERROR:
            return Object.assign({}, state, {
                loadingKleidungList: false
            });

        case KleidungActions.SHOW_KLEIDUNG_MODAL:
            return Object.assign({}, state, {
                KleidungModalVisible: action.show,
                KleidungModalID: action.GroupID,
                KleidungModalDetails: action.GroupID ? state.KleidungList.filter(kl => kl.GroupID === action.GroupID)[0] : {}
            });

        case KleidungActions.SAVE_KLEIDUNG_REQUEST:
            return Object.assign({}, state, {
                loadingKleidungList: true
            });
        case KleidungActions.SAVE_KLEIDUNG_SUCCESS:
            return Object.assign({}, state, {
                loadingKleidungList: false,
                KleidungModalVisible: false,
                KleidungModalID: 0,
                KleidungModalDetails: {}
            });
        case KleidungActions.SAVE_KLEIDUNG_ERROR:
            return Object.assign({}, state, {
                loadingKleidungList: false
            });

        default:
            return state;
    }
}

