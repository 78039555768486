import React, {Component} from "react";
import {connect} from "react-redux";
import {bindActionCreators} from "redux";
import LayoutWrapper from '../components/utility/layoutWrapper';
import {Button, Icon} from 'antd';
import Box from '../components/utility/box';
import fileDownload from 'js-file-download';
import {LeftOutlined} from "@ant-design/icons";
//import download from 'downloadjs';
let BASE_URL = process.env.REACT_APP_API_URL;
let API_VERSION = process.env.REACT_APP_API_VERSION;


class FileDownload extends Component {

    token = localStorage.getItem('id_token') || null;

    downloadFile(type, FileID) {
        let url = BASE_URL+'api/' + API_VERSION + "/"+type+"/Files/"+FileID;

        if(type === "doku") {
            url = BASE_URL+'api/' + API_VERSION + "/"+type+"/"+FileID;
        }

        if(type === "bills") {
            url = BASE_URL+'api/' + API_VERSION + "/"+type+"/" + "/pdf/" + FileID;
        }

        return fetch(url, {
            method: 'GET',
            headers: {
                'Authorization': `Bearer ${this.token}`
            }
        }).then(function(resp) {
            return resp.blob();
        }).then(function(blob) {
            //download(blob, "test");
            //var file = new Blob([blob], {type: 'images/png'});
                //--var file = blob;
                //--var fileURL = URL.createObjectURL(file);
                //--window.location.href= fileURL;
            //window.open(fileURL);

            if(type === "doku") {
                fileDownload(blob, FileID+'.pdf');
            } else {
                let fileURL = URL.createObjectURL(blob);
                //window.open(fileURL);
                let fileLink = document.createElement('a');
                fileLink.href = fileURL;
                document.body.appendChild(fileLink);
                fileLink.click();
                fileLink.remove();
            }





        });
    }


    componentWillMount() {

        this.downloadFile(this.props.match.params.type, this.props.match.params.FileID);

        // window.top.close();

    }



    render() {

        return (
            <LayoutWrapper style={{position: 'relative'}}>
                <Box>
                    <div style={{textAlign: 'center'}}>

                        <h3>Download gestartet...</h3>
                        <p>Ihr Download wurde gestartet. Sollte Ihr Download nicht automatisch starten, klicken sie bitte <a href={() => this.downloadFile(this.props.match.params.type, this.props.match.params.FileID)}>hier</a></p>
                        <Button onClick={() => window.close()} icon={<LeftOutlined />}>Zurück</Button>
                    </div>

                </Box>
            </LayoutWrapper>
        );
    }
}



export default FileDownload;
