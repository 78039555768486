import {CALL_API} from "../../helpers/middleware";
var FileSaver = require('file-saver');

const HausverboteActions = {

    LOAD_HAUSVERBOTE_LIST_REQUEST : 'LOAD_HAUSVERBOTE_LIST_REQUEST',
    LOAD_HAUSVERBOTE_LIST_SUCCESS : 'LOAD_HAUSVERBOTE_LIST_SUCCESS',
    LOAD_HAUSVERBOTE_LIST_ERROR : 'LOAD_HAUSVERBOTE_LIST_ERROR',
    SET_HAUSVERBOTE_TABLE_PAGE: 'SET_HAUSVERBOTE_TABLE_PAGE',
    SET_HAUSVERBOTE_TABLE_INFO: 'SET_HAUSVERBOTE_TABLE_INFO',
    SET_SEARCH_TEXT: 'SET_SEARCH_TEXT',

    SHOW_HAUSVERBOTE_MODAL: 'SHOW_HAUSVERBOTE_MODAL',
    SAVE_HAUSVERBOTE_REQUEST: 'SAVE_HAUSVERBOTE_REQUEST',
    SAVE_HAUSVERBOTE_SUCCESS: 'SAVE_HAUSVERBOTE_SUCCESS',
    SAVE_HAUSVERBOTE_ERROR: 'SAVE_HAUSVERBOTE_ERROR',

    LOAD_HAUSVERBOTE_DETAIL_REQUEST: 'LOAD_HAUSVERBOTE_DETAIL_REQUEST',
    LOAD_HAUSVERBOTE_DETAIL_SUCCESS: 'LOAD_HAUSVERBOTE_DETAIL_SUCCESS',
    LOAD_HAUSVERBOTE_DETAIL_ERROR: 'LOAD_HAUSVERBOTE_DETAIL_ERROR',

    DELETE_HAUSVERBOTE_REQUEST: 'DELETE_HAUSVERBOTE_REQUEST',
    DELETE_HAUSVERBOTE_SUCCESS: 'DELETE_HAUSVERBOTE_SUCCESS',
    DELETE_HAUSVERBOTE_ERROR: 'DELETE_HAUSVERBOTE_ERROR',
};

export default HausverboteActions;


export function LoadHausverboteList(params = {}) {
    return {
        [CALL_API]: {
            reqType: 'POST',
            endpoint: 'ban',
            postData: {
                results: 10,
                ...params,
            },
            types: [HausverboteActions.LOAD_HAUSVERBOTE_LIST_REQUEST, HausverboteActions.LOAD_HAUSVERBOTE_LIST_SUCCESS, HausverboteActions.LOAD_HAUSVERBOTE_LIST_ERROR]
        }
    };
}

export function saveHausverboteDetail(values, BanID) {
    let url = "ban";
    let type = "POST";
    if(BanID) {
        url += "/"+BanID;
        type = "PUT";
    } else {
        url += "/new";
    }

    return {
        [CALL_API]: {
            reqType: type,
            endpoint: url,
            postData: values,
            types: [HausverboteActions.SAVE_HAUSVERBOTE_REQUEST, HausverboteActions.SAVE_HAUSVERBOTE_SUCCESS, HausverboteActions.SAVE_HAUSVERBOTE_ERROR]
        }
    };
}

export function LoadHausverboteDetail(BanID) {
    return {
        [CALL_API]: {
            reqType: 'GET',
            endpoint: 'ban/'+BanID,
            types: [HausverboteActions.LOAD_HAUSVERBOTE_DETAIL_REQUEST, HausverboteActions.LOAD_HAUSVERBOTE_DETAIL_SUCCESS, HausverboteActions.LOAD_HAUSVERBOTE_DETAIL_ERROR]
        }
    };
}

export function deleteHausverbot(BanID) {
    return {
        [CALL_API]: {
            reqType: 'DELETE',
            endpoint: 'ban/'+BanID,
            types: [HausverboteActions.DELETE_HAUSVERBOTE_REQUEST, HausverboteActions.DELETE_HAUSVERBOTE_SUCCESS, HausverboteActions.DELETE_HAUSVERBOTE_ERROR]
        }
    };
}


export function showHausverboteModal(show, BanID) {
    return {
        type: HausverboteActions.SHOW_HAUSVERBOTE_MODAL,
        show,
        BanID
    };
}

export function setHausverboteTablePage(pager) {
    return {
        type: HausverboteActions.SET_HAUSVERBOTE_TABLE_PAGE,
        pager
    };
};

export function setHausverboteTableInfo(infos) {
    return {
        type: HausverboteActions.SET_HAUSVERBOTE_TABLE_INFO,
        infos
    };
};

export function setSearchText(search) {
    return {
        type: HausverboteActions.SET_SEARCH_TEXT,
        search
    };
};




