import React, {Component} from "react";
import {connect} from "react-redux";
import {bindActionCreators} from "redux";
import * as CheckInActions from "../redux/CheckIn/actions";
import { Button, Modal, Switch } from 'antd';
import '../containers/CheckIn/list.style.css';
import SignatureCanvas from 'react-signature-canvas';
import {CloseOutlined, CheckOutlined, DeleteOutlined} from '@ant-design/icons';


class SignAgreementLustra extends Component {

    sigCanvas = {};

    //state = { signRental: false, signImageRights: false, error: false };
    state = { signRental: false, error: false };

    componentWillMount() {
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if(prevProps.signAgreementLustraVisible && !this.props.signAgreementLustraVisible) {
            this.sigCanvas.clear();
            //this.setState({signRental: false, signImageRights: false, error: false });
            this.setState({signRental: false, error: false });
        }
    }

    handleSubmit = (e) => {
        if(this.state.signRental) {
            this.setState({error: false});
            //signImageRights: this.state.signImageRights,
            const data = {
                signRental : true,
                sign: this.sigCanvas.toDataURL('image/png')
            };
            this.props.actions.signAgreementLustra(this.props.actAgreementID, data);
        } else {
            this.setState({error: 'Mietvertrag muss unterschrieben werden!'});
        }
    };
    handleCancel = () => {
        this.props.actions.showSignAgreementLustra(false);
    };


    render() {
        return (
            <Modal
                title="Vertrag unterschreiben (Lustra)"
                visible={this.props.signAgreementLustraVisible}
                onOk={this.handleSubmit}
                onCancel={this.handleCancel}
                okText={"Vertrag unterschreiben"}
                cancelText={"Abbrechen"}
                width={"750px"}
                confirmLoading={this.props.signAgreementLustraSaving}
            >

                <div className={"signAgreement-selection"}>
                    <label>Mietvertrag unterschreiben</label>
                    <Switch
                        checkedChildren={<CheckOutlined />}
                        unCheckedChildren={<CloseOutlined />}
                        checked={this.state.signRental}
                        onClick={() => this.setState({signRental: !this.state.signRental})}
                    />
                </div>

                {/*<div className={"signAgreement-selection"}>
                    <label>Verienbarung Bildrechte unterschreiben</label>
                    <Switch
                        checkedChildren={<CheckOutlined />}
                        unCheckedChildren={<CloseOutlined />}
                        checked={this.state.signImageRights}
                        onClick={() => this.setState({signImageRights: !this.state.signImageRights})}
                    />
                </div>*/}
                <br/>
                <p className={"signAgreement-error"}>{this.state.error}</p>
                <Button icon={<DeleteOutlined />} className={"signResetBtn"} onClick={() => this.sigCanvas.clear()}>Löschen</Button>
                <div className={"signContainer"}>
                    <SignatureCanvas ref={(ref) => { this.sigCanvas = ref }} canvasProps={{width: 700, height: 300, className: 'sigCanvas'}} />
                </div>
            </Modal>
        );
    }
}


function mapStateToProps(state) {
    const { CheckInReducer } = state;
    const { signAgreementLustraVisible, actAgreementID, signAgreementLustraSaving } = CheckInReducer;
    return {
        signAgreementLustraVisible,
        actAgreementID,
        signAgreementLustraSaving
    };
}


function mapDispatchToProps(dispatch) {
    return{
        actions: bindActionCreators(CheckInActions, dispatch)
    };
}



export default connect(mapStateToProps, mapDispatchToProps)(SignAgreementLustra);
