import React from 'react';
import { Route, Redirect } from 'react-router-dom';
import { ConnectedRouter } from 'react-router-redux';
import { connect } from 'react-redux';
import SignIn from './containers/SignIn/signin';

import App from './containers/App/App';
//import asyncComponent from './helpers/AsyncFunc';

/*
const RestrictedRoute = ({ isLoggedIn, ...rest }) => {

    return isLoggedIn ?
        <Route
            {...rest}
        />
        :
         <Route
            render={props => <Redirect
                  to={{
                    pathname: '/signin',
                    state: { from: props.location },
                  }}
                />}
          />

};*/

const RestrictedRoute = ({ component: Component, isLoggedIn, ...rest }) => (
    <Route
        {...rest}
        render={props => isLoggedIn
            ? <Component {...props} />
            : <Redirect
                to={{
                    pathname: '/signin',
                    state: { from: props.location },
                }}
            />}
    />);

const PublicRoutes = ({ history, isLoggedIn }) => {
  return (
    <ConnectedRouter history={history}>
      <div>
        <Route
          exact
          path={'/'}
          component={SignIn}
        />
        <Route
          exact
          path={'/signin'}
          component={SignIn}
        />
        <RestrictedRoute
          path="/app"
          component={App}
          isLoggedIn={isLoggedIn}
        />
      </div>
    </ConnectedRouter>
  );
};

function mapStateToProps(state) {
    const { Auth } = state;
    const { idToken } = Auth;


    return {
        isLoggedIn: idToken
    }
}

export default connect(mapStateToProps)(PublicRoutes);
