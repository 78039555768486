import React, {Component} from "react";
import {Select, Modal, Input, Checkbox, Button} from 'antd';
import { Form } from '@ant-design/compatible';
import {bindActionCreators} from "redux";
import * as AufgabenActions from "../../redux/Aufgaben/actions";
import {connect} from "react-redux";
import moment from "moment";
import { MinusCircleOutlined, PlusOutlined } from '@ant-design/icons'

const { TextArea } = Input;
const FormItem = Form.Item;
const Option = Select.Option;
const confirm = Modal.confirm;

let uuid = 0;

const formItemLayout = {
    labelCol: {
        xs: { span: 20 },
        sm: { span: 6 },
    },
    wrapperCol: {
        xs: { span: 20 },
        sm: { span: 13 },
    },
};

const formItemLayoutWithOutLabel = {
    wrapperCol: {
        xs: { span: 24, offset: 0 },
        sm: { span: 24, offset: 0 },
    },
};


class AufgabenDetailModal extends Component {


    componentWillMount() {
    }

    handleSubmit = (e) => {
        e.preventDefault();
        this.props.form.validateFieldsAndScroll((err, values) => {
            if (!err) {
                // filter out null values
                const filteredValues = {...values, tasks: [...new Set((values.tasks || []).filter(task => task))] };
                this.props.actions.SaveAufgaben(filteredValues, this.props.AufgabenModalID);
            }
        });
    };


    componentWillReceiveProps(nextProps) {
        if(this.props.AufgabenModalVisible && !nextProps.AufgabenModalVisible) {
            this.props.form.resetFields();
            uuid = 0;
        }
    }


    removeAufgabe = (k) => {
        const { form } = this.props;
        const keys = form.getFieldValue('keys');
        form.setFieldsValue({
            keys: keys.filter(key => key !== k),
        });
    };

    addAufgabe = () => {
        const { form } = this.props;
        const keys = form.getFieldValue('keys');
        const nextKeys = keys.concat(uuid);
        uuid++;

        form.setFieldsValue({
            keys: nextKeys,
        });
    };



    render() {
        const { getFieldDecorator } = this.props.form;

        getFieldDecorator('keys', { initialValue: this.props.AufgabenModalDetails.tasks ? this.props.AufgabenModalDetails.tasks.map((c, i) => {return i;} ) : []} );
        const keys = this.props.form.getFieldValue('keys');

        if(this.props.AufgabenModalDetails.tasks && uuid < this.props.AufgabenModalDetails.tasks.length) { uuid = this.props.AufgabenModalDetails.tasks.length; }

        let formItems = keys.map((k, index) => {
            return (


                        <FormItem
                            label={''}
                            required={false}
                            key={k}
                        >
                            {getFieldDecorator(`tasks[${k}]`, {
                                validateTrigger: ['onChange', 'onBlur'],
                                initialValue: this.props.AufgabenModalDetails.tasks ? this.props.AufgabenModalDetails.tasks[k] ? this.props.AufgabenModalDetails.tasks[k] : "" : "",
                                rules: [{
                                    required: true,
                                    whitespace: true,
                                    message: "Bitte geben Sie eine Aufgaben ein, oder entfernen Sie dieses Feld",
                                }],
                            })(
                                <Input placeholder="Aufgabe eingeben" style={{ width: '77%', marginRight: 8 }} />
                            )}
                            { (this.props.form.getFieldValue(`tasks[${k}]`).length === 0 || this.props.form.getFieldValue(`tasks[${k}]`)) ?
                                <MinusCircleOutlined className="dynamic-delete-button" onClick={() => this.removeAufgabe(k)} /> : ''
                            }
                        </FormItem>
            );
        });


        return (
                <Modal
                    title={`Aufgabe ${this.props.AufgabenModalID ? 'bearbeiten' : 'anlegen'}`}
                    visible={this.props.AufgabenModalVisible}
                    onOk={this.handleSubmit}
                    onCancel={() => this.props.actions.showAufgabenModal(false, null)}
                    cancelText={"Abbrechen"}
                    okText={"Speichern"}
                    width={"750px"}
                    footer={[
                        <Button key="back" onClick={() => this.props.actions.showAufgabenModal(false, null)}>Abbrechen</Button>,
                        <Button key="submit" type="primary" loading={this.props.loadingAufgabenList} onClick={this.handleSubmit}>Speichern</Button>
                    ]}
                >



                    <form onSubmit={this.handleSubmit} style={{width: '100%'}}>

                        <FormItem
                            {...formItemLayout}
                            label={"Arbeitsposition"}
                            required={false}
                            key={'Arbeitsposition'}
                        >
                            {getFieldDecorator('Group', {
                                initialValue: this.props.AufgabenModalDetails.Group,
                                rules: [{
                                    required: true,
                                    whitespace: true,
                                    message: "Bitte geben Sie diese Information an."
                                }],
                            })(
                                <Input type={"text"} disabled={true}/>
                            )}
                        </FormItem>


                        <FormItem {...formItemLayout} label={"Aufgaben"}>
                        </FormItem>
                        {formItems}
                        <FormItem {...formItemLayoutWithOutLabel}>
                            <Button type="dashed" onClick={this.addAufgabe} style={{ width: '77%' }}>
                                <PlusOutlined /> Aufgabe hinzufügen
                            </Button>
                        </FormItem>


                    </form>


                </Modal>


        );
    }
}



function mapStateToProps(state) {
    const { AufgabenReducer } = state;
    const { AufgabenModalVisible, AufgabenModalID, AufgabenModalDetails, loadingAufgabenList } = AufgabenReducer;
    return {
        AufgabenModalVisible,
        AufgabenModalID,
        AufgabenModalDetails,
        loadingAufgabenList
    };
}


function mapDispatchToProps(dispatch) {
    return{
        actions: bindActionCreators(AufgabenActions, dispatch)
    };
}



export default connect(mapStateToProps, mapDispatchToProps)(Form.create()(AufgabenDetailModal));


//export default Form.create()(UsersDetailModal);