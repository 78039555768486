import React, { Component } from 'react';
import { Link, Redirect } from 'react-router-dom';
import { connect } from 'react-redux';
import { Input, Button } from 'antd';
import {bindActionCreators} from 'redux';
import * as authAction from '../../redux/auth/actions';
import * as appAction from '../../redux/app/actions';
import * as todoAction from '../../redux/Aufgaben/actions';
import * as todoRezeptionAction from '../../redux/TodoRezeption/actions';
import SignInStyleWrapper from './signin.style';
import {CheckCircleTwoTone, GiftTwoTone, LockOutlined, UserOutlined, CheckCircleOutlined} from "@ant-design/icons";
import notification from '../../components/notification';
import moment from "moment";
import "moment/locale/de";

class SignIn extends Component {

    componentWillMount() {
        moment.locale("de");
    }

    componentWillReceiveProps(nextProps) {

    }


    handleLogin = (event) => {
        event.preventDefault();
        const userInfo = {
            username: document.getElementById('inputUserName').value || '',
            password: document.getElementById('inputPassword').value || ''
        };

        this.props.actions.login(userInfo).then((loginRes) => {
            this.props.appActions.loadBirthdays().then((res) => {
                const birthdays = res?.response?.data;
                (birthdays?.users || []).map(userBirthdays => {
                    notification("info", "Geburtstag", `${userBirthdays.Vorname} ${userBirthdays.Nachname} hat ${moment.utc(userBirthdays.Geburtstag).isSame(new Date(), "day") ? "heute" : moment.utc(userBirthdays.Geburtstag).from(moment().startOf('day'))} Geburtstag!`, 0, {icon: <GiftTwoTone />});
                });

                (birthdays?.persons || []).map(personsBirthdays => {
                    notification("info", "Geburtstag", `${personsBirthdays.firstName} ${personsBirthdays.lastName} hat ${moment.utc(personsBirthdays.birthday).isSame(new Date(), "day") ? "heute" : moment.utc(personsBirthdays.birthday).from(moment().startOf('day'))} Geburtstag!`, 0, {icon: <GiftTwoTone />});
                });
            });

            this.props.todoActions.LoadMyAufgaben().then((res) => {
                const tasks = res?.response?.data?.tasks || [];

                if(Array.isArray(tasks) && tasks.length > 0) {
                    notification(
                        "info",
                        "Aufgabenbereich",
                        <ul>{tasks.map(task => <li>{task}</li>)}</ul>,
                        0,
                        {icon: <CheckCircleOutlined />
                        });
                }
            });

            if(loginRes.response.data.group === 2) {
                // TODO: Maybe we make extra endpoint wich returns current todos for logged in user-group and don't need to check group here
                this.props.todoRezeptionActions.LoadMyTodoList().then((res) => {
                    const todos = (res?.response?.data?.todos || []).filter(todo => todo.Done === 0).map(todo => todo.Todo);

                    if(Array.isArray(todos) && todos.length > 0) {
                        notification(
                            "info",
                            "Aufgaben",
                            <ul>{todos.map(todo => <li>{todo}</li>)}</ul>,
                            0,
                            {icon: <CheckCircleTwoTone />
                            });
                    }
                });
            }

        });

    };
    render() {
        const from = { pathname: '/app/RoomPlan' };

        if (this.props.idToken) {
            return <Redirect to={from} />;
        }
        return (
            <SignInStyleWrapper className="isoSignInPage">
                <div className="isoLoginContentWrapper">
                    <div className="isoLoginContent">
                        <div className="isoLogoWrapper">
                            <Link to="/app">
                                Anmelden
                            </Link>
                            <p>Lustra - das Erlebnishotel</p>
                        </div>

                        <div className="isoSignInForm">
                            <form onSubmit={this.handleLogin}>
                            <div className="isoInputWrapper">
                                <Input
                                    prefix={<UserOutlined style={{ color: 'rgba(0,0,0,.25)' }} />}
                                    id="inputUserName"
                                    size="large"
                                    placeholder="Benutzername"
                                    defaultValue=""
                                />
                            </div>

                            <div className="isoInputWrapper">
                                <Input
                                    prefix={<LockOutlined style={{ color: 'rgba(0,0,0,.25)' }} />}
                                    id="inputPassword"
                                    size="large"
                                    type="password"
                                    placeholder="Passwort"
                                    defaultValue=""
                                />
                            </div>

                            <div className="isoInputWrapper">
                                <Button type="primary" htmlType="submit" >
                                    Anmelden
                                </Button>
                            </div><p>
                            </p>
                        </form>
                        </div>
                    </div>
                </div>
            </SignInStyleWrapper>
        );
    }
}

function mapStateToProps(state) {
    const { Auth } = state;
    const { idToken } = Auth;


    return {
        idToken
    }
}

function mapDispatchToProps(dispatch) {
    return {
        actions: bindActionCreators(authAction, dispatch),
        appActions: bindActionCreators(appAction, dispatch),
        todoActions: bindActionCreators(todoAction, dispatch),
        todoRezeptionActions: bindActionCreators(todoRezeptionAction, dispatch)
    };
}

/*export default connect(state => ({
    isLoggedIn: state.Auth.get('idToken') !== null ? true : false
}), mapDispatchToProps)(SignIn);*/

export default connect(mapStateToProps, mapDispatchToProps)(SignIn);