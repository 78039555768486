import actions from './actions';
var jwtDecode = require('jwt-decode');

const initState =
    {
        idToken: localStorage.getItem('id_token') || false,
        isAdmin: (localStorage.getItem('id_token') && jwtDecode(localStorage.getItem('id_token')).admin),
        GroupID: (localStorage.getItem('id_token') && jwtDecode(localStorage.getItem('id_token')).group),
        serverprint: localStorage.getItem('serverprint') || false,
        blank: localStorage.getItem('blank') || false,
        blankmonth: localStorage.getItem('blankmonth') || false
    };

export default function authReducer(state = initState, action) {
    switch (action.type) {
        case actions.LOGIN_SUCCESS:
            localStorage.setItem('id_token', action.response.data.token);
            //return state.set('idToken', action.response.token);
            return Object.assign({}, state, {
                idToken: action.response.data.token,
                isAdmin: jwtDecode(localStorage.getItem('id_token')).admin,
                GroupID: jwtDecode(localStorage.getItem('id_token')).group
            });
        case actions.LOGOUT:
            return Object.assign({}, state, {
                idToken: false,
                isAdmin: false,
                GroupID: false
            });
        default:
            return state;
    }
}
