import LustmolcheActions from "./actions";


const initState = {
    loadingLustmolcheList: true,
    LustmolcheList: [],
    paginationLustmolche: {},
    infosTableLustmolche: {},
    search: "",
    Lustmolche: [],
    actLustmolcheID: 0,
    LustmolcheDetail: {},
    LustmolcheDetailVisible: false,
    loadingLustmolcheDetail: false,
    savingLustmolcheDetail: false,
    lustmolcheSyncLoading: false,
    previewFileModalVisible: false,
    deletingLustmolche: false,
    previewFileModalFile: {}
};

export default function LustmolcheReducer(state = initState, action) {
  switch (action.type) {
      case LustmolcheActions.LOAD_LUSTMOLCHE_TABLE_REQUEST:
        return Object.assign({}, state, {
            loadingLustmolcheList: true
        });
      case LustmolcheActions.LOAD_LUSTMOLCHE_TABLE_SUCCESS:
          const pagination = { ...state.paginationLustmolche };
          // Read total count from server
          // pagination.total = data.totalCount;
          pagination.total = action.response.data.Count;

          return Object.assign({}, state, {
              LustmolcheList: action.response.data.Lustmolche,
              loadingLustmolcheList: false,
              paginationLustmolche: pagination
          });
      case LustmolcheActions.LOAD_LUSTMOLCHE_TABLE_ERROR:
          return Object.assign({}, state, {
              loadingLustmolcheList: false
          });
      case LustmolcheActions.SET_LUSTMOLCHE_TABLE_PAGE:
          return Object.assign({}, state, {
              paginationLustmolche: action.pager
          });
      case LustmolcheActions.SET_LUSTMOLCHE_TABLE_INFO:
          return Object.assign({}, state, {
              infosTableLustmolche: action.infos
          });
      case LustmolcheActions.SET_SEARCH_TEXT:
          return Object.assign({}, state, {
              search: action.search,
              paginationLustmolche: {current: 1},
              infosTableLustmolche: {}
          });

      case LustmolcheActions.LOAD_LUSTMOLCHE_DETAIL_REQUEST:
          return Object.assign({}, state, {
              loadingLustmolcheDetail: true
          });
      case LustmolcheActions.LOAD_LUSTMOLCHE_DETAIL_SUCCESS:
          return Object.assign({}, state, {
              LustmolcheDetail: action.response.data.lustmolche,
              loadingLustmolcheDetail: false
          });
      case LustmolcheActions.LOAD_LUSTMOLCHE_DETAIL_ERROR:
          return Object.assign({}, state, {
              loadingLustmolcheDetail: false
          });

      case LustmolcheActions.SAVE_LUSTMOLCHE_DETAIL_REQUEST:
          return Object.assign({}, state, {
              savingLustmolcheDetail: true,
          });
      case LustmolcheActions.SAVE_LUSTMOLCHE_DETAIL_SUCCESS:
          return Object.assign({}, state, {
              savingLustmolcheDetail: false,
              LustmolcheDetailVisible: false,
          });
      case LustmolcheActions.SAVE_LUSTMOLCHE_DETAIL_ERROR:
          return Object.assign({}, state, {
              savingLustmolcheDetail: false,
          });

      case LustmolcheActions.LOAD_LUSTMOLCHE_REQUEST:
          return state;
      case LustmolcheActions.LOAD_LUSTMOLCHE_SUCCESS:
          return Object.assign({}, state, {
              Lustmolche: action.response.data.lustmolche
          });
      case LustmolcheActions.LOAD_LUSTMOLCHE_ERROR:
          return state;
      case LustmolcheActions.SAVE_LUSTMOLCHE_FILE_NAME_SUCCESS:
          let newLustmolcheDetail = state.LustmolcheDetail;
          newLustmolcheDetail.images = newLustmolcheDetail.images.map(img => { if(img.uid === parseInt(action.response.data.fileId)) {img.name = action.response.data.filename;}  return img;});
          return Object.assign({}, state, {
              LustmolcheDetail: newLustmolcheDetail,
              previewFileModalVisible: false,
              previewFileModalFile: {}
          });
      case LustmolcheActions.SHOW_PREVIEW_FILE_MODAL:
          return Object.assign({}, state, {
              previewFileModalVisible: action.show,
              previewFileModalFile: action.file
          });

      case LustmolcheActions.SHOW_LUSTMOLCHE_DETAIL:
          return Object.assign({}, state, {
              LustmolcheDetailVisible: action.show,
              actLustmolcheID: action.id,
          });

      case LustmolcheActions.CLEAR_LUSTMOLCHE_DETAIL:
          return Object.assign({}, state, {
              LustmolcheDetail: {},
              actLustmolcheID: 0
          });

      case LustmolcheActions.DELETE_LUSTMOLCHE_REQUEST:
          return Object.assign({}, state, {
              deletingLustmolche: true
          });
      case LustmolcheActions.DELETE_LUSTMOLCHE_SUCCESS:
          return Object.assign({}, state, {
              deletingLustmolche: false,
              LustmolcheDetailVisible: false
          });
      case LustmolcheActions.DELETE_LUSTMOLCHE_ERROR:
          return Object.assign({}, state, {
              deletingLustmolche: false
          });

    default:
      return state;
  }
}

