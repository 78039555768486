import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Layout } from 'antd';
import * as appActions from '../../redux/app/actions';
import TopbarUser from './topbarUser';
import TopbarWrapper from './topbar.style';
import theme from '../../config/themes';
import {bindActionCreators} from "redux";

const { Header } = Layout;

class Topbar extends Component {
  render() {
    const { toggleCollapsed } = this.props.actions;
    const collapsed = this.props.collapsed && !this.props.openDrawer;
    const styling = {
      background: theme.backgroundColor,
      position: 'fixed',
      width: '100%',
      height: 60
    };
    return (
      <TopbarWrapper>
        <Header
          style={styling}
          className={
            collapsed ? 'isomorphicTopbar collapsed' : 'isomorphicTopbar'
          }
        >
          <div className="isoLeft">
            <button
              className={
                collapsed ? 'triggerBtn menuCollapsed' : 'triggerBtn menuOpen'
              }
              style={{ color: theme.textColor }}
              onClick={toggleCollapsed}
            />
          </div>

          <ul className="isoRight">
            <li
              onClick={() => this.setState({ selectedItem: 'user' })}
              className="isoUser"
            >
              <TopbarUser clicked={() => {this.props.actions.changeCurrent([])}} />
            </li>
          </ul>
        </Header>
      </TopbarWrapper>
    );
  }
}

function mapStateToProps(state) {
    const { App} = state;
    const {collapsed, openDrawer } = App;

    return {
        collapsed,
        openDrawer
    };
}


function mapDispatchToProps(dispatch) {
    return{
        actions: bindActionCreators(appActions, dispatch)
    };
}


export default connect(mapStateToProps, mapDispatchToProps)(Topbar);
