import React, {Component} from "react";
import {connect} from "react-redux";
import {bindActionCreators} from "redux";
import * as PersonenActions from "../../redux/Personen/actions";
import {
    Input,
    Button,
    DatePicker,
    Radio,
    Divider,
    Modal,
    Select,
    InputNumber,
    Upload, Spin
} from 'antd';
import { Form } from '@ant-design/compatible';
import './list.style.css';
import moment from "moment";
import CheckIn from '../CheckIn';
import FileModal from '../../components/FileModal';
import {InboxOutlined, UserOutlined} from "@ant-design/icons";

let BASE_URL = process.env.REACT_APP_API_URL;
let API_VERSION = process.env.REACT_APP_API_VERSION;

const { TextArea } = Input;
const Option = Select.Option;
const FormItem = Form.Item;
const confirm = Modal.confirm;


const formItemLayout = {
    labelCol: {
        xs: { span: 24 },
        sm: { span: 8 },
    },
    wrapperCol: {
        xs: { span: 24 },
        sm: { span: 8 },
    },
};




class PersonenDetail extends Component {

    constructor(props) {
        super(props);
    }

    componentWillMount() {
        this.setState({ fileName: '', fileExtension: ''});
    }


    normMultiFile = (e) => {

        if (Array.isArray(e)) {
            return e;
        }
        e.file.name = this.state.fileName+"."+this.state.fileExtension;
        return e && e.fileList;
    };





    componentWillReceiveProps(nextProps) {
        if(this.props.PersonDetailVisible && !nextProps.PersonDetailVisible) {
            this.props.actions.clearPersonsDetail();
            this.props.form.resetFields();
            this.props.afterClose && this.props.afterClose();
        } else if(!this.props.PersonDetailVisible && nextProps.PersonDetailVisible) {

            if(nextProps.actPersonID) {

                this.props.actions.loadPersonsDetail(nextProps.actPersonID);
            }
        }
    }

    handleSubmit = (e) => {
        e.preventDefault();
        this.props.form.validateFieldsAndScroll({scroll: {offsetTop: 150}}, (err, values) => {
            if (!err) {
                this.props.actions.savePersonsDetail(this.props.actPersonID, values);
            }
        });
    };



    handleCancel = () => {
        this.props.actions.showPersonsDetail(false);
    };




    previewFile(file) {
        this.props.actions.showPreviewFileModal(true, file);
    }
    previewFileModalOK = (value, FileID) => {
        this.props.actions.updateFileName(FileID, value);
    };
    previewFileModalCancel = () => {
        this.props.actions.showPreviewFileModal(false);
    };


    renameBeforeUpload = (file) => {
        let [fileName, fileExtension] = file.name.split(/\.(?=[^\.]+$)/);
        this.setState({fileName, fileExtension});
        return new Promise(resolve => {
            confirm({
                title: 'Bitte geben Sie den Dateinamen ein:',
                content: <React.Fragment>
                    <Input defaultValue={this.state.fileName} onChange={event => this.setState({fileName:event.target.value})} />
                </React.Fragment>,
                okText: 'Speichern',
                cancelText: 'Abbrechen',
                onOk: () => {
                    let newFile = new File([file], (this.state.fileName+"."+this.state.fileExtension), {type: file.type});
                    return resolve(newFile);
                },
                onCancel: () => {
                    //return resolve(false);
                    return resolve(file);
                },
            });
        });
    };






    render() {
        const { getFieldDecorator } = this.props.form;

        return (
            <React.Fragment>
            <FileModal visible={this.props.previewFileModalVisible} file={this.props.previewFileModalFile} onOk={this.previewFileModalOK} onCancel={this.previewFileModalCancel}/>
            <Modal
                title="Kunde"
                visible={this.props.PersonDetailVisible}
                onOk={this.handleSubmit}
                onCancel={this.handleCancel}
                width={"80%"}
                footer={[
                    <Button key="back" onClick={this.handleCancel}>Abbrechen</Button>,
                    <Button key="submit" type="primary" onClick={this.handleSubmit}>Speichern</Button>
                ]}
            >
                <Spin spinning={this.props.loadingPersonDetail}>

                    <form onSubmit={this.handleSubmit} style={{width: '100%'}}>

                            
                                <Divider orientation="center" className={"lightDivider"}>Allgemein</Divider>
                                <FormItem {...formItemLayout}
                                          label="Name*:">{getFieldDecorator("lastname", {
                                    rules: [{
                                        required: false,
                                    }], initialValue: this.props.PersonDetail["lastname"],
                                })(
                                    <label>{this.props.PersonDetail["lastname"]}</label>)}
                                </FormItem>
                                <FormItem {...formItemLayout}
                                          label="Vorname*:">{getFieldDecorator("firstname", {
                                    rules: [{
                                        required: false,
                                    }], initialValue: this.props.PersonDetail["firstname"],
                                })(
                                    <label>{this.props.PersonDetail["firstname"]}</label>)}
                                </FormItem>
                                <FormItem {...formItemLayout}
                                          label="Straße, Hausnummer*:">{getFieldDecorator("address", {
                                    rules: [{
                                        required: false,
                                    }], initialValue: this.props.PersonDetail["address"],
                                })(
                                    <label>{this.props.PersonDetail["address"]}</label>)}
                                </FormItem>
                                <FormItem {...formItemLayout}
                                          label="PLZ, Ort*:">{getFieldDecorator("plzort", {
                                    rules: [{
                                        required: false,
                                    }], initialValue: this.props.PersonDetail["plzort"],
                                })(
                                    <label>{this.props.PersonDetail["zipcode"]}, {this.props.PersonDetail["city"]}</label>)}
                                </FormItem>

                                <FormItem {...formItemLayout}
                                          label="Kundennummer*:">{getFieldDecorator("kdnr", {
                                    rules: [{
                                        required: false,
                                    }], initialValue: this.props.PersonDetail["kdnr"],
                                })(
                                    <label>{this.props.PersonDetail["kdnr"]}</label>)}
                                </FormItem>

                                <FormItem {...formItemLayout}
                                          label="Telefon*:">{getFieldDecorator("telKasse", {
                                    rules: [{
                                        required: false,
                                    }], initialValue: this.props.PersonDetail["telKasse"],
                                })(
                                    <label>{this.props.PersonDetail["telKasse"]}</label>)}
                                </FormItem>

                                <FormItem {...formItemLayout}
                                          label="Mobil*:">{getFieldDecorator("mobileKasse", {
                                    rules: [{
                                        required: false,
                                    }], initialValue: this.props.PersonDetail["mobileKasse"],
                                })(
                                    <label>{this.props.PersonDetail["mobileKasse"]}</label>)}
                                </FormItem>

                                <FormItem {...formItemLayout}
                                          label="E-Mail*:">{getFieldDecorator("emailKasse", {
                                    rules: [
                                        {
                                            type: 'email',
                                            message: 'Bitte geben Sie eine gültie E-Mail Adresse ein',
                                        },
                                        {
                                            required: false,
                                        },
                                    ], initialValue: this.props.PersonDetail["emailKasse"],
                                })(
                                    <label>{this.props.PersonDetail["emailKasse"]}</label>)}
                                </FormItem>

                        <FormItem {...formItemLayout} colon={false} label={" "}>
                            <label>* Informationen werden von der Kasse abgerufen</label>
                        </FormItem>


                                <FormItem {...formItemLayout}
                                          label="Geburtstag:"
                                          key={"Geburtstag"}
                                >{getFieldDecorator("birthday", {
                                    rules: [{
                                        required: false,
                                    }],
                                    initialValue: this.props.PersonDetail["birthday"] ? moment(this.props.PersonDetail["birthday"]) : null,
                                })(
                                    <DatePicker placeholder={"Datum auswählen"} format={"DD.MM.YYYY"} style={{width: '100%'}}/>)}
                                </FormItem>

                                <FormItem {...formItemLayout}
                                          label="Notizen:"
                                          key={"note"}
                                >{getFieldDecorator("note", {
                                    rules: [{
                                        required: false,
                                    }],
                                    initialValue: this.props.PersonDetail["note"],
                                })(
                                    <TextArea rows={4} maxLength={254} style={{width: '100%'}}/>)}
                                    <Button style={{width: '100%'}} danger={true} htmlType={"button"} type={"ghost"} disabled={!this.props.form.getFieldValue('note')} onClick={() => this.props.form.setFieldsValue({note: ""})}>Notiz löschen</Button>
                                </FormItem>

                                <Divider orientation="center" className={"lightDivider"}>Gesundheitszeugnis</Divider>
                                <FormItem {...formItemLayout}
                                          label="Gültig bis:"
                                          hasFeedback
                                          validateStatus={this.props.PersonDetail["rememberHealthCertificate"] ? 'warning' : ''}
                                >{getFieldDecorator("healthCertificate", {
                                    rules: [{
                                        required: false,
                                    }], initialValue: this.props.PersonDetail["healthCertificate"] ? moment(this.props.PersonDetail["healthCertificate"]) : null,
                                })(
                                    <DatePicker placeholder={"Datum auswählen"} format={"DD.MM.YYYY"} style={{width: '100%'}}/>)}
                                </FormItem>

                                <FormItem {...formItemLayout}
                                          label="Erinnerung:">{getFieldDecorator("healthCertificateRemember", {
                                    rules: [{
                                        required: false,
                                    }], initialValue: this.props.PersonDetail["healthCertificateRemember"] || "1",
                                })(
                                    <Select
                                        getPopupContainer={trigger => trigger.parentNode}>
                                        <Option value={"1"}>
                                            1 Tag vorher
                                        </Option>
                                        <Option value={"7"}>
                                            1 Woche vorher
                                        </Option>
                                        <Option value={"14"}>
                                            2 Wochen vorher
                                        </Option>
                                        <Option value={"31"}>
                                            1 Monat vorher
                                        </Option>
                                    </Select>)}
                                </FormItem>

                            

                            

                                <Divider orientation="center" className={"lightDivider"}>Waffenschein</Divider>
                                <FormItem {...formItemLayout}
                                          label="Gültig bis:"
                                          hasFeedback
                                          validateStatus={this.props.PersonDetail["rememberWeaponCertificate"] ? 'warning' : ''}
                                >{getFieldDecorator("weaponCertificate", {
                                    rules: [{
                                        required: false,
                                    }], initialValue: this.props.PersonDetail["weaponCertificate"] ? moment(this.props.PersonDetail["weaponCertificate"]) : null,
                                })(
                                    <DatePicker placeholder={"Datum auswählen"} format={"DD.MM.YYYY"} style={{width: '100%'}}/>)}
                                </FormItem>

                                <FormItem {...formItemLayout}
                                          label="Erinnerung:">{getFieldDecorator("weaponCertificateRemember", {
                                    rules: [{
                                        required: false,
                                    }], initialValue: this.props.PersonDetail["weaponCertificateRemember"] || "1",
                                })(
                                    <Select
                                        getPopupContainer={trigger => trigger.parentNode}>
                                        <Option value={"1"}>
                                            1 Tag vorher
                                        </Option>
                                        <Option value={"7"}>
                                            1 Woche vorher
                                        </Option>
                                        <Option value={"14"}>
                                            2 Wochen vorher
                                        </Option>
                                        <Option value={"31"}>
                                            1 Monat vorher
                                        </Option>
                                    </Select>)}
                                </FormItem>

                            


                            

                                <Divider orientation="center" className={"lightDivider"}>Lustmolche</Divider>
                                <FormItem {...formItemLayout}
                                          label="Name:">{getFieldDecorator("lastname", {
                                    rules: [{
                                        required: false,
                                    }], initialValue: this.props.PersonDetail["lastname"],
                                })(
                                    <label>{this.props.PersonDetail["lastname"]}</label>)}
                                </FormItem>
                                <FormItem {...formItemLayout}
                                          label="Name:">{getFieldDecorator("firstname", {
                                    rules: [{
                                        required: false,
                                    }], initialValue: this.props.PersonDetail["firstname"],
                                })(
                                    <label>{this.props.PersonDetail["firstname"]}</label>)}
                                </FormItem>
                                <FormItem {...formItemLayout}
                                          label="Künstlername:">{getFieldDecorator("stagename", {
                                    rules: [{
                                        required: false,
                                    }], initialValue: this.props.PersonDetail["stagename"],
                                })(
                                    <Input type={"text"}/>)}
                                </FormItem>
                                <FormItem {...formItemLayout}
                                          label="Nationalität:">{getFieldDecorator("nationality", {
                                    rules: [{
                                        required: false,
                                    }], initialValue: this.props.PersonDetail["nationality"],
                                })(
                                    <Input type={"text"}/>)}
                                </FormItem>
                                <FormItem {...formItemLayout}
                                          label="Sternzeichen:">{getFieldDecorator("starsign", {
                                    rules: [{
                                        required: false,
                                    }], initialValue: this.props.PersonDetail["starsign"],
                                })(
                                    <Select
                                        getPopupContainer={trigger => trigger.parentNode}>
                                        {Sternzeichen.map(item => (
                                            <Select.Option key={item} value={item}>
                                                {item}
                                            </Select.Option>
                                        ))}
                                    </Select>)}
                                </FormItem>
                                <FormItem {...formItemLayout}
                                          label="Alter:">{getFieldDecorator("age", {
                                    rules: [{
                                        required: false,
                                    }], initialValue: this.props.PersonDetail["age"],
                                })(
                                    <InputNumber style={{width: '100%'}} min={18}/>)}
                                </FormItem>
                                <FormItem {...formItemLayout}
                                          label="Haarfarbe:">{getFieldDecorator("haircolor", {
                                    rules: [{
                                        required: false,
                                    }], initialValue: this.props.PersonDetail["haircolor"],
                                })(
                                    <Input type={"text"}/>)}
                                </FormItem>
                                <FormItem {...formItemLayout}
                                          label="BH-Größe:">{getFieldDecorator("brasize", {
                                    rules: [{
                                        required: false,
                                    }], initialValue: this.props.PersonDetail["brasize"],
                                })(
                                    <Input type={"text"}/>)}
                                </FormItem>
                                <FormItem {...formItemLayout}
                                          label="Körpergröße:">{getFieldDecorator("height", {
                                    rules: [{
                                        required: false,
                                    }], initialValue: this.props.PersonDetail["height"],
                                })(
                                    <InputNumber min={0} max={250} step={1} style={{width: '100%'}}/>)}
                                </FormItem>
                                <FormItem {...formItemLayout}
                                          label="Telefon:">{getFieldDecorator("tel", {
                                    rules: [{
                                        required: false,
                                    }], initialValue: this.props.PersonDetail["tel"],
                                })(
                                    <Input type={"text"}/>)}
                                </FormItem>
                                <FormItem {...formItemLayout}
                                          label="E-Mail:">{getFieldDecorator("email", {
                                    rules: [
                                        {
                                            type: 'email',
                                            message: 'Bitte geben Sie eine gültie E-Mail Adresse ein',
                                        },
                                        {
                                            required: false,
                                        },
                                    ], initialValue: this.props.PersonDetail["email"],
                                })(
                                    <Input type={"email"}/>)}
                                </FormItem>
                                <FormItem {...formItemLayout}
                                          label="Bundesland:">{getFieldDecorator("state", {
                                    rules: [{
                                        required: false,
                                    }], initialValue: this.props.PersonDetail["state"],
                                })(
                                    <Select
                                        getPopupContainer={trigger => trigger.parentNode}>
                                        {Bundeslaender.map(item => (
                                            <Select.Option key={item} value={item}>
                                                {item}
                                            </Select.Option>
                                        ))}
                                    </Select>)}
                                </FormItem>
                                <FormItem {...formItemLayout}
                                          label="Gewicht:">{getFieldDecorator("weight", {
                                    rules: [{
                                        required: false,
                                    }], initialValue: this.props.PersonDetail["weight"],
                                })(
                                    <InputNumber style={{width: '100%'}}/> )}
                                </FormItem>

                                <FormItem {...formItemLayout}
                                          label="Facebook Link:">{getFieldDecorator("facebook", {
                                    rules: [{
                                        required: false,
                                    }], initialValue: this.props.PersonDetail["facebook"],
                                })(
                                    <Input type={"text"}/>)}
                                </FormItem>
                                <FormItem {...formItemLayout}
                                          label="Instagram Link:">{getFieldDecorator("instagram", {
                                    rules: [{
                                        required: false,
                                    }], initialValue: this.props.PersonDetail["instagram"],
                                })(
                                    <Input type={"text"}/>)}
                                </FormItem>
                                <FormItem {...formItemLayout}
                                          label="Snapchat Name:">{getFieldDecorator("snapchat", {
                                    rules: [{
                                        required: false,
                                    }], initialValue: this.props.PersonDetail["snapchat"],
                                })(
                                    <Input type={"text"}/>)}
                                </FormItem>
                                <FormItem {...formItemLayout}
                                          label="Kategorie:">{getFieldDecorator("category", {
                                    rules: [{
                                        required: false,
                                    }], initialValue: this.props.PersonDetail["category"],
                                })(
                                    <Select
                                            getPopupContainer={trigger => trigger.parentNode}>
                                        <Option value={"Dominas"} key={"Dominas"}>
                                            Dominas
                                        </Option>
                                        <Option value={"Ladys"} key={"Ladys"}>
                                            Ladys
                                        </Option>
                                        <Option value={"She-Males"} key={"She-Males"}>
                                            She-Males
                                        </Option>
                                    </Select>)}
                                </FormItem>


                                <FormItem {...formItemLayout}
                                          label="Leidenschaften:">{getFieldDecorator("passions", {
                                    rules: [{
                                        required: false,
                                    }], initialValue: this.props.PersonDetail["passions"],
                                })(
                                    <Select
                                        mode="multiple"
                                        placeholder="Leidenschaften auswählen"
                                        style={{ width: '100%' }}
                                        getPopupContainer={trigger => trigger.parentNode}
                                    >
                                        {Leidenschaften.map(item => (
                                            <Select.Option key={item} value={item}>
                                                {item}
                                            </Select.Option>
                                        ))}
                                    </Select>
                                )}
                                </FormItem>

                                <FormItem {...formItemLayout}
                                          label="Sprachen:">{getFieldDecorator("languages", {
                                    rules: [{
                                        required: false,
                                    }], initialValue: this.props.PersonDetail["languages"],
                                })(
                                    <Select
                                        mode="multiple"
                                        placeholder="Sprachen auswählen"
                                        style={{ width: '100%' }}
                                        getPopupContainer={trigger => trigger.parentNode}
                                    >
                                        {Sprachen.map(item => (
                                            <Select.Option key={item} value={item}>
                                                {item}
                                            </Select.Option>
                                        ))}
                                    </Select>
                                )}
                                </FormItem>

                                <FormItem {...formItemLayout}
                                          label="Rasiert:">{getFieldDecorator("shaved", {
                                    rules: [{
                                        required: false,
                                    }], initialValue: this.props.PersonDetail["shaved"] || "Nein",
                                })(
                                    <Radio.Group>
                                        <Radio value="Ja">Ja</Radio>
                                        <Radio value="Nein">Nein</Radio>
                                    </Radio.Group>)}
                                </FormItem>

                                <FormItem {...formItemLayout}
                                          label="Piercings:">{getFieldDecorator("piercings", {
                                    rules: [{
                                        required: false,
                                    }], initialValue: this.props.PersonDetail["piercings"] || "Nein",
                                })(
                                    <Radio.Group>
                                        <Radio value="Ja">Ja</Radio>
                                        <Radio value="Nein">Nein</Radio>
                                    </Radio.Group>)}
                                </FormItem>

                                <FormItem {...formItemLayout}
                                          label="Tattoos:">{getFieldDecorator("inked", {
                                    rules: [{
                                        required: false,
                                    }], initialValue: this.props.PersonDetail["inked"] || "Nein",
                                })(
                                    <Radio.Group>
                                        <Radio value="Ja">Ja</Radio>
                                        <Radio value="Nein">Nein</Radio>
                                    </Radio.Group>)}
                                </FormItem>

                                <FormItem {...formItemLayout}
                                          label="Raucherin:">{getFieldDecorator("smoker", {
                                    rules: [{
                                        required: false,
                                    }], initialValue: this.props.PersonDetail["smoker"] || "Nein",
                                })(
                                    <Radio.Group>
                                        <Radio value="Ja">Ja</Radio>
                                        <Radio value="Nein">Nein</Radio>
                                    </Radio.Group>)}
                                </FormItem>

                                <FormItem {...formItemLayout}
                                          label="Behinderte:">{getFieldDecorator("handicapped", {
                                    rules: [{
                                        required: false,
                                    }], initialValue: this.props.PersonDetail["handicapped"] || "Nein",
                                })(
                                    <Radio.Group>
                                        <Radio value="Ja">Ja</Radio>
                                        <Radio value="Nein">Nein</Radio>
                                    </Radio.Group>)}
                                </FormItem>

                                <FormItem {...formItemLayout}
                                          label="Beschreibung:">{getFieldDecorator("description", {
                                    rules: [{
                                        required: false,
                                    }], initialValue: this.props.PersonDetail["description"],
                                })(<TextArea/>)}
                                </FormItem>

                            

                            

                                <Divider orientation="center" className={"lightDivider"}>Dokumente</Divider>
                                <Form.Item
                                    {...formItemLayout}
                                    label="Dateien"
                                >

                                    {getFieldDecorator('images', {
                                        valuePropName: 'fileList',
                                        getValueFromEvent: this.normMultiFile,
                                        initialValue: this.props.PersonDetail["images"],
                                    })(
                                        <Upload.Dragger
                                            name="files"
                                            action={BASE_URL+'api/'+API_VERSION+"/persons/"+this.props.actPersonID+"/Upload"}
                                            accept={".png,.jpg,.jpeg,.pdf"}
                                            headers={{authorization: `Bearer ${localStorage.getItem('id_token') || null}`}}
                                            listType={'picture'}
                                            multiple={true}
                                            onPreview={(file) => this.previewFile(file)}
                                            transformFile={this.renameBeforeUpload}
                                        >
                                            <p className="ant-upload-drag-icon">
                                                <InboxOutlined />
                                            </p>
                                            <p className="ant-upload-text">Hier klicken oder Datei in diesen Bereich ziehen</p>
                                            <p className="ant-upload-hint">Es werden nur .JPG, .JPEG, .PNG und .PDF Dateien unterstützt.</p>
                                        </Upload.Dragger>
                                    )}
                                </Form.Item>



                        {this.props.hideCheckins ? null :
                            <>
                                <Divider orientation="center" className={"lightDivider"}>Verträge</Divider>
                                <CheckIn customerId={this.props.actPersonID} />
                            </>
                        }

                    </form>
                </Spin>
            </Modal>
        </React.Fragment>
        );
    }
}


function mapStateToProps(state) {
    const { PersonsReducer } = state;
    const { actPersonID, loadingPersonDetail, PersonDetail, savingPersonDetail, previewFileModalVisible, previewFileModalFile, PersonDetailVisible } = PersonsReducer;
    return {
        actPersonID,
        loadingPersonDetail,
        PersonDetail,
        savingPersonDetail,
        previewFileModalVisible,
        previewFileModalFile,
        PersonDetailVisible
    };
}


function mapDispatchToProps(dispatch) {
    return{
        actions: bindActionCreators(PersonenActions, dispatch)
    };
}



export default connect(mapStateToProps, mapDispatchToProps)(Form.create()(PersonenDetail));


const Leidenschaften = [
    "Schmusen & Küssen",
    "Zungenküsse",
    "Körperküsse",
    "Striptease",
    "Verbalerotik",
    "Dirty Talk",
    "Fußerotik",
    "Schuherotik",
    "Straps- und Nylonerotik",
    "Busenerotik",
    "Fingerspiele",
    "Dildospiele",
    "Fesselspiele",
    "Rollenspiele",
    "Gesichtsbesamung",
    "Körperbesamung",
    "Natursekt aktiv",
    "Natursekt passiv",
    "Kaviar aktiv",
    "Stellungswechsel",
    "Girlfriend Sex",
    "Double Penetration",
    "Threesome (MFF)",
    "Threesome (MMF)",
    "LS mit Freundin(nen)",
    "Masturbation, HE",
    "Französisch",
    "Eierlecken",
    "Facesitting",
    "69",
    "Deep Throat",
    "Hodenmassage",
    "Prostatamassage",
    "Analverkehr",
    "Anal bei Dir",
    "Erotikmassage",
    "Body to Body",
    "Lack und Leder",
    "Sexy Dessous",
    "Dominanzspiele",
    "Leichte Erziehung",
    "Duschservice",
    "Badeservice",
    "Extra langes Vorspiel",
    "Haus- und Hotelbesuche",
    "Escort",
    "SM Services",
    "Demütigung",
    "Verbalerniedrigung",
    "Körperliche Demütigung",
    "Faustfick",
    "Fisting aktiv",
    "Fisting passiv",
    "Sklavenerziehung",
    "Tierdressur",
    "Hundevorführung",
    "Verhöre",
    "Erziehungsspielchen",
    "Bondage",
    "BDSM",
    "Anspucken",
    "Speichelspiele",
    "Körperfolter",
    "Nippelfolter",
    "Genitalfolter",
    "Trampeln",
    "Flagellation",
    "Ohrfeigen",
    "Auspeitschen",
    "Analbehandlung",
    "Wachsbehandlung",
    "Atemreduktion",
    "Elektrostimulation",
    "Intimrasur",
    "Intim Massage",
    "Tantra Massage",
    "Kama Sutra Massage",
    "Kama Sutra Praktiken",
    "Erotikfotos",
    "Erotikfilme",
    "Swingen",
    "Clubbegleitung"
];


const Bundeslaender = [
    "Baden-Württemberg",
    "Bayern",
    "Berlin",
    "Bremen",
    "Hamburg",
    "Hessen",
    "Mecklenburg-Vorpommern",
    "Niedersachsen",
    "Nordrhein-Westfalen",
    "Rheinland-Pfalz",
    "Saarland",
    "Sachsen",
    "Sachsen-Anhalt",
    "Schleswig-Holstein",
    "Thüringen"
];

const Sternzeichen = [
    "Wassermann",
    "Fische",
    "Widder",
    "Stier",
    "Zwillinge",
    "Krebs",
    "Jungfrau",
    "Waage",
    "Skorpion",
    "Steinbock",
    "Löwe",
    "Schütze",
];

const Sprachen = [
    "Deutsch",
    "Englisch",
    "Französisch",
    "Italienisch",
    "Polnisch",
    "Rumänisch",
    "Russisch",
    "Bulgarisch",
    "Ungarisch",
    "Spanisch",
    "Türkisch",
    "Portugiesisch",
    "Tschechisch",
    "Moldavisch",
    "Afrikaans",
    "Japanisch",
    "Koreanisch",
    "Griechisch"
];