import React, {Component} from "react";
import { Modal, Button, DatePicker } from 'antd';
import { Form } from '@ant-design/compatible';
import {bindActionCreators} from "redux";
import * as RechnungenActions from "../redux/Rechnungen/actions";
import {connect} from "react-redux";

const FormItem = Form.Item;

const formItemLayout = {
    labelCol: {
        xs: { span: 20 },
        sm: { span: 6 },
    },
    wrapperCol: {
        xs: { span: 20 },
        sm: { span: 13 },
    },
};


class BillPayedModal extends Component {



    handleSubmit = (e) => {
        e.preventDefault();
        this.props.form.validateFieldsAndScroll((err, values) => {
            if (!err) {
                this.props.actions.saveBillPayed(this.props.setBillPayedRNR, values);
            }
        });
    };


    componentWillReceiveProps(nextProps) {
        if(this.props.billPayedModalVisible && !nextProps.billPayedModalVisible) {
            this.props.form.resetFields();
        }
    }




    render() {
        const { getFieldDecorator } = this.props.form;


        return (
                <Modal
                    title={'Rechnung als bezahlt markieren'}
                    visible={this.props.billPayedModalVisible}
                    onOk={this.handleSubmit}
                    onCancel={() => this.props.actions.showBillPayedModal(null, false)}
                    cancelText={"Abbrechen"}
                    okText={"Speichern"}
                    width={"750px"}
                    footer={[
                        <Button key="cancel" onClick={() => this.props.actions.showBillPayedModal(null, false)}>Abbrechen</Button>,
                        <Button key="submit" type="primary" loading={this.props.setBillPayedLoading} onClick={this.handleSubmit}>
                            Speichern
                        </Button>
                    ]}
                >

                    <form onSubmit={this.handleSubmit} style={{width: '100%'}}>
                        <FormItem
                            {...formItemLayout}
                            label={"Rechnung bezahlt am:"}
                            required={true}
                            key={'payedAt'}
                        >
                            {getFieldDecorator('payedDate', {
                                initialValue: null,
                                rules: [{
                                    required: true,
                                    message: "Bitte geben Sie diese Information an."
                                }],
                            })(
                                <DatePicker
                                    style={{ width: 380 }}
                                    placeholder={"Rechnung bezahlt am..."}
                                    format={'DD.MM.YYYY'}
                                >
                                </DatePicker>
                            )}
                        </FormItem>
                    </form>
                </Modal>
        );
    }
}



function mapStateToProps(state) {
    const { RechnungenReducer } = state;
    const { billPayedModalVisible, setBillPayedLoading, setBillPayedRNR } = RechnungenReducer;
    return {
        billPayedModalVisible,
        setBillPayedLoading,
        setBillPayedRNR
    };
}


function mapDispatchToProps(dispatch) {
    return{
        actions: bindActionCreators(RechnungenActions, dispatch)
    };
}



export default connect(mapStateToProps, mapDispatchToProps)(Form.create()(BillPayedModal));


//export default Form.create()(UsersDetailModal);