import React, {Component} from "react";
import { Select, Modal, Input, Checkbox, Button, DatePicker, Upload } from 'antd';
import { Form } from '@ant-design/compatible';
import {bindActionCreators} from "redux";
import * as HausverboteActions from "../redux/Hausverbote/actions";
import {connect} from "react-redux";
import moment from "moment";
import {InboxOutlined} from "@ant-design/icons";

const { TextArea } = Input;
const FormItem = Form.Item;
const Option = Select.Option;
const confirm = Modal.confirm;

let BASE_URL = process.env.REACT_APP_API_URL;
let API_VERSION = process.env.REACT_APP_API_VERSION;


const formItemLayout = {
    labelCol: {
        xs: { span: 20 },
        sm: { span: 6 },
    },
    wrapperCol: {
        xs: { span: 20 },
        sm: { span: 13 },
    },
};

const formItemLayoutWithOutLabel = {
    wrapperCol: {
        xs: { span: 24, offset: 0 },
        sm: { span: 24, offset: 0 },
    },
};


class HausverboteDetailModal extends Component {


    componentWillMount() {
    }

    handleSubmit = (e) => {
        e.preventDefault();
        this.props.form.validateFieldsAndScroll((err, values) => {
            if (!err) {
                this.props.actions.saveHausverboteDetail(values, this.props.HausverboteDetailID);
            }
        });
    };


    componentWillReceiveProps(nextProps) {
        if(this.props.HausverboteModalVisible && !nextProps.HausverboteModalVisible) {
            this.props.form.resetFields();
        } else if(!this.props.HausverboteModalVisible && nextProps.HausverboteModalVisible && nextProps.HausverboteDetailID) {
            this.props.actions.LoadHausverboteDetail(nextProps.HausverboteDetailID);
        }
    }

    confirmDelete = () => {
        confirm({
            title: "Hausverbot löschen",
            content: "Wollen Sie dieses Hausverbot wirklich löschen?",
            okText: "Löschen",
            okType: 'danger',
            cancelText: "Abbrechen",
            onOk: () => this.props.actions.deleteHausverbot(this.props.HausverboteDetailID),
            onCancel() {
            },
        });
    };

    normMultiFile = (e) => {
        if (Array.isArray(e)) {
            return e;
        }
        return e && e.fileList;
    };



    render() {
        const { getFieldDecorator } = this.props.form;


        return (
                <Modal
                    title={`Hausverbot ${this.props.HausverboteDetailID ? 'bearbeiten' : 'anlegen'}`}
                    visible={this.props.HausverboteModalVisible}
                    onOk={this.handleSubmit}
                    onCancel={() => this.props.actions.showHausverboteModal(false, null)}
                    cancelText={"Abbrechen"}
                    okText={"Speichern"}
                    width={"750px"}
                    footer={[
                        this.props.HausverboteDetailID ? <Button key="deleteban" type="danger" loading={this.props.deletingBan} onClick={this.confirmDelete}>
                            Hausverbot löschen
                        </Button> : '',
                        <Button key="cancel" onClick={() => this.props.actions.showHausverboteModal(false, null)}>Abbrechen</Button>,
                        <Button key="submit" type="primary" loading={this.props.HausverboteModalSaving} onClick={this.handleSubmit}>
                            Speichern
                        </Button>
                    ]}
                >



                    <form onSubmit={this.handleSubmit} style={{width: '100%'}}>

                        <FormItem
                            {...formItemLayout}
                            label={"Vorname"}
                            required={true}
                            key={'Vorname'}
                        >
                            {getFieldDecorator('FirstName', {
                                initialValue: this.props.HausverbotDetail.FirstName,
                                rules: [{
                                    required: true,
                                    whitespace: true,
                                    message: "Bitte geben Sie diese Information an."
                                }],
                            })(
                                <Input type={"text"} placeholder={"Vorname"}/>
                            )}
                        </FormItem>
                        <FormItem
                            {...formItemLayout}
                            label={"Nachname"}
                            required={true}
                            key={'Nachname'}
                        >
                            {getFieldDecorator('LastName', {
                                initialValue: this.props.HausverbotDetail.LastName,
                                rules: [{
                                    required: true,
                                    whitespace: true,
                                    message: "Bitte geben Sie diese Information an."
                                }],
                            })(
                                <Input type={"text"} placeholder={"Nachname"}/>
                            )}
                        </FormItem>
                        <FormItem
                            {...formItemLayout}
                            label={"Verbot seit"}
                            required={true}
                            key={'Verbot_seit'}
                        >
                            {getFieldDecorator('BannedSince', {
                                initialValue: this.props.HausverbotDetail.BannedSince ? moment(this.props.HausverbotDetail.BannedSince) : null,
                                rules: [{
                                    required: true,
                                    message: "Bitte geben Sie diese Information an."
                                }],
                            })(
                                <DatePicker
                                    style={{ width: 380 }}
                                    placeholder={"Verbot seit"}
                                    format={'DD.MM.YYYY'}
                                >
                                </DatePicker>
                            )}
                        </FormItem>
                        <FormItem
                            {...formItemLayout}
                            label={"Notiz"}
                            required={false}
                            key={'note'}
                        >
                            {getFieldDecorator('Note', {
                                initialValue: this.props.HausverbotDetail.Note,
                                rules: [{
                                    required: false,
                                    message: "Bitte geben Sie diese Information an."
                                }],
                            })(
                                <TextArea
                                    rows={4}
                                    style={{ width: 380 }}
                                    placeholder="Optionale Notizen"
                                >
                                </TextArea>
                            )}
                        </FormItem>

                        <Form.Item
                            {...formItemLayout}
                            label="Dateien"
                        >

                            {getFieldDecorator('files', {
                                valuePropName: 'fileList',
                                getValueFromEvent: this.normMultiFile,
                                initialValue: this.props.HausverbotDetail["files"],
                            })(
                                <Upload.Dragger name="files" action={BASE_URL+'api/'+API_VERSION+"/ban/"+(this.props.HausverboteDetailID ? this.props.HausverboteDetailID : "new")+"/Upload"} headers={{authorization: `Bearer ${localStorage.getItem('id_token') || null}`}}  listType={'picture'}  multiple={true}>
                                    <p className="ant-upload-drag-icon">
                                        <InboxOutlined />
                                    </p>
                                    <p className="ant-upload-text">Hier klicken oder Datei in diesen Bereich ziehen</p>
                                    <p className="ant-upload-hint">Es werden alle Dateien unterstützt.</p>
                                </Upload.Dragger>
                            )}
                        </Form.Item>



                    </form>


                </Modal>


        );
    }
}



function mapStateToProps(state) {
    const { HausverboteReducer } = state;
    const { HausverboteModalVisible, HausverboteDetailID, HausverboteModalSaving, HausverbotDetail , deletingBan} = HausverboteReducer;
    return {
        HausverboteModalVisible,
        HausverboteDetailID,
        HausverboteModalSaving,
        HausverbotDetail,
        deletingBan
    };
}


function mapDispatchToProps(dispatch) {
    return{
        actions: bindActionCreators(HausverboteActions, dispatch)
    };
}



export default connect(mapStateToProps, mapDispatchToProps)(Form.create()(HausverboteDetailModal));


//export default Form.create()(UsersDetailModal);