import React, {Component} from "react";
import {connect} from "react-redux";
import {bindActionCreators} from "redux";
import * as UsersActions from "../../redux/Users/actions";
import { Input, Button, Tabs, Table } from 'antd';
import Box from '../../components/utility/box';
import LayoutWrapper from '../../components/utility/layoutWrapper';
import { history } from '../../redux/store';
import './style.css';
import UsersDetailModal from '../../components/UsersDetailModal';
import {CloseCircleOutlined, PlusOutlined} from "@ant-design/icons";

const Search = Input.Search;



class Users extends Component {

    state = {search: this.props.search};

    constructor(props) {
        super(props);
        this.onChangeSearch = this.onChangeSearch.bind(this);
    }

    componentWillMount() {
        this.props.actions.LoadUsers({...this.props.infosTableUsers, search: this.props.search});
        this.props.actions.LoadUsersGroups();
        this.setState({search: this.props.search});
    }

    showUser = (id) => {
        history.push("/app/Users/"+id);
    };


    handleTableChange = (pagination, filters, sorter) => {
        const pager = { ...this.props.paginationUsers };
        pager.current = pagination.current;
        pager.pageSize = 25;
        /*this.setState({
            pagination: pager,
        });*/


        this.props.actions.setUsersPage(pager);

        this.props.actions.setUsersTableInfo({
            results: pagination.pageSize,
            page: pagination.current,
            sortField: sorter.field,
            sortOrder: sorter.order,
            filter: filters,
        });

        this.props.actions.LoadUsers({
            results: pagination.pageSize,
            page: pagination.current,
            sortField: sorter.field,
            sortOrder: sorter.order,
            filter: filters,
            search: this.props.search
        });

    };



    componentWillUpdate(nextProps) {
        if(this.props.search !== nextProps.search) {
            //this.props.actions.LoadUsers({all: nextProps.alleUsers})
            this.props.actions.LoadUsers({...nextProps.infosTableUsers, search: nextProps.search});
        }
        if(((this.props.savingUsers && !nextProps.savingUsers) || (this.props.deletingUser && !nextProps.deletingUser)) && this.props.UsersDetailsVisible && !nextProps.UsersDetailsVisible) {
            this.props.actions.LoadUsers({...nextProps.infosTableUsers, search: nextProps.search});
        }
    }

    onChangeSearch = (e) => {
        this.setState({ search: e.target.value });
    };


    render() {

        const columns = [
            {
                title: 'ID',
                dataIndex: 'UserID',
                key: 'UserID',
                sorter: true,
                sortOrder: this.props.infosTableUsers.sortField === 'UserID' && this.props.infosTableUsers.sortOrder
            }, {
                title: 'Name',
                dataIndex: 'Nachname',
                key: 'Nachname',
                sorter: true,
                sortOrder: this.props.infosTableUsers.sortField === 'Nachname' && this.props.infosTableUsers.sortOrder
            }, {
                title: 'Vorname',
                dataIndex: 'Vorname',
                key: 'Vorname',
                sorter: true,
                sortOrder: this.props.infosTableUsers.sortField === 'Vorname' && this.props.infosTableUsers.sortOrder
            }, {
                title: 'Gruppe',
                dataIndex: 'Group',
                key: 'Group',
                sorter: true,
                sortOrder: this.props.infosTableUsers.sortField === 'Group' && this.props.infosTableUsers.sortOrder
            }, {
                title: 'Aktiv',
                dataIndex: 'Active',
                key: 'Active',
                sorter: true,
                render: (act) => { return act === 1 ? 'Ja' : 'Nein' },
                sortOrder: this.props.infosTableUsers.sortField === 'Active' && this.props.infosTableUsers.sortOrder
            }, {
                title: 'Angelegt',
                dataIndex: 'Angelegt',
                key: 'Angelegt',
                sorter: true,
                sortOrder: this.props.infosTableUsers.sortField === 'Angelegt' && this.props.infosTableUsers.sortOrder
            }];


        const searchClear = this.props.search ? <CloseCircleOutlined onClick={() => {this.props.actions.setSearchText(''); this.setState({ search: '' });}} /> : null;

        return (
            <LayoutWrapper>
                <UsersDetailModal
                    visible={this.props.UsersDetailsVisible}
                    handleCancel={() => this.props.actions.showUsersDetail(false)}
                />
                <Search onChange={this.onChangeSearch} value={this.state.search} prefix={searchClear} placeholder={"Suchen..."} onSearch={value => this.props.actions.setSearchText(value)} defaultValue={this.props.search} enterButton style={{width: '400px', minHeight: '0px !important'}} />
                <Button type={"primary"} htmlType={"button"} className={"round"} style={{marginBottom: "10px", marginLeft: "auto"}} onClick={() => this.props.actions.showUsersDetail(true)} icon={<PlusOutlined/>}>Hinzufügen</Button>
            <Box >
                <Table
                    columns={columns}
                    dataSource={this.props.Users}
                    onRow={(record) => ({
                        onClick: () => this.props.actions.showUsersDetail(true, record.UserID)
                    })}
                    rowClassName={(record, index) => { return 'tableCursor'}}
                    pagination={this.props.paginationUsers}
                    loading={this.props.loadingUsers}
                    onChange={this.handleTableChange}
                    scroll={{x: '100%'}}
                    rowKey={(record => record.UserID)}
                />
            </Box>
            </LayoutWrapper>
        );
    }
}


function mapStateToProps(state) {
    const { UsersReducer } = state;
    const { loadingUsers, paginationUsers, Users, infosTableUsers, search, UsersDetailsVisible, savingUsers, deletingUser } = UsersReducer;
    return {
        loadingUsers,
        paginationUsers,
        Users,
        infosTableUsers,
        search,
        UsersDetailsVisible,
        savingUsers,
        deletingUser
    };
}


function mapDispatchToProps(dispatch) {
    return{
        actions: bindActionCreators(UsersActions, dispatch)
    };
}



export default connect(mapStateToProps, mapDispatchToProps)(Users);
