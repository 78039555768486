import React, {Component} from "react";
import {connect} from "react-redux";
import {bindActionCreators} from "redux";
import * as HausverboteActions from "../../redux/Hausverbote/actions";
import { Input, Button, DatePicker, Table, Row, Col, Radio, Divider, Modal, Dropdown, Menu } from 'antd';
import Box from '../../components/utility/box';
import Header from '../../components/utility/pageHeader';
import LayoutWrapper from '../../components/utility/layoutWrapper';
import { history } from '../../redux/store';
import HausverboteDetailModal from '../../components/HausverboteDetailModal';
import './list.style.css';
import {CloseCircleOutlined, PlusOutlined} from "@ant-design/icons";

const { RangePicker } = DatePicker;
const RadioGroup = Radio.Group;


const Search = Input.Search;
const itemStyle = {margin: '0px'};





class Hausverbote extends Component {



    componentWillMount() {
        this.props.actions.LoadHausverboteList({...this.props.infosTableHausverbote, search: this.props.search});
        this.state = {search: this.props.search};
    }


    handleTableChange = (pagination, filters, sorter) => {
        const pager = { ...this.props.paginationHausverbote };
        pager.current = pagination.current;

        this.props.actions.setHausverboteTablePage(pager);

        this.props.actions.setHausverboteTableInfo({
            results: pagination.pageSize,
            page: pagination.current,
            sortField: sorter.field,
            sortOrder: sorter.order,
            ...filters,
        });

        this.props.actions.LoadHausverboteList({
            results: pagination.pageSize,
            page: pagination.current,
            sortField: sorter.field,
            sortOrder: sorter.order,
            ...filters,
            search: this.props.search
        });


    };



    componentWillUpdate(nextProps) {
       if(this.props.search !== nextProps.search) {
            this.props.actions.LoadHausverboteList({...nextProps.infosTableHausverbote, search: nextProps.search});
        }

        if(this.props.HausverboteModalSaving && !nextProps.HausverboteModalSaving ||
            this.props.deletingBan && !nextProps.deletingBan) {
            this.props.actions.LoadHausverboteList({...this.props.infosTableHausverbote, search: this.props.search});
        }
    }

    onChangeSearch = (e) => {
        this.setState({ search: e.target.value });
    };






    render() {

        const columns = [
            {
                title: 'ID',
                dataIndex: 'BanID',
                key: 'ID',
                sorter: true,
                sortOrder: this.props.infosTableHausverbote.sortField === 'BanID' && this.props.infosTableHausverbote.sortOrder
            }, {
                title: 'Name',
                dataIndex: 'LastName',
                key: 'LastName',
                sorter: true,
                sortOrder: this.props.infosTableHausverbote.sortField === 'LastName' && this.props.infosTableHausverbote.sortOrder
            }, {
                title: 'Vorname',
                dataIndex: 'FirstName',
                key: 'FirstName',
                sorter: true,
                sortOrder: this.props.infosTableHausverbote.sortField === 'FirstName' && this.props.infosTableHausverbote.sortOrder
            }, {
                title: 'Hausverbot seit',
                dataIndex: 'BannedSince',
                key: 'BannedSince',
                sorter: true,
                sortOrder: this.props.infosTableHausverbote.sortField === 'BannedSince' && this.props.infosTableHausverbote.sortOrder
            }, {
                title: 'Dokumente vorhanden',
                dataIndex: 'Documents',
                key: 'Documents',
                sorter: true,
                sortOrder: this.props.infosTableHausverbote.sortField === 'Documents' && this.props.infosTableHausverbote.sortOrder
            }
            ];

        const searchClear = this.props.search ? <CloseCircleOutlined onClick={() => {this.props.actions.setSearchText(''); this.setState({ search: '' });}} /> : null;

        return (
            <React.Fragment>
                <HausverboteDetailModal />
                <LayoutWrapper>
                    <Search onChange={this.onChangeSearch} value={this.state.search} prefix={searchClear} placeholder={"Suchen..."} onSearch={value => this.props.actions.setSearchText(value)} defaultValue={this.props.search} enterButton style={{width: '400px', minHeight: '0px !important'}} />
                    <Button type={"primary"} htmlType={"button"} className={"round"} style={{marginBottom: "10px", marginLeft: "auto"}} onClick={() => this.props.actions.showHausverboteModal(true, null)} icon={<PlusOutlined/>}>Neues Hausverbot anlegen</Button>
                <Box >


                    <Table
                        columns={columns}
                        dataSource={this.props.HausverboteList}
                        rowClassName={(record, index) => { return 'tableCursor'}}
                        onRow={(record) => ({
                            onClick: () => this.props.actions.showHausverboteModal(true, record.BanID)
                        })}
                        pagination={this.props.paginationHausverbote}
                        loading={this.props.loadingHausverboteList}
                        onChange={this.handleTableChange}
                        scroll={{x: '100%'}}
                        rowKey={(record => record.BanID)}
                    />



                </Box>
                </LayoutWrapper>
            </React.Fragment>
        );
    }
}


function mapStateToProps(state) {
    const { HausverboteReducer } = state;
    const { HausverboteList, loadingHausverboteList, paginationHausverbote, infosTableHausverbote, search, HausverboteModalSaving, deletingBan } = HausverboteReducer;
    return {
        HausverboteList,
        loadingHausverboteList,
        paginationHausverbote,
        infosTableHausverbote,
        search,
        HausverboteModalSaving,
        deletingBan
    };
}


function mapDispatchToProps(dispatch) {
    return{
        actions: bindActionCreators(HausverboteActions, dispatch)
    };
}



export default connect(mapStateToProps, mapDispatchToProps)(Hausverbote);
