import {CALL_API} from "../../helpers/middleware";
import moment from "moment";

const LustmolcheActions = {
    LOAD_LUSTMOLCHE_REQUEST: 'LOAD_LUSTMOLCHE_REQUEST',
    LOAD_LUSTMOLCHE_SUCCESS: 'LOAD_LUSTMOLCHE_SUCCESS',
    LOAD_LUSTMOLCHE_ERROR: 'LOAD_LUSTMOLCHE_ERROR',

    LOAD_LUSTMOLCHE_TABLE_REQUEST: 'LOAD_LUSTMOLCHE_TABLE_REQUEST',
    LOAD_LUSTMOLCHE_TABLE_SUCCESS: 'LOAD_LUSTMOLCHE_TABLE_SUCCESS',
    LOAD_LUSTMOLCHE_TABLE_ERROR: 'LOAD_LUSTMOLCHE_TABLE_ERROR',
    
    LOAD_LUSTMOLCHE_DETAIL_REQUEST: 'LOAD_LUSTMOLCHE_DETAIL_REQUEST',
    LOAD_LUSTMOLCHE_DETAIL_SUCCESS: 'LOAD_LUSTMOLCHE_DETAIL_SUCCESS',
    LOAD_LUSTMOLCHE_DETAIL_ERROR: 'LOAD_LUSTMOLCHE_DETAIL_ERROR',

    SET_LUSTMOLCHE_TABLE_PAGE: 'SET_LUSTMOLCHE_TABLE_PAGE',
    SET_LUSTMOLCHE_TABLE_INFO: 'SET_LUSTMOLCHE_TABLE_INFO',

    SAVE_LUSTMOLCHE_DETAIL_REQUEST: 'SAVE_LUSTMOLCHE_DETAIL_REQUEST',
    SAVE_LUSTMOLCHE_DETAIL_SUCCESS: 'SAVE_LUSTMOLCHE_DETAIL_SUCCESS',
    SAVE_LUSTMOLCHE_DETAIL_ERROR: 'SAVE_LUSTMOLCHE_DETAIL_ERROR',

    SYNC_LUSTMOLCHE_REQUEST: 'SYNC_LUSTMOLCHE_REQUEST',
    SYNC_LUSTMOLCHE_SUCCESS: 'SYNC_LUSTMOLCHE_SUCCESS',
    SYNC_LUSTMOLCHE_ERROR: 'SYNC_LUSTMOLCHE_ERROR',

    SAVE_LUSTMOLCHE_FILE_NAME_REQUEST: 'SAVE_LUSTMOLCHE_FILE_NAME_REQUEST',
    SAVE_LUSTMOLCHE_FILE_NAME_SUCCESS: 'SAVE_LUSTMOLCHE_FILE_NAME_SUCCESS',
    SAVE_LUSTMOLCHE_FILE_NAME_ERROR: 'SAVE_LUSTMOLCHE_FILE_NAME_ERROR',

    SHOW_LUSTMOLCHE_DETAIL: 'SHOW_LUSTMOLCHE_DETAIL',
    CLEAR_LUSTMOLCHE_DETAIL: 'CLEAR_LUSTMOLCHE_DETAIL',


    SET_SEARCH_TEXT: 'SET_SEARCH_TEXT',
    SHOW_PREVIEW_FILE_MODAL: 'SHOW_PREVIEW_FILE_MODAL',

    DELETE_LUSTMOLCHE_REQUEST : 'DELETE_LUSTMOLCHE_REQUEST',
    DELETE_LUSTMOLCHE_SUCCESS : 'DELETE_LUSTMOLCHE_SUCCESS',
    DELETE_LUSTMOLCHE_ERROR : 'DELETE_LUSTMOLCHE_ERROR',
};

export default LustmolcheActions;



export function loadLustmolche() {
    return {
        [CALL_API]: {
            reqType: 'GET',
            endpoint: 'lustmolche',
            types: [LustmolcheActions.LOAD_LUSTMOLCHE_REQUEST, LustmolcheActions.LOAD_LUSTMOLCHE_SUCCESS, LustmolcheActions.LOAD_LUSTMOLCHE_ERROR]
        }
    };
}


export function loadLustmolcheTable(params = {}) {
    return {
        [CALL_API]: {
            reqType: 'POST',
            endpoint: 'lustmolche/table',
            postData: {
                results: 10,
                ...params,
            },
            types: [LustmolcheActions.LOAD_LUSTMOLCHE_TABLE_REQUEST, LustmolcheActions.LOAD_LUSTMOLCHE_TABLE_SUCCESS, LustmolcheActions.LOAD_LUSTMOLCHE_TABLE_ERROR]
        }
    };
}

export function loadLustmolcheDetail(id) {
    return {
        [CALL_API]: {
            reqType: 'GET',
            endpoint: 'lustmolche/'+id,
            types: [LustmolcheActions.LOAD_LUSTMOLCHE_DETAIL_REQUEST, LustmolcheActions.LOAD_LUSTMOLCHE_DETAIL_SUCCESS, LustmolcheActions.LOAD_LUSTMOLCHE_DETAIL_ERROR]
        }
    };
}



export function saveLustmolcheDetail(KundenID, values) {

    values = {...values, birthday: values.birthday ? moment(values.birthday).format("YYYY-MM-DD") : undefined};

    return {
        [CALL_API]: {
            reqType: 'PUT',
            endpoint: 'lustmolche/'+KundenID,
            postData: values,
            types: [LustmolcheActions.SAVE_LUSTMOLCHE_DETAIL_REQUEST, LustmolcheActions.SAVE_LUSTMOLCHE_DETAIL_SUCCESS, LustmolcheActions.SAVE_LUSTMOLCHE_DETAIL_ERROR]
        }
    };
}

export function deleteLustmolche(LustmolcheID) {
    return {
        [CALL_API]: {
            reqType: 'DELETE',
            endpoint: 'lustmolche/'+LustmolcheID,
            types: [LustmolcheActions.DELETE_LUSTMOLCHE_REQUEST, LustmolcheActions.DELETE_LUSTMOLCHE_SUCCESS, LustmolcheActions.DELETE_LUSTMOLCHE_ERROR]
        }
    }
}





export function showPreviewFileModal(show, file = {}) {
    return {
        type: LustmolcheActions.SHOW_PREVIEW_FILE_MODAL,
        show,
        file
    };
};

export function setLustmolcheTablePage(pager) {
    return {
        type: LustmolcheActions.SET_LUSTMOLCHE_TABLE_PAGE,
        pager
    };
};

export function setLustmolcheTableInfo(infos) {
    return {
        type: LustmolcheActions.SET_LUSTMOLCHE_TABLE_INFO,
        infos
    };
};


export function setSearchText(search) {
    return {
        type: LustmolcheActions.SET_SEARCH_TEXT,
        search
    };
};

export function showLustmolcheDetail(show, id = 0) {
    return {
        type: LustmolcheActions.SHOW_LUSTMOLCHE_DETAIL,
        show,
        id,
    };
}

export function clearLustmolcheDetail() {
    return {
        type: LustmolcheActions.CLEAR_LUSTMOLCHE_DETAIL,
    };
}





