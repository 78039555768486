import {CALL_API} from "../../helpers/middleware";
let FileSaver = require('file-saver');

const CheckInActions = {

    LOAD_CHECKIN_LIST_REQUEST : 'LOAD_CHECKIN_LIST_REQUEST',
    LOAD_CHECKIN_LIST_SUCCESS : 'LOAD_CHECKIN_LIST_SUCCESS',
    LOAD_CHECKIN_LIST_ERROR : 'LOAD_CHECKIN_LIST_ERROR',
    SET_CHECKIN_TABLE_PAGE: 'SET_CHECKIN_TABLE_PAGE',
    SET_CHECKIN_TABLE_INFO: 'SET_CHECKIN_TABLE_INFO',
    SET_SEARCH_TEXT: 'SET_SEARCH_TEXT',
    LOAD_PERSONS_REQUEST: 'LOAD_PERSONS_REQUEST',
    LOAD_PERSONS_SUCCESS: 'LOAD_PERSONS_SUCCESS',
    LOAD_PERSONS_ERROR: 'LOAD_PERSONS_ERROR',
    SHOW_NEW_AGREEMENT: 'SHOW_NEW_AGREEMENT',
    SHOW_SIGN_AGREEMENT: 'SHOW_SIGN_AGREEMENT',
    SHOW_SIGN_AGREEMENT_LUSTRA: 'SHOW_SIGN_AGREEMENT_LUSTRA',
    SHOW_SHOW_AGREEMENT: 'SHOW_SHOW_AGREEMENT',
    SAVE_AGREEMENT_REQUEST: 'SAVE_AGREEMENT_REQUEST',
    SAVE_AGREEMENT_SUCCESS: 'SAVE_AGREEMENT_SUCCESS',
    SAVE_AGREEMENT_ERROR: 'SAVE_AGREEMENT_ERROR',
    SAVE_AGREEMENT_FORM1: 'SAVE_AGREEMENT_FORM1',
    SET_SAVE_AGREEMENT_FORM: 'SET_SAVE_AGREEMENT_FORM',
    LOAD_PERSON_DETAIL_REQUEST: 'LOAD_PERSON_DETAIL_REQUEST',
    LOAD_PERSON_DETAIL_SUCCESS: 'LOAD_PERSON_DETAIL_SUCCESS',
    LOAD_PERSON_DETAIL_ERROR: 'LOAD_PERSON_DETAIL_ERROR',
    SAVE_STORNO_REQUEST: 'SAVE_STORNO_REQUEST',
    SAVE_STORNO_SUCCESS: 'SAVE_STORNO_SUCCESS',
    SAVE_STORNO_ERROR: 'SAVE_STORNO_ERROR',
    HIDE_CONFIRM_FORCE_CHECKIN: 'HIDE_CONFIRM_FORCE_CHECKIN',
    SHOW_UPLOAD_IMAGES_PERSONS: 'SHOW_UPLOAD_IMAGES_PERSONS',

    SIGN_AGREEMENT_REQUEST: 'SIGN_AGREEMENT_REQUEST',
    SIGN_AGREEMENT_SUCCESS: 'SIGN_AGREEMENT_SUCCESS',
    SIGN_AGREEMENT_ERROR: 'SIGN_AGREEMENT_ERROR',

    SIGN_AGREEMENT_LUSTRA_REQUEST: 'SIGN_AGREEMENT_LUSTRA_REQUEST',
    SIGN_AGREEMENT_LUSTRA_SUCCESS: 'SIGN_AGREEMENT_LUSTRA_SUCCESS',
    SIGN_AGREEMENT_LUSTRA_ERROR: 'SIGN_AGREEMENT_LUSTRA_ERROR',

    SAVE_PERSONS_IMAGES_REQUEST: 'SAVE_PERSONS_IMAGES_REQUEST',
    SAVE_PERSONS_IMAGES_SUCCESS: 'SAVE_PERSONS_IMAGES_SUCCESS',
    SAVE_PERSONS_IMAGES_ERROR: 'SAVE_PERSONS_IMAGES_ERROR'

};

export default CheckInActions;


export function LoadCheckInList(params = {}) {
    return {
        [CALL_API]: {
            reqType: 'POST',
            endpoint: 'checkin',
            postData: {
                results: 10,
                ...params,
            },
            types: [CheckInActions.LOAD_CHECKIN_LIST_REQUEST, CheckInActions.LOAD_CHECKIN_LIST_SUCCESS, CheckInActions.LOAD_CHECKIN_LIST_ERROR]
        }
    };
}

export function sendSaveAgreement(values) {
    return {
        [CALL_API]: {
            reqType: 'POST',
            endpoint: 'checkin/agreement',
            postData: values,
            types: [CheckInActions.SAVE_AGREEMENT_REQUEST, CheckInActions.SAVE_AGREEMENT_SUCCESS, CheckInActions.SAVE_AGREEMENT_ERROR]
        }
    };
}

export function sendSignAgreement(AgreementID, data) {
    return {
        [CALL_API]: {
            reqType: 'POST',
            endpoint: 'checkin/agreement/'+AgreementID+'/sign',
            postData: data,
            raw:true,
            types: [CheckInActions.SIGN_AGREEMENT_REQUEST, CheckInActions.SIGN_AGREEMENT_SUCCESS, CheckInActions.SIGN_AGREEMENT_ERROR]
        }
    };
}

export function sendSignAgreementLustra(AgreementID, data) {
    return {
        [CALL_API]: {
            reqType: 'POST',
            endpoint: 'checkin/agreement/'+AgreementID+'/sign/lustra',
            postData: data,
            raw:true,
            types: [CheckInActions.SIGN_AGREEMENT_LUSTRA_REQUEST, CheckInActions.SIGN_AGREEMENT_LUSTRA_SUCCESS, CheckInActions.SIGN_AGREEMENT_LUSTRA_ERROR]
        }
    };
}

export function sendSaveImagesPersons(PersonID, data) {
    return {
        [CALL_API]: {
            reqType: 'PUT',
            endpoint: 'checkin/person/'+PersonID+'/personimages',
            postData: data,
            types: [CheckInActions.SAVE_PERSONS_IMAGES_REQUEST, CheckInActions.SAVE_PERSONS_IMAGES_SUCCESS, CheckInActions.SAVE_PERSONS_IMAGES_ERROR]
        }
    };
}


export function hideConfirmForceCheckin() {
    return {
        type: CheckInActions.HIDE_CONFIRM_FORCE_CHECKIN
    }
}



export function sendStornoAgreement(AgreementID) {
    return {
        [CALL_API]: {
            reqType: 'GET',
            endpoint: 'checkin/agreement/'+AgreementID+'/storno',
            raw: true,
            types: [CheckInActions.SAVE_STORNO_REQUEST, CheckInActions.SAVE_STORNO_SUCCESS, CheckInActions.SAVE_STORNO_ERROR]
        }
    };
}

function loadAgreementPDF(AgreementID) {
    return {
        [CALL_API]: {
            reqType: 'GET',
            endpoint: 'checkin/pdf/'+AgreementID,
            raw: true,
            types: [null, null, null]
        }
    };
}


function showPDF(data, AgreementID) {
    if (window.navigator && window.navigator.msSaveOrOpenBlob) {
        window.navigator.msSaveOrOpenBlob(data);
    }
    else {
        FileSaver.saveAs(data, 'Vertrag_'+AgreementID+'.pdf');
    }

    return { type: null };
}



/*export function saveAgreement(values) {
    return (dispatch) => {

        dispatch(sendSaveAgreement(values)).then((res) => {
            if(res) {
                return res.response.data.AgreementID;
            } else {
                return false;
            }
        }).then((AgreementID) => { if(AgreementID) { dispatch(loadAgreementPDF(AgreementID)).then((res) => {if(res) { return res.response.blob(); } else { return false; }
        //}).then((blob) => { if(blob) { dispatch(showPDF(blob, AgreementID)); } }) }});
        }).then((blob) => { if(blob) { /*dispatch(showShowAgreement(true, AgreementID, true));* / dispatch(showUploadImagesPersons(true, AgreementID, true));  } }) }});
    }
}*/


export function saveAgreement(values) {
    return (dispatch) => {

        dispatch(sendSaveAgreement(values)).then((res) => {
            if(res) {
                return res.response.data.AgreementID;
            } else {
                return false;
            }
        }).then((AgreementID) => { if(AgreementID) { /*dispatch(showShowAgreement(true, AgreementID, true));*/ dispatch(showUploadImagesPersons(true, AgreementID, values.person, true));  } });
    }
}


export function saveImagesPersons(AgreementID, PersonID, values) {
    return (dispatch) => {
        dispatch(sendSaveImagesPersons(PersonID, values)).then((res) => {
            return res.type === CheckInActions.SAVE_PERSONS_IMAGES_SUCCESS;
        }).then((success) => { if(success) { dispatch(showShowAgreement(true, AgreementID, true));  } }) ;
    }
}

export function showAgreementPDF(AgreementID) {
    return (dispatch) => {
        dispatch(loadAgreementPDF(AgreementID)).then((res) => { if(res) { return res.response.blob(); } else { return false; } }).then((blob) => { if(blob) { dispatch(showPDF(blob, AgreementID)); } });
    }
}

export function setAgreementStorno(AgreementID) {
    return (dispatch) => {

        dispatch(sendStornoAgreement(AgreementID)).then((res) => {
             if(res)
             {
                 return res.response.blob();
             } else {
                 return false;
             //} }).then((blob) => { if(blob) { dispatch(showPDF(blob, AgreementID)); } });
             } }).then((blob) => { if(blob) { dispatch(showShowAgreement(true, AgreementID, false)); } });
    }
}


export function signAgreement(AgreementID, data) {
    return (dispatch) => {

        dispatch(sendSignAgreement(AgreementID, data)).then((res) => {
            //return res.type === CheckInActions.SIGN_AGREEMENT_SUCCESS; }).then((blob) => { if(blob) { dispatch(showShowAgreement(true, AgreementID, false)); } });
            return res.type === CheckInActions.SIGN_AGREEMENT_SUCCESS; }).then((res) => { if(res) { dispatch(showSignAgreementLustra(true, AgreementID)); } });



    }
}


export function signAgreementLustra(AgreementID, data) {
    return (dispatch) => {

        dispatch(sendSignAgreementLustra(AgreementID, data)).then((res) => {
            return res.type === CheckInActions.SIGN_AGREEMENT_LUSTRA_SUCCESS; }).then((blob) => { if(blob) { dispatch(showShowAgreement(true, AgreementID, false)); } });

    }
}





/*
export function loadPersons() {
    return {
        [CALL_API]: {
            reqType: 'GET',
            endpoint: 'persons',
            types: [CheckInActions.LOAD_PERSONS_REQUEST, CheckInActions.LOAD_PERSONS_SUCCESS, CheckInActions.LOAD_PERSONS_ERROR]
        }
    };
}

export function loadPersonDetail(id) {
    return {
        [CALL_API]: {
            reqType: 'GET',
            endpoint: 'persons/'+id,
            types: [CheckInActions.LOAD_PERSON_DETAIL_REQUEST, CheckInActions.LOAD_PERSON_DETAIL_SUCCESS, CheckInActions.LOAD_PERSON_DETAIL_ERROR]
        }
    };
}
*/


export function setSaveNewAgreementForm(save) {
    return {
        type: CheckInActions.SET_SAVE_AGREEMENT_FORM,
        save
    };
}

export function showNewAgreement(show) {
    return {
        type: CheckInActions.SHOW_NEW_AGREEMENT,
        show
    };
}

export function showSignAgreement(show, AgreementID = 0) {
    return {
        type: CheckInActions.SHOW_SIGN_AGREEMENT,
        show,
        AgreementID
    };
}

export function showSignAgreementLustra(show, AgreementID = 0) {
    return {
        type: CheckInActions.SHOW_SIGN_AGREEMENT_LUSTRA,
        show,
        AgreementID
    };
}

export function showShowAgreement(show, AgreementID = 0, signable = false) {
    return {
        type: CheckInActions.SHOW_SHOW_AGREEMENT,
        show,
        AgreementID,
        signable
    };
}

export function showUploadImagesPersons(show, AgreementID = 0, PersonID = 0, signable = false) {
    return {
        type: CheckInActions.SHOW_UPLOAD_IMAGES_PERSONS,
        show,
        PersonID,
        AgreementID,
        signable
    };
}

export function setCheckInTablePage(pager) {
    return {
        type: CheckInActions.SET_CHECKIN_TABLE_PAGE,
        pager
    };
};

export function setCheckInTableInfo(infos) {
    return {
        type: CheckInActions.SET_CHECKIN_TABLE_INFO,
        infos
    };
};

export function setSearchText(search) {
    return {
        type: CheckInActions.SET_SEARCH_TEXT,
        search
    };
};




