import React, {Component} from "react";
import {connect} from "react-redux";
import {bindActionCreators} from "redux";
import * as PersonenActions from "../../redux/Personen/actions";
import { Input, Button, Table, Tag} from 'antd';
import Box from '../../components/utility/box';
import LayoutWrapper from '../../components/utility/layoutWrapper';
import {history} from "../../redux/store";
import moment from 'moment';
import './list.style.css';
import {CloseCircleOutlined, CloudSyncOutlined, FileExcelOutlined} from "@ant-design/icons";
import PersonenDetail from "./detail";

const Search = Input.Search;
const itemStyle = {margin: '0px'};

let BASE_URL = process.env.REACT_APP_API_URL;
let API_VERSION = process.env.REACT_APP_API_VERSION;

class Personen extends Component {



    componentWillMount() {
        this.props.actions.loadPersonsTable({...this.props.infosTablePerson, search: this.props.search});
        this.state = {search: this.props.search, ExportLoading: false};
    }


    handleTableChange = (pagination, filters, sorter) => {
        const pager = { ...this.props.paginationPerson };
        pager.current = pagination.current;

        this.props.actions.setPersonenTablePage(pager);

        this.props.actions.setPersonenTableInfo({
            results: pagination.pageSize,
            page: pagination.current,
            sortField: sorter.field,
            sortOrder: sorter.order,
            ...filters,
        });

        this.props.actions.loadPersonsTable({
            results: pagination.pageSize,
            page: pagination.current,
            sortField: sorter.field,
            sortOrder: sorter.order,
            ...filters,
            search: this.props.search
        });


    };



    componentWillUpdate(nextProps) {
       if(this.props.search !== nextProps.search) {
            this.props.actions.loadPersonsTable({...nextProps.infosTablePerson, search: nextProps.search});
        }
    }

    onChangeSearch = (e) => {
        this.setState({ search: e.target.value });
    };



    exportCSV = () => {

        this.setState({ExportLoading: true});

        let url = BASE_URL+'api/' + API_VERSION + "/persons/export";
        return fetch(url, {
            method: 'GET',
            headers: {
                'Authorization': `Bearer ${this.props.idToken}`,
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            },
            cache: "no-cache",
        }).then((resp) => {
            return resp.blob();
        }).then((blob) => {
            var fileURL = URL.createObjectURL(blob);
            //window.open(fileURL);
            var fileLink = document.createElement('a');
            fileLink.href = fileURL;
            fileLink.download = "customers.csv";
            fileLink.click();
            this.setState({ExportLoading: false});
        });
    };



    render() {

        const columns = [
            {
                title: 'KDNR',
                dataIndex: 'kdnr',
                key: 'kdnr',
                sorter: true,
                sortOrder: this.props.infosTablePerson.sortField === 'kdnr' && this.props.infosTablePerson.sortOrder
            }, {
                title: 'Name',
                dataIndex: 'lastname',
                key: 'lastname',
                sorter: true,
                sortOrder: this.props.infosTablePerson.sortField === 'lastname' && this.props.infosTablePerson.sortOrder
            }, {
                title: 'Vorname',
                dataIndex: 'firstname',
                key: 'firstname',
                sorter: true,
                sortOrder: this.props.infosTablePerson.sortField === 'firstname' && this.props.infosTablePerson.sortOrder
            }, {
                title: 'Künstlername',
                dataIndex: 'stagename',
                key: 'stagename',
                sorter: true,
                sortOrder: this.props.infosTablePerson.sortField === 'stagename' && this.props.infosTablePerson.sortOrder
            }, {
                title: 'Gesundheitszeugnis gültig bis',
                dataIndex: 'healthCertificate',
                key: 'healthCertificate',
                render: (text) => {return text ? moment(text).format('DD.MM.YYYY') : ''},
                sorter: true,
                sortOrder: this.props.infosTablePerson.sortField === 'healthCertificate' && this.props.infosTablePerson.sortOrder
            }, {
                title: 'Tags',
                dataIndex: 'tags',
                key: 'tags',
                sorter: false,
                render: tags => (
                    <span>
                        {tags.map((tag, i) => {

                            return (
                                <Tag color={tag.color} key={i}>
                                    {tag.text.toUpperCase()}
                                </Tag>
                            );
                        })}
                      </span>
                ),
            }
            ];

        const searchClear = this.props.search ? <CloseCircleOutlined onClick={() => {this.props.actions.setSearchText(''); this.setState({ search: '' });}} /> : null;

        return (
            <React.Fragment>
                <PersonenDetail />
                <LayoutWrapper>
                    <Search onChange={this.onChangeSearch} value={this.state.search} prefix={searchClear} placeholder={"Suchen..."} onSearch={(value) => this.props.actions.setSearchText(value)} defaultValue={this.props.search} enterButton style={{width: '400px', minHeight: '0px !important'}} />
                    <Button.Group style={{marginBottom: "10px", marginLeft: "auto"}} >
                        <Button type={"primary"} htmlType={"button"} className={"round"} onClick={() => this.props.actions.syncPersons()} loading={this.props.personsSyncLoading} icon={<CloudSyncOutlined/>}>Synchronisieren</Button>
                        <Button type={"primary"} htmlType={"button"} className={"round"} style={{marginLeft: "10px"}} onClick={() => this.exportCSV()} loading={this.props.ExportLoading} icon={<FileExcelOutlined/>}>CSV Export</Button>
                    </Button.Group>
                    <Box>


                    <Table
                        columns={columns}
                        dataSource={this.props.PersonList}
                        rowClassName={(record, index) => { return 'tableCursor'}}
                        onRow={(record) => ({
                            onClick: () => { this.props.actions.showPersonsDetail(true, record.id); }
                        })}
                        pagination={this.props.paginationPerson}
                        loading={this.props.loadingPersonList}
                        onChange={this.handleTableChange}
                        rowKey={(record => record.kdnr)}
                    />



                </Box>
                </LayoutWrapper>
            </React.Fragment>
        );
    }
}


function mapStateToProps(state) {
    const { PersonsReducer, Auth } = state;
    const { loadingPersonList, PersonList, paginationPerson, infosTablePerson, search, personsSyncLoading } = PersonsReducer;
    const {idToken} = Auth;
    return {
        PersonList,
        loadingPersonList,
        paginationPerson,
        infosTablePerson,
        search,
        personsSyncLoading,
        idToken
    };
}


function mapDispatchToProps(dispatch) {
    return{
        actions: bindActionCreators(PersonenActions, dispatch)
    };
}



export default connect(mapStateToProps, mapDispatchToProps)(Personen);
