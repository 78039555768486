import {CALL_API} from "../../helpers/middleware";
import { history } from '../store';
import authReducer from '../auth/reducer';
let FileSaver = require('file-saver');


const RechnungenActions = {

    LOAD_BILLS_TABLE_REQUEST: 'LOAD_BILLS_TABLE_REQUEST',
    LOAD_BILLS_TABLE_SUCCESS: 'LOAD_BILLS_TABLE_SUCCESS',
    LOAD_BILLS_TABLE_ERROR: 'LOAD_BILLS_TABLE_ERROR',
    
    LOAD_BILLS_DETAIL_REQUEST: 'LOAD_BILLS_DETAIL_REQUEST',
    LOAD_BILLS_DETAIL_SUCCESS: 'LOAD_BILLS_DETAIL_SUCCESS',
    LOAD_BILLS_DETAIL_ERROR: 'LOAD_BILLS_DETAIL_ERROR',

    SET_BILLS_TABLE_PAGE: 'SET_BILLS_TABLE_PAGE',
    SET_BILLS_TABLE_INFO: 'SET_BILLS_TABLE_INFO',

    CREATE_BILLS_REQUEST: 'CREATE_BILLS_REQUEST',
    CREATE_BILLS_SUCCESS: 'CREATE_BILLS_SUCCESS',
    CREATE_BILLS_ERROR: 'CREATE_BILLS_ERROR',


    SET_SEARCH_TEXT: 'SET_SEARCH_TEXT',

    PREVIEW_BILLS_REQUEST: 'PREVIEW_BILLS_REQUEST',
    PREVIEW_BILLS_SUCCESS: 'PREVIEW_BILLS_SUCCESS',
    PREVIEW_BILLS_ERROR: 'PREVIEW_BILLS_ERROR',

    SHOW_PREVIEW_BLOB: 'SHOW_PREVIEW_BLOB',
    HIDE_PREVIEW_BILL_MODAL: 'HIDE_PREVIEW_BILL_MODAL',

    TOGGLE_BILL_PAYED_MODAL: 'TOGGLE_BILL_PAYED_MODAL',

    SAVE_BILL_PAYED_REQUEST: 'SAVE_BILL_PAYED_REQUEST',
    SAVE_BILL_PAYED_SUCCESS: 'SAVE_BILL_PAYED_SUCCESS',
    SAVE_BILL_PAYED_ERROR: 'SAVE_BILL_PAYED_ERROR',
};

export default RechnungenActions;




export function loadRechnungenTable(params = {}) {
    return {
        [CALL_API]: {
            reqType: 'POST',
            endpoint: 'bills/table',
            postData: {
                results: 10,
                ...params,
            },
            types: [RechnungenActions.LOAD_BILLS_TABLE_REQUEST, RechnungenActions.LOAD_BILLS_TABLE_SUCCESS, RechnungenActions.LOAD_BILLS_TABLE_ERROR]
        }
    };
}

/*
export function loadRechnungenDetail(id) {
    return {
        [CALL_API]: {
            reqType: 'GET',
            endpoint: 'bills/'+id,
            types: [RechnungenActions.LOAD_BILLS_DETAIL_REQUEST, RechnungenActions.LOAD_BILLS_DETAIL_SUCCESS, RechnungenActions.LOAD_BILLS_DETAIL_ERROR]
        }
    };
}*/

export function saveBillPayed(rnr, values) {
    return {
        [CALL_API]: {
            reqType: 'POST',
            endpoint: 'bills/'+rnr+'/payed',
            postData: values,
            types: [RechnungenActions.SAVE_BILL_PAYED_REQUEST, RechnungenActions.SAVE_BILL_PAYED_SUCCESS, RechnungenActions.SAVE_BILL_PAYED_ERROR]
        }
    };
}


export function showPreviewBill(previewBLOB) {
    return {
        type: RechnungenActions.SHOW_PREVIEW_BLOB,
        previewBLOB
    };
};


export function loadPreviewRechnung(values) {
    return {
        [CALL_API]: {
            reqType: 'POST',
            endpoint: 'bills/preview',
            postData: values,
            raw: true,
            types: [RechnungenActions.PREVIEW_BILLS_REQUEST, RechnungenActions.PREVIEW_BILLS_SUCCESS, RechnungenActions.PREVIEW_BILLS_ERROR]
        }
    };
}

export function previewRechnung(values) {
    return (dispatch) => {
        dispatch(loadPreviewRechnung(values)).then((res) => {
            if(res) {
                return res.response.blob();
            } else {
                return false;
            }
        }).then((previewBLOB) => { if(previewBLOB) { dispatch(showPreviewBill(previewBLOB)) } });
    }
}


export function createRechnung(values) {
    return {
        [CALL_API]: {
            reqType: 'POST',
            endpoint: 'bills',
            postData: values,
            types: [RechnungenActions.CREATE_BILLS_REQUEST, RechnungenActions.CREATE_BILLS_SUCCESS, RechnungenActions.CREATE_BILLS_ERROR]
        }
    };
}


export function saveRechnung(values) {
    return (dispatch) => {

        dispatch(createRechnung(values)).then((res) => {
            if(res) {
                return res.response.data.rnr;
            } else {
                return false;
            }
        }).then((rnr) => { if(rnr) {
            showBillPDF(rnr);
            history.push("/app/Rechnungen");
        }});
    }
}




export function sendStornoBill(rnr) {
    return {
        [CALL_API]: {
            reqType: 'GET',
            endpoint: 'bills/'+rnr+'/storno',
            raw: true,
            types: [RechnungenActions.CREATE_BILLS_REQUEST, RechnungenActions.CREATE_BILLS_SUCCESS, RechnungenActions.CREATE_BILLS_ERROR]
        }
    };
}


export function showBillPDF(rnr) {
    let fileLink = document.createElement('a');
    fileLink.href = `/app/File/bills/${rnr}`;
    fileLink.target = '_blank';
    document.body.appendChild(fileLink);
    fileLink.click();
    fileLink.remove();
}

export function setBillStorno(rnr) {
    return (dispatch) => {

        dispatch(sendStornoBill(rnr)).then((res) => {
            if(res)
            {
                return true;
            } else {
                return false;
            } }).then((success) => { if(success) { showBillPDF(rnr); } });
    }
}


export function hidePreviewBillModal() {
    return {
        type: RechnungenActions.HIDE_PREVIEW_BILL_MODAL
    };
};

export function showBillPayedModal(rnr, show) {
    return {
        type: RechnungenActions.TOGGLE_BILL_PAYED_MODAL,
        rnr,
        show
    };
};



export function setRechnungenTablePage(pager) {
    return {
        type: RechnungenActions.SET_BILLS_TABLE_PAGE,
        pager
    };
};

export function setRechnungenTableInfo(infos) {
    return {
        type: RechnungenActions.SET_BILLS_TABLE_INFO,
        infos
    };
};

export function setSearchText(search) {
    return {
        type: RechnungenActions.SET_SEARCH_TEXT,
        search
    };
};




