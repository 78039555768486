import React, {Component} from "react";
import {connect} from "react-redux";
import {bindActionCreators} from "redux";
import * as SettingsActions from "../../redux/Settings/actions";
import { Input, Button, DatePicker, Table, Row, Col, Radio, Divider, Modal, Dropdown, Menu} from 'antd';
import { Form } from '@ant-design/compatible';
import Box from '../../components/utility/box';
import Header from '../../components/utility/pageHeader';
import LayoutWrapper from '../../components/utility/layoutWrapper';
import moment from "../RoomPlan/Reservation";
import {CheckOutlined} from "@ant-design/icons";

const formItemLayout = {
    labelCol: {
        xs: { span: 24 },
        sm: { span: 6 },
    },
    wrapperCol: {
        xs: { span: 24 },
        sm: { span: 18 },
    },
};

const FormItem = Form.Item;


class Settings extends Component {

    state = {
        confirmDirty: false
    };

    componentWillMount() {

    }


    handleSubmit = (e) => {
        e.preventDefault();
        this.props.form.validateFieldsAndScroll((err, values) => {
            if (!err) {
                this.props.actions.saveSettings(values);
                //this.props.form.resetFields();
            }
        });
    };

    handleConfirmBlur = (e) => {
        const value = e.target.value;
        this.setState({ confirmDirty: this.state.confirmDirty || !!value });
    };

    compareToFirstPassword = (rule, value, callback) => {
        const form = this.props.form;
        if (value && value !== form.getFieldValue('password')) {
            callback('Die Passwörter stimmen nicht überein!');
        } else {
            callback();
        }
    };

    validateToNextPassword = (rule, value, callback) => {
        const form = this.props.form;
        if (value && this.state.confirmDirty) {
            form.validateFields(['password2'], { force: true });
        }
        callback();
    };



    componentWillReceiveProps(nextProps) {
        if(this.props.settingsSaved === 0 && nextProps.settingsSaved === 1) {
            this.props.form.resetFields();
        }
    }


    render() {
        const { getFieldDecorator } = this.props.form;

        return (
                <LayoutWrapper>
                <Box >
                    <Header>Passwort ändern</Header>
                    <form onSubmit={this.handleSubmit} style={{width: '100%'}}>
                        <FormItem
                            {...formItemLayout}
                            label={"Neues Passwort"}
                            required={false}
                            key={'newpw'}
                        >
                            {getFieldDecorator('password', {
                                rules: [{
                                    required: true,
                                    message: "Bitte geben Sie ein Passwort ein.",
                                }, {
                                    validator: this.validateToNextPassword,
                                }],
                            })(
                                <Input style={{maxWidth: '500px'}} type={"password"} placeholder={"Neues Passwort eingeben"}/>
                            )}
                        </FormItem>
                        <FormItem
                        {...formItemLayout}
                        label={"Neues Passwort wiederholen"}
                        required={false}
                        key={'newpw2'}
                        >
                        {getFieldDecorator('password2', {
                            rules: [{
                                required: true,
                                message: "Bitte geben Sie ein Passwort ein.",
                            }, {
                                validator: this.compareToFirstPassword,
                            }],
                        })(
                            <Input style={{maxWidth: '500px'}} type={"password"} onBlur={this.handleConfirmBlur} placeholder={"Neues Passwort wiederholen"}/>
                        )}
                    </FormItem>
                        <Button disabled={this.props.settingsSaving} loading={this.props.settingsSaving} type={"primary"} style={{float:'right'}} htmlType={"submit"} icon={<CheckOutlined/>}>Speichern</Button>
                    </form>




                </Box>
                </LayoutWrapper>
        );
    }
}


function mapStateToProps(state) {
    const { SettingsReducer } = state;
    const { settingsSaving, settingsSaved } = SettingsReducer;
    return {
        settingsSaving,
        settingsSaved
    };
}


function mapDispatchToProps(dispatch) {
    return{
        actions: bindActionCreators(SettingsActions, dispatch)
    };
}

const FormSettings = Form.create()(Settings);

export default connect(mapStateToProps, mapDispatchToProps)(FormSettings);
