import {CALL_API} from "../../helpers/middleware";

const SettingsActions = {

    SAVE_PASSWORD_REQUEST: 'SAVE_PASSWORD_REQUEST',
    SAVE_PASSWORD_SUCCESS: 'SAVE_PASSWORD_SUCCESS',
    SAVE_PASSWORD_ERROR: 'SAVE_PASSWORD_ERROR'
};

export default SettingsActions;



export function saveSettings(values) {
    return {
        [CALL_API]: {
            reqType: 'POST',
            endpoint: 'settings',
            postData: values,
            types: [SettingsActions.SAVE_PASSWORD_REQUEST, SettingsActions.SAVE_PASSWORD_SUCCESS, SettingsActions.SAVE_PASSWORD_ERROR]
        }
    };
}


