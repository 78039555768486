import React, {Component} from "react";
import {connect} from "react-redux";
import {bindActionCreators} from "redux";
import * as CheckInActions from "../redux/CheckIn/actions";
import { Button, Modal, Switch } from 'antd';
import '../containers/CheckIn/list.style.css';
import SignatureCanvas from 'react-signature-canvas';
import {CloseOutlined, CheckOutlined, DeleteOutlined} from '@ant-design/icons';


class SignAgreement extends Component {

    sigCanvas = {};

    //state = { signCondom: false, signRental: false, signImageRights: false, error: false };
    state = { signCondom: false, signRental: false, signYouthProtection: false, error: false };

    componentWillMount() {
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if(prevProps.signAgreementVisible && !this.props.signAgreementVisible) {
            this.sigCanvas.clear();
           // this.setState({ signCondom: false, signRental: false, signImageRights: false, error: false });
            this.setState({ signCondom: false, signRental: false, signYouthProtection: false, error: false });
        }
    }

    handleSubmit = (e) => {
        if(this.state.signRental && this.state.signCondom && this.state.signYouthProtection) {
            this.setState({error: false});
            //signImageRights: this.state.signImageRights,
            const data = {
                signRental : true,
                sign: this.sigCanvas.toDataURL('image/png')
            };
            this.props.actions.signAgreement(this.props.actAgreementID, data);
        } else {
            this.setState({error: 'Mietvertrag, Kondomzwang und Jugenschutz müssen unterschrieben werden!'});
        }
    };
    handleCancel = () => {
        this.props.actions.showSignAgreement(false);
    };


    render() {
        return (
            <Modal
                title="Vertrag unterschreiben (Kunde)"
                visible={this.props.signAgreementVisible}
                onOk={this.handleSubmit}
                onCancel={this.handleCancel}
                okText={"Vertrag unterschreiben"}
                cancelText={"Abbrechen"}
                width={"750px"}
                confirmLoading={this.props.signAgreementSaving}
            >

                <div className={"signAgreement-selection"}>
                    <label>Mietvertrag unterschreiben</label>
                    <Switch
                        checkedChildren={<CheckOutlined />}
                        unCheckedChildren={<CloseOutlined />}
                        checked={this.state.signRental}
                        onClick={() => this.setState({signRental: !this.state.signRental})}
                    />
                </div>

                <div className={"signAgreement-selection"}>
                    <label>Kondomzwang unterschreiben</label>
                    <Switch
                        checkedChildren={<CheckOutlined />}
                        unCheckedChildren={<CloseOutlined />}
                        checked={this.state.signCondom}
                        onClick={() => this.setState({signCondom: !this.state.signCondom})}
                    />
                </div>

                <div className={"signAgreement-selection"}>
                    <label>Jugendschutz unterschreiben</label>
                    <Switch
                        checkedChildren={<CheckOutlined />}
                        unCheckedChildren={<CloseOutlined />}
                        checked={this.state.signYouthProtection}
                        onClick={() => this.setState({signYouthProtection: !this.state.signYouthProtection})}
                    />
                </div>


                {/*<div className={"signAgreement-selection"}>
                    <label>Verienbarung Bildrechte unterschreiben</label>
                    <Switch
                        checkedChildren={<CheckOutlined />}
                        unCheckedChildren={<CloseOutlined />}
                        checked={this.state.signImageRights}
                        onClick={() => this.setState({signImageRights: !this.state.signImageRights})}
                    />
                </div>*/}
                <br/>
                <p className={"signAgreement-error"}>{this.state.error}</p>
                <Button icon={<DeleteOutlined/>} className={"signResetBtn"} onClick={() => this.sigCanvas.clear()}>Löschen</Button>
                <div className={"signContainer"}>
                    <SignatureCanvas ref={(ref) => { this.sigCanvas = ref }} canvasProps={{width: 700, height: 300, className: 'sigCanvas'}} />
                </div>
            </Modal>
        );
    }
}


function mapStateToProps(state) {
    const { CheckInReducer } = state;
    const { signAgreementVisible, actAgreementID, signAgreementSaving } = CheckInReducer;
    return {
        signAgreementVisible,
        actAgreementID,
        signAgreementSaving
    };
}


function mapDispatchToProps(dispatch) {
    return{
        actions: bindActionCreators(CheckInActions, dispatch)
    };
}



export default connect(mapStateToProps, mapDispatchToProps)(SignAgreement);
