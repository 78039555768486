import SettingsActions from "./actions";


const initState = {
    settingsSaved: 0,
    settingsSaving: false
};

export default function SettingsReducer(state = initState, action) {
  switch (action.type) {
      case SettingsActions.SAVE_PASSWORD_REQUEST:
        return Object.assign({}, state, {
            settingsSaving: true,
            settingsSaved: 0
        });
      case SettingsActions.SAVE_PASSWORD_SUCCESS:
          return Object.assign({}, state, {
              settingsSaving: false,
              settingsSaved: 1
          });
      case SettingsActions.SAVE_PASSWORD_ERROR:
          return Object.assign({}, state, {
              settingsSaving: false,
              settingsSaved: -1
          });
    default:
      return state;
  }
}

